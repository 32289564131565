import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarStatus,
  editarStatus,
  buscarStatus,
} from "../../../../api/pacientes";
import { toast } from "react-toastify";

class PacientesStatus extends Component {
  state = {
    formulario: [{ type: "text", name: "status", label: "Status" }],
    status: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.status_id) {
      var objStatus = await buscarStatus(
        clinica._id,
        this.props.match.params.status_id,
      );
      this.setState({ cadastro: false, status: objStatus, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  editaStatus(objeto) {
    this.setState({ await: true });
    editarStatus(objeto).then((r) => {
      if (r) {
        toast.success("Status editado com sucesso");
        this.props.history.goBack();
      } else {
        toast.error("Infelizmente não foi possível salvar o status!");
      }
    });
  }

  salvarStatus(objeto) {
    const { clinica } = this.props;

    this.setState({ await: true });
    cadastrarStatus(clinica, objeto.status).then((r) => {
      if (r) {
        toast.success("Status cadastrado com sucesso");
        this.props.history.goBack();
      } else {
        toast.error("Infelizmente não foi possível criar o status!");
      }
    });
  }

  render() {
    return (
      <div>
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.status}
            action={
              this.state.cadastro
                ? this.salvarStatus.bind(this)
                : this.editaStatus.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(PacientesStatus);
