import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  buscarTipoPagamento,
  editarTipoPagamento,
  cadastrarTipoPagamento,
} from "../../../../api/formaPagamento";
import { toast } from "react-toastify";

class FormaPagamentoCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "tipo", label: "Tipo Pagamento" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    formaPagamento: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;
    if (this.props.match.params.formaPagamento_id) {
      var objFormaPagamento = await buscarTipoPagamento(
        clinica._id,
        this.props.match.params.formaPagamento_id,
      );
      this.setState({
        cadastro: false,
        formaPagamento: objFormaPagamento,
        await: false,
      });
    } else {
      this.setState({ await: false });
    }
  }

  editaFormaPagamento(objeto) {
    editarTipoPagamento(objeto).then((response) => {
      if (response) {
        toast.success("Tipo de pagamento editado com sucesso");
        this.props.history.goBack();
      } else {
        toast.error(
          "Infelizmente não foi possível salvar o tipo de pagamento!",
        );
      }
    });
  }

  salvarFormaPagamento(objeto) {
    const { clinica } = this.props;
    cadastrarTipoPagamento(clinica, objeto).then((response) => {
      if (response) {
        toast.success("Tipo de pagamento editado com sucesso");
        this.props.history.goBack();
      } else {
        toast.error(
          "Infelizmente não foi possível salvar o tipo de pagamento!",
        );
      }
    });
  }

  render() {
    return (
      <div>
        <Paper>
          {!this.state.await && (
            <FormComponent
              {...this.props}
              inputs={this.state.formulario}
              objeto={this.state.formaPagamento}
              action={
                this.state.cadastro
                  ? this.salvarFormaPagamento.bind(this)
                  : this.editaFormaPagamento.bind(this)
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(FormaPagamentoCadastro);
