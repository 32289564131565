import React, { Component } from "react";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { styles } from "./ClinicUsersStyles";

import { fetchUsers, clearUser } from "../../../actions/users";

import UsersList from "../../lists/UsersList.jsx";
import FormCadastrarUsuario from "../usuarios/FormCadastrarUsuario";
import PermissionsModal from "../../forms/clinic/modals/PermissionsModal";

import Modal from "../../modals/Modal";
import Paper from "@material-ui/core/Paper";
import { Dialog, DialogContent, Button, DialogTitle } from "@material-ui/core";

class ClinicUsers extends Component {
  constructor(props) {
    super(props);

    this.onOpenNewUserModal = this.onOpenNewUserModal.bind(this);
    this.onCloseNewUserModal = this.onCloseNewUserModal.bind(this);
    this.onOpenPermissionsModal = this.onOpenPermissionsModal.bind(this);
    this.onClosePermissionsModal = this.onClosePermissionsModal.bind(this);

    this.renderList = this.renderList.bind(this);

    this.state = {
      users: [],
      newUserModal: false,
      permissionsModal: false,
      idPermissions: null,
    };
  }

  componentDidMount() {
    const { fetchUsers, clinic } = this.props;

    clearUser();
    // action the fetch all the users of he current logged in clinic
    if (clinic) {
      fetchUsers((res) => {
        this.setState({ ...this.state, users: res });
      });
    }
  }

  onOpenNewUserModal() {
    this.setState({
      newUserModal: true,
    });
  }

  onCloseNewUserModal() {
    this.setState({
      newUserModal: false,
    });
  }

  onOpenPermissionsModal(id) {
    this.setState({
      permissionsModal: true,
      idPermissions: id,
    });
  }

  onClosePermissionsModal() {
    this.setState({
      permissionsModal: false,
    });
  }

  renderList() {
    // the clinic users are mapped from the redux reducer to the component props in the mapStateToProps function below
    const { match, history } = this.props;

    // if there are clinic users, it renders a list of them...
    if (this.state.users && this.state.users.length > 0) {
      return (
        <UsersList
          users={this.state.users}
          match={match}
          history={history}
          onClick={this.onOpenPermissionsModal}
        />
      );
    }

    // if not, it renders "loading"
    return <div className={css(styles.loading)}>Carregando...</div>;
  }

  render() {
    return (
      <Paper style={{ padding: "16px" }}>
        <div style={{ textAlign: "right" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onOpenNewUserModal}
          >
            Novo
          </Button>
        </div>
        <div style={{ marginTop: "16px" }}>{this.renderList()}</div>

        <Modal
          isOpen={this.state.permissionsModal}
          header="Permissões de Usuário"
          adjustStyle={styles.permissionsModal}
        >
          <PermissionsModal
            onCancel={this.onClosePermissionsModal}
            userId={this.state.idPermissions}
          />
        </Modal>

        <Dialog
          open={this.state.newUserModal}
          onClose={this.onCloseNewUserModal}
        >
          <DialogTitle>Novo usuário</DialogTitle>
          <DialogContent>
            <FormCadastrarUsuario onCancel={this.onCloseNewUserModal} />
          </DialogContent>
        </Dialog>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    //user: state.auth.user,
    clinic: state.auth.clinic,
    clinicUsers: state.clinicConfig.usersById,
  };
}

export default connect(mapStateToProps, { fetchUsers })(ClinicUsers);
