import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { CirclePicker } from "react-color";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import {
  buscarServico,
  buscarTabela,
  cadastrarServico,
  editarServico,
} from "../../../../api/servicos";
import { gruposProcedimentosEnum } from "../../../../utils/teeth";

export default function FormCadastroServico({ history, match }) {
  const { tabela_id, servico_id } = match.params;

  const [form, setForm] = useState({
    description: "",
    value: "",
    useIndice: false,
    procedure_type: "",
    observation: "",
    color: "",
    active: true,
  });
  const [formErrors, setFormErrors] = useState({
    description: false,
    value: false,
    procedure_type: false,
    finalValue: false,
  });
  const [tabela, setTabela] = useState({ indice: 0 });

  useEffect(() => {
    const fetchData = async () => {
      const result = await buscarTabela(tabela_id);
      if (result) {
        setTabela(result);
      }
      if (servico_id) {
        const service = await buscarServico(servico_id);
        if (service) setForm(service);
      }
    };
    fetchData();
  }, []);

  const handleChangeColor = (color, event) => {
    setForm({ ...form, color: color.hex });
  };

  const onSubmit = async () => {
    const errors = {};

    if (!form.description) errors.description = true;
    if (!form.value) errors.value = true;
    if (!form.finalValue) errors.finalValue = true;
    if (!form.procedure_type) errors.procedure_type = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    form.value = parseFloat(form.value);
    form.tabelaServico = match.params.tabela_id;

    let result;

    if (servico_id) {
      form._id = servico_id;
      result = await editarServico(form);
    } else {
      result = await cadastrarServico(form);
    }

    if (result) {
      toast.success("Serviço cadastrado com sucesso");
      history.goBack();
    } else {
      toast.error("Erro ao salvar serviço");
    }
  };

  const handleChangeValue = (value) => {
    let finalValue = value;
    if (form.useIndice && tabela.indice)
      finalValue = value + (tabela.indice / 100) * value;

    setForm({ ...form, value, finalValue });
  };

  const handleChangeUseIndice = (value) => {
    let finalValue = form.finalValue;
    if (value) finalValue = form.value + (tabela.indice / 100) * form.value;

    setForm({ ...form, useIndice: value, finalValue });
  };

  return (
    <Container
      maxWidth="sm"
      component={Paper}
      elevation={0}
      style={{ padding: "16px" }}
    >
      <Typography variant="h6">Serviço</Typography>
      <TextField
        label="Descrição"
        type="text"
        value={form.description}
        onChange={(e) => setForm({ ...form, description: e.target.value })}
        error={formErrors.description}
        required
        fullWidth
      />
      <NumberFormat
        label="Valor do serviço"
        fullWidth
        customInput={TextField}
        thousandSeparator="."
        decimalSeparator=","
        prefix={"R$"}
        value={form.value}
        error={formErrors.value}
        onValueChange={(value) => handleChangeValue(value.floatValue)}
      />
      <NumberFormat
        label="Valor final do serviço"
        fullWidth
        customInput={TextField}
        thousandSeparator="."
        decimalSeparator=","
        prefix={"R$"}
        value={form.finalValue}
        error={formErrors.finalValue}
        onValueChange={(value) =>
          setForm({ ...form, finalValue: value.floatValue })
        }
        disabled={form.useIndice}
      />
      <FormControl fullWidth>
        <InputLabel>Código do odontograma</InputLabel>
        <Select
          value={form.procedure_type}
          onChange={(e) => setForm({ ...form, procedure_type: e.target.value })}
          error={formErrors.procedure_type}
        >
          <MenuItem value="">
            <em>Nenhum</em>
          </MenuItem>
          {gruposProcedimentosEnum.map((procedure_type) => (
            <MenuItem key={procedure_type.name} value={procedure_type.name}>
              {procedure_type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Observação"
        type="text"
        value={form.observation}
        onChange={(e) => setForm({ ...form, observation: e.target.value })}
        fullWidth
      />
      <div style={{ marginTop: "12px", marginBottom: "12px" }}>
        <InputLabel>Cor odontograma</InputLabel>
        <div style={{ marginTop: "8px" }}>
          <CirclePicker
            color={form.color}
            onChangeComplete={handleChangeColor}
          />
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={form.useIndice}
            color="primary"
            onChange={(e) => handleChangeUseIndice(e.target.checked)}
          />
        }
        label="Usar índice para calcular preço final"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={form.active}
            color="primary"
            onChange={(e) => setForm({ ...form, active: e.target.checked })}
          />
        }
        label="Ativo"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={onSubmit}
        >
          Salvar
        </Button>
      </div>
    </Container>
  );
}
