import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  buscarDescricao,
  editarDescricao,
  cadastrarDescricao,
} from "../../../../api/contasPagar";

class DescricaoCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "descricao", label: "Descrição" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    descricao: "",
    cadastro: true,
    await: true,
    buttonDisabled: false,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.descricao_id) {
      var objDescricao = await buscarDescricao(
        clinica._id,
        this.props.match.params.descricao_id,
      );
      this.setState({ cadastro: false, descricao: objDescricao, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  editaDescricao(objeto) {
    this.setState({ buttonDisabled: true });
    editarDescricao(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Descrição editado com sucesso",
              abrir: true,
            },
          });
           this.props.history.goBack()
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a descrição!",
              abrir: true,
            },
          });
        }
      })
      .then(() =>
        this.setState({ buttonDisabled: false })
      );
  }

  salvarDescricao(objeto) {
    this.setState({ buttonDisabled: true });
    const { clinica } = this.props;
    cadastrarDescricao(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Descrição cadastrada com sucesso",
              abrir: true,
            },
          });
        this.props.history.goBack()
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a descrição!",
              abrir: true,
            },
          });
        }
      })
      .then(() =>
        this.setState({ buttonDisabled: false })
      );
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
            onClose={() => this.setState({ alerta: { ...this.state.alerta, abrir: false } })}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.descricao}
            disabledAction={this.state.buttonDisabled}
            action={
              this.state.cadastro
                ? this.salvarDescricao.bind(this)
                : this.editaDescricao.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DescricaoCadastro);
