import React, { useState } from "react";
import { useEffect } from "react";
import { getClient } from "../../../../api/clientes";
import TableComponent from "../../../table/TableComponent";
import { Box, Typography } from "@material-ui/core";

export default function View({ match }) {
  const { clientId } = match.params;
  const [client, setClient] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchClient = async () => {
      if (clientId) {
        try {
          const { data } = await getClient(clientId);
          if (data) {
            setClient(data);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    fetchClient();
  }, [clientId]);

  const colunas = [
    { label: "Nome", name: "name" },
    { label: "Email", name: "email" },
  ];

  return !isLoading ? (
    !client ? (
      <Box>
        <Typography variant="h5">Clínica não encontrada</Typography>
      </Box>
    ) : (
      <>
        <Box>
          <Typography variant="h6">{client.clinic.name}</Typography>
        </Box>
        {client.users?.length ? (
          <Box marginTop="16px">
            <TableComponent
              titulo="Usuários"
              objetos={client.users}
              colunas={colunas}
            />
          </Box>
        ) : null}
      </>
    )
  ) : null;
}
