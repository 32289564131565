import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Paper, Typography, Button, TextField } from "@material-ui/core";
import { createUserPlatform } from "../../../actions/users";

function FormUsuarioPlataforma({ createUserPlatform }) {
  const [form, setForm] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    createUserPlatform(montaObjetoUsuarioPlataforma(), (res) => {
      console.log(res);
    });
  };

  const montaObjetoUsuarioPlataforma = () => {
    return {
      name: form.nome,
      telephone: [
        {
          name: "Principal",
          value: form.telefone,
        },
      ],
      email: form.email,
      password: form.senha,
    };
  };

  return (
    <Paper
      variant="outlined"
      style={{
        padding: "24px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%",
        maxWidth: "400px",
        textAlign: "center",
      }}
    >
      <Typography variant="h6"> Cadastro de usuário plataforma </Typography>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Telefone"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, telefone: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Senha"
            type="password"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, senha: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px", textAlign: "right" }}>
          <Button variant="contained" color="primary" type="submit">
            Inserir
          </Button>
        </div>
      </form>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { createUserPlatform })(
  FormUsuarioPlataforma,
);
