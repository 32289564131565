import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import {
  buscarProteticos,
  buscarServico,
  buscarTabelaPrecoProtetico,
  editarServico,
  inserirServico,
} from "../../../../api/proteticos";
import { buscarPacientes } from "../../../../api/pacientes";
import { buscarDentistas } from "../../../../api/dentistas";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import NumberFormat from "react-number-format";

function FormProteticosServicos({ match, history }) {
  const [proteticos, setProteticos] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [dentistas, setDentistas] = useState([]);
  const [tabelaPreco, setTabelaPreco] = useState([]);
  const [form, setForm] = useState({
    protetico: null,
    paciente: null,
    dentista: null,
    itemTabela: null,
    valor: null,
    dataSolicitacao: null,
    dataRetorno: null,
    dataPagamento: null,
    dataPrevista: null,
    observacao: "",
    dentes: "",
  });
  const [formErros, setFormErros] = useState({
    protetico: false,
    dataSolicitacao: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const listaProteticos = await buscarProteticos();
      const listaPacientes = await buscarPacientes();
      const listaDentistas = await buscarDentistas();

      const verificaEdicaoCadastro = async () => {
        if (match.params.id) {
          const servico = await buscarServico(match.params.id);
          if (!servico) return;
          const servicoForm = {
            _id: servico.servicos._id,
            protetico: { _id: servico._id, nome: servico.nome },
            paciente: servico.paciente ?? null,
            dentista: servico.dentista ?? null,
            itemTabela: servico.tabela ?? null,
            valor: servico.servicos?.valor ?? null,
            dataSolicitacao: servico.servicos?.dataSolicitacao,
            dataRetorno: servico.servicos?.dataRetorno ?? null,
            dataPagamento: servico.servicos?.dataPagamento ?? null,
            dataPrevista: servico.servicos?.dataPrevista ?? null,
            observacao: servico.servicos?.observacao ?? "",
            dentes: servico.servicos?.dentes ?? "",
          };
          const protetico = listaProteticos.find(
            (protetico) => protetico._id === servicoForm.protetico._id,
          );
          const tabelaPreco = protetico.tabela;
          setForm(servicoForm);
          setTabelaPreco(tabelaPreco.itens);
        }
      };
      verificaEdicaoCadastro();
      setProteticos(listaProteticos);
      setPacientes(listaPacientes);
      setDentistas(listaDentistas);
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (!validarForm()) return;
    const servicoPersistir = {
      _id: form._id ?? undefined,
      proteticoId: form.protetico._id,
      dataSolicitacao: form.dataSolicitacao,
      itemTabela: form.itemTabela,
      itemTabelaId: form.itemTabela._id,
      valor: form.valor,
      dentistaId: form.dentista?._id ?? undefined,
      pacienteId: form.paciente?._id ?? undefined,
      dataPrevista: form.dataPrevista ?? undefined,
      dataRetorno: form.dataRetorno ?? undefined,
      dataPagamento: form.dataPagamento ?? undefined,
      observacao: form.observacao ?? undefined,
      dentes: form.dentes ?? undefined,
    };
    persistirDados(servicoPersistir);
  };

  const persistirDados = async (servicoPersistir) => {
    if (servicoPersistir._id) {
      await editarServico(servicoPersistir);
    } else {
      await inserirServico(servicoPersistir);
    }
    history.goBack();
  };

  const validarForm = () => {
    const errors = {};
    if (!form.protetico) errors.protetico = true;
    if (!form.dataSolicitacao) errors.dataSolicitacao = true;
    if (!form.itemTabela) errors.itemTabela = true;
    if (Object.keys(errors).length !== 0) {
      setFormErros(errors);
      return false;
    }
    return true;
  };

  const handleChangeProtetico = async (e, newValue) => {
    if (!newValue) {
      setTabelaPreco([]);
      setForm({ ...form, protetico: newValue, itemTabela: null, valor: null });
      return;
    }
    const listaTabelaPreco = await buscarTabelaPrecoProtetico(newValue._id);
    if (listaTabelaPreco) {
      const itensAtivos = listaTabelaPreco.itens.filter(
        (item) => item.ativo === true,
      );
      listaTabelaPreco.itens = itensAtivos;
    }
    setTabelaPreco(listaTabelaPreco.itens);
    setForm({ ...form, protetico: newValue });
  };

  const handleChangeItemTabela = (e, newValue) => {
    if (!newValue) {
      return setForm({ ...form, itemTabela: null, valor: null });
    }
    setForm({ ...form, itemTabela: newValue, valor: newValue.valor });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ padding: "24px", maxWidth: "600px" }}>
        <Typography variant="h6">Cadastro de Serviço</Typography>

        <Autocomplete
          style={{ width: "100%" }}
          value={form.protetico}
          disablePortal
          getOptionLabel={(option) => option.nome}
          options={proteticos}
          onChange={handleChangeProtetico}
          renderInput={(params) => (
            <TextField
              fullWidth
              error={formErros.protetico}
              required
              {...params}
              label="Protético"
            />
          )}
        />

        <Autocomplete
          style={{ width: "100%" }}
          value={form.itemTabela}
          disablePortal
          disabled={!form.protetico ? true : false}
          getOptionLabel={(option) => option.descricao}
          options={tabelaPreco}
          onChange={handleChangeItemTabela}
          renderInput={(params) => (
            <TextField
              fullWidth
              error={formErros.itemTabela}
              required
              {...params}
              label="Serviço"
            />
          )}
        />

        <NumberFormat
          label="Valor"
          error={formErros.valor}
          disabled={!form.itemTabela ? true : false}
          required
          fullWidth
          customInput={TextField}
          prefix="R$"
          value={form.valor}
          onValueChange={(e) => setForm({ ...form, valor: e.floatValue })}
          thousandSeparator="."
          decimalSeparator=","
        />

        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          options={dentistas}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Dentista" />}
          value={form.dentista}
          onChange={(event, newValue) =>
            setForm({ ...form, dentista: newValue })
          }
        />

        <Autocomplete
          style={{ width: "100%" }}
          disablePortal
          options={pacientes}
          getOptionLabel={(option) => `${option.name} ${option.lastName}`}
          renderInput={(params) => <TextField {...params} label="Paciente" />}
          value={form.paciente}
          onChange={(event, newValue) =>
            setForm({ ...form, paciente: newValue })
          }
        />

        <TextField
          fullWidth
          label="Dentes"
          value={form.dentes}
          onChange={(e) => setForm({ ...form, dentes: e.target.value })}
        />

        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={formErros.dataSolicitacao}
            required
            fullWidth
            label="Data de solicitação"
            format="dd/MM/yyyy"
            value={form.dataSolicitacao}
            onChange={(e) => setForm({ ...form, dataSolicitacao: e })}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data prevista de entrega"
            fullWidth
            format="dd/MM/yyyy"
            value={form.dataPrevista}
            onChange={(e) => setForm({ ...form, dataPrevista: e })}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data de entrega"
            fullWidth
            format="dd/MM/yyyy"
            value={form.dataRetorno}
            onChange={(e) => setForm({ ...form, dataRetorno: e })}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data de quitação"
            fullWidth
            format="dd/MM/yyyy"
            value={form.dataPagamento}
            onChange={(e) => setForm({ ...form, dataPagamento: e })}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          fullWidth
          label="Observação"
          value={form.observacao}
          onChange={(e) => setForm({ ...form, observacao: e.target.value })}
        />

        <div style={{ marginTop: "24px", textAlign: "right" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormProteticosServicos;
