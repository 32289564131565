import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  buscarConvenio,
  editarConvenio,
  cadastrarConvenio,
} from "../../../../api/convenios";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";

class ConveniosCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "nomeConvenio", label: "Nome Convenio" },
      { type: "mask", name: "cep", label: "CEP", maskDesign: "99999-999" },
      { type: "text", name: "address", label: "Endereço" },
      { type: "text", name: "city", label: "Cidade" },
      { type: "text", name: "uf", label: "UF" },
      {
        type: "mask",
        name: "telefone",
        label: "Telefone",
        maskDesign: "(99) 99999-9999",
      },
      {
        type: "mask",
        name: "cnpj",
        label: "CNPJ",
        maskDesign: "99.999.999/9999-99",
      },
      { type: "text", name: "inscEstadual", label: "Insc. Estadual" },
      { type: "text", name: "email", label: "E-Mail" },
      { type: "text", name: "site", label: "Site" },
      { type: "number", name: "indice", label: "Índice" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    convenio: "",
    cadastro: true,
    await: true,
    botaoSalvarDesabilitado: false, // Inicialmente o botão de salvar está habilitado
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.convenio_id) {
      var objConvenio = await buscarConvenio(
        clinica._id,
        this.props.match.params.convenio_id,
      );
      this.setState({ cadastro: false, convenio: objConvenio, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  validarCampos(objeto) {
    var error = false;
    var itens = {};

    this.state.formulario.forEach((element, index) => {
      itens[element.name] = index;
      this.state.formulario[index].error = "";
    });

    if (objeto.codigo === "") {
      error = true;
      this.state.formulario[itens.codigo].error = "Campo obrigatório";
    }

    if (objeto.nomeConvenio === "") {
      error = true;
      this.state.formulario[itens.nomeConvenio].error = "Campo obrigatório";
    }

    if (objeto.cnpj === "") {
      error = true;
      this.state.formulario[itens.cnpj].error = "Campo obrigatório";
    }

    if (objeto.cnpj && objeto.cnpj.length < 18) {
      error = true;
      this.state.formulario[itens.cnpj].error = "Campo precisa ter 11 dígitos";
    }

    this.setState({ formulario: this.state.formulario });
    return error;
  }

  async editaConvenio(objeto) {
    const { history } = this.props;

    // Desabilitar o botão de salvar
    this.setState({ botaoSalvarDesabilitado: true });

    if (this.validarCampos(objeto)) {
      return;
    }
    const notification = await editarConvenio(objeto);
    if (notification) {
      toast.success("Convenio editado com sucesso!");
      history.goBack();
    } else {
      toast.error("Infelizmente não foi possível salvar o convenio!");
    }
    this.setState({ botaoSalvarDesabilitado: false });
  }

  async salvarConvenio(objeto) {
    const { clinica, history } = this.props;

    this.setState({ botaoSalvarDesabilitado: true });

    if (this.validarCampos(objeto)) {
      this.setState({
        botaoSalvarDesabilitado: false,
      });
      return;
    }

    const response = await cadastrarConvenio(clinica, objeto);
    if (response) {
      toast.success("Convenio cadastrado com sucesso!");
      history.goBack();
    } else {
      toast.error("Infelizmente não foi possível salvar o convenio!");
    }
    this.setState({ botaoSalvarDesabilitado: false });
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        <Paper style={{ padding: "20px" }}>
          <Typography
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            variant="h6"
          >
            Cadastro de convênio
          </Typography>
          {!this.state.await && (
            <FormComponent
              {...this.props}
              inputs={this.state.formulario}
              objeto={this.state.convenio}
              disabledAction={this.state.botaoSalvarDesabilitado}
              action={
                this.state.cadastro
                  ? this.salvarConvenio.bind(this)
                  : this.editaConvenio.bind(this)
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ConveniosCadastro);
