import React, { Component } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import logoImg from "../assets/logo.png";
import { COLORS } from "../_constants/colors";

import LogInForm from "../forms/LogInForm.jsx";
import ClinicsList from "../lists/ClinicsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    background: COLORS.gradient,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "0 auto 30px auto",
    display: "block",
    maxWidth: "250px",
  },
  card: {
    width: "100%",
    maxWidth: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: theme.spacing(4),
    textAlign: "center",
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const renderForm = () => {
    const { history, auth } = props;

    if (auth.message && auth.message === "loading clinic") {
      return <ClinicsList history={history} />;
    } else {
      return <LogInForm history={history} />;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.card} elevation={3}>
        <img
          style={{
            maxWidth: "200px",
          }}
          className={classes.img}
          src={logoImg}
          alt="Logo Softmanager"
        />
        {renderForm()}
      </Paper>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Login);
