import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarStatusItem,
  editarStatusItem,
  buscarStatusItem,
} from "../../../../api/pacientes";

class PacientesStatusItem extends Component {
  state = {
    formulario: [{ type: "text", name: "titulo", label: "Status" }],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    statusItem: "",
    cadastro: true,
    await: true,
    status_id: this.props.location.state.status_id || "",
  };

  async componentWillMount() {
    const { status_id } = this.state;
    const statusItem_id = this.props.match.params.statusItem_id;

    if (!status_id) {
      this.props.history.push(`/pacientes/config/status`);
    }

    if (statusItem_id) {
      var objStatusItem = await buscarStatusItem(statusItem_id);
      this.setState({
        cadastro: false,
        statusItem: objStatusItem,
        await: false,
      });
    } else {
      this.setState({ await: false });
    }
  }

  editaStatusItem(objeto) {
    editarStatusItem(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Status editado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o status!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  salvarStatusItem(objeto) {
    const { status_id } = this.state;

    cadastrarStatusItem(status_id, objeto.titulo)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Item cadastrado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o item!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.statusItem}
            action={
              this.state.cadastro
                ? this.salvarStatusItem.bind(this)
                : this.editaStatusItem.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(PacientesStatusItem);
