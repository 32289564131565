import axios from "axios";

//Listar convenios
export async function buscarConvenios(clinicaId) {
  var convenios = await axios
    .post("/api/convenios/listar", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return convenios;
}

//Cadastrar anomalias
export async function cadastrarConvenio(clinicaId, objeto) {
  var convenio = await axios
    .post("/api/convenios/cadastrarConvenio", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      nomeConvenio: objeto.nomeConvenio,
      address: objeto.address,
      city: objeto.city,
      uf: objeto.uf,
      cep: objeto.cep,
      telefone: objeto.telefone,
      cnpj: objeto.cnpj,
      inscEstadual: objeto.inscEstadual,
      email: objeto.email,
      site: objeto.site,
      indice: objeto.indice,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return convenio;
}

// Buscar convenios
export async function buscarConvenio(clinicaId, convenioId) {
  var convenio = await axios
    .post("/api/convenios/buscarConvenio", {
      convenio_id: convenioId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return convenio;
}

// Editar convenios
export async function editarConvenio(objConvenio) {
  var convenio = await axios
    .put("/api/convenios/editarConvenio", {
      objConvenio,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return convenio;
}

// Excluir convenio
export async function deletarConvenio(objConvenio, clinic_id) {
  objConvenio.clinic_id = clinic_id;

  var convenio = await axios
    .delete("/api/convenios/deletarConvenio", {
      data: objConvenio,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return convenio;
}
