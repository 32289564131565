import React, { Component } from "react";
import { connect } from "react-redux";
import {
  desvincularTabelaPreco,
  buscarTabelaPrecoProtetico,
} from "../../../../api/proteticos";
import TableComponent from "../../../table/TableComponent";

class TabelaPrecoProteticosRelacao extends Component {
  state = {
    proteticos: [],
    idProtetico: "",
  };

  removeTabelaPreco(objeto) {
    desvincularTabelaPreco(this.state.idProtetico, objeto);
  }

  componentWillMount() {
    buscarTabelaPrecoProtetico(
      this.props.clinica._id,
      this.props.match.params.protetico_id,
    )
      .then((result) => {
        var protetico = [];
        result.tabelaPrecos.length > 0 &&
          result.tabelaPrecos.map((tabelaPreco, index) => {
            var novaTabela = {};
            novaTabela.codigo = tabelaPreco.codigo;
            novaTabela.descricao = tabelaPreco.descricao;
            novaTabela.valorIndice = tabelaPreco.valorIndice;
            novaTabela.valor = tabelaPreco.valor;
            novaTabela._id = tabelaPreco._id;
            protetico.push(novaTabela);
          });
        this.setState({
          proteticos: protetico,
          idProtetico: this.props.match.params.protetico_id,
        });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Tabela Serviços Protéticos",
      colunas = [
        { label: "Codigo", name: "codigo" },
        { label: "Descrição", name: "descricao" },
        { label: "Valor Indice", name: "valorIndice" },
        { label: "Valor", name: "valor" },
      ],
      objetos = this.state.proteticos,
      inserir = {
        nome: "Vincular Servicos",
        link: `/proteticos/vincularTabelaPreco/${this.props.match.params.protetico_id}`,
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.removeTabelaPreco.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(TabelaPrecoProteticosRelacao);
