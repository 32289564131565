import axios from "axios";

//Listar fornecedor
export async function buscarFornecedores(clinicaId) {
  var fornecedor = await axios
    .post("/api/fornecedor/listar", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return fornecedor;
}

//Cadastrar fornecedor
export async function cadastrarFornecedor(clinicaId, objeto) {
  console.log(objeto);
  console.log(clinicaId);
  var fornecedor = await axios
    .post("/api/fornecedor/cadastrarFornecedor", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      nome: objeto.nome,
      telefone: objeto.telefone,
      contato: objeto.contato,
      cnpj: objeto.cnpj,
      email: objeto.email,
      site: objeto.site,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return fornecedor;
}

// Buscar fornecedor
export async function buscarFornecedor(clinicaId, fornecedorId) {
  var fornecedor = await axios
    .post("/api/fornecedor/buscarFornecedor", {
      fornecedor_id: fornecedorId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return fornecedor;
}

// Editar fornecedor
export async function editarFornecedor(objFornecedor) {
  var fornecedor = await axios
    .put("/api/fornecedor/editarFornecedor", {
      objFornecedor,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return fornecedor;
}

// Excluir fornecedor
export async function deletarFornecedor(objFornecedor, clinic_id) {
  objFornecedor.clinic_id = clinic_id;

  var fornecedor = await axios
    .delete("/api/fornecedor/deletarFornecedor", {
      data: objFornecedor,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return fornecedor;
}
