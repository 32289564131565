import React from "react";
import { css } from "aphrodite/no-important";
import { styles } from "./IconStyles";

function IconPerio({ number, tooth, wd, ht, style }) {
  var rootColor = "rgba(234,206,139,0.5)";
  var backRootColor = "rgba(214,185,128,0.5)";
  var tag = null;

  const getSvg = (icon) => {
    let width = wd ? wd : "50px";
    let height = ht ? ht : "110px";

    switch (icon) {
      case "tooth_11":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.3,107.9s8.9-3.9,14.6-16.4c2.5-5.5-3.2-15.8-5.1-21.8-2.9-9-7.4-10.4-10.8-10.4s-7.2,2.5-9.7,8.7S8.8,86.7,10.6,91C14.1,99.3,21.5,107.9,26.3,107.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.3,67.6s-6.1,11.6-5.7,21.7c.1,3.4,7.8,13.6,9.1,13.9S10.3,83.3,17.3,67.6Z"
              fill="#ddd"
            />
            <path
              d="M27.2,65.9c5.4-.5,10.1,11.8,11.6,21.7.7,4.5-7.4,16.6-12.2,13.8-2.5-1.5-2.1-10.3-1.9-16C24.9,75.3,23.5,66.3,27.2,65.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_11":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.7.8c.1,4.8,2,11.9,5.7,30.4,2.1,10.3.8,21.4,3.3,39.2.5,3.7-1-11.1-9.9-11.1S16,68.2,16,68.2,16,17,26.7.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M31.6,58.2s-1.6-27.5-2.9-30c-4.6-9.1-3.8-14.7-2.9-17,0,0-4,6.2-6.8,47C19,58.2,25.3,52.2,31.6,58.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_12":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M36.2,101.5c3.9-3,.1-26.2-1.8-32.2-2.8-9-6.7-10.2-10-10.2s-6.7,3.3-9.2,9.5-7.3,27.9-4.8,31.8S32.3,104.5,36.2,101.5Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M18.3,64.5c-2-1.2-8,20.7-8,32.8,0,3.4,2.1,4.9,1.9,3.5C10.6,89.2,19,64.9,18.3,64.5Z"
              fill="#ddd"
            />
            <path
              d="M24.6,66.6c4.6,0,7.1,8.2,7.4,17.5.1,3.1,1.5,11.5-2.7,13.1-1.8.7-7.9.1-9.6,0-5.5-.2-2.3-8.2-1.6-13.4C19.2,74.5,21.6,66.6,24.6,66.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M31.3,64.3c2.1-1,6.8,20.9,6.1,33-.2,3.3-2.4,4.6-2.1,3.3C37.6,89.2,30.6,64.7,31.3,64.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_12":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M28.5,4.7c0,4.2-1.7,10.4-4.9,26.5-1.8,9-4.5,18.3-6.7,33.8-.5,3.2.8-6.1,8.4-6.1s9,9.7,9,9.7S37.7,18.8,28.5,4.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21,57.5s4.5-22.6,5.4-24.9c3.2-7.6,3.8-13.3,3.3-15.4,0,0,2.9,5.6,2.1,41.3C31.8,58.5,26.8,52.8,21,57.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_13":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.9,106.9s8.9-3.9,14.6-16.4C44,85,38.3,74.7,36.4,68.7c-2.9-9-7.4-10.4-10.8-10.4s-7.2,2.5-9.7,8.7S9.4,85.7,11.2,90C14.7,98.3,22.1,106.9,26.9,106.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.9,66.6s-6.2,11.6-5.7,21.7c.1,3.4,7.8,13.6,9.1,13.9S10.9,82.3,17.9,66.6Z"
              fill="#ddd"
            />
            <path
              d="M27.8,64.9c5.4-.5,10.1,11.8,11.6,21.7.7,4.5-7.4,16.6-12.2,13.8-2.5-1.5-2.1-10.3-2-16C25.5,74.3,24.1,65.3,27.8,64.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_13":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M27.5,2.3c.1,4.8,1.8,9.4,5.5,27.9,2.1,10.3.7,21.4,3.3,39.2.5,3.7-1.1-11.1-9.9-11.1s-9.8,8.9-9.8,8.9S16.9,18.5,27.5,2.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M32.2,57.2s-1.6-27.5-2.9-30c-4.6-9.1-3.8-14.7-2.9-17,0,0-4,6.2-6.8,47C19.6,57.2,25.9,51.2,32.2,57.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_14":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.6,100.9s9.2-.5,15-11.4C43.4,86.2,38,74.8,36,68.4s-5.8-9.6-10.2-9.5-5.7.5-8.2,5.9S8.2,87.2,10.1,90.9C13.6,98.2,21.8,100.9,26.6,100.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M18.5,72.6s-7.5,14-6.3,17.2,7,7.4,8.3,7.8S11.7,83.1,18.5,72.6Z"
              fill="#ddd"
            />
            <path
              d="M27.3,70.8c4.6,0,10.8,5.6,12.3,15.5.7,4.5-7.5,13.2-12.2,10.3-2.5-1.5-5-8.1-5-13.8A18.2,18.2,0,0,1,27.3,70.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_14":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M28.8,1.7l6.8,67s-1-9.8-9.9-9.8-8.6,8.2-8.6,8.2S18.9,17.3,28.8,1.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.2,56.5s-1.9-46-2.8-45.7-4,6.1-6.8,46.9C20.6,57.7,23.3,54.6,30.2,56.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_15":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.8,94.1S17,94.2,10.5,82c-3.3-6.3,7-22.6,7-22.6a42.1,42.1,0,0,1,10-1c8.9.1,9.4,2.1,9.4,2.1S45.5,72.1,43.5,78C39.7,89.2,33.2,95.6,26.8,94.1Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M18.7,62s-8.1,14.6-6.8,18.2,8.2,10,9.6,10.4S11.2,73.7,18.7,62Z"
              fill="#ddd"
            />
            <path
              d="M27.6,60.6c5,0,11.9,6.3,13.5,17.3.8,5.1-8.3,14.7-13.5,11.5-2.7-1.7-5.4-9-5.4-15.4A20.6,20.6,0,0,1,27.6,60.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_15":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M39.9,2.3S26,13.4,24.6,19L16.9,60.6s.1-.6,9-1.2,11.8,2.4,11.8,2.4S29.4,19.4,39.9,2.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.9,56.5c-.5.1,5.4-33.8,7.6-41.3,1.2-4.3,6.9-8.4,6.9-8.4S29,12.6,29.5,56.5C29.5,56.5,28,55.8,21.9,56.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_16":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M6.6,80.1c.6,6.7,5.4,12.2,10.1,12.3,9.1,0,10.4-6.1,10.4-6.1s.8,4.3,6.4,5.4,11.1-.4,12.6-5.3-.9-13.7-1.4-15.2L41.5,58.7H14S6.2,75.1,6.6,80.1Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M13,65.9s4.3-5.2,9.2-3.5,15.7,7.1,19.1,13.3,0,11.1-4.1,12c-.8.1-7.7.3-9.1-7.6s-2.4-.6-2.4-.6-.3,4.9-2,6.8-8.2,3.8-12-.7S8.9,70.9,13,65.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M26.8,79.8s-1.2,10.2-9.9,9c0,0-7.1-1.6-9.3-10.4a13.6,13.6,0,0,0,3.3,10c6.6,7.1,15.7,1.1,16-4.2.2-2.5-.1,3.3,3.1,5.2,8.2,4.8,15.4.1,15.6-3.9a33.1,33.1,0,0,0-.5-8s.4,5.9-2.2,8.4C37.4,91.3,31.2,89.8,26.8,79.8Z"
              fill="#ddd"
            />
            <path
              d="M26.6,59.9l13.1.3c1.5,1.1,4.7,15.3,4.7,15.7S40.7,63.7,26.6,59.9Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_16":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M29.8,19.6c.2-.3,6.2-5.5,6.2-5.5v4.4L32.9,40.2l-5.1,7.7-1.2.9S24.8,38.4,24.8,38s1.3-15.9,1.3-15.9Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M41.3,58.6h-16l-11.4.2s2.2-22.5,4.3-28.2c1.4-3.8,4.8-10.3,8.6-13.8a68.3,68.3,0,0,0,5.2-5s.2,4.3-.1,4.6S27,24.1,26.8,27.5s0,19.4,0,19.4,5.2-5.2,5.7-10.5,2.3-16.2,2.3-16.2,3.6-4.7,4-7.1,1.4,7.8,1.5,17.2C40.3,35.5,41.3,58.6,41.3,58.6Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M20.7,30c-2.4,3.9-1.7,8.2-2.5,18.2-.3,3.7-1.3,9-1.3,9H38.7s.4-36.6-1.2-33.8a6.5,6.5,0,0,0-1.3,3c-1.3,5.2-1.8,14-3.1,15.9-2.8,3.9-7.2,9.3-7.2,9.3a89.9,89.9,0,0,1-2.6-14.3c0-.9,0-9,.7-11.5A52.2,52.2,0,0,0,20.7,30Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_17":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M7,77.2c.6,5.9,4.7,10.7,8.9,10.7,7.9.1,9-5.3,9-5.3s.7,3.8,5.7,4.8,9.6-.4,11-4.7-.9-12-1.2-13.3-2.9-10.9-2.9-10.9h-24S6.6,72.8,7,77.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M12.7,64.8s3.7-4.6,8-3S34.4,68,37.3,73.4s0,9.7-3.6,10.4c-.6.2-6.7.3-7.9-6.5s-2.1-.6-2.1-.6-.3,4.3-1.7,6S14.8,86,11.5,82,9,69.2,12.7,64.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M24.7,76.9s-1.1,8.9-8.7,8c0,0-6.2-1.5-8.1-9.1a11.6,11.6,0,0,0,2.9,8.6c5.7,6.3,13.7,1.1,14-3.6.1-2.2-.1,2.9,2.7,4.5,7.2,4.2,13.4.2,13.6-3.4a25.9,25.9,0,0,0-.5-7s.4,5.2-1.9,7.4C33.9,87,28.5,85.7,24.7,76.9Z"
              fill="#ddd"
            />
            <path
              d="M24.5,59.5l11.5.3c1.3,1,4.1,13.4,4,13.8A23.3,23.3,0,0,0,24.5,59.5Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_17":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M27.3,24.4c.2-.3,5.4-4.9,5.4-4.9v3.9L30,42.4l-4.4,6.7-1.1.8S23,40.8,23,40.4s1-13.8,1-13.8Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M37.3,58.5H13.4s1.9-19.6,3.7-24.6c1.3-3.3,4.3-9,7.6-12s4.5-4.5,4.5-4.5.2,3.9-.1,4.1-4.2,6.8-4.4,9.8,0,16.9,0,16.9,4.5-4.5,5-9.2,2-14.1,2-14.1,2.7-3.4,2.3-9.8c-.1-2.6,6,7.6,6,15.8C40.1,35.5,37.3,58.5,37.3,58.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.3,33.5c-2,3.4-1.4,7.2-2.2,15.9A75.5,75.5,0,0,1,16,57.2H35.1c2.4-9.9,2.8-34.5,2-31.7s-2.3,3.8-2.7,5.4c-1.1,4.6-3.1,11.6-4.3,13.3-2.3,3.4-6.2,8.1-6.2,8.1a80,80,0,0,1-2.2-12.5c-.1-.7-.1-7.9.5-10A37.1,37.1,0,0,0,19.3,33.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_18":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M14.5,58.2s-9,17.4-.5,22.1c6.7,3.7,15.1,2,15.7,0s5.8-.5,7.5-1.1,2.6-.7,2-6.6.7-12.3-.5-13.9-9.6-1.4-9.6-1.4A35.4,35.4,0,0,1,14.5,58.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M26.6,63.6s-1.3,13-5.5,14.2-7.3.5-8-4.4,2.1-10.3,3.1-10.7S24.7,61.8,26.6,63.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M28.7,79a29.4,29.4,0,0,0-3.2,1c-2.2.9-6.1.6-6.1.6S28.6,82.5,28.7,79Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M29.6,76.6c1.5.8,3.9,2.7,4.6,1.2s-3.8-9-4.4-13.3S28.2,75.8,29.6,76.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M27.9,64.3c0-.5,0,13.3-.9,14.1a13.1,13.1,0,0,1-6.3,1.7C18.7,79.9,27.3,79.1,27.9,64.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_18":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M28.9,18.5s1.3-6.2,3-8.2l1.8-2.1.2,5.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M39.5,9.5c-7.8,13.1-1.2,48.9-1.2,48.9s-9.6-.9-12.3.1-10.9.3-11.4.3,1.7-13,3.9-19.4C29.3,7.5,39.5,9.5,39.5,9.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.4,57.1c-.7-5.1,6-31.6,17.6-44.6,0,0-7.2,7.4-6.6,27.4.2,6.4,1.1,15.9,1.1,15.9s-2.8-.3-4.4.6S17.2,57.7,17.4,57.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_21":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M24.3,107.9S15.4,104,9.7,91.5C7.2,86,12.9,75.7,14.8,69.7c2.9-9,7.4-10.4,10.8-10.4s7.2,2.5,9.7,8.7S41.8,86.7,40,91C36.5,99.3,29.1,107.9,24.3,107.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M33.3,67.6S39.5,79.2,39,89.3c-.1,3.4-7.8,13.6-9.1,13.9S40.3,83.3,33.3,67.6Z"
              fill="#ddd"
            />
            <path
              d="M23.4,65.9C18,65.4,13.3,77.7,11.8,87.6c-.7,4.5,7.4,16.6,12.2,13.8,2.5-1.5,2.1-10.3,2-16C25.7,75.3,27.1,66.3,23.4,65.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_21":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M23.9.8c-.1,4.8-2,11.9-5.7,30.4-2.1,10.3-.7,21.4-3.3,39.2-.5,3.7,1.1-11.1,9.9-11.1s9.8,8.9,9.8,8.9S34.6,17,23.9.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19,58.2s1.6-27.5,2.9-30c4.6-9.1,3.8-14.7,2.9-17,0,0,4,6.2,6.8,47C31.6,58.2,25.3,52.2,19,58.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_22":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M14.5,101.5c-4-3-.2-26.2,1.7-32.2,2.8-9,6.7-10.2,10-10.2s6.7,3.3,9.2,9.5,7.3,27.9,4.8,31.8S18.4,104.5,14.5,101.5Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M32.3,64.5c2-1.2,8,20.7,8,32.8,0,3.4-2.1,4.9-1.9,3.5C40,89.2,31.6,64.9,32.3,64.5Z"
              fill="#ddd"
            />
            <path
              d="M26,66.6c-4.6,0-7.1,8.2-7.4,17.5-.1,3.1-1.5,11.5,2.7,13.1,1.8.7,7.9.1,9.6,0,5.5-.2,2.3-8.2,1.6-13.4C31.4,74.5,29.1,66.6,26,66.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M19.3,64.3c-2.1-1-6.8,20.9-6.1,33,.2,3.3,2.4,4.6,2.1,3.3C13,89.2,20,64.7,19.3,64.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_22":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M22.1,4.7c.1,4.2,1.7,10.4,4.9,26.5,1.8,9,4.6,18.3,6.7,33.8.5,3.2-.8-6.1-8.4-6.1s-8.9,9.7-8.9,9.7S12.9,18.8,22.1,4.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M29.6,57.5s-4.4-22.6-5.4-24.9C21,25,20.4,19.3,21,17.2c0,0-3,5.6-2.1,41.3C18.9,58.5,23.8,52.8,29.6,57.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_23":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.7,106.9S16.9,103,11.2,90.5C8.6,85,14.3,74.7,16.3,68.7c2.8-9,7.4-10.4,10.7-10.4s7.2,2.5,9.7,8.7,6.5,18.7,4.7,23C37.9,98.3,30.5,106.9,25.7,106.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34.7,66.6s6.2,11.6,5.8,21.7c-.2,3.4-7.9,13.6-9.2,13.9S41.7,82.3,34.7,66.6Z"
              fill="#ddd"
            />
            <path
              d="M24.8,64.9c-5.4-.5-10.1,11.8-11.6,21.7-.7,4.5,7.4,16.6,12.2,13.8,2.5-1.5,2.1-10.3,2-16C27.1,74.3,28.5,65.3,24.8,64.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_23":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.1,2.3c-.1,4.8-1.8,9.4-5.5,27.9-2.1,10.3-.7,21.4-3.3,39.2-.5,3.7,1.1-11.1,9.9-11.1S36,67.2,36,67.2,35.7,18.5,25.1,2.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M20.4,57.2s1.6-27.5,2.9-30c4.6-9.1,3.8-14.7,2.9-17,0,0,4,6.2,6.8,47C33,57.2,26.7,51.2,20.4,57.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_24":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26,100.9s-9.1-.5-15-11.4c-1.8-3.3,3.6-14.7,5.6-21.1s5.9-9.6,10.2-9.5,5.7.5,8.2,5.9,9.4,22.4,7.6,26.1C39,98.2,30.8,100.9,26,100.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34.1,72.6s7.5,14,6.3,17.2-7,7.4-8.3,7.8S40.9,83.1,34.1,72.6Z"
              fill="#ddd"
            />
            <path
              d="M25.3,70.8c-4.6,0-10.8,5.6-12.3,15.5-.7,4.5,7.5,13.2,12.3,10.3,2.5-1.5,4.9-8.1,4.9-13.8A18.2,18.2,0,0,0,25.3,70.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_24":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M23.8,1.7,17,68.7s1.1-9.8,9.9-9.8,8.6,8.2,8.6,8.2S33.7,17.3,23.8,1.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M22.5,56.5s1.8-46,2.7-45.7,4,6.1,6.8,46.9C32,57.7,29.3,54.6,22.5,56.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_25":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.8,94.1s9.8.1,16.3-12.1c3.3-6.3-7-22.6-7-22.6a42.1,42.1,0,0,0-10-1c-8.9.1-9.4,2.1-9.4,2.1S7.1,72.1,9.1,78C12.9,89.2,19.5,95.6,25.8,94.1Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M33.9,62S42,76.6,40.7,80.2s-8.2,10-9.6,10.4S41.4,73.7,33.9,62Z"
              fill="#ddd"
            />
            <path
              d="M25.1,60.6c-5.1,0-12,6.3-13.6,17.3C10.7,83,19.8,92.6,25,89.4c2.7-1.7,5.4-9,5.4-15.4A20.7,20.7,0,0,0,25.1,60.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_25":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M12.8,2.3S26.6,13.4,28,19l7.7,41.6s-.1-.6-9-1.2-11.8,2.4-11.8,2.4S23.2,19.4,12.8,2.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.7,56.5c.5.1-5.4-33.8-7.6-41.3-1.2-4.3-6.8-8.4-6.8-8.4s7.3,5.8,6.8,49.7C23.1,56.5,24.6,55.8,30.7,56.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_26":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M46,80.1c-.6,6.7-5.4,12.2-10.1,12.3-9.1,0-10.4-6.1-10.4-6.1s-.8,4.3-6.4,5.4S8,91.3,6.5,86.4s.9-13.7,1.4-15.2l3.2-12.5H38.6S46.5,75.1,46,80.1Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M39.6,65.9s-4.3-5.2-9.2-3.5S14.7,69.5,11.3,75.7s0,11.1,4.1,12c.8.1,7.7.3,9.1-7.6s2.4-.6,2.4-.6.3,4.9,2,6.8,8.2,3.8,12-.7S43.7,70.9,39.6,65.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M25.8,79.8s1.2,10.2,9.9,9c0,0,7.2-1.6,9.3-10.4a13.6,13.6,0,0,1-3.3,10c-6.6,7.1-15.7,1.1-16-4.2-.1-2.5.1,3.3-3.1,5.2-8.2,4.8-15.4.1-15.6-3.9a33.1,33.1,0,0,1,.5-8s-.4,5.9,2.3,8.4C15.2,91.3,21.4,89.8,25.8,79.8Z"
              fill="#ddd"
            />
            <path
              d="M26,59.9l-13.1.3c-1.5,1.1-4.7,15.3-4.7,15.7S11.9,63.7,26,59.9Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_26":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M22.8,19.6c-.2-.3-6.2-5.5-6.2-5.5v4.4l3.1,21.7,5.1,7.7,1.2.9S27.8,38.4,27.8,38s-1.3-15.9-1.3-15.9Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M11.3,58.6h16l11.5.2s-2.2-22.5-4.4-28.2c-1.4-3.8-4.8-10.3-8.6-13.8a68.3,68.3,0,0,1-5.2-5s-.2,4.3.2,4.6,4.8,7.8,5,11.2,0,19.4,0,19.4-5.2-5.2-5.7-10.5-2.3-16.2-2.3-16.2-3.6-4.7-4-7.1-1.4,7.8-1.4,17.2C12.3,35.5,11.3,58.6,11.3,58.6Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M31.9,30c2.4,3.9,1.7,8.2,2.5,18.2.4,3.7,1.3,9,1.3,9H13.9s-.4-36.6,1.3-33.8a9.8,9.8,0,0,1,1.3,3c1.2,5.2,1.7,14,3.1,15.9,2.7,3.9,7.1,9.3,7.1,9.3a89.9,89.9,0,0,0,2.6-14.3c0-.9,0-9-.7-11.5A52.2,52.2,0,0,1,31.9,30Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_27":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M45.6,77.2c-.6,5.9-4.7,10.7-8.9,10.7-7.9.1-9-5.3-9-5.3s-.7,3.8-5.6,4.8-9.7-.4-11-4.7.8-12,1.2-13.3l2.8-10.9h24S46,72.8,45.6,77.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M40,64.8s-3.8-4.6-8.1-3S18.2,68,15.3,73.4s0,9.7,3.6,10.4c.6.2,6.7.3,7.9-6.5s2.1-.6,2.1-.6.3,4.3,1.7,6S37.8,86,41.1,82,43.6,69.2,40,64.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M27.9,76.9s1.1,8.9,8.7,8c0,0,6.2-1.5,8.1-9.1a11.6,11.6,0,0,1-2.9,8.6c-5.7,6.3-13.7,1.1-14-3.6-.1-2.2.2,2.9-2.6,4.5-7.3,4.2-13.5.2-13.7-3.4a25.9,25.9,0,0,1,.5-7s-.4,5.2,1.9,7.4C18.7,87,24.1,85.7,27.9,76.9Z"
              fill="#ddd"
            />
            <path
              d="M28.1,59.5l-11.5.3c-1.3,1-4.1,13.4-4,13.8A23.3,23.3,0,0,1,28.1,59.5Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_27":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.3,24.4c-.2-.3-5.4-4.9-5.4-4.9v3.9l2.7,19,4.5,6.7,1,.8s1.6-9.1,1.6-9.5-1.1-13.8-1.1-13.8Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M15.3,58.5H39.2s-1.9-19.6-3.7-24.6c-1.3-3.3-4.2-9-7.6-12s-4.5-4.5-4.5-4.5-.2,3.9.1,4.1,4.2,6.8,4.4,9.8,0,16.9,0,16.9-4.5-4.5-5-9.2-2-14.1-2-14.1-2.7-3.4-2.3-9.8c.1-2.6-6,7.6-6,15.8C12.5,35.5,15.3,58.5,15.3,58.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M33.3,33.5c2,3.4,1.4,7.2,2.2,15.9.3,3.2,1.1,7.8,1.1,7.8H17.5c-2.4-9.9-2.8-34.5-2-31.7s2.3,3.8,2.7,5.4c1.1,4.6,3.1,11.6,4.3,13.3,2.4,3.4,6.2,8.1,6.2,8.1A87.8,87.8,0,0,0,31,39.8c0-.7,0-7.9-.6-10A37.1,37.1,0,0,1,33.3,33.5Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_28":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M38.1,58.2s9,17.4.5,22.1c-6.7,3.7-15.1,2-15.7,0s-5.8-.5-7.5-1.1-2.6-.7-2-6.6-.7-12.3.5-13.9,9.6-1.4,9.6-1.4A35.7,35.7,0,0,0,38.1,58.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M26,63.6s1.3,13,5.5,14.2,7.3.5,8-4.4-2.1-10.3-3.1-10.7S27.9,61.8,26,63.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M23.9,79a29.4,29.4,0,0,1,3.2,1c2.2.9,6.1.6,6.1.6S24,82.5,23.9,79Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M23,76.6c-1.5.8-3.8,2.7-4.6,1.2s3.8-9,4.5-13.3S24.4,75.8,23,76.6Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M24.7,64.3c0-.5,0,13.3.9,14.1a13.1,13.1,0,0,0,6.3,1.7C34,79.9,25.3,79.1,24.7,64.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_28":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M23.7,18.5s-1.3-6.2-3-8.2L18.9,8.2l-.2,5.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M13.1,9.5c7.8,13.1,1.3,48.9,1.3,48.9s9.6-.9,12.2.1,11,.3,11.4.3-1.7-13-3.9-19.4C23.3,7.5,13.1,9.5,13.1,9.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M35.3,57.1c.6-5.1-6.1-31.6-17.7-44.6,0,0,7.2,7.4,6.6,27.4-.2,6.4-1.1,15.9-1.1,15.9s2.8-.3,4.4.6S35.4,57.7,35.3,57.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_31":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.5,12h9.1s1.5,0,1.5,3.4-3,27.6-3,27.6-3.4,6.9-6.5,6.8S19,44.3,17.8,40,14,16.3,14,15.4,13.6,12,19.6,12Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M20.1,40s8.1,2.4,11.8-9.8c.5-1.5,2.3-13.2,0-13.9s-3.2-.6-6.5-.5-8.4,0-8.3,2.7S20.1,40,20.1,40Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M19.3,42.7c-.4-.4,3,6.4,6.8,4.7C31.5,45,31,34.9,30.8,35.7,26.1,51.2,20.1,43.7,19.3,42.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_31":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M17.4,38.8C17.1,36.6,25.3,61,32,41.9c1.6-4.4.6,23.3-2.2,31-4.3,12.2-5.3,21.2-8.3,23.6C21.5,96.5,19.8,54.3,17.4,38.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M28.7,51.7a4.6,4.6,0,0,1-3.5,1.5,5.1,5.1,0,0,1-3.7-1.5l2,33.4,2.2-12.2C26.7,67.9,28.7,51.7,28.7,51.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_32":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.4,12h9.4s1.5,0,1.5,3.5S35.2,43,35.2,43s-3.5,7-6.6,6.9-7.9-5.6-9.1-10-4.9-23.5-4.9-24.4S14.2,12,20.3,12Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.1,37.9s8.3,2.4,12.2-10.2c.4-1.5,1.3-12.6-1.1-13.4s-3.3-.6-6.7-.4-8.5.9-8.5,3.7S21.1,37.9,21.1,37.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M22.3,42.7c-.4-.4,3.1,6.6,7,4.8,5.6-2.5,5.1-12.9,4.8-12C29.3,51.4,23.2,43.7,22.3,42.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_32":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M19.5,38.6c-.4-2.3,8.1,24.4,15,3.5,1.6-4.8.6,25.5-2.2,34-4.5,13.3-5.7,26.3-8.8,28.8C23.5,105,22,55.7,19.5,38.6Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M31.9,51.9a4.7,4.7,0,0,1-3.6,1.5,5.2,5.2,0,0,1-3.8-1.5l1.2,39.2,3.1-17.4C29.8,68.5,31.9,51.9,31.9,51.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_33":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.1,14c6.3,0,12.8,7.8,12.9,8.1s-2.4,21.7-3.3,24.3-7.2,4.1-9.2,4-7.5-1.3-8.5-4-5.1-18.3-5.1-18.3S20.2,14,26.1,14Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.8,38.8s9.1,2.7,15.6-2.2c1.2-.9,3.3-13.2,1.6-14.7s-8-8-11-6.8-9,10.5-8.9,13S17.8,38.8,17.8,38.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M19,44.6c.2,2.4,8.6,5.6,13.7,2.4,2-1.3,3.3-11.8,3-11.1C29.2,48.5,18.9,43.4,19,44.6Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_33":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M35.1,46.2s-1,15.2-2.4,35.8c-.3,5.3.4,10.9-.4,14.6-1.4,5.5-3.4,7-2.6,9.7s-4.1-3.6-4.5-8-3.2-16-4.4-26.2-2.3-26.4-2.3-26.4S24.8,54.9,35.1,46.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M29.7,52.8a5,5,0,0,1-6,0c-.7-.6,4.4,41.8,4.4,48,0,1,.4-2.7,1.1-5.4S29.7,52.8,29.7,52.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_34":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M31.4,15.7s-14.3-1.4-19.6,8.1c-1.7,2.9,3.1,14.8,4.7,20.4s5.3,6.1,9.2,6,6.4-.3,8.3-3.5,7.8-16,6.2-19.2C37,21.2,35.7,15.6,31.4,15.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34,43.1s5.7-10.6,4.9-13.9-4.9-10.8-6-11.3S40.3,30.8,34,43.1Z"
              fill="#ddd"
            />
            <path
              d="M24.6,44.2c-6.5,0-10.2-7.5-11.5-17.3-.7-4.6,7-11.3,11.5-8.4,2.3,1.5,4.2,8,4.6,13.7.8,12.8-4.6,12-4.6,12"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_34":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M29.2,108.5c-.1,1-2.7-.2-2.8-1.3L16,43.1c.1,0,.2,7.4,9.7,7.4,7.4,0,7-1.6,9.2-6.8C35,43.4,31.1,90.2,29.2,108.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M22.4,54.2c-.3-.1,5.2,46.3,5.2,46.3s.7-8.6,1.7-46.2C29.3,54.3,25.1,55.2,22.4,54.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_35":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.2,13.8s-7.9.5-13,10.5c-1.5,3,3.2,13.4,4.9,19.2s5,6.6,8.7,6.5,5.3.2,7.1-3.1,8-20.6,6.5-24C36.3,16.3,29.3,13.8,25.2,13.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M33,41.5s6.1-14,5.1-17.2-6.2-7.4-7.4-7.8S37.8,29.7,33,41.5Z"
              fill="#ddd"
            />
            <path
              d="M24.5,43.4c-4.1,0-9.7-6.9-10.9-16.8-.4-3.1,6.6-11.8,10.9-8.9,2.2,1.5,4.4,8,4.4,13.7,0,13-4.4,12-4.4,12"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_35":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M32.7,106.7s-2.5-1.4-3.9-6.5L17.3,43s.6,6.1,7.5,6.3,7.2-.9,9.2-5.8C34,43.3,31.7,91.8,32.7,106.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M23.8,52.4c-.3-.1,3.7,38.5,5.2,46.1.6,3,1.8-1.5,1.8-1.5s-1.2-.6-1.7-44.8A8.9,8.9,0,0,1,23.8,52.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_36":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M41,49s4.6-6.8,6.2-13.3c1-4.2,1.1-5.3.4-6.9-1.6-3.2-9.4-8.7-11.2-9.1s-5.3,2.7-6.6,2.9-7.7-2.3-11.6-2.3S2.6,25.1,3.2,30.5,8.5,49,8.5,49Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M45.1,29.3c-.7,0-5.6-3-7.4-2.5s-4.7,1.9-4.2,4.1S36,44,37.7,44.5s5.4-1.4,5.6-2S48.8,29.7,45.1,29.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M21.4,26.7c.6.2,6.4,1.1,7.6,4.4s1.1,9.4,0,11.1a8.1,8.1,0,0,1-7,3.5s-5.7-.4-7.7,0-5.8-12.1-6-13.6S17.5,25.5,21.4,26.7Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M41.9,26.7c.1.1-6.2-3.6-8.5-2.8s-6.6,2.9-5,5.6a13.8,13.8,0,0,1,2,7c0,2.8,2.6,7.7,2.7,7.6s-1.5-12.5.1-14.2S36,27,37.5,26.7A12.8,12.8,0,0,1,41.9,26.7Z"
              fill="#ddd"
            />
            <path
              d="M14.7,23.8c5.7-.4,4.2,0,4.2,0S5.5,29.2,6.4,34.1a69.8,69.8,0,0,0,1.9,8,25.3,25.3,0,0,1-3.2-7.8C4.4,30.5,5.3,24.4,14.7,23.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_36":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M40.6,49.1c.2,0,1.7,21.4,0,29.7-6.3,30.8-11.3,24.1-11.3,24.1s-1.4-18.2-1.7-25.4c-.1-3.5,0-14-2.8-13.5s-4,.7-4.2,5.7-3.4,17.2-5.1,23.2-3.5,6.2-5,6S9.3,96,9.3,95.3s-1-46.2-1-46.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M38,77.9c-1,5.3-4.5,13.6-4.5,13.6s-1-28.2-8-28.8c-4.5-.4-6.5.9-7.2,6.2-1.2,9-6.5,21.8-6.6,24.6,0,.6.2-38.4.2-42.3H37.7C38.1,51.6,38.6,74.9,38,77.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_37":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.5,22.2a12.7,12.7,0,0,1,6.1-1.9c.8,0,8.8,0,11.2,1s5.6,3.1,5.9,7.3-4.5,17.8-4.5,17.8-1.4,3.3-2.3,3.3H27.1L6.6,49.2,3.7,39.4a21.6,21.6,0,0,1-.9-7.3c.1-2.5,1.7-10.7,7.5-11C14.5,20.9,25.5,22.2,25.5,22.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M32.7,26.4s6.6-.9,9.1,1.7,4.5,9.6.8,15.1S40,47.6,40,47.6H11.8S6.6,32.9,9.5,29.1s15.3-1,15.3-1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M40.4,22.4s5,.2,5.9,4.1a15.6,15.6,0,0,1,0,7.8C46.1,34.9,46.2,24.8,40.4,22.4Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M6.9,48.7S3.8,38,3.5,36s-.3-8.8,2.3-11.4,3.2-2.8,5.8-2.8,9.3.7,13.1,1.1,2.3-.8,4.5-1.3,5.8-.1,7.5-.1c5,0,8,6.9,8,6.9s-4.5-4.3-6.2-4.6a20,20,0,0,0-7.4,0c-2.7.5-6.5,2-8.3,1.9s-10.6-2.2-12.4-.9-4.3,1.7-4.3,6S10,48.7,10,48.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_37":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M41.9,49.7s-4.4,22.7-5.4,28a37.6,37.6,0,0,1-6.4,14.8L17.3,108.9V107a35.5,35.5,0,0,1,3.3-14.7,31.4,31.4,0,0,0,2.6-7c.3-3.1-.5-16.6-.5-16.6S15.3,75,13.4,79.1,6.8,92,6.8,97s-3.7-.7-4-5,0-12.1,2.6-18.4S5.2,49.1,6.6,49.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M31.4,83.6c-2.5,3.7-8.2,14.8-8.2,14.8S27,83.1,28.1,80.6s-2.8-20.4-2.8-20.4-7.6,5.7-11,10.4S5.8,85.2,5.8,88.2c0,.6,1.9-15.2,4.6-21.2S13,50.1,13.2,51.5l24.7.4S33.9,79.9,31.4,83.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_38":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M28.3,24.3c1.2-.6,1.1-1,5.3-1s10.5-1.9,11,1,0,7-1.4,11.9-2.8,10.4-4.8,12.7c-.5.6-28.8.4-28.8.4S5,40.9,4.8,36,4.3,24.2,7.1,24.2s5.9,1,7.5,0,5.2-2,9.4-.1C24.9,24.5,27.2,24.9,28.3,24.3Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M40.1,26.8c-3.5,0-7,.1-7.4,2.1S31.4,46,31.4,46a26.7,26.7,0,0,0,4.7,0C38.5,45.9,43.9,26.8,40.1,26.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M26.3,28.5s.2,14.9-1,16.7S14.6,47.4,13,44.3s-2.6-14.4,0-15.6S26.1,26.9,26.3,28.5Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M31.7,26.4s-1.7,18-2.9,19.9,0-4.7-.3-9-.7-10.6-.8-10.9S31.3,24.3,31.7,26.4Z"
              fill="#ddd"
            />
            <path
              d="M11.3,27.1s-3.9,7.6-1.2,21.4c.5,2.3-4.2-8.2-4.6-11s-1-10.4.9-12.1C6.6,25.2,6.7,26.9,11.3,27.1Z"
              fill="#ddd"
            />
            <path
              d="M43.5,23.8s.8-.9-4.5-.6l-6.4.6a6.8,6.8,0,0,0-3.4.7c-1.1.7-2.3,1.3-5,.5s-3.5-1.7-5.5-1.5-1.8.7-3.1,1.2a4,4,0,0,1-2.5.5,44.1,44.1,0,0,0-5.4-.6,1.2,1.2,0,0,0-1.5.8c-.5,1.1-1,2.1.1,2.4s4.5-.2,6.9-.9a27.3,27.3,0,0,1,7.7-1.2c2,0,4.5,1.3,8.3,1.1s11.1-2.1,12.3-1.1,1.8,4.2.8,8.8-.7,4.6-.7,4.5,2.2-7,2.4-9.6S44.2,24,43.5,23.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_38":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M9.7,49.3l.8,24.5s2.3,16.1,6.8,19.9,5.9,6.2,5.9,6.2,9.2-8.5,11-18.5a131.9,131.9,0,0,0,2.2-15.3l2-16.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M24,99.3c-.5,0-2.2-6.5-2.1-14.1S23.1,65.3,20.8,62"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M24,60.5s-1.5,2.8-1.7,12.8"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M35,51.7s-2.2,23.1-4.1,29.6a79.9,79.9,0,0,1-5.1,12.5s.7-7.9.8-13.1c.2-9.9,2.9-21.8,2.3-29Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M19.9,92.2a39.5,39.5,0,0,1-4.9-16c-.8-8.9-1.9-24.5-1.9-24.5h6.2a21.4,21.4,0,0,0-1.8,9.7c.3,3.4,1.8,11,1.4,13.9S18.9,88,19.9,92.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_41":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.1,12H16s-1.5,0-1.5,3.4,3,27.6,3,27.6,3.4,6.9,6.5,6.8,7.6-5.5,8.8-9.8,3.8-23.7,3.8-24.6S37,12,31,12Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.5,40s-8.1,2.4-11.8-9.8c-.5-1.5-2.3-13.2,0-13.9s3.2-.6,6.6-.5,8.3,0,8.2,2.7S30.5,40,30.5,40Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M31.3,42.7c.4-.4-3,6.4-6.8,4.7-5.4-2.4-4.9-12.5-4.7-11.7C24.5,51.2,30.5,43.7,31.3,42.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_41":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M33.2,38.8c.4-2.2-7.9,22.2-14.6,3.1-1.6-4.4-.6,23.3,2.2,31,4.3,12.2,5.3,21.2,8.3,23.6C29.1,96.5,30.8,54.3,33.2,38.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M21.9,51.7a4.6,4.6,0,0,0,3.5,1.5,5.1,5.1,0,0,0,3.7-1.5l-2,33.4L24.9,72.9C24,67.9,21.9,51.7,21.9,51.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_42":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M24.2,12H14.8s-1.5,0-1.5,3.5S15.4,43,15.4,43s3.5,7,6.6,6.9,7.9-5.6,9.2-10S36,16.4,36,15.5s.4-3.5-5.7-3.5Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M29.5,37.9s-8.3,2.4-12.2-10.2c-.4-1.5-1.3-12.6,1.1-13.4s3.3-.6,6.7-.4,8.6.9,8.5,3.7S29.5,37.9,29.5,37.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M28.3,42.7c.4-.4-3.1,6.6-7,4.8-5.6-2.5-5.1-12.9-4.8-12C21.3,51.4,27.5,43.7,28.3,42.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_42":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M31.1,38.6c.4-2.3-8.1,24.4-15,3.5-1.6-4.8-.6,25.5,2.3,34,4.4,13.3,5.6,26.3,8.7,28.8C27.1,105,28.7,55.7,31.1,38.6Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M18.7,51.9a4.7,4.7,0,0,0,3.6,1.5,5.2,5.2,0,0,0,3.8-1.5L24.9,91.1,21.8,73.7C20.8,68.5,18.7,51.9,18.7,51.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_43":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M26.6,14c-6.4,0-12.9,7.8-13,8.1S16,43.8,16.9,46.4s7.2,4.1,9.3,4,7.4-1.3,8.4-4,5.2-18.3,5.2-18.3S32.4,14,26.6,14Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34.8,38.8s-9,2.7-15.6-2.2c-1.2-.9-3.3-13.2-1.6-14.7s8-8,11-6.8,9,10.5,8.9,13S34.8,38.8,34.8,38.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M33.6,44.6C33.4,47,25,50.2,20,47c-2.1-1.3-3.4-11.8-3.1-11.1C23.4,48.5,33.7,43.4,33.6,44.6Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_43":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M17.5,46.2s1,15.2,2.4,35.8c.3,5.3-.4,10.9.5,14.6,1.3,5.5,3.3,7,2.6,9.7s4-3.6,4.4-8,3.3-16,4.4-26.2,2.3-26.4,2.3-26.4S27.8,54.9,17.5,46.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M22.9,52.8a5,5,0,0,0,6,0c.7-.6-4.4,41.8-4.4,48,0,1-.4-2.7-1.1-5.4S22.9,52.8,22.9,52.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_44":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M21.2,15.7s14.3-1.4,19.6,8.1c1.7,2.9-3.1,14.8-4.7,20.4s-5.3,6.1-9.2,6-6.4-.3-8.3-3.5-7.8-16-6.2-19.2C15.6,21.2,16.9,15.6,21.2,15.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M18.6,43.1s-5.7-10.6-4.9-13.9,4.9-10.8,6.1-11.3S12.4,30.8,18.6,43.1Z"
              fill="#ddd"
            />
            <path
              d="M28,44.2c6.6,0,10.2-7.5,11.5-17.3.7-4.6-7-11.3-11.4-8.4-2.4,1.5-4.3,8-4.7,13.7-.8,12.8,4.6,12,4.6,12"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_44":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M23.4,108.5c.1,1,2.7-.2,2.9-1.3L36.6,43.1s-.2,7.4-9.7,7.4c-7.3,0-7-1.6-9.2-6.8C17.7,43.4,21.5,90.2,23.4,108.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.2,54.2c.3-.1-5.2,46.3-5.2,46.3s-.7-8.6-1.7-46.2C23.3,54.3,27.5,55.2,30.2,54.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_45":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M27.4,13.8s7.9.5,13,10.5c1.5,3-3.2,13.4-4.9,19.2s-5,6.6-8.7,6.5-5.3.2-7.1-3.1-8-20.6-6.5-24C16.3,16.3,23.4,13.8,27.4,13.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.6,41.5s-6.1-14-5.1-17.2,6.3-7.4,7.4-7.8S14.8,29.7,19.6,41.5Z"
              fill="#ddd"
            />
            <path
              d="M28.1,43.4c4.1,0,9.7-6.9,10.9-16.8.4-3.1-6.6-11.8-10.8-8.9-2.3,1.5-4.4,8-4.4,13.7,0,13,4.3,12,4.3,12"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_45":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M19.9,106.7s2.5-1.4,3.9-6.5L35.3,43s-.6,6.1-7.5,6.3-7.2-.9-9.2-5.8C18.6,43.3,20.9,91.8,19.9,106.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M28.8,52.4c.3-.1-3.7,38.5-5.2,46.1-.6,3-1.8-1.5-1.8-1.5s1.2-.6,1.7-44.8A8.9,8.9,0,0,0,28.8,52.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_46":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M9.8,50.1c-.2,0-1.7,21.4,0,29.7,6.3,30.8,11.3,24.1,11.3,24.1s1.4-18.2,1.7-25.4c.1-3.5,0-14,2.8-13.5s4,.7,4.2,5.7,3.4,17.2,5.1,23.2,3.5,6.2,5,6,1.2-2.9,1.2-3.6.9-46.2.9-46.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M12.4,78.9c1,5.3,4.5,13.6,4.5,13.6s1-28.2,8-28.8c4.5-.4,6.5.9,7.2,6.2,1.2,9,6.5,21.8,6.5,24.6.1.6-.1-38.4-.1-42.3H12.6C12.3,52.6,11.8,75.9,12.4,78.9Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_46":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M9.4,50S4.8,43.2,3.2,36.7c-1-4.2-1.1-5.3-.4-6.9,1.6-3.2,9.4-8.7,11.2-9.1s5.3,2.7,6.5,2.9,7.8-2.3,11.7-2.3,15.6,4.8,15,10.2S41.9,50,41.9,50Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M5.3,30.3c.7,0,5.6-3,7.4-2.5s4.7,1.9,4.2,4.1S14.4,45,12.7,45.5s-5.5-1.4-5.6-2S1.6,30.7,5.3,30.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M28.9,27.7c-.5.2-6.3,1.1-7.5,4.4s-1.1,9.4,0,11.1a8.1,8.1,0,0,0,7,3.5s5.7-.4,7.7,0,5.8-12.1,6-13.6S32.9,26.5,28.9,27.7Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M8.5,27.7c-.1.1,6.2-3.6,8.5-2.8s6.6,2.9,5,5.6a13.8,13.8,0,0,0-2,7c-.1,2.8-2.6,7.7-2.7,7.6s1.5-12.5-.1-14.2-2.8-2.9-4.4-3.2A12.2,12.2,0,0,0,8.5,27.7Z"
              fill="#ddd"
            />
            <path
              d="M35.7,24.8c-5.7-.4-4.2,0-4.2,0S44.9,30.2,44,35.1s-1.9,8-1.9,8a25.3,25.3,0,0,0,3.2-7.8C46,31.5,45.1,25.4,35.7,24.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "tooth_47":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M25.1,22.2A12.7,12.7,0,0,0,19,20.3c-.7,0-8.8,0-11.1,1s-5.7,3.1-6,7.3S6.4,46.4,6.4,46.4s1.5,3.3,2.3,3.3H23.5L44,49.2l3-9.8a20.3,20.3,0,0,0,.8-7.3c-.1-2.5-1.7-10.7-7.5-11C36.1,20.9,25.1,22.2,25.1,22.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.9,26.4s-6.6-.9-9.1,1.7S4.3,37.7,8,43.2s2.6,4.4,2.6,4.4H38.9S44,32.9,41.1,29.1s-15.3-1-15.3-1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M10.2,22.4s-5,.2-5.9,4.1a15.6,15.6,0,0,0,0,7.8C4.6,34.9,4.4,24.8,10.2,22.4Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M43.7,48.7S46.8,38,47.1,36s.3-8.8-2.3-11.4-3.2-2.8-5.8-2.8-9.3.7-13.1,1.1-2.3-.8-4.5-1.3-5.8-.1-7.5-.1c-4.9,0-8,6.9-8,6.9s4.6-4.3,6.2-4.6a20.7,20.7,0,0,1,7.5,0c2.6.5,6.4,2,8.2,1.9s10.6-2.2,12.4-.9,4.3,1.7,4.3,6-3.9,17.9-3.9,17.9Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_47":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M8.7,49.7s4.4,22.7,5.4,28a37.6,37.6,0,0,0,6.4,14.8l12.8,16.4V107A34,34,0,0,0,30,92.3a37.5,37.5,0,0,1-2.6-7c-.3-3.1.5-16.6.5-16.6s7.5,6.3,9.4,10.4S43.8,92,43.8,97s3.7-.7,4-5,0-12.1-2.6-18.4.2-24.5-1.2-24.4Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.2,83.6c2.5,3.7,8.2,14.8,8.2,14.8s-3.8-15.3-4.9-17.8,2.8-20.4,2.8-20.4,7.6,5.7,11,10.4,8.5,14.6,8.5,17.6c.1.6-1.9-15.2-4.6-21.2s-2.6-16.9-2.8-15.5l-24.7.4S16.7,79.9,19.2,83.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_48":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M22.3,24.3c-1.2-.6-1.1-1-5.2-1S6.5,21.4,6,24.3s0,7,1.4,11.9,2.8,10.4,4.9,12.7c.5.6,28.7.4,28.7.4s4.6-8.4,4.8-13.3.5-11.8-2.3-11.8-5.9,1-7.5,0-5.2-2-9.4-.1C25.7,24.5,23.4,24.9,22.3,24.3Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M10.5,26.8c3.5,0,7,.1,7.4,2.1S19.2,46,19.2,46a26.7,26.7,0,0,1-4.7,0C12.1,45.9,6.7,26.8,10.5,26.8Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M24.4,28.5s-.3,14.9.9,16.7,10.7,2.2,12.3-.9,2.6-14.4,0-15.6S24.5,26.9,24.4,28.5Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M18.9,26.4s1.7,18,2.9,19.9,0-4.7.3-9,.7-10.6.8-10.9S19.4,24.3,18.9,26.4Z"
              fill="#ddd"
            />
            <path
              d="M39.3,27.1s3.9,7.6,1.2,21.4c-.5,2.3,4.2-8.2,4.6-11s1-10.4-.8-12.1C44,25.2,43.9,26.9,39.3,27.1Z"
              fill="#ddd"
            />
            <path
              d="M7.1,23.8s-.8-.9,4.5-.6l6.4.6a6.8,6.8,0,0,1,3.4.7c1.1.7,2.3,1.3,5,.5s3.5-1.7,5.5-1.5,1.8.7,3.1,1.2a4,4,0,0,0,2.5.5,44.1,44.1,0,0,1,5.4-.6,1.3,1.3,0,0,1,1.6.8c.4,1.1.9,2.1-.1,2.4s-4.6-.2-7-.9a27.3,27.3,0,0,0-7.7-1.2c-2,0-4.5,1.3-8.3,1.1S10.3,24.7,9.1,25.7s-1.8,4.2-.8,8.8S9,39.1,9,39s-2.2-7-2.4-9.6S6.4,24,7.1,23.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_48":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 110"
            width={width}
            height={height}
          >
            <path
              d="M41,49.3l-.9,24.5s-2.3,16.1-6.8,19.9-5.9,6.2-5.9,6.2-9.2-8.5-11-18.5-2.2-15.3-2.2-15.3l-2-16.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M26.6,99.3c.5,0,2.2-6.5,2.1-14.1S27.5,65.3,29.8,62"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M26.6,60.5s1.5,2.8,1.7,12.8"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M15.6,51.7s2.2,23.1,4.1,29.6a79.9,79.9,0,0,0,5.1,12.5s-.6-7.9-.7-13.1c-.2-9.9-3-21.8-2.4-29Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M30.7,92.2a40.4,40.4,0,0,0,5-16c.7-8.9,1.9-24.5,1.9-24.5H31.3a21.4,21.4,0,0,1,1.8,9.7c-.3,3.4-1.7,11-1.4,13.9S31.7,88,30.7,92.2Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_55":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M13.4,77.7c0,4.7.3,5.4,5.2,6.8,2.9.9,5.8-2.1,5.8-2.1s.9,1.8,3.9,2.3c4.9.8,5.2-.6,6.7-3.4s-.8-7.6-1.1-8.4l-1.7-6.7H17.4S13.4,75,13.4,77.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M16.8,70.1s2.3-2.8,5-1.9,8.4,3.8,10.3,7.2,0,6-2.3,6.4a4.4,4.4,0,0,1-4.8-4c-.8-4.3-1.3-.4-1.3-.4s-.2,2.6-1.1,3.7-4.4,2-6.5-.4S14.6,72.8,16.8,70.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M24.3,77.6s-.7,5.4-5.4,4.8a7.4,7.4,0,0,1-5-5.6,7.2,7.2,0,0,0,1.8,5.4c3.5,3.8,8.5.6,8.6-2.3.1-1.3-.1,1.8,1.7,2.8,4.4,2.6,8.3.1,8.4-2.1a21,21,0,0,0-.3-4.3s.2,3.2-1.2,4.6C29.9,83.8,26.6,82.9,24.3,77.6Z"
              fill="#ddd"
            />
            <path
              d="M24.2,67.8l7,.2c.8.6,2.6,8.2,2.5,8.5A14.2,14.2,0,0,0,24.2,67.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_55":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M28.4,38.7a55.5,55.5,0,0,0,.1,9.1,47.5,47.5,0,0,1,.2,9L26,62.6s-2-6-2-6.2a53.4,53.4,0,0,1,.2-7.6C24.5,46.8,28.4,38.7,28.4,38.7Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M32.2,66.7H17.4s.7-4.4,1-7.7-2.3-7.2-1.7-14.3c.4-5,3.1-8.3,3-6.7-.2,6.8,4.7,20.1,6.4,20.1S32.8,49,35,37.7c.1-.9,1.9-.5,1,9.2-.1,1.6-1.9,7.4-2.9,12.4A48.2,48.2,0,0,0,32.2,66.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.8,61.1c-.3,1.9-1,4.5-1,4.5H30.4c1-7.1,4.3-16.8,4.1-20.3-.2-1.9,0,0-1.6,4.4C32.3,51.6,28.2,60,25.6,60s-7.2-16.5-7.3-16.3C17.1,49.2,20.7,52.8,19.8,61.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_54":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M16.9,77.6c0,4.1.3,4.7,4.5,5.9,2.5.7,5-1.8,5-1.8a4.5,4.5,0,0,0,3.4,1.9c4.2.7,4.5-.5,5.7-2.9s-.7-6.5-.9-7.2l-1.5-5.8H20.4S16.9,75.3,16.9,77.6Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.9,71.1s2-2.5,4.3-1.7,7.2,3.3,8.8,6.2,0,5.2-1.9,5.6a4,4,0,0,1-4.2-3.5c-.6-3.7-1.1-.4-1.1-.4a8.2,8.2,0,0,1-.9,3.2,3.6,3.6,0,0,1-5.6-.3C17.4,78,18,73.4,19.9,71.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M26.3,77.5s-.6,4.7-4.6,4.2a6.4,6.4,0,0,1-4.3-4.8,5.9,5.9,0,0,0,1.5,4.6c3.1,3.3,7.3.5,7.4-2s0,1.6,1.5,2.5c3.8,2.2,7.1,0,7.2-1.9a13,13,0,0,0-.2-3.7s.2,2.8-1,4S28.3,82.1,26.3,77.5Z"
              fill="#ddd"
            />
            <path
              d="M26.2,68.2l6.1.2c.7.5,2.2,7.1,2.2,7.3A12.8,12.8,0,0,0,26.2,68.2Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_54":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M29.9,43.6a40.7,40.7,0,0,0,.1,7.8,39.4,39.4,0,0,1,.1,7.7l-2.3,5.1s-1.7-5.2-1.7-5.4a49.6,49.6,0,0,1,.1-6.5C26.5,50.5,29.9,43.6,29.9,43.6Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M33.2,67.7H20.4s.6-3.8.8-6.7-1.9-6.2-1.4-12.3c.3-4.3,2.6-7.2,2.6-5.8-.2,5.9,4,17.4,5.5,17.4s5.8-7.8,7.6-17.6c.2-.8,1.7-.5.9,7.9-.1,1.4-1.6,6.4-2.5,10.7A49.4,49.4,0,0,0,33.2,67.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M22.4,62.8a36.5,36.5,0,0,1-.8,4h10c.8-6.2,3.7-14.6,3.5-17.6s0,0-1.3,3.8c-.6,1.6-4.1,8.9-6.3,8.9s-6.2-14.2-6.3-14C20.1,52.6,23.2,55.7,22.4,62.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_53":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.4,92.2s5.1-2.2,8.3-9.4c1.4-3.1-1.8-8.9-2.9-12.4-1.6-5.1-4.2-5.8-6.1-5.8s-4.1,1.4-5.5,4.9-3.7,10.6-2.7,13.1C19.5,87.3,23.7,92.2,26.4,92.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.3,69.2s-3.5,6.7-3.2,12.4c0,1.9,4.4,7.7,5.2,7.9S17.4,78.1,21.3,69.2Z"
              fill="#ddd"
            />
            <path
              d="M27,68.3c3-.3,5.7,6.7,6.5,12.3.4,2.6-4.2,9.5-6.9,7.9s-1.2-5.9-1.1-9.1C25.7,73.6,24.9,68.5,27,68.3Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_53":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.7,32.4c0,2.8,1.1,6.8,3.2,17.3,1.2,5.9.4,12.2,1.9,22.3.3,2.1-.6-6.3-5.7-6.3s-5.5,5.1-5.5,5.1S20.6,41.7,26.7,32.4Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M29.4,65.1S28.5,49.5,27.8,48c-2.6-5.1-2.2-8.3-1.7-9.6s-2.2,3.5-3.8,26.7C22.3,65.1,25.9,61.7,29.4,65.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_52":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M31.7,87.8c2.2-1.7.1-14.9-1-18.3-1.6-5.2-3.8-5.8-5.7-5.8s-3.8,1.9-5.2,5.4S15.6,85,17.1,87.2,29.4,89.5,31.7,87.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.5,66.8c-1.1-.7-4.6,11.7-4.6,18.6,0,1.9,1.3,2.8,1.1,2C17.1,80.8,21.9,67,21.5,66.8Z"
              fill="#ddd"
            />
            <path
              d="M25.1,68c2.6-.1,4.1,4.6,4.2,9.9.1,1.7.9,6.6-1.5,7.5s-4.5,0-5.5,0c-3.1-.2-1.3-4.7-.9-7.7C22,72.4,23.4,68,25.1,68Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M28.9,66.7c1.2-.6,3.9,11.9,3.5,18.7-.1,1.9-1.4,2.6-1.2,1.9C32.5,80.8,28.5,66.9,28.9,66.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_52":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.9,36.5c-.1,2.4-.8,2.9-2.6,12-1,5.1-2.6,10.4-3.8,19.2-.2,1.8.5-3.5,4.8-3.5s5.1,5.5,5.1,5.5S32.1,44.5,26.9,36.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M22.8,63.4S27.5,42,27.2,40.8,29.4,43.7,29,64C29,64,26.2,60.8,22.8,63.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_51":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.6,89.5s5-2.2,8.3-9.4C36.3,77,33,71.2,32,67.8c-1.7-5.2-4.2-5.9-6.1-5.9s-4.2,1.4-5.6,4.9-3.7,10.7-2.7,13.1C19.7,84.6,23.9,89.5,26.6,89.5Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.4,66.6S18,73.2,18.2,78.9c.1,1.9,4.5,7.7,5.2,7.9S17.5,75.5,21.4,66.6Z"
              fill="#ddd"
            />
            <path
              d="M27.1,65.6c3.1-.3,5.7,6.7,6.6,12.3.4,2.6-4.2,9.5-6.9,7.9s-1.2-5.9-1.2-9.1C25.8,71,25,65.8,27.1,65.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_51":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.1,34.2c.7,1.8,1.7,2.6,3.2,11.6,1,5.9.9,12.9,2.4,23.1.3,2-.6-6.4-5.7-6.4s-5.5,5.1-5.5,5.1S20,43.4,26.1,34.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M29.3,62.7s-2-23.5-3.2-24.8-2.3,1.6-3.9,24.8C22.2,62.7,25.8,59.3,29.3,62.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_61":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M22.5,89.5s-5-2.2-8.3-9.4C12.8,77,16,71.2,17.1,67.8c1.6-5.2,4.2-5.9,6.1-5.9s4.1,1.4,5.6,4.9,3.7,10.7,2.6,13.1C29.4,84.6,25.2,89.5,22.5,89.5Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M27.6,66.6s3.5,6.6,3.3,12.3c-.1,1.9-4.5,7.7-5.2,7.9S31.6,75.5,27.6,66.6Z"
              fill="#ddd"
            />
            <path
              d="M22,65.6c-3.1-.3-5.8,6.7-6.6,12.3-.4,2.6,4.2,9.5,6.9,7.9s1.2-5.9,1.1-9.1C23.3,71,24.1,65.8,22,65.6Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_61":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M23,34.2c-.8,1.8-1.7,2.6-3.2,11.6-1.1,5.9-.9,12.9-2.4,23.1-.3,2,.6-6.4,5.6-6.4s5.6,5.1,5.6,5.1S29.1,43.4,23,34.2Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M19.8,62.7s2-23.5,3.2-24.8,2.3,1.6,3.9,24.8C26.9,62.7,23.3,59.3,19.8,62.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_62":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M18.4,87.8c-2.2-1.7-.1-14.9,1-18.3,1.6-5.2,3.8-5.8,5.7-5.8s3.8,1.9,5.2,5.4S34.5,85,33,87.2,20.6,89.5,18.4,87.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M28.6,66.8c1.1-.7,4.5,11.7,4.5,18.6,0,1.9-1.2,2.8-1.1,2C33,80.8,28.2,67,28.6,66.8Z"
              fill="#ddd"
            />
            <path
              d="M25,68c-2.6-.1-4.1,4.6-4.2,9.9-.1,1.7-.9,6.6,1.5,7.5s4.5,0,5.5,0c3.1-.2,1.3-4.7.9-7.7C28,72.4,26.7,68,25,68Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M21.2,66.7c-1.2-.6-3.9,11.9-3.5,18.7.1,1.9,1.4,2.6,1.2,1.9C17.6,80.8,21.6,66.9,21.2,66.7Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_62":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M23.2,37.5c0,2.4.8,2.9,2.6,12,1,5.1,2.5,10.4,3.8,19.2.2,1.8-.5-3.5-4.8-3.5s-5.1,5.5-5.1,5.5S18,45.5,23.2,37.5Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M27.3,64.4S22.6,43,22.9,41.8,20.7,44.7,21.1,65C21.1,65,23.9,61.8,27.3,64.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_63":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M25.7,92.2s-5.1-2.2-8.3-9.4c-1.5-3.1,1.8-8.9,2.9-12.4,1.6-5.1,4.2-5.8,6.1-5.8s4.1,1.4,5.5,4.9,3.7,10.6,2.7,13.1C32.6,87.3,28.4,92.2,25.7,92.2Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.8,69.2s3.5,6.7,3.2,12.4c-.1,1.9-4.4,7.7-5.2,7.9S34.7,78.1,30.8,69.2Z"
              fill="#ddd"
            />
            <path
              d="M25.1,68.3C22,68,19.4,75,18.5,80.6c-.3,2.6,4.3,9.5,7,7.9s1.2-5.9,1.1-9.1C26.4,73.6,27.2,68.5,25.1,68.3Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "root_63":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M25.4,32.4c0,2.8-1.1,6.8-3.2,17.3C21,55.6,21.8,61.9,20.3,72c-.3,2.1.6-6.3,5.7-6.3s5.5,5.1,5.5,5.1S31.5,41.7,25.4,32.4Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M22.7,65.1s.8-15.6,1.6-17.1c2.6-5.1,2.2-8.3,1.7-9.6s2.2,3.5,3.8,26.7C29.8,65.1,26.2,61.7,22.7,65.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_64":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M33.2,77.6c0,4.1-.3,4.7-4.5,5.9-2.5.7-5-1.8-5-1.8a4.5,4.5,0,0,1-3.4,1.9c-4.2.7-4.5-.5-5.7-2.9s.7-6.5.9-7.2L17,67.7H29.7S33.2,75.3,33.2,77.6Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.2,71.1s-2-2.5-4.3-1.7-7.3,3.3-8.8,6.2,0,5.2,1.9,5.6a4,4,0,0,0,4.2-3.5c.6-3.7,1.1-.4,1.1-.4a8.2,8.2,0,0,0,.9,3.2,3.6,3.6,0,0,0,5.6-.3C32.7,78,32.1,73.4,30.2,71.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M23.8,77.5s.6,4.7,4.6,4.2a6.4,6.4,0,0,0,4.3-4.8,6.4,6.4,0,0,1-1.5,4.6,4.3,4.3,0,0,1-7.5-2c0-1.1.1,1.6-1.4,2.5-3.8,2.2-7.1,0-7.3-1.9a15.6,15.6,0,0,1,.3-3.7s-.2,2.8,1,4S21.7,82.1,23.8,77.5Z"
              fill="#ddd"
            />
            <path
              d="M23.9,68.2l-6.1.2c-.7.5-2.2,7.1-2.2,7.3A12.5,12.5,0,0,1,23.9,68.2Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_64":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M20.2,43.6a51.8,51.8,0,0,1-.1,7.8,39.4,39.4,0,0,0-.1,7.7l2.3,5.1S24,59,24,58.8a39.2,39.2,0,0,0-.2-6.5C23.5,50.5,20.2,43.6,20.2,43.6Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M16.9,67.7H29.7s-.6-3.8-.9-6.7,2-6.2,1.5-12.3c-.3-4.3-2.7-7.2-2.6-5.8.2,5.9-4,17.4-5.5,17.4s-5.8-7.8-7.6-17.6c-.2-.8-1.7-.5-.9,7.9.1,1.4,1.6,6.4,2.5,10.7A49.4,49.4,0,0,1,16.9,67.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M27.7,62.8a36.5,36.5,0,0,0,.8,4h-10c-.8-6.2-3.7-14.6-3.5-17.6s0,0,1.3,3.8c.6,1.6,4.1,8.9,6.3,8.9s6.2-14.2,6.3-14C30,52.6,26.9,55.7,27.7,62.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_65":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M35.7,77.7c0,4.7-.3,5.4-5.2,6.8-2.9.9-5.8-2.1-5.8-2.1s-.9,1.8-3.9,2.3c-4.9.8-5.2-.6-6.7-3.4s.8-7.6,1.1-8.4l1.7-6.7H31.7S35.7,75,35.7,77.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M32.3,70.1s-2.3-2.8-5-1.9S18.9,72,17,75.4s0,6,2.3,6.4a4.4,4.4,0,0,0,4.8-4c.8-4.3,1.3-.4,1.3-.4s.2,2.6,1.1,3.7,4.4,2,6.5-.4S34.5,72.8,32.3,70.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M24.8,77.6s.7,5.4,5.4,4.8a7.4,7.4,0,0,0,5-5.6,7.2,7.2,0,0,1-1.8,5.4c-3.5,3.8-8.5.6-8.6-2.3-.1-1.3,0,1.8-1.7,2.8-4.4,2.6-8.3.1-8.4-2.1a15.1,15.1,0,0,1,.3-4.3s-.2,3.2,1.2,4.6C19.1,83.8,22.5,82.9,24.8,77.6Z"
              fill="#ddd"
            />
            <path
              d="M24.9,67.8l-7,.2c-.8.6-2.6,8.2-2.5,8.5A14.2,14.2,0,0,1,24.9,67.8Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_65":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M20.7,38.7s.4,6-.2,9.1a53.8,53.8,0,0,0-.1,9l2.7,5.8s2-6,2-6.2a74.9,74.9,0,0,0-.2-7.6C24.5,46.8,20.7,38.7,20.7,38.7Z"
              fill={backRootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M16.9,66.7H31.7s-.7-4.4-1-7.7,2.3-7.2,1.7-14.3c-.4-5-3.1-8.3-3-6.7.2,6.8-4.7,20.1-6.4,20.1S16.3,49,14.1,37.7c-.2-.9-1.9-.5-1,9.2.1,1.6,1.9,7.4,2.9,12.4A48.2,48.2,0,0,1,16.9,66.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M29.3,61.1a29.6,29.6,0,0,0,1,4.5H18.7c-1-7.1-4.3-16.8-4.1-20.3.2-1.9,0,0,1.5,4.4.7,1.9,4.8,10.3,7.4,10.3s7.2-16.5,7.2-16.3C32,49.2,28.4,52.8,29.3,61.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_71":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M24.5,27h5.1s.8,0,.8,1.7-1.7,15.7-1.7,15.7-1.9,3.4-3.6,3.4-4.2-2.7-4.9-4.8-2.1-13.8-2.1-14.3S17.9,27,21.2,27Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.5,42s4.5,1.1,6.6-4.9c.3-.8,1.3-8.6,0-8.9a10.5,10.5,0,0,0-3.7-.3c-2.2.1-4.6.5-4.6,1.8S21.5,42,21.5,42Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M21,44.3c-.2-.2,1.7,3.2,3.9,2.3s2.7-6.2,2.6-5.8C24.9,48.5,21.5,44.8,21,44.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_71":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M19.6,42.3c-.1-1.1,4.4,11,8.2,1.5.9-2.2-.4,11.4-1.2,15.4s-2.5,6.3-4.2,7.5C22.4,64.8,21,50,19.6,42.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M25.9,48.7a2.5,2.5,0,0,1-1.9.7,3.4,3.4,0,0,1-2.1-.7l1.6,14.5s1-3,1.5-5.4S25.9,48.7,25.9,48.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_72":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M24.4,27.9h5s.8,0,.8,1.8S28.5,47,28.5,47s-1.8,3.8-3.5,3.7-4.1-3-4.8-5.3-2.1-15.1-2.1-15.7-.2-1.8,3-1.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.1,44.3s4.4,1.3,6.4-5.4c.3-.8,1.3-9.4,0-9.8a8.1,8.1,0,0,0-3.6-.2c-2.2.1-4.5.5-4.5,2S21.1,44.3,21.1,44.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M21.7,46.9c-.2-.2,1.6,3.5,3.7,2.5S28.1,42.6,28,43C25.4,51.5,22.1,47.4,21.7,46.9Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_72":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M19.4,43.7c-.2-1.2,4.3,13,8,1.9.8-2.6.3,13.5-1.2,18s-2.4,6.4-4,8C22.2,71.6,20.7,52.8,19.4,43.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M26,50.8a2.4,2.4,0,0,1-1.9.8,3.2,3.2,0,0,1-2.1-.8l1.2,16.6s.7-2.3,1.2-5S26,50.8,26,50.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_73":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M25.9,23.7c3.2,0,6.5,3.8,6.5,4s-1.1,10.9-1.6,12.2-3.6,2.1-4.7,2-3.7-.7-4.2-2-2.6-9.2-2.6-9.2S23,23.7,25.9,23.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M21.8,36.1s4.5,1.3,7.9-1.1c.5-.5,1.6-6.6.7-7.4s-4-4-5.5-3.4-4.5,5.2-4.4,6.5A32.9,32.9,0,0,0,21.8,36.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M22.4,39c.1,1.2,4.3,2.8,6.9,1.2,1-.6,1.6-5.9,1.5-5.6C27.5,40.9,22.4,38.4,22.4,39Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_73":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M30.8,39.1s-.5,7.6-1.2,17.9c-.2,2.7-.4,7.5-.8,9.3s-1.7,8.1-1.3,9.4-2-1.8-2.2-4S24.2,57.2,23.6,52s-1.2-13.2-1.2-13.2S25.6,43.4,30.8,39.1Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M28,42.4a2.6,2.6,0,0,1-3,0c-.3-.3,1.7,22.9,1.7,26.1,0,.5.2-1.4.6-2.7S28,42.4,28,42.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_74":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M32.7,42.8A29.6,29.6,0,0,0,36,35.7c.5-2.3.6-2.8.2-3.7s-5-4.6-6-4.8-2.8,1.4-3.5,1.5-4.1-1.2-6.2-1.2-8.3,2.5-7.9,5.4,2.7,9.9,2.7,9.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34.9,32.3c-.4,0-3-1.6-4-1.4s-2.5,1.1-2.2,2.2,1.3,7,2.2,7.3a4.4,4.4,0,0,0,3-1.1C34,39.1,36.8,32.5,34.9,32.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M22.3,30.9c.3.1,3.4.5,4,2.3s.6,5.1,0,6A4.2,4.2,0,0,1,22.5,41a25.8,25.8,0,0,0-4,0c-1.1.3-3.1-6.4-3.3-7.2S20.2,30.3,22.3,30.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M33.1,30.9c.1,0-3.2-1.9-4.4-1.5s-3.6,1.6-2.7,3a6.9,6.9,0,0,1,1,3.7,11.7,11.7,0,0,0,1.5,4.1c.1-.1-.8-6.7.1-7.6S30,31,30.9,30.9A5.3,5.3,0,0,1,33.1,30.9Z"
              fill="#ddd"
            />
            <path
              d="M18.7,29.3c3-.2,2.2,0,2.2,0s-7.1,2.9-6.6,5.5a31.2,31.2,0,0,0,.9,4.3A12.9,12.9,0,0,1,13.6,35C13.2,32.9,13.6,29.7,18.7,29.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_74":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M32.7,42.8c.1,0-.5,5.2-.7,6.1-.7,5.9,7.2,23.1,3.3,22.8s-8-21.1-12-20.9S17.9,73.2,12.9,73.2c-1.5,0,2.7-10.1,2.4-25.1V42.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M34.6,67.1S27.7,49,23.3,49s-7.4,16-8,17.3S17,46.4,17,44.2H31C28.9,51.1,34.6,67.1,34.6,67.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_75":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M35.1,42.6a27.6,27.6,0,0,0,3.3-7.1c.5-2.2.6-2.8.2-3.6s-3.4-4.7-4.4-4.8a13.1,13.1,0,0,0-5.1.7c-.4.1-1.8-1.1-3.7-1.1s-3.2,1-4.4,1-3-1-4.8-.6-1.6,2.8-1.2,5.7,2.7,9.8,2.7,9.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M37.3,32.2c-.4-.1-3-1.7-4-1.4s-2.5,1-2.2,2.2,1.3,7,2.2,7.2a4.4,4.4,0,0,0,3-1C36.3,38.9,39.2,32.3,37.3,32.2Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M25.6,30.9a3.4,3.4,0,0,1,2.7,2.3,14.5,14.5,0,0,1,0,5.9A3,3,0,0,1,25.7,41,11.7,11.7,0,0,0,23,41c-.7.2-2.1-6.5-2.2-7.3S24.1,30.2,25.6,30.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M17.3,32.5c.1-.1,1.3.4,1.7,1.9a35.2,35.2,0,0,1,.8,5.4c0,.8.3,1.2-.1,1.2s-1.2-.1-1.4.1-1.8-5-1.9-5.7S16.1,32.7,17.3,32.5Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M35.5,30.8c.1,0-3.2-2-4.4-1.6s-3.6,1.6-2.7,3a7.2,7.2,0,0,1,1,3.8,10.2,10.2,0,0,0,1.5,4c.1-.1-.8-6.7.1-7.6s1.4-1.5,2.3-1.6A4,4,0,0,1,35.5,30.8Z"
              fill="#ddd"
            />
            <path
              d="M21.4,30.1l1.6-.4s-3,2.5-2.5,5.1a40.9,40.9,0,0,0,1,4.3A14.8,14.8,0,0,1,19.8,35C19.4,32.9,16.5,31.4,21.4,30.1Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_75":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M35,43c.2,0-.5,5.1-.6,6.1C33.6,55,41.6,72,37.7,71.9s-8.1-21.1-12.1-21S20.3,73.4,15.3,73.4c-1.6,0,2.7-10.1,2.4-25.1,0-.4-.1-5.3-.1-5.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M37,67.3S30.1,49.2,25.6,49.1s-7.4,16-8,17.3,1.8-19.9,1.8-22.1H33.3C31.2,51.3,37,67.3,37,67.3Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_81":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M28,27H22.9s-.8,0-.8,1.7,1.7,15.7,1.7,15.7,1.9,3.4,3.6,3.4,4.2-2.7,4.9-4.8,2.1-13.8,2.1-14.3.2-1.7-3.1-1.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M31,42s-4.5,1.1-6.6-4.9c-.3-.8-1.3-8.6,0-8.9a10.5,10.5,0,0,1,3.7-.3c2.2.1,4.6.5,4.6,1.8S31,42,31,42Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M31.5,44.3c.2-.2-1.7,3.2-3.9,2.3s-2.7-6.2-2.6-5.8C27.6,48.5,31,44.8,31.5,44.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_81":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M32.8,42.3c.2-1.1-4.3,11-8.1,1.5-.9-2.2.4,11.4,1.2,15.4s2.5,6.3,4.2,7.5C30.1,64.8,31.5,50,32.8,42.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M26.5,48.7a2.9,2.9,0,0,0,2,.7,3.4,3.4,0,0,0,2.1-.7L29,63.2s-1-3-1.5-5.4S26.5,48.7,26.5,48.7Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_82":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M28.1,27.9h-5s-.8,0-.8,1.8S24,47,24,47s1.8,3.8,3.5,3.7,4.1-3,4.8-5.3,2.1-15.1,2.1-15.7.2-1.8-3.1-1.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M31.4,44.3S27,45.6,25,38.9c-.3-.8-1.3-9.4,0-9.8a8.1,8.1,0,0,1,3.6-.2c2.2.1,4.5.5,4.5,2S31.4,44.3,31.4,44.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M30.8,46.9c.2-.2-1.6,3.5-3.7,2.5s-2.7-6.8-2.6-6.4C27.1,51.5,30.4,47.4,30.8,46.9Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_82":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M33.1,44.7c.2-1.2-4.3,13-8,1.9-.8-2.6-.3,13.5,1.2,18s2.4,6.4,4,8C30.3,72.6,31.8,53.8,33.1,44.7Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M26.5,51.8a2.4,2.4,0,0,0,1.9.8,3.2,3.2,0,0,0,2.1-.8L29.3,68.4s-.7-2.3-1.2-5S26.5,51.8,26.5,51.8Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_83":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M26.6,23.7c-3.2,0-6.5,3.8-6.5,4s1.1,10.9,1.6,12.2,3.6,2.1,4.6,2,3.8-.7,4.3-2,2.6-9.2,2.6-9.2S29.5,23.7,26.6,23.7Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M30.7,36.1s-4.6,1.3-7.9-1.1c-.5-.5-1.6-6.6-.7-7.4s4-4,5.5-3.4,4.5,5.2,4.4,6.5A32.9,32.9,0,0,1,30.7,36.1Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M30.1,39c-.1,1.2-4.3,2.8-6.9,1.2-1-.6-1.7-5.9-1.5-5.6C25,40.9,30.1,38.4,30.1,39Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_83":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M22.7,39.1s.5,7.6,1.2,17.9c.2,2.7.3,7.5.8,9.3s1.7,8.1,1.3,9.4,2-1.8,2.2-4S29.3,57.2,29.9,52s1.2-13.2,1.2-13.2S27.9,43.4,22.7,39.1Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M25.5,42.4a2.5,2.5,0,0,0,2.9,0c.4-.3-1.6,22.9-1.6,26.1,0,.5-.2-1.4-.6-2.7S25.5,42.4,25.5,42.4Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_84":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M19.8,42.8a29.6,29.6,0,0,1-3.3-7.1c-.5-2.3-.6-2.8-.2-3.7s5-4.6,6-4.8,2.8,1.4,3.4,1.5,4.2-1.2,6.3-1.2,8.3,2.5,7.9,5.4-2.7,9.9-2.7,9.9Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.6,32.3c.4,0,3-1.6,4-1.4S24,32,23.8,33.1s-1.3,7-2.2,7.3a4.4,4.4,0,0,1-3-1.1C18.5,39.1,15.7,32.5,17.6,32.3Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M30.2,30.9c-.3.1-3.4.5-4,2.3s-.6,5.1,0,6A4.2,4.2,0,0,0,29.9,41,27.1,27.1,0,0,1,34,41c1.1.3,3.1-6.4,3.3-7.2S32.3,30.3,30.2,30.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M19.3,30.9s3.3-1.9,4.5-1.5,3.6,1.6,2.7,3a6.9,6.9,0,0,0-1,3.7A11.7,11.7,0,0,1,24,40.2c-.1-.1.8-6.7-.1-7.6s-1.4-1.6-2.3-1.7A5.9,5.9,0,0,0,19.3,30.9Z"
              fill="#ddd"
            />
            <path
              d="M33.8,29.3c-3-.2-2.2,0-2.2,0s7.1,2.9,6.6,5.5-.9,4.3-.9,4.3A12.9,12.9,0,0,0,38.9,35C39.3,32.9,38.9,29.7,33.8,29.3Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_84":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M19.8,42.8c-.1,0,.5,5.2.7,6.1.7,5.9-7.2,23.1-3.3,22.8s8-21.1,12-20.9,5.4,22.4,10.4,22.4c1.5,0-2.7-10.1-2.4-25.1V42.8Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M17.9,67.1S24.8,49,29.2,49s7.4,16,8,17.3-1.7-19.9-1.7-22.1h-14C23.6,51.1,17.9,67.1,17.9,67.1Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "tooth_85":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M17.4,42.6a27.6,27.6,0,0,1-3.3-7.1c-.5-2.2-.6-2.8-.2-3.6s3.4-4.7,4.4-4.8a13.1,13.1,0,0,1,5.1.7c.4.1,1.8-1.1,3.7-1.1s3.2,1,4.4,1,3-1,4.8-.6,1.6,2.8,1.2,5.7-2.7,9.8-2.7,9.8Z"
              fill="rgba(242,242,242,0.4)"
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M15.2,32.2c.4-.1,3-1.7,4-1.4s2.4,1,2.2,2.2-1.3,7-2.2,7.2a4.4,4.4,0,0,1-3-1C16.1,38.9,13.3,32.3,15.2,32.2Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M26.9,30.9a3.4,3.4,0,0,0-2.7,2.3,14.5,14.5,0,0,0,0,5.9A3,3,0,0,0,26.8,41a11.7,11.7,0,0,1,2.7,0c.7.2,2.1-6.5,2.2-7.3S28.4,30.2,26.9,30.9Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M35.2,32.5c-.1-.1-1.3.4-1.7,1.9a35.2,35.2,0,0,0-.8,5.4c0,.8-.3,1.2.1,1.2s1.2-.1,1.4.1,1.7-5,1.9-5.7S36.4,32.7,35.2,32.5Z"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M16.9,30.8s3.3-2,4.5-1.6,3.6,1.6,2.7,3a7.2,7.2,0,0,0-1,3.8,10.2,10.2,0,0,1-1.5,4c-.1-.1.8-6.7-.1-7.6s-1.4-1.5-2.3-1.6A4.4,4.4,0,0,0,16.9,30.8Z"
              fill="#ddd"
            />
            <path
              d="M31.1,30.1l-1.6-.4s3,2.5,2.5,5.1a40.9,40.9,0,0,1-1,4.3A14.8,14.8,0,0,0,32.7,35C33.1,32.9,35.9,31.4,31.1,30.1Z"
              fill="#ddd"
            />
          </svg>
        );
      case "root_85":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M17.4,43c-.1,0,.6,5.1.7,6.1.8,5.9-7.2,22.9-3.3,22.8s8-21.1,12-21,5.4,22.5,10.4,22.5c1.6,0-2.7-10.1-2.4-25.1,0-.4.1-5.3.1-5.3Z"
              fill={rootColor}
              stroke="rgba(0,0,0,0.2)"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <path
              d="M15.5,67.3s6.9-18.1,11.3-18.2,7.5,16,8.1,17.3-1.8-19.9-1.8-22.1H19.2C21.3,51.3,15.5,67.3,15.5,67.3Z"
              fill="#fff"
              opacity="0.4"
            />
          </svg>
        );
      case "implante_top_large":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M36,63.7H17l.9-27.1S21.4,22.1,21.4,22s2.3-2.1,5.2-2.1,5.2.9,5.2,2.1,3.3,16.6,3.3,16.6Z"
              fill="rgba(153,153,153,0.4)"
            />
            <path
              d="M32.2,21.9l3.3,16.5L36.4,64H16.7l.9-27.5h0c1.2-4.9,3.4-14.1,3.5-14.6s2.4-2.5,5.6-2.5S32.2,20.5,32.2,21.9ZM17.4,63.3H35.7l-.9-24.8C31.5,22,31.5,21.9,31.5,21.9s-2-1.7-4.8-1.7-4.9.9-4.9,1.7,0,.1-3.5,14.7Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="37.8 48 36 45.1 17.6 42.2 15.7 44.5 37.8 48"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="13.4 56.8 15.6 60.2 37.3 63.4 39.5 60.7 13.4 56.8"
              fill="#fff"
              opacity="0.4"
            />
            <line
              x1="39.5"
              y1="60.7"
              x2="13.4"
              y2="56.8"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M40,60.7l-2.4-3.2-22-3.3-2.7,2.6,2.4,3.7,22.1,3.3ZM14.1,57.3,38.8,61l-1.7,2L15.8,59.8Zm24.6,3L14.1,56.6,15.8,55l21.4,3.2Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="14.9 50.3 16.8 53.4 36.6 56.5 38.6 54.1 14.9 50.3"
              fill="rgba(230,230,230,0.4)"
            />
            <line
              x1="38.3"
              y1="54.3"
              x2="14.9"
              y2="50.3"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M39.1,54.1l-2.1-3L16.9,47.9l-2.5,2.3,2.2,3.5,20.1,3.2ZM15.6,50.8l22.2,3.8-1.4,1.5L17.1,53Zm22.5,3.1L15.6,50.1l1.5-1.4,19.5,3.1Z"
              fill="rgba(77, 77, 77, 0.4)"
            />
            <polygon
              points="15.7 44.2 17.5 47 35.9 49.9 37.8 47.7 15.7 44.2"
              fill="rgba(230,230,230,0.4)"
            />
            <line
              x1="37.5"
              y1="47.9"
              x2="15.7"
              y2="44.2"
              fill="#fff"
              opacity="0.4"
            />
            <path
              d="M38.3,47.7l-2-2.8L17.6,42l-2.4,2.1,2.1,3.2,18.8,3Zm-21.9-3L37,48.2l-1.2,1.4L17.7,46.7Zm20.8,2.8L16.4,43.9l1.4-1.2,18.1,2.9Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="35.3 43.4 18.3 40.6 17 38.8 19.2 39.1 20.7 41.1 26.8 42 27.3 40.5 36.5 42.1 35.3 43.4"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="36.7 41.4 27.3 39.8 27.2 38.3 35.5 39.6 36.7 41.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="18.4 36.9 20 37.2 19 38.4 17.1 38.1 18.4 36.9"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="20 37.2 18.4 36.9 17.1 38.1 19 38.4 18.8 38.7 19.3 39.1 17.1 38.8 18.3 40.7 35.4 43.4 36.5 42.1 27.3 40.5 27.3 39.8 36.7 41.4 35.5 39.6 27.2 38.3 27.2 37.6 35.9 39 37.8 41.6 35.7 44.1 17.9 41.3 15.9 38.2 18.1 36.2 20.6 36.5 20 37.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="34.6 37.4 27.5 36.3 27.9 35 35.6 36.3 34.6 37.4"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="19.2 31.6 20.7 31.8 19.6 32.9 18.1 32.6 19.2 31.6"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="35.8 35.6 27.8 34.3 27.7 33 34.7 34.1 35.8 35.6"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="18.1 33.3 19.7 33.6 20.3 35.1 19.1 35 18.1 33.3"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="27.5 36.3 34.6 37.4 35.6 36.3 27.9 35 27.9 34.9 27.8 34.3 35.8 35.6 34.7 34.1 27.7 33 27.7 32.2 35.1 33.4 36.9 35.9 34.9 38.2 27.3 37 27.5 36.3"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20.7 31.8 19.2 31.6 18.1 32.6 19.6 32.9 19.4 33.1 19.7 33.6 18.1 33.3 19.1 35 20.3 35.1 20.6 35.9 18.7 35.6 16.9 32.8 19 30.9 21.3 31.2 20.7 31.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="18.8 28 20.3 28.2 20.9 29.6 19.8 29.4 18.8 28"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="35.2 30 28.4 28.9 28.5 27.8 34.3 28.7 35.2 30"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="19.8 26.4 21.4 26.6 20.4 27.5 18.9 27.3 19.8 26.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="34.2 31.7 28 30.8 28.4 29.6 35 30.7 34.2 31.7"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="21.4 26.6 19.8 26.4 18.9 27.3 20.4 27.5 20.1 27.8 20.3 28.2 18.8 28 19.8 29.4 20.9 29.6 21.3 30.4 19.4 30.1 17.6 27.4 19.6 25.6 22 26 21.4 26.6"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="28 30.8 34.2 31.7 35 30.7 28.4 29.6 28.4 29.5 28.4 28.9 35.2 30 34.3 28.7 28.5 27.8 28.5 27.1 34.7 28 36.3 30.3 34.5 32.5 27.8 31.4 28 30.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20 23.5 21.1 23.7 21.7 24.8 20.7 24.6 20 23.5"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="34 25.2 29 24.3 28.9 23.4 33.3 24.1 34 25.2"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="33.2 26.6 28.8 25.9 29 25 33.9 25.8 33.2 26.6"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="21.7 22.2 20.7 22.1 20 22.8 21.3 23 21 23.5 21.1 23.7 20 23.5 20.7 24.6 21.7 24.8 22 25.6 20.3 25.3 18.8 22.9 20.5 21.3 22 21.6 21.7 22.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="28.8 25.9 33.2 26.6 33.9 25.8 29 25 29.1 24.9 29 24.3 34 25.2 33.3 24.1 28.9 23.4 28.8 22.6 33.7 23.4 35.1 25.5 33.5 27.4 28.6 26.6 28.8 25.9"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20.7 22.1 21.5 22.2 21.1 23 20 22.8 20.7 22.1"
              fill="rgba(179,179,179,0.4)"
            />
            <polyline
              points="20.5 41.7 21 41.4 19.1 38.8 21.2 36.3 19.9 33.4 21.9 31.2 20.7 28.1 22.7 26 21.5 23.5 22.5 21.7 22.1 21.5 21 23.5 22.1 25.9 20.1 27.8 21.4 31.1 19.3 33.2 20.7 36.2 18.5 38.8"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M28.8,22.7h0c-.2,0-.3.1-.2.2l.2,1.6-.6,2.2h0v2.5l-.7,2.4h0l.2,2.9-.7,2.6h0v2.8l-.5,2.2c0,.2.1.3.2.3s.3,0,.3-.2l.5-2.2h0V37.3l.7-2.6h0l-.3-2.9.8-2.4h0V26.8l.5-2.2s.1,0,0-.1L29,22.9Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="39.7 61 37.5 57.7 15.7 54.4 13.5 57.1 39.7 61"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="13.4 56.8 15.6 60.2 37.3 63.4 39.5 60.7 13.4 56.8"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="39.5" y1="60.7" x2="13.4" y2="56.8" fill="#fff" />
            <path
              d="M40,60.7l-2.4-3.2-22-3.3-2.7,2.6,2.4,3.7,22.1,3.3ZM14.1,57.3,38.8,61l-1.7,2L15.8,59.8Zm24.6,3L14.1,56.6,15.8,55l21.4,3.2Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="38.6 54.5 36.7 51.4 16.9 48.3 14.9 50.7 38.6 54.5"
              fill="rgba(179,179,179,0.4)"
            />
            <line x1="38.3" y1="54.3" x2="14.9" y2="50.3" fill="#fff" />
            <path
              d="M39.1,54.1l-2.1-3L16.9,47.9l-2.5,2.3,2.2,3.5,20.1,3.2ZM15.6,50.8l22.2,3.8-1.4,1.5L17.1,53Zm22.5,3.1L15.6,50.1l1.5-1.4,19.5,3.1Z"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M12.7,63.6H40.5a0,0,0,0,1,0,0v0a6,6,0,0,1-6,6H18.7a6,6,0,0,1-6-6v0a0,0,0,0,1,0,0Z"
              fill="rgba(230,230,230,0.9)"
            />
            <path
              d="M40.8,68a2,2,0,0,1-2,2H14.4a2,2,0,0,1-2.1-2V63.3H40.8ZM13,68a1.3,1.3,0,0,0,1.4,1.3H38.8A1.3,1.3,0,0,0,40.1,68V64H13Z"
              fill="rgba(77,77,77,0.9)"
            />
          </svg>
        );
      case "implante_top":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M32.7,66.8H17.5L18.2,45S21,33.4,21,33.3s1.9-1.7,4.2-1.7,4.2.8,4.2,1.7S32,46.6,32,46.6Z"
              fill="rgba(153,153,153,0.4)"
            />
            <path
              d="M29.7,33.2c.1.5,1.7,8.9,2.6,13.3L33.1,67H17.2L18,45h0c.9-3.9,2.7-11.2,2.8-11.7s1.9-1.9,4.4-1.9S29.7,32.1,29.7,33.2ZM17.8,66.4H32.5l-.8-19.8L29.1,33.2c0-.6-1.6-1.4-3.9-1.4s-3.9.8-3.9,1.4L18.5,45Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="34.2 54.2 32.7 51.9 17.9 49.5 16.4 51.3 34.2 54.2"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="14.6 61.3 16.3 63.9 33.8 66.5 35.5 64.4 14.6 61.3"
              fill="#fff"
            />
            <line x1="35.5" y1="64.4" x2="14.6" y2="61.3" fill="#fff" />
            <path d="M35.9,64.4,34,61.8,16.3,59.2l-2.1,2,2,3,17.7,2.6ZM15.2,61.6l19.8,3-1.4,1.6L16.5,63.7ZM34.9,64,15.2,61.1l1.3-1.3,17.2,2.6Z" />
            <polygon
              points="15.8 56 17.4 58.5 33.2 61 34.8 59 15.8 56"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="34.6" y1="59.2" x2="15.8" y2="56" fill="#fff" />
            <path d="M35.2,59.1l-1.7-2.4L17.4,54.1l-2,1.9,1.8,2.7,16.1,2.6ZM16.4,56.4l17.7,3-1,1.3L17.5,58.2Zm18,2.5-18-3.1,1.2-1.1,15.6,2.5Z" />
            <polygon
              points="16.4 51.1 17.9 53.4 32.7 55.7 34.2 53.9 16.4 51.1"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="34" y1="54.1" x2="16.4" y2="51.1" fill="#fff" />
            <path
              d="M34.6,53.9,33,51.7,17.9,49.3l-1.8,1.8,1.6,2.5L32.8,56ZM17,51.5l16.5,2.8-.9,1.1L18.1,53.1Zm16.7,2.3L17,50.9l1.1-1,14.5,2.3Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="32.2 50.4 18.5 48.3 17.5 46.8 19.3 47.1 20.5 48.6 25.3 49.4 25.7 48.2 33.1 49.4 32.2 50.4"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="33.3 48.9 25.7 47.6 25.7 46.4 32.3 47.5 33.3 48.9"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="18.6 45.3 19.9 45.5 19.1 46.4 17.6 46.2 18.6 45.3"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="19.9 45.5 18.6 45.3 17.6 46.2 19.1 46.4 18.9 46.7 19.3 47.1 17.6 46.8 18.5 48.3 32.2 50.5 33.1 49.4 25.8 48.2 25.7 47.6 33.3 48.9 32.3 47.5 25.7 46.4 25.6 45.8 32.6 46.9 34.2 49.1 32.5 51.1 18.2 48.8 16.6 46.3 18.4 44.7 20.4 45 19.9 45.5"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="31.6 45.7 25.9 44.8 26.2 43.7 32.4 44.8 31.6 45.7"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="19.2 41 20.4 41.2 19.6 42 18.4 41.8 19.2 41"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="32.6 44.2 26.2 43.1 26.1 42.1 31.7 43 32.6 44.2"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="18.4 42.4 19.6 42.6 20.1 43.9 19.2 43.7 18.4 42.4"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="25.9 44.8 31.6 45.7 32.4 44.8 26.2 43.7 26.2 43.7 26.2 43.1 32.6 44.2 31.7 43 26.1 42.1 26 41.5 32 42.5 33.4 44.4 31.9 46.3 25.8 45.3 25.9 44.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20.4 41.2 19.2 41 18.4 41.8 19.6 42 19.4 42.2 19.6 42.6 18.4 42.4 19.2 43.7 20.1 43.9 20.4 44.5 18.9 44.2 17.4 42 19.1 40.4 20.9 40.7 20.4 41.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="18.9 38.1 20.1 38.3 20.6 39.4 19.7 39.3 18.9 38.1"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="32.1 39.8 26.6 38.8 26.7 37.9 31.3 38.7 32.1 39.8"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="19.7 36.8 21 37 20.3 37.8 19 37.5 19.7 36.8"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="31.3 41.1 26.3 40.3 26.6 39.4 31.9 40.3 31.3 41.1"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="21 37 19.7 36.8 19 37.5 20.3 37.8 20 38 20.1 38.3 18.9 38.1 19.7 39.3 20.6 39.4 20.9 40.1 19.4 39.8 18 37.7 19.6 36.2 21.5 36.5 21 37"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="26.3 40.3 31.3 41.1 31.9 40.3 26.6 39.4 26.6 39.3 26.6 38.8 32.1 39.8 31.3 38.7 26.7 37.9 26.7 37.4 31.6 38.1 33 40 31.5 41.7 26.1 40.9 26.3 40.3"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="19.9 34.5 20.8 34.7 21.2 35.6 20.5 35.4 19.9 34.5"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="31.2 35.8 27.1 35.1 27 34.4 30.5 35 31.2 35.8"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="30.5 37 26.9 36.5 27.1 35.7 31 36.4 30.5 37"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="21.3 33.5 20.5 33.4 19.9 33.9 20.9 34.1 20.7 34.5 20.8 34.7 19.9 34.5 20.5 35.4 21.2 35.6 21.5 36.2 20.1 36 18.9 34.1 20.3 32.8 21.5 33 21.3 33.5"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="26.9 36.5 30.5 37 31 36.4 27.1 35.7 27.2 35.7 27.1 35.1 31.2 35.8 30.5 35 27 34.4 26.9 33.8 30.9 34.5 32 36.1 30.7 37.6 26.8 37 26.9 36.5"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20.5 33.4 21.1 33.5 20.8 34.1 19.9 33.9 20.5 33.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polyline
              points="20.3 49.1 20.7 48.9 19.2 46.8 20.9 44.7 19.8 42.4 21.4 40.7 20.5 38.2 22.1 36.5 21.1 34.5 21.9 33.1 21.6 32.9 20.7 34.5 21.6 36.4 20 38 21 40.6 19.3 42.3 20.4 44.7 18.7 46.8"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M27,33.8h-.1c-.1,0-.2.1-.1.2v1.3l-.4,1.7h0v2l-.6,2h0l.2,2.3-.6,2.1h0v2.3l-.4,1.7a.2.2,0,0,0,.2.2c.1.1.2,0,.2-.1l.4-1.8h0V45.6l.6-2.1h0l-.2-2.3.6-1.9h0v-2l.4-1.7h0L27.1,34A.3.3,0,0,0,27,33.8Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="35.7 64.6 33.9 61.9 16.5 59.3 14.7 61.5 35.7 64.6"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="14.6 61.3 16.3 63.9 33.8 66.5 35.5 64.4 14.6 61.3"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="35.5" y1="64.4" x2="14.6" y2="61.3" fill="#fff" />
            <path
              d="M35.9,64.4,34,61.8,16.3,59.2l-2.1,2,2,3,17.7,2.6ZM15.2,61.6l19.8,3-1.4,1.6L16.5,63.7ZM34.9,64,15.2,61.1l1.3-1.3,17.2,2.6Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="34.8 59.4 33.3 56.9 17.4 54.4 15.8 56.3 34.8 59.4"
              fill="rgba(179,179,179,0.4)"
            />
            <line x1="34.6" y1="59.2" x2="15.8" y2="56" fill="#fff" />
            <path
              d="M35.2,59.1l-1.7-2.4L17.4,54.1l-2,1.9,1.8,2.7,16.1,2.6ZM16.4,56.4l17.7,3-1,1.3L17.5,58.2Zm18,2.5-18-3.1,1.2-1.1,15.6,2.5Z"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M14,66.7H36.3a0,0,0,0,1,0,0v0a4.8,4.8,0,0,1-4.8,4.8H18.9A4.8,4.8,0,0,1,14,66.7v0A0,0,0,0,1,14,66.7Z"
              fill="rgba(230,230,230,0.4)"
            />
            <path
              d="M36.6,70.2A1.6,1.6,0,0,1,35,71.8H15.4a1.7,1.7,0,0,1-1.7-1.6V66.4H36.6Zm-22.3,0a1,1,0,0,0,1.1,1H35a1,1,0,0,0,1-1V67H14.3Z"
              fill="rgba(77,77,77,0.4)"
            />
          </svg>
        );
      case "implante_bot_large":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M34,42.8H15l.9,27,3.5,14.7c0,1.1,2.3,2.1,5.2,2.1s5.2-1,5.2-2.1l3.3-16.6Z"
              fill="rgba(153,153,153,0.4)"
            />
            <path
              d="M24.7,87c-3.2,0-5.6-1-5.6-2.4S16.8,74.8,15.6,70h0l-.9-27.4H34.4L33.5,68,30.2,84.6C30.2,86,27.8,87,24.7,87ZM16.3,69.9l3.5,14.6c0,.9,2,1.8,4.9,1.8s4.8-.9,4.8-1.8l3.3-16.6.9-24.7H15.4Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="35.8 58.5 34 61.3 15.6 64.2 13.7 62 35.8 58.5"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="11.4 49.6 13.6 46.3 35.3 43 37.5 45.7 11.4 49.6"
              fill="#fff"
            />
            <line x1="37.5" y1="45.7" x2="11.4" y2="49.6" fill="#fff" />
            <path
              d="M35.4,42.7,13.3,46l-2.4,3.7,2.7,2.5,22-3.3L38,45.7ZM13.8,46.6l21.3-3.2,1.7,2.1L12.1,49.1Zm21.4,1.6L13.8,51.4l-1.7-1.5,24.6-3.7Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="12.9 56.1 14.8 53.1 34.6 50 36.6 52.4 12.9 56.1"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="36.3" y1="52.2" x2="12.9" y2="56.1" fill="#fff" />
            <path
              d="M34.7,49.6,14.6,52.8l-2.2,3.4,2.5,2.3L35,55.3l2.1-3ZM15.1,53.4l19.3-3.1,1.4,1.6L13.6,55.7Zm19.5,1.3-19.5,3-1.5-1.3,22.5-3.8Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="13.7 62.3 15.5 59.4 33.9 56.5 35.8 58.7 13.7 62.3"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="35.5" y1="58.6" x2="13.7" y2="62.3" fill="#fff" />
            <path
              d="M34.1,56.1l-18.8,3-2.1,3.2,2.4,2.2,18.7-3,2-2.8ZM15.7,59.8l18.1-2.9L35,58.3,14.4,61.8Zm18.2,1.1L15.8,63.7l-1.4-1.2L35.2,59Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="33.3 63.1 16.3 65.8 15 67.7 17.2 67.3 18.7 65.4 24.8 64.4 25.3 65.9 34.5 64.4 33.3 63.1"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="34.7 65.1 25.3 66.7 25.2 68.1 33.5 66.8 34.7 65.1"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="16.4 69.5 18 69.3 17 68.1 15.1 68.4 16.4 69.5"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="18 69.3 16.4 69.5 15.1 68.4 17 68.1 16.8 67.8 17.3 67.3 15.1 67.7 16.3 65.8 33.4 63.1 34.5 64.4 25.3 65.9 25.3 66.7 34.7 65.1 33.5 66.8 25.2 68.1 25.2 68.9 33.9 67.5 35.8 64.8 33.7 62.3 15.9 65.1 13.9 68.2 16.1 70.3 18.6 69.9 18 69.3"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="32.6 69 25.5 70.2 25.9 71.5 33.6 70.2 32.6 69"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="17.2 74.8 18.7 74.6 17.6 73.6 16.1 73.8 17.2 74.8"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="33.8 70.8 25.8 72.2 25.7 73.5 32.7 72.4 33.8 70.8"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="16.1 73.1 17.7 72.9 18.3 71.3 17.1 71.5 16.1 73.1"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="25.5 70.2 32.6 69 33.6 70.2 25.9 71.5 25.9 71.5 25.8 72.2 33.8 70.8 32.7 72.4 25.7 73.5 25.7 74.2 33.1 73 34.9 70.6 32.9 68.3 25.3 69.5 25.5 70.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="18.7 74.6 17.2 74.8 16.1 73.8 17.6 73.6 17.4 73.4 17.7 72.9 16.1 73.1 17.1 71.5 18.3 71.3 18.6 70.5 16.7 70.8 14.9 73.7 17 75.6 19.3 75.2 18.7 74.6"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="16.8 78.5 18.3 78.2 18.9 76.8 17.8 77 16.8 78.5"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="33.2 76.4 26.4 77.6 26.5 78.7 32.3 77.8 33.2 76.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="17.8 80.1 19.4 79.8 18.4 78.9 16.9 79.2 17.8 80.1"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="32.2 74.7 26 75.7 26.4 76.8 33 75.7 32.2 74.7"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="19.4 79.8 17.8 80.1 16.9 79.2 18.4 78.9 18.1 78.6 18.3 78.2 16.8 78.5 17.8 77 18.9 76.8 19.3 76 17.4 76.4 15.6 79 17.6 80.8 20 80.5 19.4 79.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="26 75.7 32.2 74.7 33 75.7 26.4 76.8 26.4 76.9 26.4 77.6 33.2 76.4 32.3 77.8 26.5 78.7 26.5 79.4 32.7 78.4 34.3 76.1 32.5 74 25.8 75 26 75.7"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="18 83 19.1 82.8 19.7 81.6 18.7 81.8 18 83"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="32 81.3 27 82.2 26.9 83.1 31.3 82.4 32 81.3"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="31.2 79.8 26.8 80.5 27 81.4 31.9 80.6 31.2 79.8"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="19.7 84.2 18.7 84.4 18 83.7 19.3 83.5 19 82.9 19.1 82.8 18 83 18.7 81.8 19.7 81.6 20 80.9 18.3 81.1 16.8 83.5 18.5 85.1 20 84.8 19.7 84.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="26.8 80.5 31.2 79.8 31.9 80.6 27 81.4 27.1 81.5 27 82.2 32 81.3 31.3 82.4 26.9 83.1 26.8 83.8 31.7 83 33.1 81 31.5 79 26.6 79.8 26.8 80.5"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="18.7 84.4 19.5 84.2 19.1 83.5 18 83.7 18.7 84.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polyline
              points="18.5 64.7 19 65 17.1 67.6 19.2 70.2 17.9 73.1 19.9 75.3 18.7 78.4 20.7 80.5 19.5 83 20.5 84.7 20.1 84.9 19 83 20.1 80.6 18.1 78.6 19.4 75.4 17.3 73.3 18.7 70.3 16.5 67.6"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M26.8,83.8h0c-.2,0-.3-.2-.2-.3l.2-1.6-.6-2.1h0V77.2l-.7-2.4h0l.2-2.9-.7-2.6h0V66.3l-.5-2.2.2-.3c.1,0,.3.1.3.2l.5,2.2h0v2.8l.7,2.6h0l-.3,2.9.8,2.4h0v2.5l.5,2.1c0,.1.1.1,0,.1L27,83.6A.2.2,0,0,1,26.8,83.8Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="37.7 45.5 35.5 48.8 13.7 52 11.5 49.4 37.7 45.5"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="11.4 49.6 13.6 46.3 35.3 43 37.5 45.7 11.4 49.6"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="37.5" y1="45.7" x2="11.4" y2="49.6" fill="#fff" />
            <path
              d="M35.4,42.7,13.3,46l-2.4,3.7,2.7,2.5,22-3.3L38,45.7ZM13.8,46.6l21.3-3.2,1.7,2.1L12.1,49.1Zm21.4,1.6L13.8,51.4l-1.7-1.5,24.6-3.7Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="36.6 52 34.7 55 14.9 58.1 12.9 55.7 36.6 52"
              fill="rgba(179,179,179,0.4)"
            />
            <line x1="36.3" y1="52.2" x2="12.9" y2="56.1" fill="#fff" />
            <path
              d="M34.7,49.6,14.6,52.8l-2.2,3.4,2.5,2.3L35,55.3l2.1-3ZM15.1,53.4l19.3-3.1,1.4,1.6L13.6,55.7Zm19.5,1.3-19.5,3-1.5-1.3,22.5-3.8Z"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M16.7,36.8H32.4a6,6,0,0,1,6,6v0a0,0,0,0,1,0,0H10.7a0,0,0,0,1,0,0v0A6,6,0,0,1,16.7,36.8Z"
              fill="rgba(230,230,230,0.9)"
            />
            <path
              d="M38.8,43.2H10.3V38.5a2.1,2.1,0,0,1,2.1-2.1H36.8a2.1,2.1,0,0,1,2,2.1ZM11,42.5H38.1v-4a1.3,1.3,0,0,0-1.3-1.3H12.4A1.3,1.3,0,0,0,11,38.5Z"
              fill="rgba(77,77,77,0.9)"
            />
          </svg>
        );
      case "implante_bot":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 110"
          >
            <path
              d="M34.1,39.5H18.9l.7,21.7L22.4,73c0,.9,1.9,1.7,4.2,1.7s4.2-.8,4.2-1.7l2.6-13.3Z"
              fill="rgba(153,153,153,0.4)"
            />
            <path
              d="M26.6,75c-2.5,0-4.4-.8-4.4-1.9L19.4,61.4h0l-.7-22H34.5l-.8,20.5c-.9,4.4-2.5,12.8-2.6,13.3S29.2,75,26.6,75ZM20,61.2,22.7,73c0,.7,1.6,1.4,3.9,1.4s3.9-.7,3.9-1.4l2.6-13.3.8-19.8H19.2Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="35.6 52.1 34.1 54.4 19.4 56.7 17.8 54.9 35.6 52.1"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="16 45 17.7 42.3 35.2 39.7 37 41.9 16 45"
              fill="#fff"
            />
            <line x1="37" y1="41.9" x2="16" y2="45" fill="#fff" />
            <path d="M35.3,39.4,17.6,42.1l-2,3,2.1,2,17.8-2.7,1.8-2.5ZM17.9,42.6,35,40l1.4,1.7L16.6,44.6Zm17.2,1.3L17.9,46.5l-1.3-1.3,19.7-2.9Z" />
            <polygon
              points="17.2 50.2 18.8 47.8 34.6 45.3 36.3 47.2 17.2 50.2"
              fill="rbga(230,230,230,0.4)"
            />
            <line x1="36" y1="47" x2="17.2" y2="50.2" fill="#fff" />
            <path d="M34.7,45,18.6,47.5l-1.8,2.8,2,1.8,16.1-2.5,1.7-2.4ZM18.9,48.1l15.6-2.5,1.1,1.2L17.8,49.9Zm15.7,1L19,51.5l-1.2-1.1,18-3Z" />
            <polygon
              points="17.8 55.2 19.3 52.9 34.1 50.5 35.6 52.3 17.8 55.2"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="35.4" y1="52.2" x2="17.8" y2="55.2" fill="#fff" />
            <path
              d="M34.2,50.2,19.1,52.6l-1.6,2.6,1.8,1.7,15.1-2.3L36,52.3ZM19.5,53.1,34,50.8l.9,1.2L18.4,54.8Zm14.6.9L19.5,56.3l-1.1-1,16.7-2.8Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="33.6 55.8 19.9 58 18.9 59.5 20.7 59.2 21.9 57.7 26.7 56.9 27.1 58.1 34.5 56.9 33.6 55.8"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="34.7 57.4 27.1 58.7 27.1 59.9 33.7 58.8 34.7 57.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="20 61 21.3 60.8 20.5 59.8 19 60.1 20 61"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="21.3 60.8 20 61 19 60.1 20.5 59.8 20.3 59.6 20.7 59.2 19 59.5 19.9 58 33.6 55.8 34.5 56.9 27.2 58.1 27.1 58.7 34.7 57.4 33.7 58.8 27.1 59.9 27 60.4 34.1 59.3 35.6 57.2 33.9 55.2 19.6 57.5 18 59.9 19.8 61.6 21.8 61.3 21.3 60.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="33 60.6 27.3 61.5 27.6 62.5 33.8 61.5 33 60.6"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="20.6 65.2 21.8 65 21 64.2 19.8 64.4 20.6 65.2"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="34 62 27.6 63.1 27.5 64.2 33.1 63.3 34 62"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="19.8 63.9 21 63.7 21.5 62.4 20.6 62.6 19.8 63.9"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="27.3 61.5 33 60.6 33.8 61.5 27.6 62.5 27.6 62.6 27.6 63.1 34 62 33.1 63.3 27.5 64.2 27.4 64.7 33.4 63.8 34.8 61.8 33.3 60 27.2 60.9 27.3 61.5"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="21.8 65 20.6 65.2 19.8 64.4 21 64.2 20.9 64.1 21 63.7 19.8 63.9 20.6 62.6 21.5 62.4 21.8 61.8 20.3 62 18.8 64.3 20.5 65.8 22.3 65.5 21.8 65"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="20.4 68.2 21.5 68 22 66.8 21.1 67 20.4 68.2"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="33.5 66.5 28 67.4 28.1 68.3 32.7 67.6 33.5 66.5"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="21.1 69.4 22.4 69.2 21.7 68.5 20.4 68.7 21.1 69.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="32.7 65.1 27.7 65.9 28 66.9 33.3 65.9 32.7 65.1"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="22.4 69.2 21.1 69.4 20.4 68.7 21.7 68.5 21.4 68.3 21.5 68 20.4 68.2 21.1 67 22 66.8 22.3 66.2 20.8 66.5 19.4 68.6 21 70 22.9 69.8 22.4 69.2"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="27.7 65.9 32.7 65.1 33.3 65.9 28 66.9 28 66.9 28 67.4 33.5 66.5 32.7 67.6 28.1 68.3 28.1 68.9 33.1 68.1 34.4 66.3 32.9 64.5 27.6 65.4 27.7 65.9"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="21.3 71.8 22.2 71.6 22.6 70.7 21.9 70.8 21.3 71.8"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="32.6 70.4 28.5 71.1 28.4 71.9 32 71.3 32.6 70.4"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="31.9 69.2 28.3 69.8 28.5 70.5 32.4 69.9 31.9 69.2"
              fill="rgba(230,230,230,0.4)"
            />
            <polygon
              points="22.7 72.8 21.9 72.9 21.3 72.3 22.4 72.2 22.1 71.7 22.2 71.6 21.3 71.8 21.9 70.8 22.6 70.7 22.9 70.1 21.5 70.3 20.3 72.2 21.7 73.5 22.9 73.2 22.7 72.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="28.3 69.8 31.9 69.2 32.4 69.9 28.5 70.5 28.6 70.6 28.5 71.1 32.6 70.4 32 71.3 28.4 71.9 28.3 72.4 32.3 71.8 33.4 70.2 32.1 68.6 28.2 69.2 28.3 69.8"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="21.9 72.9 22.5 72.8 22.2 72.2 21.3 72.3 21.9 72.9"
              fill="rgba(179,179,179,0.4)"
            />
            <polyline
              points="21.7 57.1 22.1 57.4 20.6 59.4 22.3 61.5 21.2 63.8 22.8 65.6 21.9 68.1 23.5 69.8 22.5 71.8 23.3 73.2 23 73.4 22.1 71.8 23 69.8 21.4 68.3 22.4 65.7 20.8 64 21.8 61.6 20.1 59.5"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M28.4,72.4h-.1c-.1,0-.1-.1-.1-.2V70.9l-.4-1.7h0v-2l-.6-2h0l.2-2.3-.6-2.1h0V58.4l-.4-1.8a.2.2,0,1,1,.4,0l.4,1.7h0v2.3l.6,2.1h0l-.2,2.3.6,2h0v2l.4,1.7h0l-.2,1.3C28.5,72.3,28.5,72.4,28.4,72.4Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="37.1 41.7 35.3 44.3 17.9 46.9 16.1 44.8 37.1 41.7"
              fill="rgba(179,179,179,0.4)"
            />
            <polygon
              points="16 45 17.7 42.3 35.2 39.7 37 41.9 16 45"
              fill="rgba(230,230,230,0.4)"
            />
            <line x1="37" y1="41.9" x2="16" y2="45" fill="#fff" />
            <path
              d="M35.3,39.4,17.6,42.1l-2,3,2.1,2,17.8-2.7,1.8-2.5ZM17.9,42.6,35,40l1.4,1.7L16.6,44.6Zm17.2,1.3L17.9,46.5l-1.3-1.3,19.7-2.9Z"
              fill="rgba(77,77,77,0.4)"
            />
            <polygon
              points="36.3 46.9 34.7 49.3 18.8 51.9 17.2 49.9 36.3 46.9"
              fill="rgba(179,179,179,0.4)"
            />
            <line x1="36" y1="47" x2="17.2" y2="50.2" fill="#fff" />
            <path
              d="M34.7,45,18.6,47.5l-1.8,2.8,2,1.8,16.1-2.5,1.7-2.4ZM18.9,48.1l15.6-2.5,1.1,1.2L17.8,49.9Zm15.7,1L19,51.5l-1.2-1.1,18-3Z"
              fill="rgba(77,77,77,0.4)"
            />
            <path
              d="M20.3,34.7H32.9a4.8,4.8,0,0,1,4.8,4.8v0a0,0,0,0,1,0,0H15.4a0,0,0,0,1,0,0v0A4.8,4.8,0,0,1,20.3,34.7Z"
              fill="rgba(230,230,230,0.4)"
            />
            <path
              d="M38,39.9H15.1V36.1a1.8,1.8,0,0,1,1.7-1.7H36.4A1.7,1.7,0,0,1,38,36.1Zm-22.3-.6H37.4V36.1a1.1,1.1,0,0,0-1-1.1H16.8a1.1,1.1,0,0,0-1.1,1.1Z"
              fill="rgba(77,77,77,0.4)"
            />
          </svg>
        );
      default:
        return "";
    }
  };

  if (tooth.status != "NORMAL" && tooth.status != "IMPLANTE") {
    rootColor = "rgba(206,206,206,0.5)";
    backRootColor = "rgba(175,175,175,0.5)";

    switch (tooth.status) {
      case "INCLUDED":
        tag = (
          <span
            className={css(styles.balloon)}
            style={{ background: "rgba(134,191,191,0.5)" }}
          >
            {" "}
            INC{" "}
          </span>
        );
        break;
      case "AGENESIS":
        tag = (
          <span
            className={css(styles.balloon)}
            style={{ background: "rgba(122,109,137,0.5)" }}
          >
            {" "}
            AGE{" "}
          </span>
        );
        break;
      case "EXODONTIA":
        tag = (
          <span
            className={css(styles.balloon)}
            style={{ background: "rgba(216,199,76,0.5)" }}
          >
            {" "}
            EXO{" "}
          </span>
        );
        break;
      case "MISSING":
        tag = (
          <span
            className={css(styles.balloon)}
            style={{ background: "rgba(157,196,88,0.5)" }}
          >
            {" "}
            AUS{" "}
          </span>
        );
        break;
      case "LOST":
        tag = (
          <span
            className={css(styles.balloon)}
            style={{ background: "rgba(226,175,93,0.5)" }}
          >
            {" "}
            PER{" "}
          </span>
        );
        break;
      default:
        break;
    }

    var listTop = [
      "55",
      "54",
      "53",
      "52",
      "51",
      "61",
      "62",
      "63",
      "64",
      "65",
      "18",
      "17",
      "16",
      "15",
      "14",
      "13",
      "12",
      "11",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ];
    var positionTag = "-2px";
    if (listTop.indexOf(number) != -1) {
      positionTag = "91px";
    }

    return (
      <div>
        <span style={{ position: "absolute" }}>
          {getSvg("tooth_" + number)}
        </span>
        <span style={{ position: "absolute" }}>{getSvg("root_" + number)}</span>
        <span style={{ position: "absolute", top: positionTag }}>{tag}</span>
      </div>
    );
  } else if (tooth.status == "IMPLANTE") {
    var listTop = [
      "55",
      "54",
      "53",
      "52",
      "51",
      "61",
      "62",
      "63",
      "64",
      "65",
      "18",
      "17",
      "16",
      "15",
      "14",
      "13",
      "12",
      "11",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ];

    var implante;

    if (listTop.indexOf(number) != -1) {
      implante = "implante_top_large";
      return (
        <div>
          <span style={{ position: "absolute" }}>
            {getSvg("tooth_" + number)}
          </span>
          <span
            style={{
              position: "absolute",
              marginLeft: "-1px",
              marginTop: "-5px",
            }}
          >
            {getSvg(implante)}
          </span>
        </div>
      );
    } else {
      implante = "implante_bot_large";
      return (
        <div>
          <span style={{ position: "absolute" }}>
            {getSvg("tooth_" + number)}
          </span>
          <span
            style={{
              position: "absolute",
              marginLeft: "1px",
              marginTop: "7px",
            }}
          >
            {getSvg(implante)}
          </span>
        </div>
      );
    }
  } else {
    return (
      <div>
        <span style={{ position: "absolute" }}>
          {getSvg("tooth_" + number)}
        </span>
        <span style={{ position: "absolute" }}>{getSvg("root_" + number)}</span>
      </div>
    );
  }
}

export default IconPerio;
