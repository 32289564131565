import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarServico,
  buscarServico,
  editarServico,
} from "../../../../api/servicos";
import { listarCodigos } from "../../../../api/odontograma";

class ServicosCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "descricao", label: "Descrição" },
      { type: "number", name: "preco", label: "Preço" },
      {
        type: "select",
        name: "tipo_procedimento",
        label: "Tipo do procedimento",
        values: [
          { name: "NOTYPE", value: "NOTYPE" },
          { name: "CROWN", value: "CROWN" },
          { name: "RESTORATION", value: "RESTORATION" },
          { name: "RIZECTOMY", value: "RIZECTOMY" },
          { name: "APICECTOMY", value: "APICECTOMY" },
          { name: "CHANNEL", value: "CHANNEL" },
          { name: "XRAY", value: "XRAY" },
          { name: "DENTURES", value: "DENTURES" },
          { name: "MOBILE", value: "MOBILE" },
          { name: "FIXED", value: "FIXED" },
          { name: "ADHESIVE", value: "ADHESIVE" },
          { name: "SPLINTER", value: "SPLINTER" },
          { name: "GRAFT", value: "GRAFT" },
          { name: "CONEMORSE", value: "CONEMORSE" },
          { name: "SHORT", value: "SHORT" },
          { name: "HEXAGONAL_OUT", value: "HEXAGONAL_OUT" },
          { name: "HEXAGONAL_IN", value: "HEXAGONAL_IN" },
          { name: "ZYGOMATIC", value: "ZYGOMATIC" },
          { name: "NOBEL_REPLACE", value: "NOBEL_REPLACE" },
          { name: "RETAIL", value: "RETAIL" },
          { name: "STEEL_CROWN", value: "STEEL_CROWN" },
          { name: "PULPECTOMY", value: "PULPECTOMY" },
          { name: "FACETS", value: "FACETS" },
          { name: "CORE", value: "CORE" },
          { name: "METAL_BLOCK", value: "METAL_BLOCK" },
          { name: "RESORPTION", value: "RESORPTION" },
          { name: "RAP", value: "RAP" },
          { name: "FRENULOTOMY", value: "FRENULOTOMY" },
          { name: "SELANTE", value: "SELANTE" },
          { name: "EXTRACTION", value: "EXTRACTION" },
        ],
      },
      {
        type: "select",
        name: "ativo",
        label: "Status",
        values: [
          { name: "Ativo", value: true },
          { name: "Inativo", value: false },
        ],
      },
      { type: "text", name: "observacao", label: "Observação" },
      { type: "color", name: "cor", label: "Cor odontograma" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    servico: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;
    var odontograma = await listarCodigos();

    var codOdontograma = {
      type: "select",
      name: "codOdontograma",
      label: "Código Odontograma",
      values: [],
    };

    odontograma.map((obj) => {
      var novo = {};
      novo.name = `(${obj.codOdontograma}) - ${obj.descricao}`;
      novo.value = obj._id;
      return codOdontograma.values.push(novo);
    });

    var duplica = this.props.location.state
      ? this.props.location.state.servico
      : "";

    if (this.props.match.params.servico_id) {
      var objServico = await buscarServico(
        clinica._id,
        this.props.match.params.servico_id,
      );
      this.setState({
        cadastro: false,
        servico: objServico,
        await: false,
        formulario: [...this.state.formulario, codOdontograma],
      });
    } else {
      if (duplica) {
        duplica.descricao = duplica.descricao + "  (cópia)";
        this.setState({ servico: duplica });
      }
      this.setState({
        await: false,
        formulario: [...this.state.formulario, codOdontograma],
      });
    }
  }

  editaServico(objeto) {
    editarServico(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Serviço editado com sucesso!",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o serviço!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  salvarServico(objeto) {
    const { clinica } = this.props;

    cadastrarServico(clinica._id, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Servico cadastrado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o serviço!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.servico}
            action={
              this.state.cadastro
                ? this.salvarServico.bind(this)
                : this.editaServico.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ServicosCadastro);
