import React from "react";
import { Route, Switch } from "react-router-dom";
import ClienteListagem from "./components/ClienteListagem";
import FormCadastroCliente from "./components/FormCadastroCliente";
import View from "./View";
import { Container, Paper } from "@material-ui/core";

export default function ClientsRouter(props) {
  return (
    <>
      <Container maxWidth="lg" component={Paper} style={{ padding: "16px" }}>
        <Switch>
          <Route
            exact
            path={`${props.match.url}/`}
            component={ClienteListagem}
          />
          <Route
            exact
            path={`${props.match.url}/novo`}
            component={FormCadastroCliente}
          />
          <Route
            exact
            path={`${props.match.url}/:clientId`}
            render={(props) => <View {...props} />}
          />
        </Switch>
      </Container>
    </>
  );
}
