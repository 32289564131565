import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import DentistasConfigCategoria from "./DentistasConfigCategoria";
import DentistasCategorias from "./DentistasCategorias";
import DentistasConfigEspecialidade from "./DentistasConfigEspecialidade";
import DentistasEspecialidade from "./DentistasEspecialidade";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    minHeight: "83vh",
  },
}));

function DentistasConfig(props) {
  useEffect(() => {
    const { tab, trocarTab } = props;

    const tabSelecionada = tab.lista.filter(
      (tab) => tab.url === "configuracoes",
    )[0];

    if (tabSelecionada.numero !== tab.tabAtiva) {
      trocarTab(tabSelecionada.numero);
    }
  });

  const classes = useStyles();
  const { match } = props;

  return (
    <Paper className={classes.paper}>
      {match.isExact && (
        <List
          component="nav"
          className={classes.root}
          aria-label="Configurações"
        >
          <ListItem
            onClick={() => props.history.push(`${match.url}/categoria`)}
            button
          >
            <ListItemText primary="Cadastro de Categorias" />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => props.history.push(`${match.url}/especialidade`)}
            button
          >
            <ListItemText primary="Cadastro de Especialidades" />
          </ListItem>
          <Divider />
        </List>
      )}
      <Switch>
        <Route
          exact
          path={`${match.url}/categoria`}
          render={(props) => <DentistasConfigCategoria {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarCategoria/:categoria_id?`}
          render={(props) => <DentistasCategorias {...props} />}
        />
        <Route
          exact
          path={`${match.url}/especialidade`}
          render={(props) => <DentistasConfigEspecialidade {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarEspecialidade/:especialidade_id?`}
          render={(props) => <DentistasEspecialidade {...props} />}
        />
      </Switch>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DentistasConfig);
