import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarTabela,
  editarTabela,
  buscarTabela,
} from "../../../../api/servicos";
import { buscarConvenios } from "../../../../api/convenios";

class TabelaCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "titulo", label: "Titulo" },
      { type: "number", name: "indice", label: "Indice" },
      {
        type: "select",
        name: "ativo",
        label: "Status",
        values: [
          { name: "Ativo", value: true },
          { name: "Inativo", value: false },
        ],
      },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    tabela: "",
    cadastro: true,
    await: true,
    convenios: [],
  };

  async componentWillMount() {
    const { clinica } = this.props;
    const convenios = await buscarConvenios();

    convenios.map((convenio) => {
      convenio.name = convenio.nomeConvenio;
      convenio.value = convenio._id;
      return convenio;
    });

    const convenioForm = {
      type: "select",
      name: "convenio",
      label: "Convênio",
      values: convenios,
    };

    if (this.props.match.params.tabela_id) {
      var objTabela = await buscarTabela(
        clinica._id,
        this.props.match.params.tabela_id,
      );
      this.setState({
        cadastro: false,
        tabela: objTabela,
        await: false,
        convenios: convenios,
        formulario: { ...this.state.formulario, convenioForm },
      });
    } else {
      this.setState({
        await: false,
        convenios: convenios,
        formulario: [...this.state.formulario, convenioForm],
      });
    }
  }

  editaTabela(objeto) {
    editarTabela(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Tabela editada com sucesso!",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a tabela!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  salvarTabela(objeto) {
    const { clinica } = this.props;

    cadastrarTabela(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Tabela cadastrada com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a tabela!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.tabela}
            action={
              this.state.cadastro
                ? this.salvarTabela.bind(this)
                : this.editaTabela.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(TabelaCadastro);
