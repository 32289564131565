export function mobilidadeConst() {
  var MOBILIDADE = {
    patient_id: null,
    tooth_18: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_17: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_16: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_15: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_14: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_13: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_12: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_11: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_21: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_22: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_23: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_24: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_25: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_26: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_27: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_28: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_48: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_47: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_46: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_45: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_44: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_43: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_42: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_41: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_31: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_32: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_33: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_34: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_35: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_36: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_37: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
    tooth_38: {
      status: "NORMAL",
      grauMobilidade: 0,
    },
  };
  return { type: "MOBILIDADE", mobilidade: MOBILIDADE };
}
