import axios from "axios";

export async function getListaParcelas({ dataInicial, dataFinal, pago }) {
  try {
    const { data, status } = await axios.get("/api/financeiro/listaParcelas", {
      params: { dataInicial, dataFinal, pago },
    });
    if (status === 200) return data;
  } catch (error) {
    console.log(error);
  }
}

export const getFluxoCaixa = async () => {
  try {
    const { status, data } = await axios.get(`/api/financeiro/fluxoCaixa`);
    if (status === 200) return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchPendingBudgets = async () => {
  try {
    const { status, data } = await axios.get(
      "/api/financeiro/orcamentosPendentes"
    );
    if (status === 200) return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchInstallments = async ({
  startDate,
  endDate,
  search,
  patientId,
}) => {
  try {
    const { status, data } = await axios.get("/api/financeiro/faturas", {
      params: { startDate, endDate, search, patientId },
    });
    if (status === 200) return data;
  } catch (error) {
    console.log(error);
  }
};
