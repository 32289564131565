import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import moment from "moment";

import { css } from "aphrodite/no-important";
import { styles } from "./OdontogramFormStyles";
import ReactTooltip from "react-tooltip";

import {
  buscarPlacas,
  atualizarPlacas,
  novaMedicaoPlacas,
} from "../../../actions/placasActions";
import { placasConst } from "../../_constants/placasConst";

import IconOdontogram from "../../common/IconOdontogram";
import Button from "../../common/Button";
import ButtonMui from "@material-ui/core/Button";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

class PlacasForm extends Component {
  constructor(props) {
    super(props);

    this.novaMedicao = this.novaMedicao.bind(this);
    this.selecionarMedicao = this.selecionarMedicao.bind(this);
    this.onClickTooth = this.onClickTooth.bind(this);
    this.onClickFace = this.onClickFace.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      modal: false,
      modalNewMeasure: false,
      isLoading: false,
      placas: null,
      listaMedicoes: [],
      toothSelected: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { buscarPlacas } = this.props;

    if (nextProps.selectedPatient !== this.props.selectedPatient) {
      buscarPlacas(nextProps.selectedPatient._id, (ret) => {
        if (ret.length > 0) {
          this.setState({
            placas: ret[0],
            listaMedicoes: ret,
          });
        }
      });
    }
  }

  componentWillMount() {
    const { placasConst, selectedPatient, buscarPlacas } = this.props;

    var newplacas;

    newplacas = placasConst();
    newplacas = newplacas.placas;

    this.setState({
      placas: newplacas,
    });

    if (selectedPatient) {
      buscarPlacas(selectedPatient._id, (ret) => {
        if (ret.length > 0) {
          this.setState({
            placas: ret[0],
            listaMedicoes: ret,
          });
        }
      });
    } else {
      this.setState({
        placas: newplacas,
      });
    }
  }

  selecionarMedicao(id) {
    var medicao = this.state.listaMedicoes.filter(function (medicao) {
      return medicao._id === id;
    })[0];

    this.setState({ placas: medicao });
  }

  novaMedicao() {
    const { novaMedicaoPlacas, selectedPatient } = this.props;

    let lista = this.state.listaMedicoes;

    var newplacas;

    newplacas = placasConst();
    newplacas = newplacas.placas;
    newplacas.date = this.state.placas.date;

    newplacas.patient_id = selectedPatient._id;

    this.setState({ isLoading: true });

    novaMedicaoPlacas(newplacas, (call) => {
      lista.push(call);
      this.setState({
        listaMedicoes: lista,
        placas: call,
        modalNewMeasure: false,
      });
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    });
  }

  openModal() {
    this.setState({
      modal: true,
    });
  }

  closeModal() {
    this.setState({
      modal: false,
    });
  }

  onClickTooth(res) {
    this.setState({
      toothSelected: res.currentTarget.id,
    });
  }

  onClickFace(event) {
    const { selectedPatient, atualizarPlacas } = this.props;

    var split = this.state.toothSelected.split("_");
    var dente = split[0] + "_" + split[1];

    this.state.placas[dente].placas[event.target.id] = event.target.checked;

    atualizarPlacas(this.state.placas, selectedPatient._id, (ret) => {
      this.setState({
        placas: ret,
      });
    });
  }

  renderOdontogram() {
    return (
      <div className={css(styles.status_odontogram)}>
        <div style={{ marginBottom: "20px" }}>
          <select
            style={{ float: "left" }}
            value={this.state.placas._id}
            name="medicao"
            id="medicao"
            onChange={(e) => this.selecionarMedicao(e.target.value)}
          >
            {this.state.listaMedicoes.map((medicao, index) => (
              <option value={medicao._id}>
                {moment(medicao.date).format("DD/MM/YYYY")}
              </option>
            ))}
          </select>
          <div
            style={{ width: "fit-content", marginRight: 0, marginLeft: "auto" }}
          >
            <Button
              text={"Nova medição"}
              color="green"
              onClick={() => this.setState({ modalNewMeasure: true })}
            />
          </div>
        </div>
        <div className={css(styles.status_top)}>
          <div className={css(styles.status_top_left)}>
            <div
              id={"tooth_18"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"18"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_18}
              />
            </div>
            <div
              id={"tooth_17"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"17"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_17}
              />
            </div>
            <div
              id={"tooth_16"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"16"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_16}
              />
            </div>
            <div
              id={"tooth_15"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"15"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_15}
              />
            </div>
            <div
              id={"tooth_14"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"14"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_14}
              />
            </div>
            <div
              id={"tooth_13"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"13"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_13}
              />
            </div>
            <div
              id={"tooth_12"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"12"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_12}
              />
            </div>
            <div
              id={"tooth_11"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"11"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_11}
              />
            </div>
          </div>

          <div className={css(styles.status_top_right)}>
            <div
              id={"tooth_21"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"21"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_21}
              />
            </div>
            <div
              id={"tooth_22"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"22"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_22}
              />
            </div>
            <div
              id={"tooth_23"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"23"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_23}
              />
            </div>
            <div
              id={"tooth_24"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"24"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_24}
              />
            </div>
            <div
              id={"tooth_25"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"25"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_25}
              />
            </div>
            <div
              id={"tooth_26"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"26"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_26}
              />
            </div>
            <div
              id={"tooth_27"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"27"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_27}
              />
            </div>
            <div
              id={"tooth_28"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTop)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"28"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_28}
              />
            </div>
          </div>
        </div>

        <div className={css(styles.status_top_deciduos)}>
          <div className={css(styles.status_top_left_deciduos)}>
            <div
              id={"tooth_55"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"55"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_55}
              />
            </div>
            <div
              id={"tooth_54"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"54"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_54}
              />
            </div>
            <div
              id={"tooth_53"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"53"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_53}
              />
            </div>
            <div
              id={"tooth_52"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"52"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_52}
              />
            </div>
            <div
              id={"tooth_51"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"51"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_51}
              />
            </div>
          </div>

          <div className={css(styles.status_top_right_deciduos)}>
            <div
              id={"tooth_61"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"61"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_61}
              />
            </div>
            <div
              id={"tooth_62"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"62"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_62}
              />
            </div>
            <div
              id={"tooth_63"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"63"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_63}
              />
            </div>
            <div
              id={"tooth_64"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"64"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_64}
              />
            </div>
            <div
              id={"tooth_65"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusTopDeciduos)}
            >
              <span className={css(styles.crownNumberTopDeciduos)}>
                {" "}
                {"65"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_65}
              />
            </div>
          </div>
        </div>

        <div className={css(styles.status_bot_deciduos)}>
          <div className={css(styles.status_bot_left_deciduos)}>
            <div
              id={"tooth_85"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"85"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_85}
              />
            </div>
            <div
              id={"tooth_84"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"84"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_84}
              />
            </div>
            <div
              id={"tooth_83"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"83"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_83}
              />
            </div>
            <div
              id={"tooth_82"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"82"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_82}
              />
            </div>
            <div
              id={"tooth_81"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"81"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_81}
              />
            </div>
          </div>

          <div className={css(styles.status_bot_right_deciduos)}>
            <div
              id={"tooth_71"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"71"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_71}
              />
            </div>
            <div
              id={"tooth_72"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"72"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_72}
              />
            </div>
            <div
              id={"tooth_73"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"73"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_73}
              />
            </div>
            <div
              id={"tooth_74"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"74"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_74}
              />
            </div>
            <div
              id={"tooth_75"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBotDeciduos)}
            >
              <span className={css(styles.crownNumberBotDeciduos)}>
                {" "}
                {"75"}{" "}
              </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_75}
              />
            </div>
          </div>
        </div>

        <div className={css(styles.status_bot)}>
          <div className={css(styles.status_bot_left)}>
            <div
              id={"tooth_48"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"48"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_48}
              />
            </div>
            <div
              id={"tooth_47"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"47"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_47}
              />
            </div>
            <div
              id={"tooth_46"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"46"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_46}
              />
            </div>
            <div
              id={"tooth_45"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"45"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_45}
              />
            </div>
            <div
              id={"tooth_44"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"44"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_44}
              />
            </div>
            <div
              id={"tooth_43"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"43"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_43}
              />
            </div>
            <div
              id={"tooth_42"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"42"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_42}
              />
            </div>
            <div
              id={"tooth_41"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"41"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_41}
              />
            </div>
          </div>

          <div className={css(styles.status_bot_right)}>
            <div
              id={"tooth_31"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"31"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_31}
              />
            </div>
            <div
              id={"tooth_32"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"32"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_32}
              />
            </div>
            <div
              id={"tooth_33"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"33"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_33}
              />
            </div>
            <div
              id={"tooth_34"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"34"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_34}
              />
            </div>
            <div
              id={"tooth_35"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"35"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_35}
              />
            </div>
            <div
              id={"tooth_36"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"36"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_36}
              />
            </div>
            <div
              id={"tooth_37"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"37"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_37}
              />
            </div>
            <div
              id={"tooth_38"}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
              className={css(styles.listItemStatusBot)}
            >
              <span className={css(styles.statusNumberBot)}> {"38"} </span>
              <IconOdontogram
                number={"0"}
                crown={true}
                tooth={this.state.placas.tooth_38}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.placas) {
      return (
        <>
          <div className={css(styles.flex)}>
            <form className={css(styles.form2)}>
              <div className={css(styles.sectionStatus)}>
                <div className={css(styles.flex, styles.flex2)}>
                  {this.renderOdontogram(this.state.placas)}
                </div>
              </div>
              <ReactTooltip
                place="left"
                effect="solid"
                className={css(styles.pointer)}
                offset={{ top: 10, left: -15 }}
              >
                {/* <ReactTooltip place="left" globalEventOff='click' effect="solid" className={css(styles.pointer)} offset={{top: 10, left: -15}} > */}
                <div className={css(styles.btn_modal)}>
                  <label htmlFor="distal">Distal</label>
                  <input
                    style={{ marginLeft: "10px" }}
                    name="distal"
                    id="distal"
                    type="checkbox"
                    onChange={(e) => {
                      this.onClickFace(e);
                    }}
                    checked={
                      this.state.toothSelected
                        ? this.state.placas[this.state.toothSelected].placas
                            .distal
                        : false
                    }
                  />
                </div>
                <div className={css(styles.btn_modal)}>
                  <label htmlFor="mesial">Mesial</label>
                  <input
                    style={{ marginLeft: "10px" }}
                    name="mesial"
                    id="mesial"
                    type="checkbox"
                    onChange={(e) => {
                      this.onClickFace(e);
                    }}
                    checked={
                      this.state.toothSelected
                        ? this.state.placas[this.state.toothSelected].placas
                            .mesial
                        : false
                    }
                  />
                </div>
                <div className={css(styles.btn_modal)}>
                  <label htmlFor="vestibular">Vestibular</label>
                  <input
                    style={{ marginLeft: "10px" }}
                    name="vestibular"
                    id="vestibular"
                    type="checkbox"
                    onChange={(e) => {
                      this.onClickFace(e);
                    }}
                    checked={
                      this.state.toothSelected
                        ? this.state.placas[this.state.toothSelected].placas
                            .vestibular
                        : false
                    }
                  />
                </div>
                <div className={css(styles.btn_modal)}>
                  <label htmlFor="lingual">Lingual</label>
                  <input
                    style={{ marginLeft: "10px" }}
                    name="lingual"
                    id="lingual"
                    type="checkbox"
                    onChange={(e) => {
                      this.onClickFace(e);
                    }}
                    checked={
                      this.state.toothSelected
                        ? this.state.placas[this.state.toothSelected].placas
                            .lingual
                        : false
                    }
                  />
                </div>
              </ReactTooltip>
            </form>
          </div>
          <Dialog open={this.state.modalNewMeasure} maxWidth="sm">
            <DialogTitle>Nova Medição</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Informe a data da nova medição:
              </DialogContentText>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  label="Data"
                  format="dd/MM/yyyy"
                  value={this.state.placas.date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date) => {
                    this.setState({
                      placas: { ...this.state.placas, date: date },
                    });
                  }}
                  inputVariant="outlined"
                  // error={errors.concludedDate}
                />
              </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
              <ButtonMui
                variant="contained"
                disableElevation
                onClick={() => {
                  this.setState({
                    modalNewMeasure: false,
                  });
                }}
              >
                Fechar
              </ButtonMui>
              <ButtonMui
                variant="contained"
                color="primary"
                disabled={this.state.isLoading}
                disableElevation
                onClick={() => {
                  this.novaMedicao();
                }}
              >
                Criar
              </ButtonMui>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  return errors;
}

const placasForm = reduxForm({
  validate,
  form: "placasForm",
})(PlacasForm);

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;
  let initialValues = {};

  if (selectedPatient) {
    initialValues = selectedPatient;
  }

  return {
    selectedPatient: state.patientsCreation.selectedPatient,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  buscarPlacas,
  atualizarPlacas,
  placasConst,
  novaMedicaoPlacas,
})(placasForm);
