import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { esconderModalConfirmacao } from "../../actions/notificacao/NotificacaoAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  function handleClose(objeto) {
    if (objeto.continua) {
      props.dispatch(esconderModalConfirmacao(objeto.funcaoExecutar, true));
    } else {
      props.dispatch(esconderModalConfirmacao(objeto.funcaoExecutar, false));
    }
  }

  return (
    <div>
      <Dialog
        open={props.modalConfirmacao.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Está prestes a realizar uma ação importante"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você tem certeza de que deseja prosseguir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose({ continua: false, funcaoExecutar: "" })}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() =>
              handleClose({
                continua: true,
                funcaoExecutar: props.modalConfirmacao.payload.funcaoExecutar,
              })
            }
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
