import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, InputAdornment, Link, TextField } from "@material-ui/core";
import { logInUser } from "../../actions/auth";
import { AccountCircle, Lock } from "@material-ui/icons";

function LogInForm({ history, logInUser, auth }) {
  const [user, setUser] = useState({ email: "", password: "" });

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  const login = () => {
    logInUser(
      {
        email: user.email,
        password: user.password,
      },
      (user) => {
        if (user.professional_platform === true) {
          redirecionarPlataformaProfissional();
        } else {
          redirecionarClinica();
        }
      },
    );
  };

  const redirecionarClinica = () => {
    history.push("/");
  };

  const redirecionarPlataformaProfissional = () => {
    history.push("/admin");
  };

  const renderAuthMsg = () => {
    if (auth.message === "unauthorized") {
      return renderMessageUnauthorized();
    } else if (auth.message === "drop") {
      return renderMessageDrop();
    } else if (auth.message === "blocked") {
      return renderMessageBlocked();
    }
  };

  const renderMessageUnauthorized = () => {
    return (
      <>
        <span style={{ color: "#CC4C29" }}>
          Não conseguimos reconhecer esta conta. Gostaria de tentar novamente ou
          <Link href="http://www.softmanager.com.br/contato/">
            criar uma conta nova?
          </Link>
        </span>
      </>
    );
  };

  const renderMessageDrop = () => {
    return (
      <>
        <span>
          Você foi desconectado porque outro dispositivo acessou este usuário.
        </span>
      </>
    );
  };

  const renderMessageBlocked = () => {
    return (
      <>
        <span style={{ color: "#CC4C29" }}>
          Erro ao acessar! Contate o administrador da clínica.
        </span>
      </>
    );
  };

  return (
    <>
      <div style={{ marginTop: "24px" }}>{renderAuthMsg()}</div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "24px" }}>
          <TextField
            label="E-mail"
            placeholder="seuemail@exemplo.com"
            type="email"
            fullWidth
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "#939393" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <TextField
            label="Senha"
            type="password"
            fullWidth
            variant="outlined"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock style={{ color: "#939393" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Button variant="contained" color="primary" type="submit">
            Entrar
          </Button>
        </div>
        <p>
          Esqueceu sua senha? <Link href="/rememberpass">Recuperar</Link>
        </p>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { logInUser })(LogInForm);
