import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { css } from "aphrodite/no-important";
import { styles } from "./BudgetFormStyles";
import "./TabStyles.css";

import { defaultOdontogram } from "../../_constants/odontogram";
import {
  getTreatment,
  getTreatmentByPatientId,
  updateTreatment,
  createBudget,
  editBudget,
  deleteBudget,
  editTreatmentName,
  editProcedures,
} from "../../../actions/treatment";
import { getPatient } from "../../../actions/patientsCreation";
import { addToWindows } from "../../../actions/windowsController";
import { fetchDentists } from "../../../actions/dentists";
import { getDentalStatus } from "../../../actions/dentalStatus";

import ModalDeleteProcedure from "./treatmentModals/ModalDeleteProcedure";
import ModalDeleteBudget from "./treatmentModals/ModalDeleteBudget";
import ModalProcedureForm from "./treatmentModals/ModalProcedureForm";
import ModalTreatmentCreateForm from "./treatmentModals/ModalTreatmentCreateForm";
import ModalApproveBudget from "./treatmentModals/ModalApproveBudget";
import DialogEmail from "../../common/DialogEmail";
// import RecebimentoParcelaFormDialog from './orcamento/RecebimentoParcelaFormDialog'

import DateTimePicker from "../date/DateTimePicker";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SelectBox from "../../common/SelectBox";
import Modal from "../../modals/Modal";
import Button from "../../common/Button";
import Toggle from "../../common/Toggle";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";
import ButtonMUI from "@material-ui/core/Button";
import MUIDataTable from "mui-datatables";
import { formataMoedaReal } from "../../../helpers/Helpers";
import { Add, Check, Edit, Payment, Receipt } from "@material-ui/icons";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// import TabelaParcelasOrcamento from './orcamento/TabelaParcelasOrcamento';
import TabPanelContent from "./orcamento/TabPanelContent";
// import FormConcluirProcedimentoOrcamento from './orcamento/FormConcluirProcedimentoOrcamento';

const OPTIONS = [
  { value: "pendent", label: "Pendente" },
  { value: "realized", label: "Realizado" },
];

class TreatmentForm extends Component {
  constructor(props) {
    super(props);
    this.addToWindows = this.addToWindows.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.closeModalDelete = this.closeModalDelete.bind(this);
    this.deleteProcedure = this.deleteProcedure.bind(this);
    this.renderDentist = this.renderDentist.bind(this);
    this.mathRound = this.mathRound.bind(this);
    this.openModalName = this.openModalName.bind(this);
    this.closeModalName = this.closeModalName.bind(this);
    this.editName = this.editName.bind(this);
    this.renderTab = this.renderTab.bind(this);
    this.addToTab = this.addToTab.bind(this);
    this.renderTabPanel = this.renderTabPanel.bind(this);
    this.renderBudgetList = this.renderBudgetList.bind(this);
    this.deleteBudget = this.deleteBudget.bind(this);
    this.renderParcels = this.renderParcels.bind(this);
    this.saveBudget = this.saveBudget.bind(this);
    this.getMoney = this.getMoney.bind(this);
    this.moneyPercentInput = this.moneyPercentInput.bind(this);
    this.getEntrance = this.getEntrance.bind(this);
    this.setToggle = this.setToggle.bind(this);
    this.closeModalApproveBudget = this.closeModalApproveBudget.bind(this);
    this.executarServico = this.executarServico.bind(this);

    this.state = {
      modal: false,
      modalDelete: false,
      modalDeleteBudget: false,
      treatment: null,
      originalTreatment: {},
      procedure: null,
      modalName: false,
      modalApproveBudget: false,
      tab: [],
      tabSelected: 0,
      budgetSelected: {},
      emailModels: [],
      openDialogEmail: false,
      modalExecutarServico: false,
      dialogFormReceber: false,
    };
  }

  componentDidMount() {
    this.fetchTreatment();
  }

  fetchTreatment() {
    const {
      getTreatment,
      patientId,
      selectedPatient,
      defaultOdontogram,
      getDentalStatus,
    } = this.props;
    getTreatmentByPatientId(patientId, (ret) => {
      if (ret) {
        this.state.tab = ret.budgets;
        if (ret.list_id) {
          this.setState({
            treatment: ret,
            originalTreatment: JSON.parse(JSON.stringify(ret)), // cloning deep object
          });
        } else {
          if (ret.treatment.length > 0) {
            ret.list_id = ret.treatment[0].list_id;
          }
          this.setState({
            treatment: ret,
            originalTreatment: JSON.parse(JSON.stringify(ret)), // cloning deep object
          });
        }
      } else {
        var odontogram;
        odontogram = defaultOdontogram();
        odontogram = odontogram.odontogram;
        odontogram.name = "odontograma";

        getDentalStatus(patientId, (ret) => {
          if (ret) {
            for (var i = 0; i <= 85; i++) {
              if (ret["tooth_" + i] && odontogram["tooth_" + i]) {
                odontogram["tooth_" + i].status = ret["tooth_" + i].status;

                if (
                  odontogram["tooth_" + i].status === "INCLUDED" ||
                  odontogram["tooth_" + i].status === "AGENESIS" ||
                  odontogram["tooth_" + i].status === "EXODONTIA" ||
                  odontogram["tooth_" + i].status === "LOST"
                ) {
                  odontogram["tooth_" + i].status = "MISSING";
                }
              }
            }
            this.onSend(odontogram);
          } else {
            this.onSend(odontogram);
          }
        });
      }
    });
  }

  onSend(values) {
    const { updateTreatment, selectedPatient, history, match } = this.props;

    values.patient_id = selectedPatient._id;
    values.date = new Date();

    if (selectedPatient) {
      updateTreatment(values, selectedPatient._id, (ret) => {});
    }
  }

  componentDidUpdate() {
    if (this.state.treatment) {
      if (!this.state.treatment.list_id) {
        if (this.state.treatment.treatment.length > 0) {
          this.state.treatment.list_id =
            this.state.treatment.treatment[0].list_id;
        }
      }
    }
  }

  mathRound(number) {
    function formatBR(v) {
      if (!v) {
        v = "";
      }
      v = v.toString();

      if (v.search(/([/.]+)/g) === -1) {
        v = v + ".00";
      }

      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{1,2})$/, ",$1");
      v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      v = v !== "" ? "R$ " + v : "R$ ";
      return v;
    }

    if (number === 0) {
      return "R$ 0,00";
    } else {
      number = Math.round(number * 100) / 100;
      number = number.toFixed(2);
    }
    return formatBR(number);
  }

  openModal(values, idx) {
    this.setState({
      procedure: values?.toString(),
      budgetSelected: { idx: idx?.toString() },
      modal: true,
    });
  }

  closeModal() {
    this.setState({
      modal: false,
    });
  }

  openModalDeleteBudget(item, idx) {
    item.idx = idx;
    this.setState({
      modalDeleteBudget: true,
      budgetSelected: item,
    });
  }

  closeModalDeleteBudget = () => {
    this.setState({ modalDeleteBudget: false });
  };

  openModalDelete(values, budgetIndex) {
    this.setState({
      budgetSelected: { idx: budgetIndex?.toString() },
      procedure: values?.toString(),
      modalDelete: true,
    });
  }

  closeModalDelete() {
    this.setState({
      modalDelete: false,
    });
  }

  openModalName(values) {
    this.setState({
      modalName: true,
    });
  }

  closeModalName(values) {
    this.setState({
      modalName: false,
    });
  }

  openModalApproveBudget(values) {
    this.setState({
      modalApproveBudget: true,
    });
  }

  closeModalApproveBudget(values) {
    this.setState({
      modalApproveBudget: false,
    });
  }

  editName(value) {
    const { editTreatmentName } = this.props;

    editTreatmentName(this.state.treatment._id, value, (ret) => {
      this.state.treatment.name = ret;
      this.setState({});
    });

    this.closeModalName();
  }

  deleteProcedure(treatment, procedure) {
    const { editProcedures } = this.props;
    var editedTreatment = treatment;
    editedTreatment.budgets[this.state.budgetSelected.idx].procedures.splice(
      procedure,
      1,
    );
    editProcedures(editedTreatment, () => {
      this.setState({});
    });
    this.closeModalDelete();
  }

  addToWindows() {
    const { addToWindows } = this.props;
    addToWindows(
      {
        type: "TREATMENT",
        id: this.state.treatment._id,
        opened: true,
        data: { value: this.state.treatment },
      },
      (res) => {},
    );
  }

  renderDentist(id) {
    const { dentistsById } = this.props;

    if (dentistAux) {
      var dentistAux = dentistsById.find((dentist) => {
        return dentist._id === id;
      });
    }

    return dentistAux ? dentistAux.name : "";
  }

  renderSelectBox(OPTIONS) {
    return (
      <Field
        key={"role"}
        type={"selectbox"}
        name={"role"}
        itens={OPTIONS}
        component={SelectBox}
      />
    );
  }

  renderParcels(item) {
    if (item.payment_type === "cash") {
      var total = 0;
      item.procedures.map((proc) => {
        total += proc.specification.value;
      });
      item.parcels[0].value = total;
      item.parcels[0].number = "unic";

      if (item.discount.value_calc > 0) {
        if (item.discount.value_type === "percent") {
          item.parcels[0].value -=
            (item.parcels[0].value * item.discount.value_calc) / 100;
        } else {
          item.parcels[0].value -= item.discount.value_calc;
        }
      }
    } else {
      var parcelAux = [];
      var total = 0;
      item.procedures.map((proc) => {
        total += proc.specification.value;
      });
      if (item.discount.value_calc > 0) {
        if (item.discount.value_type === "percent") {
          total -= (total * item.discount.value_calc) / 100;
        } else {
          total -= item.discount.value_calc;
        }
      }
      if (item.entrance.value_calc > 0) {
        if (item.entrance.value_type === "percent") {
          total -= (total * item.entrance.value_calc) / 100;
        } else {
          total -= item.entrance.value_calc;
        }
      }
      var parcelValue = total / item.parcel_number;
      if (item.parcels[0].number === "unic") {
        for (var par = 0; par < item.parcel_number; par++) {
          parcelAux.push({
            number: par + 1,
            value: parcelValue,
            expiration: item.parcels[par]
              ? new Date(item.parcels[par].expiration).setMonth(
                  new Date(item.parcels[par].expiration).getMonth() + 1,
                )
              : new Date(item.parcels[0].expiration).setMonth(
                  new Date(item.parcels[0].expiration).getMonth() + par + 1,
                ),
          });
        }
      } else {
        for (var par = 0; par < item.parcel_number; par++) {
          parcelAux.push({
            number: par + 1,
            value: parcelValue,
            expiration: item.parcels[par]
              ? item.parcels[par].expiration
              : new Date(item.parcels[0].expiration).setMonth(
                  new Date(item.parcels[0].expiration).getMonth() + par,
                ),
          });
        }
      }
      item.parcels = parcelAux;
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Parcela</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Data de vencimento</TableCell>
              <TableCell>Valor de pagamento</TableCell>
              <TableCell>Data de pagamento</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.entrance.value_calc > 0 && (
              <TableRow>
                <TableCell>Entrada</TableCell>
                <TableCell>
                  {this.mathRound(this.getEntrance(item).entrance.value)}
                </TableCell>
                <TableCell>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      size="small"
                      value={item.entrance.expiration}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(date) => {
                        item.entrance.expiration = date;
                        this.setState([]);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell>
                  {this.mathRound(item.entrance.receipt?.value)}
                </TableCell>
                <TableCell>
                  {moment(item.entrance.receipt?.date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  <Tooltip title="Receber" size="small">
                    <IconButton
                      onClick={() =>
                        this.setState({
                          budgetSelected: item,
                          parcelSelected: item.entrance,
                          dialogFormReceber: true,
                        })
                      }
                    >
                      <Payment fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {item.payment_type === "parcel" ? ( // Múltiplas parcelas
              item.parcels.map((parcel, idx) => (
                <TableRow key={parcel.number}>
                  <TableCell scope="row">{parcel.number}</TableCell>
                  <TableCell>{this.mathRound(parcel.value)}</TableCell>
                  <TableCell>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        size="small"
                        value={parcel.expiration}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onChange={(date) => {
                          parcel.expiration = date;
                          this.setState([]);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell>{parcel.paid ? "Sim" : "Não"}</TableCell>
                  <TableCell>
                    <Tooltip title="Receber" size="small">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            budgetSelected: item,
                            parcelSelected: parcel,
                            dialogFormReceber: true,
                          })
                        }
                      >
                        <Payment fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              // Parcela única
              <TableRow>
                <TableCell scope="row">Parcela única</TableCell>
                <TableCell>{this.mathRound(item.parcels[0].value)}</TableCell>
                <TableCell>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      size="small"
                      value={item.parcels[0].expiration}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(date) => {
                        item.parcels[0].expiration = date;
                        this.setState([]);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell>{item.parcels[0].paid ? "Sim" : "Não"}</TableCell>
                <TableCell>
                  <Tooltip title="Receber" size="small">
                    <IconButton
                      onClick={() =>
                        this.setState({
                          budgetSelected: item,
                          parcelSelected: item.parcels[0],
                          dialogFormReceber: true,
                        })
                      }
                    >
                      <Payment fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderResumo() {
    const colunas = [
      { label: " ", name: "titulo" },
      { label: "(%)", name: "porcento" },
      { label: "R$", name: "valor" },
    ];

    const tabela = [
      { titulo: "Total bruto", porcento: 0, valor: 0 },
      { titulo: "Desconto", porcento: 0, valor: 0 },
      { titulo: "Total líquido", porcento: 0, valor: 0 },
      { titulo: "Entrada", porcento: 0, valor: 0 },
      { titulo: "Juro", porcento: 0, valor: 0 },
      { titulo: "Parcela", porcento: 0, valor: 0 },
      { titulo: "Total + Juro", porcento: 0, valor: 0 },
      { titulo: "Total pago", porcento: 0, valor: 0 },
      { titulo: "Saldo", porcento: 0, valor: 0 },
    ];

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>(%)</TableCell>
              <TableCell>R$</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tabela.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.titulo}</TableCell>
                <TableCell>{item.porcento}</TableCell>
                <TableCell>{item.valor}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderBudgetList(procedureList, idx) {
    const colunas = [
      { label: "Serviço", name: "procedure.descricao" },
      {
        label: "Status",
        name: "concluido",
        options: {
          customBodyRender: (value) => (value ? "Concluído" : "Pendente"),
        },
      },
      {
        label: "Dentes",
        name: "specification.tooth",
        options: {
          customBodyRender: (value) => value.toString(),
        },
      },
      {
        label: "Faces",
        name: "specification.face",
        options: {
          customBodyRender: (value) => console.log("Faces"),
        },
      },
      {
        label: "Valor",
        name: "specification.value",
        options: {
          customBodyRender: (value) => value && formataMoedaReal(value),
        },
      },
      {
        label: "Dentista",
        name: "dentist.name",
      },
      {
        label: "Data conclusão",
        name: "dataConclusao",
        options: {
          customBodyRender: (value) =>
            value && moment(value).format("DD/MM/YYYY"),
        },
      },
      {
        label: " ",
        name: "concluido",
        options: {
          customBodyRender: (value, tableMeta) =>
            !value && (
              <>
                <IconButton
                  onClick={() =>
                    this.openModalExecutarServico(tableMeta.rowIndex, idx)
                  }
                >
                  <Check fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.openModal(tableMeta.rowIndex, idx);
                  }}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </>
            ),
        },
      },
    ];

    const options = {
      elevation: 1,
      sort: false,
      selectableRows: "single",
      customFooter: () => {},
      onRowsDelete: (rowsDeleted) => {
        this.openModalDelete(rowsDeleted.data[0].dataIndex.toString(), idx);
      },
    };

    const myTheme = createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          root: {
            display: "none",
          },
        },
      },
    });

    return (
      <div style={{ marginTop: "12px", marginBottom: "16px" }}>
        <ThemeProvider theme={myTheme}>
          <MUIDataTable
            columns={colunas}
            data={procedureList[idx]?.procedures ?? []}
            options={options}
          />
        </ThemeProvider>
      </div>
    );
  }

  renderTab(tab) {
    return tab.map((item, idx) => {
      return <Tab key={idx}>{item.name}</Tab>;
    });
  }

  openModalExecutarServico(value, indexBudget) {
    this.setState({
      procedure: value.toString(),
      budgetSelected: { idx: indexBudget?.toString() },
      modalExecutarServico: true,
    });
  }

  deleteBudget = (idx) => {
    const { deleteBudget } = this.props;

    const budget = this.state.treatment.budgets[idx];

    deleteBudget(budget._id, () => {
      this.state.tab.splice(idx, 1);
      this.state.originalTreatment.budgets.splice(idx, 1);
      this.state.treatment.budgets.splice(idx, 1);
      this.setState({});
    });
  };

  setToggle(item, e) {
    item.approved = e;
    this.setState({});
  }

  onCloseDialogEmail = () => {
    this.setState({ openDialogEmail: false });
  };

  onApproveBudget = (item, idx) => {
    const { editBudget } = this.props;

    editBudget(item, (ret) => {
      this.state.originalTreatment.budgets[idx] = ret;
      this.state.treatment.budgets[idx] = ret;
      this.closeModalApproveBudget();
      this.setState({});
    });
  };

  executarServico(form, servico) {
    const procedure =
      this.state.treatment.budgets[this.state.budgetSelected.idx].procedures[
        servico
      ];
    procedure.concluido = true;
    procedure.dataConclusao = form.dataExecucao;
    procedure.dentist = form.dentista._id;
    procedure.dentist = form.dentista;
    this.setState({
      treatment: this.state.treatment,
      modalExecutarServico: false,
    });
  }

  handleSubmitRecebimento = (form) => {
    const receipt = {
      date: form.dataPagamento,
      value: form.valorPagamento,
      contaFinanceira_id: form.contaFinanceira._id,
    };
    this.setState({ dialogFormReceber: false });
  };

  renderTabPanel(tab) {
    return tab.map((item, idx) => {
      return (
        <TabPanel key={idx}>
          <Paper style={{ padding: "12px" }}>
            <div style={{ textAlign: "right" }}>
              <ButtonMUI
                color="primary"
                variant="contained"
                disableElevation
                startIcon={<Add />}
                onClick={() => {
                  this.openModal(null);
                  this.setState({ budgetSelected: { idx } });
                }}
              >
                Serviço
              </ButtonMUI>
            </div>
            {this.renderBudgetList(this.state.tab, idx)}
            <Container maxWidth="md">
              <div>
                <label className={css(styles.label)}>Forma de Pagamento</label>
              </div>
              <div>
                <fieldset
                  className={css(styles.fieldset)}
                  style={{ textAlign: "left", width: "33%", float: "left" }}
                >
                  <label className={css(styles.label)}>Juros mensal(%)</label>
                  <input
                    type="number"
                    name="jurosMensal"
                    min="1"
                    max="100"
                    className={css(styles.input)}
                    defaultValue={item.jurosMensal}
                    style={{ width: "100px" }}
                    onBlur={(e) => {
                      item.jurosMensal = e.target.value;
                      this.setState([]);
                    }}
                  ></input>
                </fieldset>
                <fieldset
                  className={css(styles.fieldset)}
                  style={{ textAlign: "left", width: "33%", float: "left" }}
                >
                  <label className={css(styles.label)}>Juros mora(%)</label>
                  <input
                    type="number"
                    name="parcel_number"
                    min="1"
                    max="100"
                    className={css(styles.input)}
                    defaultValue={item.jurosMora}
                    style={{ width: "100px" }}
                    onBlur={(e) => {
                      item.jurosMora = e.target.value;
                      this.setState([]);
                    }}
                  ></input>
                </fieldset>
                <fieldset
                  className={css(styles.fieldset)}
                  style={{ textAlign: "left", width: "33%", float: "left" }}
                >
                  <label className={css(styles.label)}>
                    Multa por atraso(%)
                  </label>
                  <input
                    type="number"
                    name="parcel_number"
                    min="1"
                    max="100"
                    className={css(styles.input)}
                    defaultValue={item.multaAtraso}
                    style={{ width: "100px" }}
                    onBlur={(e) => {
                      item.multaAtraso = e.target.value;
                      this.setState([]);
                    }}
                  ></input>
                </fieldset>
              </div>
              <div>
                <fieldset
                  className={css(styles.fieldset)}
                  style={{ textAlign: "left", width: "33%", float: "left" }}
                >
                  <input
                    type="radio"
                    name="payment_type"
                    value="cash"
                    style={{ marginRight: "0.5rem" }}
                    checked={item.payment_type === "cash"}
                    onChange={() => {
                      item.payment_type = "cash";
                      item.entrance.value = 0;
                      this.setState([]);
                    }}
                  />
                  À vista
                  <input
                    type="radio"
                    name="payment_type"
                    value="parcel"
                    style={{ marginLeft: "1rem", marginRight: "0.5rem" }}
                    checked={item.payment_type === "parcel"}
                    onChange={() => {
                      item.payment_type = "parcel";
                      this.setState([]);
                    }}
                  />
                  Parcelado
                </fieldset>
                <fieldset
                  className={css(styles.fieldset)}
                  style={{ textAlign: "left" }}
                >
                  {this.moneyPercentInput(
                    item.discount,
                    "Desconto",
                    item.approved,
                  )}
                  <div style={{ width: "33%", float: "left" }}>
                    <div style={{ width: "140px" }}>
                      <DateTimePicker
                        name="budget_expiration"
                        value={item.budget_expiration}
                        label="Validade do Orçamento"
                        onChange={(e) => {
                          item.budget_expiration = e;
                          this.setState([]);
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
                {item.payment_type === "parcel" ? (
                  <div>
                    <fieldset
                      className={css(styles.fieldset)}
                      style={{ textAlign: "left", width: "33%", float: "left" }}
                    >
                      <label className={css(styles.label)}>
                        Número de Parcelas
                      </label>
                      <input
                        type="number"
                        name="parcel_number"
                        min="1"
                        max="48"
                        className={css(styles.input)}
                        defaultValue={item.parcel_number}
                        style={{ width: "80px" }}
                        onBlur={(e) => {
                          item.parcel_number = e.target.value;
                          this.setState([]);
                        }}
                      ></input>
                    </fieldset>
                    <fieldset>
                      {this.moneyPercentInput(
                        item.entrance,
                        "Entrada",
                        item.approved,
                      )}
                    </fieldset>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <TabelaParcelasOrcamento orcamento={item} /> */}
              <Divider />
              <Container maxWidth="sm" style={{ marginTop: "12px" }}>
                {this.renderResumo()}
              </Container>
            </Container>
            <div style={{ marginTop: "12px" }}>
              <Toggle
                label={"Orçamento Aprovado?"}
                defaultValue={item.approved}
                change={(e) => this.setToggle(item, e)}
              />
            </div>

            <div>
              <Button
                text={"Enviar por e-mail"}
                color="green2"
                icon="mail"
                onClick={() => this.setState({ openDialogEmail: true })}
              />
            </div>
            <div style={{ textAlign: "right" }}>
              <Button
                text={"Excluir"}
                color="red"
                onClick={() => this.openModalDeleteBudget(item, idx)}
              />
              <Button
                text={"Salvar"}
                color="green"
                onClick={() => this.saveBudget(item, idx)}
              />
            </div>
          </Paper>
        </TabPanel>
      );
    });
  }

  addToTab() {
    const { createBudget } = this.props;
    let total = 0;
    const proc = this.state.treatment.treatment;

    proc.map((procedure) => {
      total += procedure.specification.value;
      procedure.date = new Date();
    });

    const budget = {
      procedures: proc,
      name: `Orçamento ${this.state.treatment.budgets.length + 1} - ${moment(new Date()).format("DD/MM/YYYY")}`,
      approved: false,
      payment_type: "cash",
      parcel_number: 1,
      entrance: { value: 0, value_type: "percent", expiration: new Date() },
      discount: { value: 0, value_type: "percent" },
      budget_expiration: new Date().setMonth(new Date().getMonth() + 1),
      parcels: [{ number: "unic", value: total, expiration: new Date() }],
    };

    createBudget(this.state.treatment._id, budget, (ret) => {
      this.state.originalTreatment.budgets.push({ ...ret });
      this.state.tab.push({ ...ret });
      this.setState({});
    });
  }

  saveBudget(item, idx) {
    const { editBudget } = this.props;

    this.state.budgetSelected = JSON.parse(JSON.stringify(item));
    this.state.budgetSelected.idx = idx;
    //this.openModalApproveBudget();
    editBudget(this.state.budgetSelected, (ret) => {
      this.fetchTreatment();
    });
  }

  getMoney(str) {
    return parseFloat(str.replace(/[\D]+/g, "")) / 100; // pega a string no formato R$ 0.000,00 e transforma em 0000.00
  }

  getEntrance(item) {
    if (item.entrance.value_type === "percent") {
      var total = 0;
      item.procedures.map((proc) => {
        total += proc.specification.value;
      });

      var toCash = (total * item.entrance.value_calc) / 100;
      item.entrance.value = toCash;
    } else {
      item.entrance.value = item.entrance.value;
    }
    return item;
    //return	this.mathRound(item.entrance.value);
  }

  moneyPercentInput(item, label, approved = false) {
    if (item.value_type === "cash") {
      return (
        <fieldset
          className={css(styles.fieldset)}
          style={{ textAlign: "left", width: "33%" }}
        >
          <label className={css(styles.label)}>{label}</label>
          <input
            type="text"
            className={css(styles.input)}
            value={this.mathRound(item.value)}
            style={{ width: "140px" }}
            onChange={(e) => {
              item.value = this.getMoney(e.target.value);
              this.setState([]);
            }}
          ></input>
          <div
            style={{ position: "absolute", top: "27px", left: "109px" }}
            onClick={() => {
              item.value_type = "percent";
              item.value = 0;
              this.setState([]);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26 26"
              height={26}
              width={26}
            >
              <rect width="26" height="26" rx="4" ry="4" fill="#00b1e8" />
              <path
                d="M7.2,15.1H5.4v4.8H2.1V6.3H7.5a6.3,6.3,0,0,1,3.8,1.1,3.5,3.5,0,0,1,1.3,3,4.3,4.3,0,0,1-.6,2.4,3.5,3.5,0,0,1-1.8,1.5l2.9,5.5H9.6ZM5.4,12.6H7.5a2,2,0,0,0,1.4-.5,2,2,0,0,0,.4-1.4,1.7,1.7,0,0,0-.4-1.3,1.6,1.6,0,0,0-1.4-.5H5.4Z"
                fill="#fff"
              />
              <path
                d="M20.7,16.3a1.6,1.6,0,0,0-.4-1.1,3.2,3.2,0,0,0-1.3-.8l-1.7-.8a3.8,3.8,0,0,1-1.3-.9,3.1,3.1,0,0,1-.9-1.1,4.1,4.1,0,0,1-.3-1.6,3.3,3.3,0,0,1,1.1-2.6,4.5,4.5,0,0,1,2.8-1.2v-2h1.5v2a3.9,3.9,0,0,1,2.7,1.4,4.3,4.3,0,0,1,1,2.9H20.7A2.4,2.4,0,0,0,20.3,9a1.2,1.2,0,0,0-1-.5,1.3,1.3,0,0,0-1,.4A1.8,1.8,0,0,0,18,10a1.5,1.5,0,0,0,.4,1.1l1.2.7,1.7.8,1.3.9a2.7,2.7,0,0,1,.9,1.2,3.8,3.8,0,0,1,.3,1.6,3.4,3.4,0,0,1-1,2.6A4.3,4.3,0,0,1,20,20.1V22H18.5V20.1a5.3,5.3,0,0,1-3.1-1.4,4.5,4.5,0,0,1-1.1-3.1h3.2a2.1,2.1,0,0,0,.5,1.6,1.4,1.4,0,0,0,1.3.5,1.7,1.7,0,0,0,1-.3A1.8,1.8,0,0,0,20.7,16.3Z"
                fill="#fff"
              />
            </svg>
          </div>
        </fieldset>
      );
    }

    return (
      <fieldset
        className={css(styles.fieldset)}
        style={{ textAlign: "left", width: "33%" }}
      >
        <label className={css(styles.label)}>{label}</label>
        <input
          type="number"
          className={css(styles.input)}
          value={item.value_calc}
          style={{ width: "140px" }}
          onChange={(e) => {
            item.value_calc = e.target.value;
            this.setState([]);
          }}
        ></input>
        <div
          style={{ position: "absolute", top: "27px", left: "109px" }}
          onClick={() => {
            item.value_type = "cash";
            item.value_calc = 0;
            this.setState([]);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 26"
            height={26}
            width={26}
          >
            <rect width="25.9" height="25.94" rx="4" ry="4" fill="#00b1e8" />
            <path
              d="M18,14.4a3.4,3.4,0,0,0-3.2,3.7A3.5,3.5,0,0,0,18,21.8a3.5,3.5,0,0,0,3.2-3.7A3.5,3.5,0,0,0,18,14.4Z"
              fill="#fff"
            />
            <path
              d="M8.2,4.2A3.6,3.6,0,0,0,5,7.9a3.5,3.5,0,0,0,3.3,3.7,3.3,3.3,0,0,0,3.1-3.7A3.5,3.5,0,0,0,8.2,4.2Z"
              fill="#fff"
            />
            <polygon
              points="7 21.8 4.4 19.4 19.4 4.2 22 6.6 7 21.8"
              fill="#fff"
            />
          </svg>
        </div>
      </fieldset>
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        <Container maxWidth="lg" style={{ paddingTop: "12px" }}>
          <div style={{ textAlign: "right", marginRight: "12px" }}>
            <Button
              icon={"tooth"}
              text={"Odontograma"}
              color="green2"
              onClick={this.addToWindows}
            />
            <Button
              icon={"plus"}
              text={"Criar Orçamento"}
              onClick={this.addToTab}
            />
          </div>
          <Tabs
            style={{ marginTop: "12px" }}
            selectedIndex={this.state.tabSelected}
            onSelect={(index) => this.setState({ tabSelected: index })}
          >
            <TabList>
              {this.state.tab.map((budget, index) => (
                <Tab key={index}>{budget.name}</Tab>
              ))}
            </TabList>
            {this.state.tab.map((budget, index) => (
              <TabPanel key={index}>
                <TabPanelContent budget={budget} />
              </TabPanel>
            ))}
            {/* {this.renderTabPanel(this.state.tab)} */}
          </Tabs>
        </Container>

        <Dialog open={this.state.modal} maxWidth="xs" fullWidth>
          <DialogTitle>Escolher serviço</DialogTitle>
          <DialogContent>
            <ModalProcedureForm
              onCancel={this.closeModal}
              budgetSelected={this.state.budgetSelected}
              treatment={this.state.treatment}
              procedure={this.state.procedure}
            />
          </DialogContent>
        </Dialog>
        {/* {this.state.modalExecutarServico &&
          <FormConcluirProcedimentoOrcamento
            onClose={() => this.setState({ modalExecutarServico: false })}
            servico={this.state.procedure}
            onSubmit={this.executarServico}
          />
        } */}

        <Modal
          isOpen={this.state.modalDelete}
          header={"Excluir Serviço"}
          adjustStyle={styles.modal}
        >
          <ModalDeleteProcedure
            treatment={this.state.treatment}
            procedure={this.state.procedure}
            budgetSelected={this.state.budgetSelected}
            onCancel={this.closeModalDelete}
            onSubmit={this.deleteProcedure}
          />
        </Modal>

        <Modal
          isOpen={this.state.modalName}
          header={"Editar nome do Tratamento"}
          adjustStyle={styles.modal}
        >
          <ModalTreatmentCreateForm
            name={this.state.treatment ? this.state.treatment.name : null}
            onCancel={this.closeModalName}
            onSubmit={this.editName}
          />
        </Modal>

        <Modal
          isOpen={this.state.modalDeleteBudget}
          header={"Excluir Orçamento"}
          adjustStyle={styles.modal}
        >
          <ModalDeleteBudget
            onSubmit={this.deleteBudget}
            idx={this.state.budgetSelected.idx}
            orcamento={this.state.budgetSelected}
            onCancel={this.closeModalDeleteBudget}
          />
        </Modal>

        <Modal
          isOpen={this.state.modalApproveBudget}
          header={"Aprovar Orçamento"}
          adjustStyle={styles.modal}
        >
          <ModalApproveBudget
            onCancel={this.closeModalApproveBudget}
            idx={this.state.budgetSelected.idx}
            orcamento={this.state.budgetSelected}
            onSubmit={this.onApproveBudget}
          />
        </Modal>
        {/* {this.state.dialogFormReceber &&
          <RecebimentoParcelaFormDialog
            orcamento={this.state.budgetSelected}
            parcela={this.state.parcelSelected}
            onClose={() => this.setState({ dialogFormReceber: false })}
            submit={this.handleSubmitRecebimento}
          />
        } */}
        {this.state.openDialogEmail && (
          <DialogEmail
            list={[this.props.selectedPatient]}
            onClose={this.onCloseDialogEmail}
            typeMentions={["patient", "treatment", "receiver"]}
          />
        )}
      </>
    );
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  return errors;
}

const treatmentForm = reduxForm({
  validate,
  form: "treatmentForm",
})(TreatmentForm);

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;
  let initialValues = {};

  if (selectedPatient) {
    initialValues = selectedPatient;
  }

  return {
    clinic: state.auth.clinic,
    dentistsById: state.clinicConfig.dentistsById,
    selectedPatient: state.patientsCreation.selectedPatient,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  fetchDentists,
  getTreatment,
  getTreatmentByPatientId,
  updateTreatment,
  getPatient,
  addToWindows,
  createBudget,
  editBudget,
  deleteBudget,
  editTreatmentName,
  editProcedures,
  getDentalStatus,
  defaultOdontogram,
})(treatmentForm);
