import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { criarContaFinanceira } from "../../../../api/contaFinanceira";
import { connect } from "react-redux";
import { showMessageSystem } from "../../../../../src/actions/systemMsg";

function FormCadastroContaFinanceira({ history, showMessageSystem }) {
  const [form, setForm] = useState();
  const [validaDescricao, setValidaDescricao] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    criarContaFinanceira(form, (res) => {
      if (res.error) {
        showMessageSystem(res.error, "error");
        setValidaDescricao(true);
        return;
      }
      showMessageSystem(
        `Conta ${res.descricao} inserida com sucesso.`,
        "success",
      );
      history.goBack();
    });
  };

  return (
    <div>
      <Typography variant="h6"> Cadastro de conta financeira </Typography>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "12px" }}>
          <TextField
            error={validaDescricao}
            onChange={(e) => setForm({ ...form, descricao: e.target.value })}
            helperText={validaDescricao && "Insira outra descrição"}
            variant="outlined"
            size="small"
            required
            label="Nome da conta"
          />
        </div>
        <div style={{ marginTop: "8px" }}>
          <NumberFormat
            required
            label="Saldo inicial"
            customInput={TextField}
            variant="outlined"
            size="small"
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$"}
            onValueChange={(values) =>
              setForm({ ...form, saldoInicial: values.floatValue })
            }
          />
        </div>
        <div>
          <FormControlLabel
            required
            control={
              <Checkbox
                defaultChecked
                onChange={(e) => setForm({ ...form, ativo: e.target.checked })}
              />
            }
            label="Ativo"
          />
        </div>
        <div style={{ marginTop: "8px" }}>
          <Button variant="contained" color="primary" type="submit">
            Inserir
          </Button>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showMessageSystem })(
  FormCadastroContaFinanceira,
);
