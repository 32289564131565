import React from "react";
import { Route } from "react-router-dom";
import MedicalRecordReport from "./medical_record_report";
import { Container } from "@material-ui/core";
import MedicalRecordReportPDF from "./medical_record_report_pdf";
import EndodonticReport from "./endodontic_report";

export default function ReportRoutes() {
  return (
    <Container maxWidth="lg">
      <Route
        exact
        path={"/relatorios/prontuario"}
        component={MedicalRecordReport}
      />
      <Route
        path={"/relatorios/prontuario/pdf"}
        component={MedicalRecordReportPDF}
      />
      <Route path={"/relatorios/endodontia/pdf"} />
      <Route path={"/relatorios/endodontia"} component={EndodonticReport} />
    </Container>
  );
}
