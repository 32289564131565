import React, { useState } from "react";
import { connect } from "react-redux";
import WysiwygMention from "./WysiwygMention";
import { sendEmail } from "../../actions/email";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Icon,
} from "@material-ui/core";
import { Send, Delete } from "@material-ui/icons";

const SendEmailDialog = ({
  onClose,
  modelEmail,
  listEmail,
  menu,
  sendEmail,
}) => {
  const [list, setList] = useState(listEmail);
  const [emailContext, setEmailContext] = useState(modelEmail);
  const [warningDialog, setWarningDialog] = useState({
    context: "",
    open: false,
  });
  const [loading, setLoading] = useState(false);

  const removeFromListEmail = (item) => {
    if (list.length <= 1) {
      setWarningDialog({
        context:
          "Necessário manter ao menos um destinatário para envio de e-mail.",
        open: true,
      });
    } else {
      const newList = list.filter((element) => element.id !== item.id);
      setList(newList);
    }
  };

  const sendEmailAction = () => {
    const email = {
      title: emailContext.title,
      html: emailContext.html,
      destination: list,
    };

    if (!email.title || email.html === "" || email.destination.length < 1) {
      setWarningDialog({
        context:
          "Verifique se o e-mail possui destinatário(s), assunto e corpo antes de continuar.",
        open: true,
      });
    } else {
      if (!loading) {
        setLoading(true);
      }

      sendEmail(email.title, email.html, email.destination, (res) => {
        setLoading(false);
        onClose();
      });
    }
  };

  const handleChangeTitle = (e) => {
    setEmailContext({ ...emailContext, title: e.target.value });
  };

  return (
    <>
      <Dialog fullWidth maxWidth={"md"} open={true}>
        <DialogTitle>{emailContext.name}</DialogTitle>

        <DialogContent>
          <Grid container spacing={12}>
            <Grid item xs={4}>
              <List
                subheader={
                  <ListSubheader>Lista de destinatarios</ListSubheader>
                }
              >
                {list.map((item, index) => (
                  <ListItem key={`model_${index}`} dense>
                    <ListItemText primary={item.name} secondary={item.email} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => removeFromListEmail(item)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={8}>
              <TextField
                required
                variant="outlined"
                autoFocus
                fullWidth
                label="Assunto"
                placeholder="Digite o assunto do e-mail"
                onChange={handleChangeTitle}
              />

              <div style={{ marginTop: "10px" }}>
                <WysiwygMention
                  type="Digite o e-mail..."
                  maxHeight={500}
                  model={emailContext.html}
                  onModelChange={(model) =>
                    setEmailContext({ ...emailContext, html: model })
                  }
                  menu={menu}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button disabled={loading} onClick={() => onClose()}>
            Fechar
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={loading}
            endIcon={<Icon>send</Icon>}
            onClick={() => sendEmailAction()}
          >
            Enviar
          </Button>
          {loading && <CircularProgress />}
        </DialogActions>
      </Dialog>

      <Dialog open={warningDialog.open}>
        <DialogContent>
          <DialogContentText>{warningDialog.context}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => setWarningDialog({ ...warningDialog, open: false })}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { sendEmail })(SendEmailDialog);
