var smoothScroll = {
  timer: null,

  stop: function () {
    clearTimeout(this.timer);
  },

  scrollTo: function (node, container, direction, fit, callback) {
    if (direction === "vertical")
      var dirConfig = {
        size: "Height",
        distance: "Top",
      };
    else
      var dirConfig = {
        size: "Width",
        distance: "Left",
      };
    var settings = {
      duration: 1000,
      easing: {
        outQuint: (x, t, b, c, d) =>
          c * ((t = t / d - 1) * t * t * t * t + 1) + b,
      },
    };
    var percentage;
    var startTime;
    var nodeDistance = node[`offset${dirConfig.distance}`] + (fit ? fit : 0);
    var nodeSize = node[`offset${dirConfig.size}`];
    var body = document.body;
    var html = document.documentElement;
    var distance = Math.max(
      body[`scroll${dirConfig.size}`],
      body[`offset${dirConfig.size}`],
      html[`client${dirConfig.size}`],
      html[`scroll${dirConfig.size}`],
      html[`offset${dirConfig.size}`],
    );
    var containerSize = container[`offset${dirConfig.size}`];
    var offset = container[`scroll${dirConfig.distance}`];
    var delta = nodeDistance - offset;
    var sizeScrollable = distance - containerSize;
    var targetDirection =
      sizeScrollable < delta
        ? sizeScrollable - (distance - nodeDistance - nodeSize + offset)
        : delta;

    startTime = Date.now();
    percentage = 0;

    if (this.timer) clearInterval(this.timer);

    function step() {
      var posScroll;
      var elapsed = Date.now() - startTime;

      if (elapsed > settings.duration) {
        clearTimeout(this.timer);
      }

      percentage = elapsed / settings.duration;

      if (percentage > 1) {
        clearTimeout(this.timer);

        if (callback) {
          callback();
        }
      } else {
        posScroll = settings.easing.outQuint(
          0,
          elapsed,
          offset,
          targetDirection,
          settings.duration,
        );
        direction === "vertical"
          ? container.scrollTo(0, posScroll)
          : container.scrollTo(posScroll, 0);
        this.timer = setTimeout(step, 10);
      }
    }

    this.timer = setTimeout(step, 10);
  },
};

export default smoothScroll;
