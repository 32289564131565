import React from "react";
import { Container, Paper } from "@material-ui/core";
import OndontogramComponent from "../../../forms/windows/OndontogramComponent";

export default function Odontogram({ patientId }) {
  return (
    <Container maxWidth="lg">
      <Paper elevation={0} style={{ padding: "16px" }}>
        <OndontogramComponent patientId={patientId} />
      </Paper>
    </Container>
  );
}
