import axios from "axios";

export async function listCashFlow(startDate, finalDate) {
  return await axios.get("/api/cashFlow", {
    params: {
      startDate: startDate.toISOString(),
      finalDate: finalDate.toISOString(),
    },
  });
}
