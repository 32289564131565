import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "../BudgetFormStyles";

import Select from "react-select";
import "react-select/dist/react-select.css";

import Toggle from "../../../common/Toggle";
import DateTimePicker from "../../date/DateTimePicker";
// import Button from '../../../common/Button';
import { Button, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SelectBox from "../../../common/SelectBox";
import InputField from "../../../forms/InputField";
import ProcedureInputAuto from "../treatmentModals/ProcedureInputAuto";

import { editProcedures } from "../../../../actions/treatment";
import { fetchDentists } from "../../../../actions/dentists";
import { fetchListCallback } from "../../../../actions/procedure";
import { listarTabelas } from "../../../../api/servicos";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import NumberFormat from "react-number-format";
const teeth = require("../../../../utils/teeth");
const FIELDS = [
  {
    name: "observation",
    label: "Observação",
    placeholder: "Digite a observação",
  },
];

class ModalProcedureForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.mathRound = this.mathRound.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.renderSelectBox = this.renderSelectBox.bind(this);
    // this.renderSelectDentists = this.renderSelectDentists.bind(this);
    this.onChangeSelectDentists = this.onChangeSelectDentists.bind(this);
    this.renderSpecification = this.renderSpecification.bind(this);
    this.renderFaces = this.renderFaces.bind(this);
    this.renderInputXray = this.renderInputXray.bind(this);
    this.renderInputTeeth = this.renderInputTeeth.bind(this);
    this.onChangeSelectedTooth = this.onChangeSelectedTooth.bind(this);
    this.onChangeSelectedToothInput =
      this.onChangeSelectedToothInput.bind(this);
    this.onChangeFaces = this.onChangeFaces.bind(this);
    this.showFaces = this.showFaces.bind(this);
    this.renderMultiplication = this.renderMultiplication.bind(this);
    this.renderFrenu = this.renderFrenu.bind(this);

    this.state = {
      listProcedure: [],
      selectedDentist: null,
      selectedProcedure: null,
      selectedTooth: [],
      selectedToothAux: [],
      faces: [],
      showFaces: false,
      procedureValue: null,
      date: null,
      toggle: false,
      listDisabled: false,

      listaTabelas: [],
      listIdTabelas: [],
      tabelaSelecionada: "",
    };
  }

  componentWillMount() {
    const { clinic, fetchListCallback, fetchDentists, treatment, procedure } =
      this.props;
    fetchDentists(clinic);
    fetchListCallback((ret) => {
      this.setState({
        listProcedure: ret,
      });
    });

    listarTabelas(clinic._id).then((tabelas) => {
      var idTabelas = [];
      if (tabelas.length > 0) {
        tabelas.map((tabela) => {
          if (tabela.ativo === true) {
            idTabelas.push({
              _id: tabela._id,
              titulo: tabela.titulo,
              ativo: tabela.ativo,
            });
          }
        });
        this.setState({
          listaTabelas: tabelas,
          listIdTabelas: idTabelas,
          tabelaSelecionada: idTabelas[0]._id,
        });
      }
    });
    if (procedure) {
      var procedureSelected =
        treatment.budgets[this.props.budgetSelected.idx].procedures[procedure];
      this.setState({
        selectedDentist: procedureSelected.dentist_id,
        selectedProcedure: procedureSelected.procedure,
        date: procedureSelected.date,

        selectedTooth: procedureSelected.specification.tooth,
        selectedToothAux: procedureSelected.specification.tooth,
        faces: procedureSelected.specification.face,
        procedureValue: procedureSelected.specification.value,
        toggle: procedureSelected.specification.value_acc,
        observation: procedureSelected.specification.observation,
        showFaces:
          procedureSelected.specification.face &&
          procedureSelected.specification.face.length > 0
            ? true
            : false,
      });
    } else {
      this.setState({
        date: new Date(),
      });
    }
  }

  component;
  componentDidMount() {
    const { treatment } = this.props;

    if (treatment.list_id) {
      this.props.change("list_id", treatment.list_id);
      this.setState({
        listDisabled: true,
      });
    }
  }

  onSubmit(values) {
    const {
      onCancel,
      selectedPatient,
      treatment,
      editProcedures,
      procedure,
      budgetSelected,
    } = this.props;

    var editedTreatment = treatment;
    if (procedure) {
      editedTreatment.budgets[budgetSelected.idx].procedures[procedure] = {
        list_id: values.list_id,
        dentist_id: this.state.selectedDentist,
        procedure: this.state.selectedProcedure,
        date: this.state.date,
        specification: {
          tooth: this.state.selectedTooth,
          face: this.state.faces,
          value: this.state.procedureValue,
          value_acc: this.state.toggle,
          frenu: values.frenu,
          xray: values.xray,
        },
        observation: values.observation,
      };
    } else {
      editedTreatment.budgets[budgetSelected.idx].procedures.push({
        list_id: values.list_id,
        dentist_id: this.state.selectedDentist,
        procedure: this.state.selectedProcedure,
        date: this.state.date,
        specification: {
          tooth: this.state.selectedTooth,
          face: this.state.faces,
          value: this.state.procedureValue,
          value_acc: this.state.toggle,
          frenu: values.frenu,
          xray: values.xray,
        },
        observation: values.observation,
      });
    }
    onCancel();
    if (selectedPatient) {
      editProcedures(editedTreatment, (ret) => {
        this.setState({});
      });
    }
  }

  mathRound(number) {
    function formatBR(v) {
      if (!v) {
        v = "";
      }
      v = v.toString();

      if (v.search(/([/.]+)/g) === -1) {
        v = v + ".00";
      }

      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{1,2})$/, ",$1");
      v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      v = v !== "" ? "R$ " + v : "R$ ";
      return v;
    }

    number = Math.round(number * 100) / 100;
    number = number.toFixed(2);
    return formatBR(number);
  }

  renderAuthMsg() {
    const { errorMsg } = this.state;

    if (errorMsg) {
      return (
        <div className={css(styles.msgAuth_modal)}>
          <span>{errorMsg}</span>
        </div>
      );
    }
  }

  renderFields(fields) {
    // iterate through the FIELDS array and create a Field for each item
    return fields.map((field) => {
      return (
        <Field
          key={field.name}
          type={field.type ? field.type : "text"}
          name={field.name}
          label={field.label}
          placeholder={field.placeholder ? field.placeholder : ""}
          mask={field.mask ? field.mask : ""}
          component={InputField}
        />
      );
    });
  }

  onDateChange(date) {
    this.setState({
      date: date,
    });
  }

  onToggle(res) {
    var value = this.state.selectedProcedure.precoFinal;
    if (res) {
      value = value * this.state.selectedTooth.length;
    }

    this.setState({
      toggle: res,
      procedureValue: value,
    });
  }

  renderSelectBox(options) {
    const list = [];

    if (options.length > 0) {
      options.map((item) => {
        list.push({ value: "", label: "Selecione uma lista" });
        if (item.ativo) list.push({ value: item._id, label: item.titulo });
      });
    } else {
      list.push({ value: "", label: "Nenhum serviço cadastrado" });
    }

    return (
      <Field
        key={"list_id"}
        type={"selectbox"}
        name={"list_id"}
        label={"Selecione a tabela"}
        itens={list}
        component={SelectBox}
      />
    );
  }

  // renderSelectDentists(options) {
  //   let dentist = [];
  //   if (options) {
  //     options.map(field => {
  //       dentist.push({ value: field._id, label: field.name });
  //     });
  //   }
  //   return (
  //     <fieldset className={css(styles.fieldset)}>
  //       <label className={css(styles.label)}>Dentista</label>
  //       <Select
  //         name="select"
  //         placeholder="Selecione o Dentista"
  //         value={this.state.selectedDentist}
  //         onChange={this.onChangeSelectDentists}
  //         options={dentist}
  //         multi={false}
  //       />
  //     </fieldset>
  //   );
  // }

  onChangeSelectDentists(e) {
    this.setState({
      selectedDentist: e.value,
    });
  }

  onChangeSelectedTooth(e) {
    var regex = /^[0-9,]+$/;

    if (regex.test(e.currentTarget.value)) {
      var eAux = e.currentTarget.value.split(",");

      this.setState({
        selectedToothAux: eAux,
      });
    }

    if (e.currentTarget.value === "") {
      this.setState({
        selectedToothAux: [],
      });
    }
  }

  onChangeSelectedToothInput(e) {
    var regex = /^[0-9,]+$/;

    if (regex.test(e.currentTarget.value)) {
      var eAux = e.currentTarget.value.split(",");
      this.setState({
        selectedTooth: eAux,
        selectedToothAux: eAux,
      });
    }

    if (e.currentTarget.value === "") {
      this.setState({
        selectedTooth: [],
        selectedToothAux: [],
      });
    }
  }

  onChangeFaces(newValue, index) {
    const nextFaces = this.state.faces.map((face, i) => {
      if (i === index) face.faces = newValue;
      return face;
    });
    this.setState({ faces: nextFaces });
  }

  showFaces() {
    if (this.state.selectedToothAux.length < 1) {
      this.setState({
        faces: [],
        selectedTooth: this.state.selectedToothAux,
        showFaces: false,
      });
    } else {
      var faces = [];
      this.state.selectedToothAux.map((tooth) => {
        var exists = false;
        this.state.faces.map((face) => {
          if (tooth === face.tooth) {
            faces.push(face);
            exists = true;
          }
        });

        if (!exists) {
          faces.push({ tooth: tooth, faces: [] });
        }
      }, this);

      this.setState({
        faces: faces,
        selectedTooth: this.state.selectedToothAux,
        showFaces: true,
      });
    }
  }

  handleChangePrecoFinal(valor) {
    this.setState({
      selectedProcedure: { ...this.state.selectedProcedure, precoFinal: valor },
      procedureValue: this.state.toggle
        ? valor * this.state.selectedTooth.length
        : valor,
    });
  }

  renderFaces() {
    return (
      <>
        <div>
          <NumberFormat
            label="Valor"
            customInput={TextField}
            // variant='outlined'
            size="small"
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$"}
            value={this.state.selectedProcedure.precoFinal}
            onValueChange={(value) =>
              this.handleChangePrecoFinal(value.floatValue)
            }
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Autocomplete
            multiple
            fullWidth
            size="small"
            value={this.state.selectedToothAux || null}
            options={teeth.dentes}
            onChange={(_, newValue) => {
              this.setState({ ...this.state, selectedToothAux: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Selecione os dentes"}
                // variant="outlined"
              />
            )}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: "12px" }}
            size="small"
            variant="contained"
            disableElevation
            onClick={this.showFaces}
          >
            Confirmar
          </Button>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <label className={css(styles.label)}>
            Selecione as faces dos dentes
          </label>
          {this.state.showFaces &&
            this.state.faces.map((item, index) => (
              <Autocomplete
                multiple
                size="small"
                options={teeth.faces}
                value={item.faces}
                onChange={(_, newValue) => {
                  this.onChangeFaces(newValue, index);
                }}
                key={index}
                style={{ marginTop: "8px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Dente: " + item.tooth}
                    // variant="outlined"
                  />
                )}
              />
            ))}
        </div>
      </>
      // <fieldset className={css(styles.fieldset)}>
      //   <fieldset className={css(styles.fieldset)}>
      //     <input className={css(styles.inputTeeth)} placeholder={'ex: 11,12,13 ...'} value={this.state.selectedToothAux.toString()} onChange={this.onChangeSelectedTooth}></input>
      //   </fieldset>
      //   {this.state.showFaces ?
      //     this.state.faces.map(item => {
      //       return (
      //         <div id={item.tooth} className={css(styles.fieldFace)}>
      //           <label className={css(styles.label)}>{item.tooth}</label>
      //           <input className={css(styles.input)} style={{ width: '85px' }} placeholder={'ex: V,D,M,L,O,C'} value={item.faces} onChange={this.onChangeFaces}></input>
      //         </div>
      //       )
      //     })
      //     :
      //     ''
      //   }
      // </fieldset>
    );
  }

  renderInputXray() {
    var list = [
      { value: "CM", label: "região da coroa e com tomada mesio-radial" },
      { value: "CD", label: "região da coroa e com tomada disto-radial" },
      { value: "CO", label: "região da coroa e com tomada orto-radial" },
      { value: "RM", label: "região da raiz e com tomada mesio-radial" },
      { value: "RD", label: "região da raiz e com tomada disto-radial" },
      { value: "RO", label: "região da raiz e com tomada orto-radial" },
      { value: "C", label: "região da cervical e com tomada mesio-radial" },
    ];

    return (
      <div>
        <fieldset className={css(styles.fieldset)}>
          <label className={css(styles.label)}>Dentes</label>
          <input
            className={css(styles.inputTeeth)}
            placeholder={"ex: 11,12,13 ..."}
            value={this.state.selectedToothAux.toString()}
            onChange={this.onChangeSelectedToothInput}
            style={{ width: "100%" }}
          ></input>
        </fieldset>
        <fieldset className={css(styles.fieldset)}>
          <Field
            key={"xray"}
            type={"selectbox"}
            name={"xray"}
            label={"Selecione a radiografia"}
            itens={list}
            component={SelectBox}
          />
        </fieldset>
      </div>
    );
  }

  renderFrenu() {
    var list = [
      { value: "SUPERIOR", label: "Superior" },
      { value: "INFERIOR", label: "Inferior" },
      { value: "LINGUAL", label: "Lingual" },
    ];

    return (
      <fieldset className={css(styles.fieldset)}>
        <Field
          key={"frenu"}
          type={"selectbox"}
          name={"frenu"}
          label={"Selecione a posição"}
          itens={list}
          component={SelectBox}
        />
      </fieldset>
    );
  }

  renderInputTeeth() {
    return (
      <fieldset className={css(styles.fieldset)}>
        <label className={css(styles.label)}>Dentes</label>
        <input
          className={css(styles.inputTeeth)}
          placeholder={"ex: 11,12,13 ..."}
          value={this.state.selectedToothAux.toString()}
          onChange={this.onChangeSelectedToothInput}
          style={{ width: "100%" }}
        ></input>
      </fieldset>
    );
  }

  renderSpecification(procedure) {
    if (procedure) {
      switch (procedure.procedimento) {
        case "NOTYPE":
          return "";
        case "CROWN":
          return this.renderFaces();
        case "RESTORATION":
          return this.renderFaces();
        case "RIZECTOMY":
          return this.renderFaces();
        case "APICECTOMY":
          return this.renderFaces();
        case "CHANNEL":
          return this.renderFaces();
        case "XRAY":
          return this.renderInputXray();
        case "DENTURES":
          return this.renderInputTeeth();
        case "MOBILE":
          return this.renderInputTeeth();
        case "FIXED":
          return this.renderInputTeeth();
        case "ADHESIVE":
          return this.renderInputTeeth();
        case "SPLINTER":
          return this.renderInputTeeth();
        case "GRAFT":
          return this.renderInputTeeth();
        case "CONEMORSE":
          return this.renderInputTeeth();
        case "SHORT":
          return this.renderInputTeeth();
        case "HEXAGONAL_OUT":
          return this.renderInputTeeth();
        case "HEXAGONAL_IN":
          return this.renderInputTeeth();
        case "ZYGOMATIC":
          return this.renderInputTeeth();
        case "NOBEL_REPLACE":
          return this.renderInputTeeth();
        case "RETAIL":
          return this.renderInputTeeth();
        case "STEEL_CROWN":
          return this.renderInputTeeth();
        case "PULPECTOMY":
          return this.renderInputTeeth();
        case "FACETS":
          return this.renderInputTeeth();
        case "CORE":
          return this.renderInputTeeth();
        case "METAL_BLOCK":
          return this.renderFaces();
        case "RESORPTION":
          return this.renderFaces();
        case "RAP":
          return this.renderFaces();
        case "FRENULOTOMY":
          return this.renderFrenu();
        case "SELANTE":
          return this.renderFaces();
        case "EXTRACTION":
          return this.renderInputTeeth();
        default:
          return "";
      }
    }
  }

  renderMultiplication(teeth) {
    if (teeth.length > 1) {
      return (
        <fieldset className={css(styles.fieldset)}>
          <Toggle
            label={"Multiplicar valor do serviço por número de dentes?"}
            defaultValue={this.state.toggle}
            change={(e) => {
              this.onToggle(e);
            }}
          />
          <fieldset className={css(styles.fieldset)}>
            <label className={css(styles.label)}>
              {" "}
              Valor Total: {this.mathRound(this.state.procedureValue)}
            </label>
          </fieldset>
        </fieldset>
      );
    } else if (teeth.length > 0) {
      return (
        <fieldset className={css(styles.fieldset)}>
          <label className={css(styles.label)}>
            {" "}
            Valor Total: {this.mathRound(this.state.procedureValue)}
          </label>
        </fieldset>
      );
    }
    return "";
  }

  render() {
    const { handleSubmit, onCancel } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {this.renderAuthMsg()}

        {this.renderSelectBox(this.state.listaTabelas)}
        <ProcedureInputAuto
          label={"Serviço"}
          placeholder={"Digite o nome do serviço"}
          limit={5}
          list={
            this.state.selectedList
              ? this.state.listaTabelas.filter((tabela) => {
                  return tabela._id === this.props.selectedList;
                })[0]
              : this.state.listaTabelas
          }
          selectedList={this.props.selectedList}
          value={this.state.selectedProcedure}
          onSelect={(e) => {
            this.setState({
              selectedProcedure: e,
              procedureValue: e.precoFinal,
            });
          }}
        />
        {/* {this.renderSelectDentists(this.props.dentistsById)} */}
        {/* <DateTimePicker name="date" value={this.state.date} label="Data" onChange={this.onDateChange} /> */}
        {/* <h3 className={css(styles.sectionTitle)}>Especificações</h3> */}

        {this.renderSpecification(this.state.selectedProcedure)}
        {this.renderMultiplication(this.state.selectedTooth)}
        {this.renderFields(FIELDS)}

        <Button
          type="submit"
          disableElevation
          variant="contained"
          color="primary"
        >
          Salvar
        </Button>
        <Button onClick={onCancel}>Cancelar</Button>
      </form>
    );
  }
}

// Redux Form function to handle form validation
/*
function validate(values) {
  const errors = {};

  if (!values.code) {
    errors.code = 'Digite o código';
  }

  if (values.description) {
    if(values.description.trim().length <= 0){
      errors.description = 'Digite a descrição';
    }
  }
  if (!values.description) {
    errors.description = 'Digite a descrição';
  }

  if (!values.price) {
    errors.price = 'Digite o valor';
  }
  return errors;
}*/

const modalProcedureForm = reduxForm({
  //validate,
  form: "modalProcedureForm",
})(ModalProcedureForm);

function mapStateToProps(state, props) {
  let initialValues = {};

  if (props.procedure) {
    initialValues =
      props.treatment.budgets[props.budgetSelected.idx].procedures[
        props.procedure
      ];
    if (initialValues.specification.xray) {
      initialValues.xray = initialValues.specification.xray;
    }
    if (initialValues.specification.frenu) {
      initialValues.frenu = initialValues.specification.frenu;
    }
  }

  const selector = formValueSelector("modalProcedureForm");
  let selectedList = selector(state, "list_id");

  return {
    clinic: state.auth.clinic,
    selectedPatient: state.patientsCreation.selectedPatient,
    selectedList: selectedList,
    ListProcedure: state.procedureConfig.proceduresById,
    dentistsById: state.clinicConfig.dentistsById,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  fetchListCallback,
  fetchDentists,
  editProcedures,
})(modalProcedureForm);
