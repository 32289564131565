import { StyleSheet } from "aphrodite/no-important";

import { COLORS, PALLETE } from "../_constants/colors";

export const styles = StyleSheet.create({
  sideMenu: {
    position: "relative",
    backgroundColor: COLORS.primary,
    color: "#fff",
    fontSize: ".85rem",
    gridColumn: 1,
    gridRow: "1 / 3",
    justifySelf: "stretch",
    flex: 1,
  },
  navLink: {
    position: "relative",
    display: "block",
    // height: '2.5rem',
    padding: "0.25rem 1rem",
    color: "white",
    textDecoration: "none",
    transition: "all .5s ease",
    ":hover": {
      backgroundColor: PALLETE.primary.dark,
      color: COLORS.white,
    },
  },
  navList: {
    padding: 0,
    listStyle: "none",
    margin: "1rem 0",
  },
  navListBottom: {
    width: "100%",
  },
  active: {
    color: COLORS.white,
    backgroundColor: PALLETE.primary.darker,
  },
  navText: {
    verticalAlign: "middle",
    lineHeight: "2rem",
    marginLeft: ".9em",
  },

  wrapper: {
    height: "99vh",
    position: "absolute",
  },
});
