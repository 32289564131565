import axios from "axios";
import { SHOW_MESSAGE } from "../actions/systemMsg";

export function sendEmail(title, html, destination, callback) {
  return async (dispatch) => {
    try {
      // Não fará o envio de e-mail ainda, pois servidor de e-mail não está configurado
      const res = await axios.post('/api/email', {title, html, destination});
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Email enviado com sucesso!`, type: "success" },
      });
      if (callback) callback("sucesso");
    } catch (err) {
      console.log(err);
    }
  };
}
