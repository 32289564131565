export const dataHoraFormadata = (objeto) => {
  var date = new Date(objeto),
    dia = date.getDate().toString(),
    diaF = dia.length === 1 ? "0" + dia : dia,
    mes = (date.getMonth() + 1).toString(),
    mesF = mes.length === 1 ? "0" + mes : mes,
    anoF = date.getFullYear(),
    hora = date.getHours().toString(),
    horaF = hora.length === 1 ? `0${hora}` : hora,
    minuto = date.getMinutes().toString(),
    minutoF = minuto.length === 1 ? `0${minuto}` : minuto,
    segundo = date.getSeconds().toString(),
    segundoF = segundo.length === 1 ? `0${segundo}` : segundo;

  return {
    data: `${diaF}/${mesF}/${anoF}`,
    horario: `${horaF}:${minutoF}:${segundoF}`,
  };
};

export const validaCpf = (cpf) => {
  var Soma;
  var Resto;
  Soma = 0;
  cpf = cpf
    .split(".")
    .join(",")
    .split("-")
    .join(",")
    .replace(/,/g, "")
    .replace(/ /g, "");
  console.log(cpf);
  if (cpf == "00000000000") return false;

  for (var i = 1; i <= 9; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);

  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10))) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const validaEmail = (email) => {
  var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if (reg.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const formataMoedaReal = (valor) => {
  if (!valor) return;
  return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
};

export const percentualOuDinheiro = (tipo, valor, valorTotal) => {
  switch (tipo) {
    case "percent":
      return valorTotal / ((100 - valor) / 100) - valorTotal;
    case "cash":
      return valor;
  }
};
