import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import {
  createUser,
  verifyUserExistAndExistInClinic,
} from "../../../actions/users";
import { showMessageSystem } from "../../../actions/systemMsg";

function FormCadastrarUsuario({
  onCancel,
  createUser,
  verifyUserExistAndExistInClinic,
}) {
  const [formEmail, setFormEmail] = useState({
    email: "",
    error: false,
    errorMensagem: "",
  });
  const [render, setRender] = useState({
    formEmail: true,
    formUsuarioExistente: false,
    formNovoUsuario: false,
  });

  const verificarEmailCadastradoClinica = () => {
    verifyUserExistAndExistInClinic(formEmail.email, (res) => {
      if (res.cadastrado && res.cadastradoClinica) {
        setFormEmail({
          error: true,
          errorMensagem: "E-mail já cadastrado na clínica.",
        });
      } else {
        montaProximaTela(res.cadastrado);
      }
    });
  };

  const montaProximaTela = (cadastrado) => {
    if (cadastrado) {
      montaCadastroUsuarioExistente();
    } else {
      montaCadastroNovoUsuario();
    }
  };

  const montaCadastroUsuarioExistente = () => {
    setRender({
      formUsuarioExistente: true,
      formEmail: false,
    });
  };

  const montaCadastroNovoUsuario = () => {
    setRender({
      formNovoUsuario: true,
      formEmail: false,
    });
  };

  const cadastrarUsuario = (user) => {
    const novoUsuario = {
      name: user.name ?? "",
      password: user.password ?? "",
      email: formEmail.email,
      userActive: user.userActive,
    };
    createUser(novoUsuario, () => {
      onCancel();
    });
  };

  return (
    <>
      {render.formEmail && (
        <FormEmail
          formEmail={formEmail}
          setEmail={setFormEmail}
          submit={verificarEmailCadastradoClinica}
        />
      )}
      {render.formUsuarioExistente && (
        <FormUsuarioExistente
          email={formEmail.email}
          cadastrarUsuario={cadastrarUsuario}
        />
      )}
      {render.formNovoUsuario && (
        <FormNovoUsuario
          email={formEmail.email}
          cadastrarUsuario={cadastrarUsuario}
        />
      )}
    </>
  );
}

function FormEmail({ formEmail, setEmail, submit }) {
  const changeEmail = (e) => {
    setEmail({ email: e.target.value, error: false, errorMensagem: "" });
  };

  const handleSubmit = () => {
    if (!formEmail.email) {
      return setEmail({
        error: true,
        errorMensagem: "Insira um e-mail para continuar",
      });
    }
    submit();
  };

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <Typography variant="subtitle1">
          Insira um e-mail para iniciar o cadastro.
        </Typography>
      </div>
      <TextField
        autoFocus
        onChange={changeEmail}
        type={"email"}
        variant="outlined"
        label="Email"
        fullWidth
        required
        error={formEmail.error}
        helperText={formEmail.error && formEmail.errorMensagem}
      />
      <div style={{ marginTop: "24px", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Avançar
        </Button>
      </div>
    </div>
  );
}

function FormNovoUsuario({ email, cadastrarUsuario }) {
  const [form, setForm] = useState({
    nome: "",
    senha: "",
    ativo: false,
  });

  const [errors, setErrors] = useState({
    nome: false,
    nomeMensagem: "Campo obrigatório",
    senha: false,
    senhaMensagem: "Campo Obrigatório",
  });

  useEffect(() => {
    setErrors({ ...errors, nome: false });
  }, [form.nome]);

  useEffect(() => {
    setErrors({ ...errors, senha: false });
  }, [form.senha]);

  const handleSubmit = () => {
    const formError = {
      nome: !form.nome ? true : false,
      senha: !form.senha ? true : false,
    };

    if (formError.nome || formError.senha) {
      return setErrors({ ...errors, ...formError });
    }

    const user = {
      name: form.nome,
      password: form.senha,
      userActive: form.ativo,
    };

    cadastrarUsuario(user);
  };

  return (
    <>
      <Typography>Email: {email}</Typography>
      <div style={{ marginTop: "16px" }}>
        <TextField
          onChange={(e) => setForm({ ...form, nome: e.target.value })}
          variant="outlined"
          label="Nome"
          fullWidth
          required
          error={errors.nome}
          helperText={errors.nome && errors.nomeMensagem}
        />
      </div>
      <div style={{ marginTop: "16px" }}>
        <TextField
          onChange={(e) => setForm({ ...form, senha: e.target.value })}
          type={"password"}
          variant="outlined"
          label="Senha"
          fullWidth
          required
          error={errors.senha}
          helperText={errors.senha && errors.senhaMensagem}
        />
      </div>
      <FormControlLabel
        label="Ativar usuário ao finalizar o cadastro?"
        control={
          <Checkbox
            defaultChecked={false}
            color="primary"
            onChange={(e) => setForm({ ...form, ativo: e.target.checked })}
          />
        }
      />
      <div style={{ marginTop: "24px", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Cadastrar
        </Button>
      </div>
    </>
  );
}

function FormUsuarioExistente({ email, cadastrarUsuario }) {
  const [form, setForm] = useState({
    ativo: false,
  });

  const handleSubmit = () => {
    const user = {
      userActive: form.ativo,
    };
    cadastrarUsuario(user);
  };

  return (
    <>
      <Typography>Email: {email}</Typography>
      <div style={{ marginTop: "16px" }}>
        <Typography>Usuário já cadastro em outra clínica.</Typography>
        <Typography>
          Deseja vincular este usuário mantendo usuário e senha?
        </Typography>
      </div>
      <FormControlLabel
        label="Ativar usuário ao finalizar o cadastro?"
        control={
          <Checkbox
            defaultChecked={false}
            color="primary"
            onChange={(e) => setForm({ ...form, ativo: e.target.checked })}
          />
        }
      />
      <div style={{ marginTop: "24px", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Cadastrar
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  verifyUserExistAndExistInClinic,
  createUser,
  showMessageSystem,
})(FormCadastrarUsuario);
