import React from "react";
import moment from "moment";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import { Button, IconButton } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import TableComponent from "../../../table/TableComponent";

const ParcelsTable = ({ parcels, receiptParcel, confirmPayment }) => {
  parcels.map((parcel) => {
    if (parcel.number === "entrance") parcel.number = "Entrada";
    parcel.functions = {
      parcelId: parcel._id,
      paid: parcel.paid,
      confirmPayment: parcel.payment.confirm,
    };
    return parcel;
  });

  const columns = [
    { label: "Parcela", name: "number" },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => formataMoedaReal(value),
      },
    },
    {
      label: "Data de vencimento",
      name: "expiration",
      options: {
        customBodyRender: (value) =>
          value ? moment(value).format("DD/MM/YYYY") : null,
      },
    },
    {
      label: "Data de pagamento",
      name: "payment.date",
      options: {
        customBodyRender: (value) =>
          value ? moment(value).format("DD/MM/YYYY") : null,
      },
    },
    {
      label: " ",
      name: "functions",
      options: {
        customBodyRender: (value) =>
          !value.paid ? (
            <Button onClick={() => receiptParcel(value.parcelId)}>Pagar</Button>
          ) : !value.confirmPayment ? (
            <IconButton
              title="Confirmar pagamento"
              onClick={() => confirmPayment(value.parcelId)}
            >
              <Check />
            </IconButton>
          ) : null,
      },
    },
  ];

  const options = {
    elevation: 1,
    selectableRows: "none",
  };

  return (
    <TableComponent
      title="parcels"
      colunas={columns}
      objetos={parcels}
      otherOptions={options}
    />
  );
};

export default ParcelsTable;
