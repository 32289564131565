import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const FETCH_CLINIC_USERS = "FETCH_CLINIC_USERS";
export const GET_CLINIC_USER = "GET_CLINIC_USER";
export const CLEAR_USER = "CLEAR_USER";
export const PUSH_USER = "PUSH_USER";

export function fetchUsers(callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/users");
      dispatch({ type: FETCH_CLINIC_USERS, payload: res.data });
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function createUser(user, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/user", user);
      dispatch({ type: PUSH_USER, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Usuário inserido com sucesso.`, type: "success" },
      });

      return callback();
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: err.response.data, type: "error" },
      });
    }
  };
}

export function createUserPlatform(user, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/user/createUserPlatform", user);
      dispatch({ type: PUSH_USER, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: "Usuário inserido com sucesso.", type: "success" },
      });

      return callback();
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: err.response.data, type: "error" },
      });
    }
  };
}

export function getUsersPlatform(callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/user/platform");
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function getUser(userId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/user/${userId}`);

      dispatch({ type: GET_CLINIC_USER, payload: res.data });

      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function verifyUserExistAndExistInClinic(email, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/user/exist`, {
        params: {
          email: email,
        },
      });
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function updateUser(data, userId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/user_update/${userId}`, data);

      dispatch({ type: GET_CLINIC_USER, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: ` As informações de ${res.data.name} foram atualizadas`,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Usuário não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export function clearUser() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_USER, payload: null });
  };
}

export function updateStatus(user, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/user/updateStatus/${user.id}`, user);
      callback(res);
    } catch (err) {
      console.log(err);
    }
  };
}
