import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  buscarEspecialidades,
  adicionarEspecialidade,
} from "../../../../api/dentistas";

class DentistasListagemCadastro extends Component {
  state = {
    formulario: [],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    dentista_id: "",
    especialidades: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.dentista_id) {
      var objEspecialidade = await buscarEspecialidades(clinica._id);
      var formulario = this.state.formulario;

      var selectEspecialidade = {
        type: "select",
        name: "especialidades",
        label: "Especialidades",
        values: [],
      };

      objEspecialidade.map((obj) => {
        var novo = {};
        novo.name = obj.titulo;
        novo.value = obj._id;
        selectEspecialidade.values.push(novo);
      });

      formulario.push(selectEspecialidade);
      console.log(formulario);
      this.setState({
        especialidades: objEspecialidade,
        await: false,
        dentista_id: this.props.match.params.dentista_id,
        formulario: formulario,
      });
    } else {
      // this.setState({await:false});
    }
  }

  vinculaEspecialidade(objeto) {
    const { dentista_id } = this.state;

    adicionarEspecialidade(dentista_id, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Especialidade vinculada com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível vincular a Especialidade!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            action={this.vinculaEspecialidade.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DentistasListagemCadastro);
