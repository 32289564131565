import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "../PatientFormStyles";

import Button from "../../../common/Button";

import { createList } from "../../../../actions/procedure";
import FroalaEditor from "react-froala-wysiwyg";
// import WysiwygMention from "../../../common/WysiwygMention";
// import InputSearch from "../../InputSearch";
// import Icon from "../../../common/Icon";
import InputField from "../../../forms/InputField";

class ModalClinicalNote extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.render = this.render.bind(this);

    this.state = {
      model: this.props.model,
      menu: [],
      errorMsg: null,
      title: "",
      note: "",
      disabledButton: false,
    };
  }

  componentWillMount() {
    const { title, note } = this.props.initialValues;
    this.setState({ title, note });
  }

  onSubmit(values) {
    const { onSubmit, onCancel } = this.props;
    this.setState({ disabledButton: true });
    console.log(typeof this.state.title);

    let noteObject = {
      issue_date:
        this.state.note?.issue_date !== undefined
          ? this.state.note.issue_date
          : new Date(),
      note: this.state.note,
      title: this.state.title !== undefined ? this.state.title : "Sem Título",
    };

    onSubmit(noteObject);
    onCancel();
  }

  renderAuthMsg() {
    const { errorMsg } = this.state;

    if (errorMsg) {
      return (
        <div className={css(styles.msgAuth_modal)}>
          <span>{errorMsg}</span>
        </div>
      );
    }
  }

  renderFields({ input }) {
    return <textarea {...input} name="note" rows="15" cols="70"></textarea>;
  }

  render() {
    const { handleSubmit, onCancel } = this.props;

    return (
      <form
        className={css(styles.form_modal)}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        {this.renderAuthMsg()}
        <div className={css(styles.fieldset)}>
          <Field
            type={"input"}
            name="title"
            value={this.state.title ?? "Sem Título"}
            label={"Título"}
            onChange={(e) => {
              this.setState({
                title: e.target.value,
              });
            }}
            component={InputField}
          />
          <div style={{ padding: "1rem" }}>
            <div className={css(styles.htmlGrid)}>
              <FroalaEditor
                tag="textarea"
                config={{
                  heightMin: "250px",
                  heightMax: "250px",
                  charCounterCount: true,
                  toolbarButtons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                    "|",
                    "fontFamily",
                    "fontSize",
                    "color",
                  ],
                  pluginsEnabled: ["fontFamily", "fontSize", "colors"],
                }}
                model={this.state.note}
                onModelChange={(note) => this.setState({ note })}
              />
            </div>
          </div>
        </div>
        <Button
          text="Salvar"
          color={!this.state.disabledButton ? "green" : "disabled"}
          disabled={this.state.disabledButton}
          submit
        />

        <Button text="Cancelar" color="secondary" onClick={onCancel} right />
      </form>
    );
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  if (values.note) {
    if (values.note.trim().length <= 0) {
      errors.note = "Digite o nome da Lista";
    }
  }
  if (!values.note) {
    errors.note = "Digite o nome da Lista";
  }
  return errors;
}

const modalClinicalNote = reduxForm({
  validate,
  form: "modalClinicalNote",
})(ModalClinicalNote);

function mapStateToProps(state, props) {
  const note = props.note;
  let initialValues = {};

  if (note) {
    initialValues = note;
  }

  return {
    selectedPatient: state.patientsCreation.selectedPatient,
    user: state.auth.user,
    initialValues,
  };
}

export default connect(mapStateToProps, { createList })(modalClinicalNote);
