import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  section: { textAlign: "center", fontSize: 10 },
  page: {
    margin: 10,
    flexDirection: "column",
    fontSize: 4,
  },
  viewTitle: { textAlign: "center" },
});

export default function EndodonticReportPDF({ data, header }) {
  const { pdfData, totalRecords, totalPatients } = data;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>RELATÓRIO ESTATÍSTICO DA ENDODONTIA</Text>
        </View>
        <View style={{ marginBottom: 10 }}>
          <Text>Data emissão: {moment().format("DD/MM/YYYY")}</Text>
        </View>
        <ItemsTable data={pdfData} header={header} />
        <View style={{ marginTop: 10 }}>
          <Text>TOTAL</Text>
          <Text>Pacientes: {totalPatients} </Text>
          <Text>Casos: {totalRecords}</Text>
        </View>
      </Page>
    </Document>
  );
}

const ItemsTable = ({ data, header }) => {
  return (
    <View>
      <TableHeader header={header} />
      <TableRow items={data} header={header} />
    </View>
  );
};

const styleTable = {
  head: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  patient: { flex: 1.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  birthday: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  tooth: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  channel: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  odontometric: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  realLength: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  workLength: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  lime: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  drill: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  core: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  reference: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  xray: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  diagnostic: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  dentalTrauma: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  procedure: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  curative: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  irrigationSolutions: {
    flex: 1,
    padding: "2px",
    borderBottom: "1 solid #eeeeee",
  },
  preparionTechniques: {
    flex: 1,
    padding: "2px",
    borderBottom: "1 solid #eeeeee",
  },
  dentalCement: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  typeFilling: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
};

const TableHeader = ({ header }) => {
  return (
    <View style={styleTable.head}>
      {header.map((head) => (
        <Text style={styleTable[head.name]} key={head}>
          {head.label}
        </Text>
      ))}
    </View>
  );
};

const TableRow = ({ items, header }) => {
  return items.map((item, index) => (
    <View style={styleTable.row} key={index}>
      {header.map((head) => (
        <Text style={styleTable[head.name]} key={head}>
          {item[head.name]}
        </Text>
      ))}
    </View>
  ));
};
