import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  section: { textAlign: "center", fontSize: 10 },
  page: {
    margin: 10,
    flexDirection: "column",
    fontSize: 7,
  },
  viewTitle: { textAlign: "center" },
});

export default function MedicalRecordReportPDF({ data, header }) {
  const { pdfData, totalRecords, totalPatients } = data;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>RELATÓRIO ESTATÍSTICO DO PRONTUÁRIO</Text>
        </View>
        <View style={{ marginBottom: 10 }}>
          <Text>Data emissão: {moment().format("DD/MM/YYYY")}</Text>
        </View>
        <ItemsTable data={pdfData} header={header} />
        <View style={{ marginTop: 10 }}>
          <Text>TOTAL</Text>
          <Text>Pacientes: {totalPatients} </Text>
          <Text>Casos: {totalRecords}</Text>
        </View>
      </Page>
    </Document>
  );
}

const ItemsTable = ({ data, header }) => {
  return (
    <View>
      <TableHeader header={header} />
      <TableRow items={data} header={header} />
    </View>
  );
};

const styleTable = {
  head: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  patient: { flex: 1.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  service: { flex: 1.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  codService: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  dentist: { flex: 1.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  age: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  birthday: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  treatmentDate: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  teeth: { flex: 1.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  gender: { flex: 0.5, padding: "2px", borderBottom: "1 solid #eeeeee" },
  // situation: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
  value: { flex: 1, padding: "2px", borderBottom: "1 solid #eeeeee" },
};

const TableHeader = ({ header }) => {
  return (
    <View style={styleTable.head}>
      {header.map((head) => (
        <Text style={styleTable[head.name]}>{head.label}</Text>
      ))}
    </View>
  );
};

const TableRow = ({ items, header }) => {
  return items.map((item, index) => (
    <View style={styleTable.row}>
      {header.map((head) => (
        <Text style={styleTable[head.name]}>{item[head.name]}</Text>
      ))}
    </View>
  ));
};
