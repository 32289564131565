import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  editarFornecedor,
  buscarFornecedor,
  cadastrarFornecedor,
} from "../../../../api/fornecedor";

class FornecedorCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "nome", label: "Nome" },
      { type: "text", name: "telefone", label: "Telefone" },
      { type: "text", name: "contato", label: "Contato" },
      { type: "number", name: "cnpj", label: "CNPJ" },
      { type: "text", name: "email", label: "E-Mail" },
      { type: "text", name: "site", label: "Site" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    fornecedor: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;
    if (this.props.match.params.fornecedor_id) {
      var objFornecedor = await buscarFornecedor(
        clinica._id,
        this.props.match.params.fornecedor_id,
      );
      this.setState({
        cadastro: false,
        fornecedor: objFornecedor,
        await: false,
      });
    } else {
      this.setState({ await: false });
    }
  }

  editaFornecedor(objeto) {
    editarFornecedor(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Fornecedor editado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 3000);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o fornecedor!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  salvarFornecedor(objeto) {
    const { clinica } = this.props;
    cadastrarFornecedor(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Fornecedor cadastrado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 3000);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o Fornecedor!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        <Paper>
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
            />
          )}
          {!this.state.await && (
            <FormComponent
              {...this.props}
              inputs={this.state.formulario}
              objeto={this.state.fornecedor}
              action={
                this.state.cadastro
                  ? this.salvarFornecedor.bind(this)
                  : this.editaFornecedor.bind(this)
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(FornecedorCadastro);
