import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { css } from "aphrodite/no-important";
import { gridStyles } from "./DashboardStyles";

// iniciando importacao da estilização do novo Dash
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// Finaliza importacao

import SideMenu from "../navigation/SideMenu";
import TopBar from "../bars/TopBar";
import SystemMsg from "../notifications/SystemMsg";

import resumeDashboard from "./resume/resumeDashboard";
import PatientsRouter from "./patients/PatientsRouter";
import StorageRouter from "./storage/StorageRouter";
import AuditRouter from "./audit/AuditRouter";
import AgendaRouter from "./agenda/AgendaRouter";
import ConfigurationRouter from "./configurations/ConfigurationRouter";
import ImportRouter from "./import/ImportRouter";
import WindowsController from "./windows/WindowsController";
import financeAccount from "./finances/financeAccount";
import Confirmacao from "../notificacao/Confirmacao";

import PacientesRouter from "./pacientes/PacientesRouter";
import ServicosRouter from "./servicos/ServicosRouter";
import DentistasRoutes from "./dentistas/DentistasRouter";
import ProteticosRouter from "./proteticos/ProteticosRouter";
import ContasPagarRouter from "./contasPagar/ContasPagarRouter";
import FinanceiroRouter from "./finances/FinanceiroRouter";
import ConveniosRouter from "./convenios/ConveniosRouter";
import FornecedorRouter from "./fornecedor/FornecedorRouter";
import RecebimentosRouter from "./recebimento/RecebimentosRouter";
import { ToastContainer } from "react-toastify";
import UsuarioPlataformaRouter from "./usuarios/UsuarioPlataformaRouter";
import { AdminRouter } from "./admin/AdminRouter";
import ReportRoutes from "./report/report_routes";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100vh",
    backgroundColor: "#fafafa",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fff",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    zIndex: 99999999,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function Dashboard(props) {
  const { history, match, user } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userClinicRoutes = () => {
    return (
      <>
        <Route path={`${match.url}pacientes`} component={PacientesRouter} />
        <Route path={`${match.url}servicos`} component={ServicosRouter} />
        <Route path={`${match.url}patients`} component={PatientsRouter} />
        <Route path={`${match.url}dentistas`} component={DentistasRoutes} />
        <Route path={`${match.url}proteticos`} component={ProteticosRouter} />
        <Route
          path={`${match.url}recebimentos`}
          component={RecebimentosRouter}
        />
        <Route path={`${match.url}contas`} component={ContasPagarRouter} />
        <Route path={`${match.url}financeiro`} component={FinanceiroRouter} />
        <Route path={`${match.url}convenios`} component={ConveniosRouter} />
        <Route path={`${match.url}fornecedor`} component={FornecedorRouter} />
        <Route path={`${match.url}agenda`} component={AgendaRouter} />
        <Route
          path={`${match.url}finances/:type?/:account?/:since?/:to?`}
          component={financeAccount}
        />
        <Route
          exact
          path={`${match.url}finances`}
          render={() => (
            <Redirect
              to={`${match.url}finances/general/all/${new Date().toISOString().slice(0, 10)}/infinite`}
            />
          )}
        />
        <Route path={`${match.url}storage`} component={StorageRouter} />
        <Route
          path={`${match.url}communication`}
          component={() => <h1>communication</h1>}
        />
        <Route path={`${match.url}bi`} component={() => <h1>bi</h1>} />
        <Route path={`${match.url}audit`} component={AuditRouter} />
        <Route path={`${match.url}relatorios`} component={ReportRoutes} />

        <Route path={`${match.url}import`} component={ImportRouter} />

        <Route exact path={match.url} component={resumeDashboard} />
      </>
    );
  };

  const userProfessionalRoutes = () => {
    return (
      <>
        <Route
          exact
          path={match.url}
          render={() => <Redirect to={match.url + "admin"} />}
        />
        <Route path={match.url + "admin"} component={AdminRouter} />
      </>
    );
  };

  const renderTopBar = () => {
    return <TopBar history={history} />;
  };

  if (props.auth.authenticated) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {!user?.professional_platform && renderTopBar()}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon color="primary" />
              ) : (
                <ChevronRightIcon color="primary" />
              )}
            </IconButton>
          </div>
          <Divider />
          <SideMenu match={match} history={history} />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <div className={css(gridStyles.content)}>
            <SystemMsg />
            <Route
              path={`${match.url}configuration`}
              component={ConfigurationRouter}
            />
            {user?.professional_platform
              ? userProfessionalRoutes()
              : userClinicRoutes()}

            <Confirmacao {...props} />

            <WindowsController />
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <h1> Carregando </h1>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalConfirmacao: state.modalConfirmacao,
    user: state.auth?.user,
  };
}
export default connect(mapStateToProps)(Dashboard);
