import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import TableComponent from "../../../table/TableComponent";
import { toast } from "react-toastify";
import { Edit, Delete as DeleteIcon } from "@material-ui/icons";
import { Chip, IconButton } from "@material-ui/core";
import { deletarParcelaConta, buscarContas } from "../../../../api/contasPagar";
import SimpleDialog from "../../../dialog/SimpleDialog";
import moment from "moment";

const currentDate = new Date();
class ContasPagarListagem extends Component {
  removerConta = this.removerConta.bind(this);
  state = {
    contas: [],
    isOpenDialog: false,
    selectedContaId: "",
    dataInicial: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    dataFinal: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ),
    pago: "",
  };

  async removerConta() {
    try {
      await deletarParcelaConta({ parcelaId: this.state.selectedContaId });
      toast.success("Parcela excluída com sucesso!");
      // setTabelas(tabelas.filter((tabela) => tabela._id !== servicoId));
      this.setState({ isOpenDialog: false });
      this.setState({ selectedContaId: "" });
      this.searchAccount();
    } catch (error) {
      toast.error("Erro ao excluir parcela!");
    }
  }

  searchAccount = async () => {
    try {
      const { dataInicial, dataFinal, pago } = this.state;
      const clinicaId = this.props.clinica._id;
      const contas = await buscarContas(clinicaId, {
        dataInicial,
        dataFinal,
        pago: pago === "" ? null : pago,
      });
      this.processarContas(contas);
    } catch (error) {
      toast.error("Erro ao buscar contas!");
    }
  };
  processarContas(contas) {
    var listaContas = [];

    if (contas.length > 0) {
      contas.map((conta, index) => {
        var novo = {};
        var valorDevido = conta.parcelas.valor;

        novo.nome = conta.parcelas.nome;
        novo.contaId = conta.parcelas.parcelaId;
        novo.dataVenc = moment(conta.parcelas.dataVenc).format("DD/MM/YYYY");
        novo.valor = parseFloat(valorDevido).toFixed(2);
        novo.valorTotal = parseFloat(conta.parcelas.valorTotal).toFixed(2);
        novo.indice = conta.parcelas.indice;
        novo.observacao = conta.parcelas.observacao;
        novo.pago = conta.parcelas.pago;
        novo.funcoes = [
          <IconButton
            title="Editar parcela"
            key={index}
            style={{ maxWidth: 40 }}
            onClick={() => {
              this.props.history.push(
                `/contas/cadastrarConta/${conta.parcelas.parcelaId}`
              );
            }}
          >
            <Edit />
          </IconButton>,
          <IconButton
            title="Deletar parcela"
            key={conta.parcelas.parcelaId}
            style={{ maxWidth: 40 }}
            onClick={() => {
              this.setState({ isOpenDialog: true });
              this.setState({
                selectedContaId: conta.parcelas.parcelaId,
              });
            }}
          >
            <DeleteIcon />
          </IconButton>,
        ];

        listaContas.push(novo);
      });
    }
    this.setState({ contas: listaContas });
  }

  componentWillMount() {
    this.searchAccount();
  }

  handleDateChange = (field, date) => {
    this.setState({ [field]: date });
  };

  handlePagoChange = (event) => {
    this.setState({ pago: event.target.value });
  };

  render() {
    const { dataInicial, dataFinal, pago } = this.state;
    const titulo = "Contas A Pagar",
      colunas = [
        { label: "Nome do Credor", name: "nome" },
        { label: "Data Vencimento", name: "dataVenc" },
        { label: "Valor Devido", name: "valor" },
        // { label: "Valor Total", name: "valorTotal" },
        { label: "Prestação", name: "indice" },
        { label: "Observação", name: "observacao" },
        {
          label: "Status",
          name: "pago",
          options: {
            customBodyRender: (value) => {
              let color = "";
              let label = "";
              switch (value) {
                case true:
                  color = "#198754";
                  label = "Pago";
                  break;
                default:
                  color = "#dc3545";
                  label = "Não pago";
                  break;
              }
              return (
                <Chip
                  size="small"
                  label={label}
                  style={{ background: `${color}`, color: "white" }}
                />
              );
            },
          },
        },
        {
          label: " ",
          name: "funcoes",
          options: {
            filter: false,
            sort: false,
          },
        },
      ],
      objetos = this.state.contas,
      inserir = {
        nome: "Cadastrar Conta",
        link: "cadastrarConta",
      };
    return (
      <>
        <Paper style={{ padding: "16px", marginBottom: "16px" }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Data Inicial"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  value={dataInicial}
                  onChange={(date) =>
                    this.handleDateChange("dataInicial", date)
                  }
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Data Final"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  value={dataFinal}
                  onChange={(date) => this.handleDateChange("dataFinal", date)}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                style={{ marginLeft: "12px", width: "100%" }}
              >
                <Select
                  id="tipoBusca"
                  value={pago}
                  onChange={this.handlePagoChange}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={false}>Em aberto</MenuItem>
                  <MenuItem value={true}>Pagas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={this.searchAccount}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <TableComponent
          funcoes={{ deletar: this.removerConta }}
          titulo={titulo}
          inserir={inserir}
          colunas={colunas}
          objetos={objetos}
          {...this.props}
        />
        <SimpleDialog
          open={this.state.isOpenDialog}
          title="Deseja excluir a tabela?"
          content="Ao excluir a tabela a operação não poderá ser desfeita."
          closeButtonTitle="Cancelar"
          submitButtonTitle="Excluir"
          handleClose={() => this.setState({ isOpenDialog: false })}
          handleSubmit={this.removerConta}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ContasPagarListagem);
