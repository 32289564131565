import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  editarEspecialidade,
  buscarEspecialidade,
  cadastrarEspecialidade,
} from "../../../../api/dentistas";
import { toast } from "react-toastify";

class DentistasEspecialidade extends Component {
  state = {
    formulario: [{ type: "text", name: "titulo", label: "Especialidade" }],
    especialidade: "",
    cadastro: true,
    await: true,
    desabilitaBotao: false,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.especialidade_id) {
      var objEspecialidade = await buscarEspecialidade(
        clinica._id,
        this.props.match.params.especialidade_id,
      );
      this.setState({
        cadastro: false,
        especialidade: objEspecialidade,
        await: false,
      });
    } else {
      this.setState({ await: false });
    }
  }

  editaEspecialidade(objeto) {
    this.setState({ desabilitaBotao: true });
    editarEspecialidade(objeto)
      .then((r) => {
        if (r) {
          toast.success("Especidalide editada com sucesso");
          this.props.history.goBack();
        } else {
          toast.error("Erro ao editar especialidade");
        }
      })
      .then(() => this.setState({ desabilitaBotao: false }));
  }

  salvarEspecialidade(objeto) {
    const { clinica } = this.props;
    this.setState({ desabilitaBotao: true });
    cadastrarEspecialidade(clinica, objeto.titulo)
      .then((r) => {
        if (r) {
          toast.success("Especialidade salva com sucesso");
          this.props.history.goBack();
        } else {
          toast.error("Erro ao dicionar especialidade");
        }
      })
      .then(() => this.setState({ desabilitaBotao: false }));
  }

  render() {
    return (
      !this.state.await && (
        <FormComponent
          {...this.props}
          inputs={this.state.formulario}
          objeto={this.state.especialidade}
          disabledAction={this.state.desabilitaBotao}
          action={
            this.state.cadastro
              ? this.salvarEspecialidade.bind(this)
              : this.editaEspecialidade.bind(this)
          }
        />
      )
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DentistasEspecialidade);
