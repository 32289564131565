import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { gridStyles } from "../DashboardStyles";

import ViewNavBar from "../../bars/ViewNavBar";
// import MenuActions from './MenuActions';
import PatientForm from "../../forms/patient/PatientForm";
import DentalStatusForm from "../../forms/patient/DentalStatusForm";
import PeriogramRouter from "./PeriogramRouter";
// import PerioForm from '../../forms/patient/PerioForm';

import { getPatient } from "../../../actions/patientsCreation";
import FurcaForm from "../../forms/patient/FurcaForm";
import PlacasForm from "../../forms/patient/PlacasForm";
import MobilidadeForm from "../../forms/patient/MobilidadeForm";
import FichaAtm from "../pacientes/components/PacientesFichaATM.jsx";
import PacientesFichaAnamnese from "../pacientes/components/PacienteFichaAnamnese.jsx";
import MedicalRecordPatient from "./medical_record_patient";
import Odontogram from "../pacientes/components/Odontogram";
import EndodonticForm from "../pacientes/endodontic/EndodonticForm.jsx";
import EndodonticList from "../pacientes/endodontic/EndodonticList.jsx";
import EndodonticEditView from "../pacientes/endodontic/EndodonticEditView.jsx";

class PatientInfoRouter extends React.Component {
  constructor(props) {
    super(props);

    // this.onView = this.onView.bind(this);

    this.state = {
      selectedPatient: null,
      view: "info",
      form: PatientForm,
      LINKSPROFILE: [
        { text: "Cadastro", path: "", exact: true },
        { text: "Prontuario", path: "/prontuario" },
        { text: "Ficha Endodontica", path: "/endodontic" },
        { text: "Odontograma", path: "/odontograma" },
        { text: "Status da Arcada Dentária", path: "/dental_status" },
        { text: "Periograma", path: "/periograms" },
        { text: "Furca", path: "/furca" },
        { text: "Mobilidade", path: "/mobilidade" },
        { text: "Placas", path: "/placas" },
        { text: "Anamnese", path: "/anamnese" },
        { text: "ATM", path: "/atm" },
      ],
    };
  }

  componentDidUpdate() {
    if (this.state.selectedPatient === null && this.props.selectedPatient) {
      this.setState({ selectedPatient: this.props.selectedPatient });
    }
  }

  render() {
    const { match, history } = this.props;
    if (!this.state.selectedPatient) {
      this.props.getPatient(this.props.patientId, (patient) => {
        this.setState({
          selectedPatient: patient,
        });
      });
      return "Loading...";
    }

    return (
      <div className={css(gridStyles.flex)}>
        <ViewNavBar
          history={history}
          match={match}
          links={this.state.LINKSPROFILE}
        />

        <Route exact path={`${match.url}`} component={PatientForm} />
        <Route
          path={`${match.url}/prontuario`}
          component={MedicalRecordPatient}
        />
        <Route
          exact
          path={`${match.url}/endodontic`}
          render={(props) => (
            <EndodonticList {...props} patientId={this.props.patientId} />
          )}
        />
        <Route
          path={`${match.url}/endodontic/new`}
          render={(props) => (
            <EndodonticForm {...props} patientId={this.props.patientId} />
          )}
        />
        <Route
          path={`${match.url}/endodontic/:endodonticId/edit`}
          render={(props) => (
            <EndodonticEditView {...props} patientId={this.props.patientId} />
          )}
        />
        <Route
          path={`${match.url}/odontograma`}
          render={(props) => <Odontogram patientId={this.props.patientId} />}
        />
        {/* <Route exact path={`${match.url}`} render={(props) => <PatientForm {...props} patientId={this.props.patientId} />} /> */}
        <Route
          path={`${match.url}/dental_status`}
          component={DentalStatusForm}
        />
        <Route path={`${match.url}/periograms`} component={PeriogramRouter} />
        <Route path={`${match.url}/furca`} component={FurcaForm} />
        <Route path={`${match.url}/mobilidade`} component={MobilidadeForm} />
        <Route path={`${match.url}/placas`} component={PlacasForm} />
        <Route
          path={`${match.url}/anamnese`}
          component={PacientesFichaAnamnese}
        />
        <Route path={`${match.url}/atm`} component={FichaAtm} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
}
export default connect(mapStateToProps, { getPatient })(PatientInfoRouter);
