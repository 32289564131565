import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

export default function SimpleDialog({
  open,
  title,
  content,
  handleClose,
  handleSubmit,
  submitButtonTitle,
  closeButtonTitle,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={handleClose}>
          {closeButtonTitle}
        </Button>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {submitButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
