import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import FormUsuarioPlataforma from "./FormUsuarioPlataforma";
import UsuarioPlataformaLista from "./UsuarioPlataformaLista";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function UsuarioPlataformaRouter(props) {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/list`}
        render={() => <UsuarioPlataformaLista {...props} />}
      />
      <Route
        exact
        path={`${props.match.url}/`}
        render={() => <Redirect to={`${props.match.url}/list`} />}
      />
      <Route
        path={`${props.match.url}/cadastrar`}
        render={() => <FormUsuarioPlataforma />}
      />
    </Switch>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(UsuarioPlataformaRouter);
