import React, { Component } from "react";
import { connect } from "react-redux";
import {
  buscarCategoriaDentista,
  desvincularCategoria,
} from "../../../../api/dentistas";
import TableComponent from "../../../table/TableComponent";

class CategoriaConfigDentistaRelacao extends Component {
  state = {
    dentistas: [],
    idDentista: "",
  };

  removeCategoria(objeto) {
    desvincularCategoria(this.state.idDentista, objeto);
  }

  componentWillMount() {
    buscarCategoriaDentista(
      this.props.clinica._id,
      this.props.match.params.dentista_id,
    )
      .then((result) => {
        var dentista = [];
        result.categorias.length > 0 &&
          result.categorias.map((categoria, index) => {
            var novoCategoria = {};
            novoCategoria.titulo = categoria.titulo;
            novoCategoria._id = categoria._id;
            dentista.push(novoCategoria);
          });
        this.setState({
          dentistas: dentista,
          idDentista: this.props.match.params.dentista_id,
        });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Categoria",
      colunas = [{ label: "Titulo", name: "titulo" }],
      objetos = this.state.dentistas,
      inserir = {
        nome: "Vincular Categoria",
        link: `/dentistas/vincularCategoria/${this.props.match.params.dentista_id}`,
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.removeCategoria.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(CategoriaConfigDentistaRelacao);
