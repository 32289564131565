import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchBudgets } from "../../../actions/treatment";
import { fetchInstallments } from "../../../api/financeiro";
import { Chip } from "@material-ui/core";
import { montaParcelasPacientes } from "../../../helpers/parcelasPacientes";
import moment from "moment";
import TableComponent from "../../table/TableComponent";

const FinancialForm = ({ selectedPatient }) => {
  const [objetos, setObjetos] = useState([]);

  useEffect(() => {
    if (selectedPatient) {
      const fetchData = async () => {
        const startDate = new Date();
        let endDate;
        startDate.setDate(1);
        // endDate.setMonth(10);
        const result = await fetchInstallments({
          startDate,
          endDate: null,
          search: "all",
          patientId: selectedPatient._id,
        });
        setObjetos(result.data);
      };
      fetchData();
    }
  }, [selectedPatient]);

  const colunas = [
    {
      label: "Vencimento",
      name: "expiration",
      options: {
        customBodyRender: (value) => {
          return value ? moment(value).format("DD/MM/YYYY") : null;
        },
      },
    },
    { label: "Fatura", name: "number" },
    { label: "Orçamento", name: "budgetName" },
    {
      label: "Data Recebimento",
      name: "datePayment",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Valor Recebimento",
      name: "valuePayment",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let color = "";
          let label = "";
          switch (value) {
            case "paid":
              color = "#198754";
              label = "Pago";
              break;
            case "overdue":
              color = "#dc3545";
              label = "Vencido";
              break;
            default:
              color = "#0d6efd";
              label = "Aberto";
              break;
          }
          return (
            <Chip
              size="small"
              label={label}
              style={{ background: `${color}`, color: "white" }}
            />
          );
        },
      },
    },
  ];

  return <TableComponent colunas={colunas} objetos={objetos} />;
};

const mapStateToProps = (state) => {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
};

export default connect(mapStateToProps)(FinancialForm);
