import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const FETCH_DENTAL_FURCATION = "FETCH_DENTAL_FURCATION";
export const EDIT_DENTAL_FURCATION = "EDIT_DENTAL_FURCATION";
export const CLEAR_DENTAL_FURCATION = "CLEAR_DENTAL_FURCATION";

export function getFurcation(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/furca/buscar/${patientId}`);

      dispatch({ type: FETCH_DENTAL_FURCATION, payload: res.data });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro. Lesão de furca não encontrada.`,
          type: "error",
        },
      });
    }
  };
}

export function updateDentalFurcation(data, patientId, callback) {
  return async (dispatch) => {
    try {
      data.patient_id = patientId;

      const res = await axios.put(`/api/furca/editar/${patientId}`, data);

      dispatch({ type: EDIT_DENTAL_FURCATION, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Lesão de furca atualizada com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro. Lesão de furca não encontrada.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export function clearDentalStatus() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_DENTAL_FURCATION, payload: null });
  };
}
