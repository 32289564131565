import React from "react";
import TableComponent from "../../../table/TableComponent";
import moment from "moment";
import MUIDataTable from "mui-datatables";

export default function PacientesBuscaAvancadaLista({ listaPacientes }) {
  const pacientes = [];

  if (listaPacientes.length > 0) {
    listaPacientes.map((paciente) => {
      const novoPaciente = {};
      novoPaciente.id = paciente._id;
      novoPaciente.paciente = paciente.name;
      novoPaciente.registro = paciente.registry;
      novoPaciente.email = paciente.email || "";
      novoPaciente.nascimento =
        paciente.birthday !== undefined &&
        moment(paciente.birthday).format("DD/MM/YYYY");
      novoPaciente.telefone =
        paciente.telephones.length > 0
          ? paciente.telephones.filter(
              (telefone) => telefone.name === "Principal",
            )[0].value
            ? paciente.telephones.filter(
                (telefone) => telefone.name === "Principal",
              )[0].value
            : ""
          : "";
      pacientes.push(novoPaciente);
    });
  }

  const colunas = [
    { label: "Registro", name: "registro" },
    { label: "Paciente", name: "paciente" },
    { label: "Email", name: "email" },
    { label: "Telefone", name: "telefone" },
    { label: "Data Nascimento", name: "nascimento" },
  ];

  return (
    <div>
      <MUIDataTable columns={colunas} data={pacientes} />
      {/* <TableComponent colunas={colunas} objetos={pacientes} checkboxSelection/> */}
    </div>
  );
}
