import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

import ProteticosListagem from "./component/ProteticosListagem";
import ProteticosCadastro from "./component/ProteticosCadastro";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TableChart from "@material-ui/icons/TableChart";
import TabelaPrecoListagem from "./component/TabelaPrecoListagem";
import TabelaPreco from "./component/TabelaPreco";
import TabelaPrecoProteticosRelacao from "./component/TabelaPrecoProteticosRelacao";
import TabelaPrecoVinculaProteticos from "./component/TabelaPrecoVinculaProteticos";
import { People, Work } from "@material-ui/icons";
import ProteticosServicos from "./component/ProteticosServicos";
import FormProteticosServicos from "./component/FormProteticosServicos";
import ListaTabelaPrecoProtetico from "./component/ListaTabelaPrecoProtetico";
import FormServicoTabelaProtetico from "./component/FormTabelaPrecoProtetico";
import { Container } from "@material-ui/core";

class ProteticosRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "servicos", numero: 0 },
        { url: "listagem", numero: 1 },
        // { url: "listagemServicos", numero: 2 }
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/servicos`)}
            label="Serviços Solicitados"
            icon={<Work />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Protéticos"
            icon={<People />}
          />
          {/* <BottomNavigationAction onClick={() => this.props.history.push(`${match.url}/config`)} label="Tabela serviços" icon={<TableChart />} /> */}
        </BottomNavigation>
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={0}
          style={{ padding: "16px" }}
        >
          <Switch>
            <Route
              path={`${match.url}/config`}
              render={(props) => (
                <TabelaPrecoListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/servicos`}
              render={(props) => (
                <ProteticosServicos
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/listagem`}
              render={(props) => (
                <ProteticosListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/servicos/novo`}
              render={(props) => <FormProteticosServicos {...props} />}
            />
            <Route
              exact
              path={`${match.url}/servicos/:id`}
              render={(props) => <FormProteticosServicos {...props} />}
            />
            <Route
              exact
              path={`${match.url}/cadastrarProtetico/:protetico_id?`}
              render={(props) => <ProteticosCadastro {...props} />}
            />
            {/* <Route exact path={`${match.url}/cadastrarTabelaPreco/:tabelaPreco_id?`} render={props => <TabelaPreco {...props} />} /> */}
            <Route
              exact
              path={`${match.url}/tabela/:protetico_id`}
              render={(props) => <ListaTabelaPrecoProtetico {...props} />}
            />
            <Route
              exact
              path={`${match.url}/tabela/:protetico_id/item/novo`}
              render={(props) => <FormServicoTabelaProtetico {...props} />}
            />
            <Route
              exact
              path={`${match.url}/tabela/:protetico_id/item/:tabelapreco_id`}
              render={(props) => <FormServicoTabelaProtetico {...props} />}
            />
            {/* <Route exact path={`${match.url}/vincularTabelaPreco/:protetico_id?`} render={props => <TabelaPrecoVinculaProteticos {...props} />} /> */}
            <Route
              exact
              path={`${match.url}/`}
              render={() => <Redirect to={`${match.url}/servicos`} />}
            />
          </Switch>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectProtetico: state.patientsCreation.selectProtetico,
  };
}
export default connect(mapStateToProps)(ProteticosRouter);
