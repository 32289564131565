import React, { Component } from "react";
import { connect } from "react-redux";
import { deletarCategoria } from "../../../../api/dentistas";
import { buscarCategorias } from "../../../../api/dentistas";
import { editDentist } from "../../../../actions/dentistas/dentistSearch";
import TableComponent from "../../../table/TableComponent";
import { toast } from "react-toastify";
//Icone de arquivar
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

class DentistasConfigCategoria extends Component {
  state = {
    categorias: [],
  };

  async deletaCategoria(categoriaId) {
    const categoria = await deletarCategoria(categoriaId);
    if (categoria) {
      toast.success("Categoria excluída com sucesso");
      this.findCategoria();
      return;
    }
    toast.error("Erro ao excluir Categoria");
  }

  findCategoria() {
    buscarCategorias(this.props.clinica._id).then((categorias) => {
      var listaCategorias = [];
      categorias.length > 0 &&
        categorias.map((categoria, index) => {
          var novaCategoria = {};

          novaCategoria.titulo = categoria.titulo;
          novaCategoria.categoriaId = categoria._id;
          novaCategoria.funcoes = [
            <IconButton>
              <EditIcon
                key={index}
                onClick={() =>
                  this.props.history.push(
                    `/dentistas/config/cadastrarCategoria/${categoria._id}`,
                  )
                }
              />
            </IconButton>,
            <IconButton>
              <DeleteIcon
                key={`delete-${index}`}
                onClick={() => this.deletaCategoria(categoria._id)}
              />
            </IconButton>,
          ];

          listaCategorias.push(novaCategoria);
        });

      this.setState({ categorias: listaCategorias });
    });
  }
  componentWillMount() {
    this.findCategoria();
  }

  render() {
    const titulo = "Categorias",
      colunas = [
        { label: "Titulo", name: "titulo" },
        { label: "Id", name: "categoriaId" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.categorias,
      inserir = {
        nome: "Cadastrar Categoria",
        link: "cadastrarCategoria",
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaCategoria.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editDentist })(
  DentistasConfigCategoria,
);
