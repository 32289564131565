import axios from "axios";

export async function buscarClientes() {
  var clinicas = await axios
    .get("/api/listClinics")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return clinicas;
}

export async function getClient(clientId) {
  return axios.get(`/api/client/${clientId}`);
}

export async function buscarTodos(callback) {
  try {
    const res = await axios.get("/api/clientes/buscarTodos");
    callback(res.data);
  } catch (err) {
    console.log(err);
  }
}

export async function subscription(cliente, callback) {
  try {
    const res = await axios.post("/api/subscription", cliente);
    callback(res.data);
  } catch (err) {
    console.log(err);
  }
}

export async function buscarLaudoPadrao() {
  var laudo = await axios
    .get("/api/clinic/buscarLaudoPadrao")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return laudo;
}

export async function atualizarLaudoPadrao(laudo) {
  var laudo = await axios
    .post("/api/clinic/atualizarLaudoPadrao", {
      laudoPadrao: laudo,
    })
    .then((response) => response.status)
    .catch((error) => console.log(error));

  return laudo;
}

export async function criarRecebimento(recebimento) {
  var recebimento = await axios
    .post("/api/clinica/recebimento/criar", {
      objRecebimento: recebimento,
    })
    .then((response) => response.status)
    .catch((error) => console.log(error));

  return recebimento;
}

export async function listarRecebimentos(clinica) {
  var listaRecebimentos = await axios
    .get(`/api/clinica/recebimento/listar/${clinica}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return listaRecebimentos;
}
