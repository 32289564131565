import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import ListaParcelasResumo from "./ListaParcelasResumo";
import { getListaParcelas } from "../../../api/financeiro";

export default function FinanceiroPacienteResumo() {
  const [parcelas, setParcelas] = useState([]);
  const [pago, setPago] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const dataInicial = new Date();
      const dataFinal = new Date();
      dataInicial.setDate(1);
      dataFinal.setDate(30);
      const pago = false;
      const lista = await getListaParcelas({ dataInicial, dataFinal, pago });
      setParcelas(lista);
    };
    fetchData();
  }, []);

  const buscarParcelas = async (dataInicial, dataFinal, pago) => {
    const lista = await getListaParcelas({ dataInicial, dataFinal, pago });
    setPago(pago);
    setParcelas(lista);
  };

  return (
    <Paper style={{ padding: "16px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formulario buscarParcelas={buscarParcelas} />
        </Grid>
        {parcelas.length ? (
          parcelas.map((parcela) => (
            <Grid item xs={12}>
              <ListaParcelasResumo parcelas={parcela} pago={pago} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography> Nenhum valor encontrado </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

const dateNow = new Date();
const daysInMonth = new Date(
  dateNow.getFullYear(),
  dateNow.getMonth() + 1,
  0
).getDate();

const Formulario = ({ buscarParcelas }) => {
  const inicial = new Date();
  const final = new Date();
  inicial.setDate(1);
  final.setDate(daysInMonth);
  const [dataInicial, setDataInicial] = useState(inicial);
  const [dataFinal, setDataFinal] = useState(final);
  const [pago, setPago] = useState(false);

  const handleChange = () => {
    buscarParcelas(dataInicial, dataFinal, pago);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data Inicial"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={dataInicial}
            onChange={(e) => setDataInicial(e)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={3}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data Final"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            style={{ marginLeft: "12px" }}
            value={dataFinal}
            onChange={(e) => setDataFinal(e)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="outlined" style={{ marginLeft: "12px" }}>
          <Select
            id="tipoBusca"
            value={pago}
            onChange={(e) => setPago(e.target.value)}
          >
            <MenuItem value={false}>Em aberto</MenuItem>
            <MenuItem value={true}>Pagas</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleChange}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};
