export const ABRIR_MODAL = "ABRIR_MODAL";
export const ESCONDER_MODAL = "ESCONDER_MODAL";

export function abrirModalConfirmacao(funcao) {
  return async (dispatch) => {
    dispatch({ type: ABRIR_MODAL, payload: { funcaoExecutar: funcao } });
  };
}

export function esconderModalConfirmacao(funcao, executar) {
  return async (dispatch) => {
    dispatch({
      type: ESCONDER_MODAL,
      payload: { funcaoExecutar: funcao, executar: executar },
    });
    setTimeout(
      () =>
        dispatch({
          type: ESCONDER_MODAL,
          payload: { funcaoExecutar: "", executar: false },
        }),
      500,
    );
  };
}
