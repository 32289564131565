import axios from "axios";

export const buscarContasFinanceiras = async (callback) => {
  try {
    const result = await axios.get("/api/financeiro/contas");
    callback(result.data);
  } catch (err) {
    console.log(err);
  }
};

export const criarContaFinanceira = async (conta, callback) => {
  try {
    console.log(conta);
    const result = await axios.post("/api/financeiro/contas", conta);
    callback(result.data);
  } catch (err) {
    callback(err.response.data);
  }
};
