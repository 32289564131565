import React from "react";
import { Link } from "react-router-dom";

import { css } from "aphrodite/no-important";
import { styles } from "./ModelsListStyles";
import { Button } from "@material-ui/core";

const renderItems = (models, match, openModal, message) => {
  if (models.length > 0)
    return models.map((model) => {
      return (
        <li key={model._id} id={model._id} className={css(styles.listItem)}>
          <div className={css(styles.boxName)}>{model.name}</div>
          <div className={css(styles.boxEditExclude)}>
            <Button
              color="primary"
              size="small"
              component={Link}
              to={`${match.url}/${message}/${model.type ? model.type : "anamnesis"}/${model._id}`}
            >
              Editar
            </Button>
            <Button
              color="secondary"
              size="small"
              onClick={(event) => openModal(model._id, message)}
            >
              Excluir
            </Button>
          </div>
          <div style={{ clear: "both" }}></div>
        </li>
      );
    });
  else return <li className={css(styles.noItems)}>Nenhum modelo cadastrado</li>;
};

// 'ModelsList' will manage the routes inside the app
function ModelsList(props) {
  const { match, models, onClick, message } = props;
  return (
    <ul className={css(styles.list)}>
      {renderItems(models, match, onClick, message)}
    </ul>
  );
}

export default ModelsList;
