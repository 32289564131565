import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import InputMask from "react-input-mask";

import { css } from "aphrodite/no-important";
import { styles } from "../forms/InputFieldStyles";

import Button from "../common/Button";

import { buscarSituacoes } from "../../api/pacientes";

class SituacaoLista extends Component {
  constructor(props) {
    super(props);

    this.addSituacao = this.addSituacao.bind(this);
    this.removeSituacao = this.removeSituacao.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      situacoes: [{ name: "", value: "" }],
      listaSituacoes: "",
      valueSelect: "",
    };
  }

  async componentWillMount() {
    var objSituacoes = await buscarSituacoes(this.props.clinica._id);
    this.setState({ listaSituacoes: objSituacoes });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ situacoes: [...nextProps.situacoes] });
  }

  handleChange(event) {
    this.setState({ valueSelect: event.target.value });
  }

  addSituacao = () => {
    const { getSituacoes } = this.props;
    var { valueSelect } = this.state;

    if (valueSelect !== "") {
      var existe = this.state.situacoes.filter(function (situacaoAdd) {
        return situacaoAdd.value === valueSelect;
      })[0];
      if (!existe) {
        var select = this.state.listaSituacoes.filter(function (situacao) {
          return situacao._id === valueSelect;
        })[0];

        this.setState({
          situacoes: this.state.situacoes.concat([
            { name: select.titulo, value: select._id },
          ]),
        });

        getSituacoes(
          this.state.situacoes.concat([
            { name: select.titulo, value: select._id },
          ]),
        );
      }
    }
  };

  removeSituacao = (idx) => () => {
    const { getSituacoes } = this.props;
    this.setState({
      situacoes: this.state.situacoes.filter((s, sidx) => idx !== sidx),
    });

    getSituacoes(this.state.situacoes.filter((s, sidx) => idx !== sidx));
  };

  render() {
    return (
      <div>
        {this.state.listaSituacoes && (
          <select
            className={css(styles.inputSelect)}
            defaultValue={this.state.valueSelect}
            onChange={this.handleChange}
          >
            <option value="">Selecione ---</option>
            {this.state.listaSituacoes.map((situacao, index) => (
              <option value={situacao._id} key={index}>
                {situacao.titulo}
              </option>
            ))}
          </select>
        )}
        <Button text="Adicionar" color="primary" onClick={this.addSituacao} />
        {this.state.situacoes.map((situacao, idx) => (
          <div key={idx} className={css(styles.row_2)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.fieldset)}>{situacao.name}</label>
            </fieldset>

            <fieldset className={css(styles.fieldset)}>
              <span
                className={css(styles.link, styles.red)}
                onClick={this.removeSituacao(idx)}
              >
                Remover
              </span>
            </fieldset>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}

export default connect(mapStateToProps, {})(SituacaoLista);
