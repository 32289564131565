import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export const Notificacao2 = ({ alerta, onClose }) => {
  const { tipo, msg, abrir } = alerta;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={abrir}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert variant="filled" onClose={handleClose} severity={tipo}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
