import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import InputMask from "react-input-mask";

import { css } from "aphrodite/no-important";
import { styles } from "../../forms/InputFieldStyles";
import Axios from "axios";

class TelephoneList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    zip: this.props.zip || "",
    address: this.props.address || "",
    city: this.props.city || "",
  };

  handleChange = (name) => (event) => {
    if (name === "zip") {
      var zip = event.target.value
        .replace("_", "")
        .replace(".", "")
        .replace("-", "");
      if (zip.length === 8) {
        Axios.get(`https://api.postmon.com.br/v1/cep/${zip}`)
          .then((r) => {
            this.props.getAddress({
              zip: zip,
              address: r.data.logradouro,
              city: r.data.cidade,
            });
            this.setState({
              address: r.data.logradouro,
              city: r.data.cidade,
            });
          })
          .catch((err) => console.log(err));
      }
    }

    this.setState({ [name]: event.target.value });
  };

  render() {
    if (!this.state.zip && this.props.zip) {
      this.setState({
        zip: this.props.zip,
        city: this.props.city,
        address: this.props.address,
      });
    }
    return (
      <div>
        <div className={css(styles.section)}>
          <div className={css(styles.row_3)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.label)}>CEP</label>
              <InputMask
                className={css(styles.input)}
                onChange={this.handleChange("zip")}
                type={"text"}
                value={this.state.zip}
                placeholder={"CEP"}
                mask={"99.999-999"}
              />
            </fieldset>
          </div>
          <div className={css(styles.row_1)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.label)}>Endereço</label>
              <InputMask
                className={css(styles.input)}
                onChange={this.handleChange("address")}
                type={"text"}
                value={this.state.address}
                placeholder={"Endereço..."}
              />
            </fieldset>
          </div>
          <div className={css(styles.row_3)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.label)}>Cidade</label>
              <InputMask
                className={css(styles.input)}
                onChange={this.handleChange("city")}
                type={"text"}
                value={this.state.city}
                placeholder={"Cidade..."}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

const telephoneList = reduxForm({
  form: "telephoneList",
})(TelephoneList);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(telephoneList);
