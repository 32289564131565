'use strict'

exports.__esModule = true

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i]
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

var _propTypes = require('prop-types')

var _propTypes2 = _interopRequireDefault(_propTypes)

var _react = require('react')

var _react2 = _interopRequireDefault(_react)

var _reactDnd = require('react-dnd')

var _reactDndHtml5Backend = require('react-dnd-html5-backend')

var _compose = require('./compose')

var _compose2 = _interopRequireDefault(_compose)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    )
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    )
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  })
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass)
}

var ResizableEvent = (function (_React$Component) {
  _inherits(ResizableEvent, _React$Component)

  function ResizableEvent() {
    _classCallCheck(this, ResizableEvent)

    return _possibleConstructorReturn(
      this,
      _React$Component.apply(this, arguments)
    )
  }

  ResizableEvent.prototype.componentDidMount = function componentDidMount() {
    this.props.connectTopDragPreview(
      (0, _reactDndHtml5Backend.getEmptyImage)(),
      {
        captureDraggingState: true,
      }
    )
    this.props.connectBottomDragPreview(
      (0, _reactDndHtml5Backend.getEmptyImage)(),
      {
        captureDraggingState: true,
      }
    )
    this.props.connectLeftDragPreview(
      (0, _reactDndHtml5Backend.getEmptyImage)(),
      {
        captureDraggingState: true,
      }
    )
    this.props.connectRightDragPreview(
      (0, _reactDndHtml5Backend.getEmptyImage)(),
      {
        captureDraggingState: true,
      }
    )
  }

  ResizableEvent.prototype.render = function render() {
    var _props = this.props,
      title = _props.title,
      event = _props.event,
      connectTopDragSource = _props.connectTopDragSource,
      connectBottomDragSource = _props.connectBottomDragSource,
      connectLeftDragSource = _props.connectLeftDragSource,
      connectRightDragSource = _props.connectRightDragSource

    var _map = [connectTopDragSource, connectBottomDragSource].map(
        function (connectDragSource) {
          return connectDragSource(
            _react2.default.createElement(
              'div',
              { className: 'rbc-addons-dnd-resize-anchor' },
              _react2.default.createElement('div', {
                className: 'rbc-addons-dnd-resize-icon',
              })
            )
          )
        }
      ),
      Top = _map[0],
      Bottom = _map[1]

    var _map2 = [connectLeftDragSource, connectRightDragSource].map(
        function (connectDragSource) {
          return connectDragSource(
            _react2.default.createElement(
              'div',
              { className: 'rbc-addons-dnd-resize-month-event-anchor' },
              ' '
            )
          )
        }
      ),
      Left = _map2[0],
      Right = _map2[1]

    var patient = _react2.default.createElement(
      'div',
      { className: 'rbc-addons-dnd-resizable-event' },
      'Paciente: ' + _props.event.patient
    )

    // var user = _react2.default.createElement(
    //   'div',
    //   { className: 'rbc-addons-dnd-resizable-event' },
    //   'Dentista: ' + _props.event.user
    // )
    var desc = _react2.default.createElement(
      'div',
      { className: 'rbc-addons-dnd-resizable-event' },
      'Descrição: ' + _props.event.desc
    )

    return event.allDay || this.props.isAllDay
      ? _react2.default.createElement(
          'div',
          { className: 'rbc-addons-dnd-resizable-month-event' },
          Left,
          title,
          Right
        )
      : _react2.default.createElement(
          'div',
          { className: 'rbc-addons-dnd-resizable-event' },
          Top,
          title,
          patient,
          desc,
          Bottom
        )
  }

  return ResizableEvent
})(_react2.default.Component)

ResizableEvent.propTypes = {
  connectBottomDragPreview: _propTypes2.default.func,
  connectBottomDragSource: _propTypes2.default.func,
  connectLeftDragPreview: _propTypes2.default.func,
  connectLeftDragSource: _propTypes2.default.func,
  connectRightDragPreview: _propTypes2.default.func,
  connectRightDragSource: _propTypes2.default.func,
  connectTopDragPreview: _propTypes2.default.func,
  connectTopDragSource: _propTypes2.default.func,
  event: _propTypes2.default.object,
  title: _propTypes2.default.string,
  isAllDay: _propTypes2.default.bool,
}

var eventSourceTop = {
  beginDrag: function beginDrag(_ref) {
    var event = _ref.event
    return _extends({}, event, { type: 'resizeTop' })
  },
}

var eventSourceBottom = {
  beginDrag: function beginDrag(_ref2) {
    var event = _ref2.event
    return _extends({}, event, { type: 'resizeBottom' })
  },
}

var eventSourceLeft = {
  beginDrag: function beginDrag(_ref3) {
    var event = _ref3.event
    return _extends({}, event, { type: 'resizeLeft' })
  },
}

var eventSourceRight = {
  beginDrag: function beginDrag(_ref4) {
    var event = _ref4.event
    return _extends({}, event, { type: 'resizeRight' })
  },
}

exports.default = (0, _compose2.default)(
  (0, _reactDnd.DragSource)('resize', eventSourceTop, function (connect) {
    return {
      connectTopDragPreview: connect.dragPreview(),
      connectTopDragSource: connect.dragSource(),
    }
  }),
  (0, _reactDnd.DragSource)('resize', eventSourceBottom, function (connect) {
    return {
      connectBottomDragSource: connect.dragSource(),
      connectBottomDragPreview: connect.dragPreview(),
    }
  }),
  (0, _reactDnd.DragSource)('resize', eventSourceLeft, function (connect) {
    return {
      connectLeftDragSource: connect.dragSource(),
      connectLeftDragPreview: connect.dragPreview(),
    }
  }),
  (0, _reactDnd.DragSource)('resize', eventSourceRight, function (connect) {
    return {
      connectRightDragSource: connect.dragSource(),
      connectRightDragPreview: connect.dragPreview(),
    }
  })
)(ResizableEvent)
module.exports = exports['default']
