import axios from "axios";

import { SHOW_MESSAGE } from "../actions/systemMsg";
import { buscarPacientes } from "../api/pacientes";

export const CREATE_PATIENT = "CREATE_PATIENT";
export const CREATE_PATIENT_ERROR = "CREATE_PATIENT_ERROR";
export const GET_PATIENT = "GET_PATIENT";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const LIST_PATIENTS = "LIST_PATIENTS";

export function createPatient(
  { name, lastName, telephone, clinicId },
  callback,
) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/patient", {
        name,
        lastName,
        clinic_id: clinicId,
        telephones: [{ name: "Principal", value: telephone }],
      });

      if (typeof res.data !== "string") {
        const list = await buscarPacientes(clinicId);

        dispatch({ type: LIST_PATIENTS, payload: list });
        dispatch({ type: CREATE_PATIENT, payload: res.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            message: `Paciente salvo com sucesso (${res.data.name})`,
            type: "success",
          },
        });

        callback(res.data);
      } else {
        callback(res.data);
        dispatch({ type: CREATE_PATIENT_ERROR, payload: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function getAllPatients(clinic_id) {
  return async (dispatch) => {
    try {
      const res = await buscarPacientes(clinic_id);
      dispatch({ type: LIST_PATIENTS, payload: res });
    } catch (err) {
      console.log(err);
    }
  };
}

export function createPatientFull(data, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/patient", data);
      dispatch({ type: CREATE_PATIENT, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Paciente salvo com sucesso (${res.data.name})`,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro na criação do Lista. Alguma informação está faltando.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export function updatePatient(data, patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/patient/${patientId}`, data);

      dispatch({ type: GET_PATIENT, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: ` As informações de ${res.data.name} foram atualizadas`,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: err.response.data, type: "error" },
      });
    }
  };
}

export function getPatient(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/patient/${patientId}`);
      dispatch({ type: GET_PATIENT, payload: res.data });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Paciente não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export function passPatient(patient) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PATIENT, payload: patient });
    } catch (err) {
      console.log(err);
    }
  };
}

export function clearPatient() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PATIENT, payload: null });
  };
}
