import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

import ContasPagarCadastro from "./components/ContasPagarCadastro";
import ContasPagarListagem from "./components/ContasPagarListagem";
import DescricaoListagem from "./components/DescricaoListagem";
import DescricaoCadastro from "./components/DescricaoCadastro";
import ContasPagasListagem from "./components/ContasPagasListagem";
import ContasPagasCadastro from "./components/ContasPagasCadastro";

class ContasPagarRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "listagem", numero: 0 },
        { url: "configuracoes", numero: 1 },
        { url: "listContasPagas", numero: 2 },
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Contas A Pagar"
            icon={<AccountBalanceWalletIcon />}
          />
          <BottomNavigationAction
            onClick={() =>
              this.props.history.push(`${match.url}/listContasPagas`)
            }
            label="Contas Pagas"
            icon={<AccountBalanceWalletIcon />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/config`)}
            label="Tipos de Conta"
            icon={<ReceiptIcon style={{ color: "" }} />}
          />
        </BottomNavigation>
        <Switch>
          <Route
            path={`${match.url}/config`}
            render={(props) => (
              <DescricaoListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/listagem`}
            render={(props) => (
              <ContasPagarListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/listContasPagas`}
            render={(props) => (
              <ContasPagasListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/cadastrarConta/:conta_id?`}
            render={(props) => <ContasPagarCadastro {...props} />}
          />
          <Route
            exact
            path={`${match.url}/cadastrarDescricao/:descricao_id?`}
            render={(props) => <DescricaoCadastro {...props} />}
          />
          <Route
            exact
            path={`${match.url}/cadastrarContasPagas/:contaPaga_id?`}
            render={(props) => <ContasPagasCadastro {...props} />}
          />
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/listagem`} />}
          />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectDentist: state.patientsCreation.selectDentist,
  };
}
export default connect(mapStateToProps)(ContasPagarRouter);
