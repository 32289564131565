import TableComponent from "../../../table/TableComponent.js";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getAgendamento } from "../../../../api/pacientes";
import { LIBRARY } from "../../../_constants/library.js";

export function PacientAgendamentos({ patientId }) {
  const [listAgendamento, setListAgendamentos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getAgendamentos = async () => {
      try {
        const { data } = await getAgendamento(patientId);
        setListAgendamentos(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getAgendamentos();
  }, [patientId]);

  const colunas = [
    {
      label: "Data",
      name: "start",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Horário",
      name: "end",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("HH:mm");
        },
      },
    },
    {
      label: "Dentista",
      name: "id_user.name",
    },
    {
      label: "Atividade",
      name: "title",
    },
    {
      label: "Situação do agendamento",
      name: "status",
      options: {
        customBodyRender: (value) => {
          return LIBRARY[value] || value; // Traduz a situação, se disponível
        },
      },
    },
  ];

  return (
    <TableComponent
      colunas={colunas}
      objetos={listAgendamento}
      selecionarLinhas="none"
    />
  );
}
