import React, { memo } from "react";
import {
  IconButton,
  ThemeProvider,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";
import { Check, Delete, Edit } from "@material-ui/icons";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import moment from "moment";
import TableComponent from "../../../table/TableComponent";

const ListProceduresBudget = memo((props) => {
  const { procedures, conclusionProcedure, editProcedure, removeProcedure } =
    props;

  const rows = procedures.map((procedure) => {
    return {
      description: procedure.procedure.description,
      status: !!procedure.concludedDate,
      tooth: procedure.specification?.tooth.map(
        (tooth) => `(${tooth.dente}: ${tooth.faces})` ?? "",
      ),
      value: procedure.specification?.value
        ? formataMoedaReal(procedure.specification.value)
        : 0,
      dentist: procedure.dentist?.name ?? "",
      concluded: procedure.concludedDate ? "Concluído" : "Pendente",
      concludedDate: procedure.concludedDate
        ? moment(procedure.concludedDate).format("DD/MM/YYYY")
        : "",
      functions: {
        procedureId: procedure._id,
        concluded: !!procedure.concludedDate,
      },
    };
  });

  const columns = [
    { label: "Serviço", name: "description" },
    {
      label: "Status",
      name: "concluded",
    },
    {
      label: "Dentes",
      name: "tooth",
    },
    {
      label: "Valor",
      name: "value",
    },
    {
      label: "Dentista",
      name: "dentist",
    },
    {
      label: "Data conclusão",
      name: "concludedDate",
    },
    {
      label: " ",
      name: "functions",
      options: {
        customBodyRender: ({ procedureId, concluded }) =>
          !concluded && (
            <>
              <Tooltip title="Concluir serviço">
                <IconButton onClick={() => conclusionProcedure(procedureId)}>
                  <Check fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar serviço">
                <IconButton onClick={() => editProcedure(procedureId)}>
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remover serviço">
                <IconButton onClick={() => removeProcedure(procedureId)}>
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          ),
      },
    },
  ];

  const myTheme = createMuiTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          display: "none",
        },
      },
    },
  });

  const options = {
    elevation: 1,
    sort: false,
    selectableRows: "none",
    // customFooter: () => { },
  };

  return (
    <ThemeProvider theme={myTheme}>
      <TableComponent colunas={columns} objetos={rows} otherOptions={options} />
    </ThemeProvider>
  );
});

export default ListProceduresBudget;
