import React, { Component } from "react";
import { connect } from "react-redux";
import { listarTabelas, deletarTabela } from "../../../../api/servicos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";

class ServicosConfigServico extends Component {
  state = {
    tabelas: [],
  };

  deletaTabela(objeto) {
    const { clinica } = this.props;
    deletarTabela(objeto, clinica._id);
  }

  componentWillMount() {
    listarTabelas(this.props.clinica._id)
      .then((arrTabelas) => {
        var listaTabelas = [];

        arrTabelas.length > 0 &&
          arrTabelas.map((tabela, index) => {
            var novaTabela = {};

            novaTabela.titulo = tabela.titulo;
            novaTabela.indice = tabela.indice;
            novaTabela.ativo = tabela.ativo ? "Ativo" : "Inativo";
            novaTabela._id = tabela._id;
            novaTabela.funcoes = [
              <Tooltip title={"Editar Tabela"} key={index}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(
                      `/servicos/config/cadastrarTabela/${tabela._id}`,
                    )
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>,
              <Tooltip title={"Lista Serviços"} key={index}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(
                      `/servicos/config/tabelaRelacao/${tabela._id}`,
                    )
                  }
                >
                  <Pageview />
                </IconButton>
              </Tooltip>,
            ];

            listaTabelas.push(novaTabela);
          });

        this.setState({ tabelas: listaTabelas });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Servico",
      colunas = [
        { label: "Titulo", name: "titulo" },
        { label: "Indice", name: "indice" },
        { label: "Ativo", name: "ativo" },
        { label: "", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.tabelas,
      inserir = {
        nome: "Cadastrar Tabela",
        link: "cadastrarTabela",
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaTabela.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ServicosConfigServico);
