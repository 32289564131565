import React from "react";
import { Route, Switch } from "react-router-dom";
import FinanceiroPacientesRouter from "./FinanceiroPacientesRouter";
import ListDentistComission from "./list_dentist_comission";
import CashFlow from "./cashFlow/CashFlow.jsx";

const FinanceiroRouter = ({ match }) => {
  const menu = [
    {
      url: "/fluxoCaixa",
      label: "Fluxo de Caixa",
      componente: CashFlow,
    },
    {
      url: "/pacientes",
      label: "Pacientes",
      componente: FinanceiroPacientesRouter,
    },
    {
      url: "/dentistas",
      label: "Dentistas",
      componente: ListDentistComission,
    },
  ];

  return (
    <>
      <Switch>
        {menu.map((item, idx) => (
          <Route
            key={idx}
            path={match.url + item.url}
            component={item.componente}
          />
        ))}
        ;
      </Switch>
    </>
  );
};

export default FinanceiroRouter;
