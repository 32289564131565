import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ClientsRouter from "./clients/ClientsRouter";
import UsuarioPlataformaRouter from "../usuarios/UsuarioPlataformaRouter";
import { Container, Paper } from "@material-ui/core";

export const AdminRouter = (props) => {
  const { match } = props;
  return (
    <Container style={{ padding: "16px" }}>
      <Switch>
        <Route path={match.url + "/clientes"} component={ClientsRouter} />
        <Route
          exact
          path={match.url + "/"}
          render={() => <Redirect to={match.url + "/clientes"} />}
        />
        <Route
          path={match.url + "/users"}
          component={UsuarioPlataformaRouter}
        />
      </Switch>
    </Container>
  );
};
