import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import SendEmailDialog from "./SendEmailDialog";
import { fetchModelByType, getMention } from "../../actions/model";

const DialogEmail = ({
  list,
  open,
  onClose,
  typeMentions,
  fetchModelByType,
  getMention,
}) => {
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [emailModels, setEmailModels] = useState([]);
  const [modelEmailSelected, setModelEmailSelected] = useState({});
  const [listEmail, setListEmail] = useState([]);
  const [mentions, setMentions] = useState([]);

  if (list.length >= 1) {
    const newList = list.filter((item) => item.email && item.email !== "");
    newList.length >= 1 ? (open = true) : (open = false);
  }

  useEffect(() => {
    fetchModelByType("email", (model) => {
      setEmailModels(model);
    });

    getMention((res) =>
      setMentions(res.filter((item) => [...typeMentions].includes(item.url))),
    );
  }, []);

  // remove todos que não possuem possuem e-mail
  const filterListEmails = (callback) => {
    const newList = list.filter((item) => item.email !== "");
    setListEmail(newList);
    return callback();
  };

  return (
    <>
      <Dialog open={!open ? true : false}>
        <DialogContent>
          <DialogContentText>
            Não foi possível encontrar um e-mail de destinatário.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => onClose()}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth={"xs"} open={open}>
        <DialogTitle>Modelos de e-mail</DialogTitle>
        <DialogContent>
          {emailModels.length > 0 ? (
            <>
              Selecione um modelo abaixo:
              <List>
                {emailModels.map((item, index) => (
                  <ListItem
                    key={`model_${index}`}
                    button
                    onClick={() => {
                      filterListEmails(() => {
                        setModelEmailSelected(item);
                        setOpenSendEmailDialog(true);
                      });
                    }}
                  >
                    - {item.name}
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <p>Nenhum modelo de e-mail cadastrado.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose()}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {openSendEmailDialog && (
        <SendEmailDialog
          onClose={onClose}
          modelEmail={modelEmailSelected}
          listEmail={listEmail}
          menu={mentions}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { fetchModelByType, getMention })(
  DialogEmail,
);
