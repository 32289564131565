import React, { Component } from "react";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { styles } from "../forms/InputFieldStyles";

import Button from "../common/Button";

class ObservacaoLista extends Component {
  constructor(props) {
    super(props);

    this.addObservacao = this.addObservacao.bind(this);
    this.removeObservacao = this.removeObservacao.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      observacoes: [{ value: "" }],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ observacoes: [...nextProps.observacoes] });
  }

  handleChange(event) {
    this.setState({ valueSelect: event.target.value });
  }

  addObservacao = () => {
    const { getObservacoes } = this.props;
    var { valueSelect } = this.state;

    if (valueSelect !== "") {
      this.setState({
        observacoes: this.state.observacoes.concat([{ value: valueSelect }]),
        valueSelect: "",
      });

      getObservacoes(this.state.observacoes.concat([{ value: valueSelect }]));
    }
  };

  removeObservacao = (idx) => () => {
    const { getObservacoes } = this.props;
    this.setState({
      observacoes: this.state.observacoes.filter((s, sidx) => idx !== sidx),
    });

    getObservacoes(this.state.observacoes.filter((s, sidx) => idx !== sidx));
  };

  render() {
    console.log(this.state.valueSelect);
    return (
      <div>
        <textarea
          style={{ resize: "none", width: "100%", marginBottom: "15px" }}
          placeholder="Adicione aqui sua observação..."
          rows="6"
          cols="10"
          onChange={this.handleChange}
          value={this.state.valueSelect}
        ></textarea>

        <Button text="Adicionar" color="primary" onClick={this.addObservacao} />
        {this.state.observacoes.map((observacao, idx) => (
          <div key={idx} className={css(styles.row_2)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.fieldset)}>{observacao.value}</label>
            </fieldset>

            <fieldset className={css(styles.fieldset)}>
              <span
                className={css(styles.link, styles.red)}
                onClick={this.removeObservacao(idx)}
              >
                Remover
              </span>
            </fieldset>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}

export default connect(mapStateToProps, {})(ObservacaoLista);
