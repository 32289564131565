import React, { Component } from "react";
import { connect } from "react-redux";

// import { fetch } from '../../actions/component';

import { css } from "aphrodite/no-important";
import { styles } from "./InputAutoCompleteStyles";

class AutoCompleteByProps extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.resultDiv = this.resultDiv.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);

    this.state = {
      value: "",
      results: [],
      fullResults: [],
      inside: false,
      onFocus: false,
      selected: -1,
    };
  }

  componentWillMount() {
    const { results, value } = this.props;
    this.setState({
      results: results,
      fullResults: results,
      value: value,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    });
  }

  onChange(e) {
    const { onSelect } = this.props;

    onSelect({ id: null, value: e.currentTarget.value });

    this.setState({
      value: e.currentTarget.value,
    });

    this.state.results = this.state.fullResults;
    var resAux = [];
    this.state.fullResults.filter((item) => {
      if (item.name.includes(e.currentTarget.value)) {
        resAux.push(item);
      }
    });

    this.setState({
      results: resAux,
    });
  }

  onSelect({ id, value }) {
    const { onSelect } = this.props;

    this.setState({
      selected: -1,
      value: value,
      results: [],
    });
    onSelect({ id, value });
  }

  onBlur() {
    const noResult = () => {
      this.setState({
        selected: -1,
        results: [],
      });
    };
    var time = setTimeout(noResult, 250, this);
  }

  onFocus() {
    const { results } = this.props;
    this.setState({
      onFocus: true,
      results: results,
      fullResults: results,
    });
  }
  keyDown(inputEvent) {
    console.log(inputEvent.keyCode);
    if (inputEvent.keyCode === 40) {
      if (
        this.state.selected < this.state.results.length - 1 &&
        (this.props.limit ? this.state.selected < this.props.limit : true)
      ) {
        inputEvent.preventDefault();
        this.setState((prevState) => {
          return { selected: prevState.selected + 1 };
        });
      }
    } else if (inputEvent.keyCode === 38) {
      if (this.state.selected > 0) {
        inputEvent.preventDefault();
        this.setState((prevState) => {
          return { selected: prevState.selected - 1 };
        });
      }
    } else if (
      inputEvent.keyCode === 13 &&
      this.state.results[this.state.selected]
    ) {
      const { _id, name } = this.state.results[this.state.selected];
      this.onSelect({ id: _id, value: name });
    }
  }

  resultDiv() {
    const { limit } = this.props;

    const renderItems = () => {
      return this.state.results
        .slice(0, limit ? limit : this.state.results.length)
        .map((item, index) => {
          return (
            <li
              className={css(
                styles.suggestion,
                this.state.selected === index
                  ? styles.suggestionSelected
                  : null,
              )}
              key={item._id}
              id={item._id}
            >
              <div
                onClick={(event) => {
                  this.onSelect({
                    id: event.target.parentElement.id,
                    value: event.target.textContent,
                  });
                }}
              >
                {this.setBold(item.name)}
              </div>
            </li>
          );
        });
    };

    if (this.state.onFocus && this.state.results.length > 0) {
      return (
        <div className={css(styles.suggestionsContainer)}>
          <ul className={css(styles.suggestionsList)}>{renderItems()}</ul>
        </div>
      );
    } else {
      return null;
    }
  }

  setBold(text) {
    var match = text
      .toString()
      .split(
        new RegExp(
          "(" +
            this.state.value.replace(/[`~,.<>;':"/[\]|{}()=_+]/g, "\\$&") +
            ")",
          "gi",
        ),
      );
    for (var k = 1; k < match.length; k += 2)
      match[k] = <b key={"b_" + k}>{match[k]}</b>;
    return match;
  }

  render() {
    const { placeholder, label } = this.props;

    return (
      <fieldset className={css(styles.fieldset)}>
        <label className={css(styles.label)}>{label}</label>
        <input
          onChange={this.onChange}
          placeholder={placeholder ? placeholder : ""}
          value={this.state.value}
          className={css(styles.input)}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onKeyDown={(e) => this.keyDown(e)}
          autoComplete="nope"
        ></input>
        {this.resultDiv()}
      </fieldset>
    );
  }
}

export default connect(() => {
  return {};
}, {})(AutoCompleteByProps);
