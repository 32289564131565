import React, { Component } from "react";
import { connect } from "react-redux";
import { editDentist } from "../../../../actions/dentistas/dentistSearch";
import TableComponent from "../../../table/TableComponent";
import Edit from "@material-ui/icons/Edit";
import {
  deletarDescricao,
  buscarDescricoes,
} from "../../../../api/contasPagar";

class DescricaoListagem extends Component {
  state = {
    descricoes: [],
  };

  deletaDescricao(objeto) {
    const { clinica } = this.props;
    deletarDescricao(objeto, clinica._id);
  }

  componentWillMount() {
    buscarDescricoes(this.props.clinica._id).then((descricoes) => {
      var listaDescricao = [];
      descricoes.length > 0 &&
        descricoes.map((descricao, index) => {
          var novo = {};
          novo.codigo = descricao.codigo;
          novo.descricaoId = descricao._id;
          novo.descricao = descricao.descricao;
          novo.funcoes = [
            <Edit
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/contas/cadastrarDescricao/${descricao._id}`,
                )
              }
            />,
          ];
          listaDescricao.push(novo);
        });
      this.setState({ descricoes: listaDescricao });
    });
  }
  render() {
    const titulo = "Descrições das Contas",
      colunas = [
        { label: "Código", name: "codigo" },
        { label: "Descrição", name: "descricao" },
        { label: "", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.descricoes,
      inserir = {
        nome: "Cadastrar Descricao",
        link: "cadastrarDescricao",
      };
    return (
      <TableComponent
        funcoes={{ deletar: this.deletaDescricao.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editDentist })(DescricaoListagem);
