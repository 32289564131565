import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { fetchPatients } from "../../../../actions/patientsSearch";
import { fetchBudgets } from "../../../../actions/treatment";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import TableComponent from "../../../table/TableComponent";
import { montaParcelasPacientes } from "../../../../helpers/parcelasPacientes";
import { Chip, IconButton } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";

const RecebimentosPacienteCadastro = ({ fetchPatients, fetchBudgets }) => {
  const [pacientes, setPacientes] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState();
  const [objetos, setObjetos] = useState([]);

  const colunas = [
    { label: "Fatura", name: "fatura" },
    { label: "Tratamento", name: "tratamento" },
    { label: "Orçamento", name: "orcamento" },
    { label: "Data Vencimento", name: "vencimento" },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let color = "";
          switch (value) {
            case "Pago":
              color = "#198754";
              break;
            case "Vencido":
              color = "#dc3545";
              break;
            default:
              color = "#0d6efd";
              break;
          }
          return (
            <Chip
              size="small"
              label={value}
              style={{ background: `${color}`, color: "white" }}
            />
          );
        },
      },
    },
    { label: "Data Pagamento", name: "pagamento" },
    { label: "Valor", name: "valor" },
    {
      label: "Receber Fatura",
      name: "receber",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton>
              <AttachMoney style={{ color: "#4caf50" }} />
            </IconButton>
          );
        },
      },
    },
  ];

  // const formulario = [
  //     { type: 'number', name: 'valorTotal', label: 'Valor total' },
  //     { type: 'number', name: 'qtdParcelas', label: 'Quantidade de parcelas' },
  //     { type: 'number', name: 'nrParcela', label: 'Número da parcela' },
  //     { type: 'number', name: 'multaAtraso', label: 'Multa por atraso' },
  //     { type: 'number', name: 'jurosAtraso', label: 'Juros por atraso' },
  //     { type: 'date', name: 'dtVencimento', label: 'Data de vencimento' },
  //     { type: 'date', name: 'dtQuitacao', label: 'Data de quitação' },
  //     { type: 'date', name: 'dtCompensacao', label: 'Data de compensação' },
  //     { type: 'text', name: 'dentist_id', label: 'Dentista' },
  //     { type: 'text', name: 'nrRecibo', label: 'Código do recibo' },
  //     { type: 'text', name: 'cheque', label: 'Cheque' },
  //     { type: 'text', name: 'convenio', label: 'Convenio' },
  //     { type: 'text', name: 'banco', label: 'Banco' },
  //     { type: 'text', name: 'agencia', label: 'Agência' },
  //     { type: 'text', name: 'conta', label: 'Conta' }
  // ];

  useEffect(() => {
    if (pacienteSelecionado) {
      fetchBudgets(
        { approved: true, patientId: pacienteSelecionado._id },
        (res) => {
          const novaLista = montaParcelasPacientes(res).map((parcela) => {
            if (parcela.status !== "pago") {
              parcela.receber = "Receber";
            } else {
              parcela.receber = "-";
            }
            return parcela;
          });
          setObjetos(novaLista);
        },
      );
    }
  }, [pacienteSelecionado, fetchBudgets]);

  const handleChange = (input) => {
    fetchPatients({ value: input }, (res) => {
      setPacientes(res);
    });
  };

  return (
    <>
      <Paper style={{ padding: "30px" }}>
        <Autocomplete
          value={pacienteSelecionado}
          onChange={(event, newValue) => {
            setPacienteSelecionado(newValue);
          }}
          options={pacientes}
          onInputChange={(event, newInputValue) => {
            handleChange(newInputValue);
          }}
          getOptionLabel={(option) => `${option.name} ${option.lastName}`}
          renderOption={(option) => (
            <span>
              {option.registry} | {option.name} {option.lastName}
            </span>
          )}
          renderInput={(params) => <TextField {...params} label={"Paciente"} />}
        />
        {pacienteSelecionado && (
          <>
            <div style={{ marginTop: "30px" }}>
              <TableComponent colunas={colunas} objetos={objetos} />
            </div>
          </>
        )}
      </Paper>
    </>
  );
};

//         alerta: {
//             tipo: '',
//             msg: '',
//             abrir: false
//         },
//         recebimento: '',
//         cadastro: true,
//         await: true
//     }

//     async componentWillMount() {
//         const { clinica } = this.props;

//         if (this.props.match.params.conta_id) {
//             // var objConta = await buscarConta(clinica._id, this.props.match.params.conta_id);
//             // console.log(objConta);
//             // this.setState({ cadastro: false, conta: objConta, await: false })
//         } else {
//             this.setState({ await: false });
//         }

//         // var objDescricao = await buscarDescricoes(clinica._id);
//         // var formulario = this.state.formulario;

//         // var selectDescricao = {
//         //     type: 'select',
//         //     name: 'descricao',
//         //     label: 'Detalhe da Conta',
//         //     values: []
//         // };

//         // objDescricao.map(obj => {
//         //     var novo = {};
//         //     novo.name = obj.descricao;
//         //     novo.value = obj._id;
//         //     selectDescricao.values.push(novo)
//         // })

//         // formulario.push(selectDescricao);
//         // this.setState({ await: false, conta_id: this.props.match.params.conta_id, formulario: formulario })
//     }

//     editaRecebimento(objeto) {
//         // editarConta(objeto)
//         //     .then(r => {
//         //         if (r) {
//         //             this.setState({
//         //                 alerta: {
//         //                     tipo: 'success',
//         //                     msg: 'Conta editada com sucesso',
//         //                     abrir: true
//         //                 }
//         //             });
//         //             setTimeout(() => this.props.history.goBack(), 3000)
//         //         } else {
//         //             this.setState({
//         //                 alerta: {
//         //                     tipo: 'error',
//         //                     msg: 'Infelizmente não foi possível salvar o lançamento da Conta!',
//         //                     abrir: true
//         //                 }
//         //             })
//         //         }
//         //     })
//         //     .then(this.setState({ alerta: { abrir: false } }))
//     }

//     salvarRecebimento(objeto) {
//         const { clinica } = this.props;

//         objeto.clinic_id = clinica._id;
//         objeto.banco = {
//             banco: objeto.banco,
//             agencia: objeto.agencia,
//             conta: objeto.conta
//         }

//         criarRecebimento( objeto )
//             .then(r => {
//                 if (r) {
//                     this.setState({
//                         alerta: {
//                             tipo: 'success',
//                             msg: 'Recebimento cadastrado com sucesso',
//                             abrir: true
//                         }
//                     });
//                     setTimeout(() => this.props.history.goBack(), 3000)
//                 } else {
//                     this.setState({
//                         alerta: {
//                             tipo: 'error',
//                             msg: 'Infelizmente não foi possível cadastrar o recebimento!',
//                             abrir: true
//                         }
//                     })
//                 }
//             })
//             .then(this.setState({ alerta: { abrir: false } }))
//     }

//     render() {
//         return (
//             <div>
//                 <Paper className="pb-30">
//                     {this.state.alerta.abrir &&
//                         <Notificacao tipo={this.state.alerta.tipo} msg={this.state.alerta.msg} />
//                     }
//                     {!this.state.await &&
//                         <FormComponent {...this.props} inputs={this.state.formulario} objeto={this.state.conta} action={this.state.cadastro ? this.salvarRecebimento.bind(this) : this.editaRecebimento.bind(this)} />
//                     }
//                 </Paper>
//             </div>
//         );
//     }
// }

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { fetchPatients, fetchBudgets })(
  RecebimentosPacienteCadastro,
);
