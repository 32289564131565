import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Face from "@material-ui/icons/Face";
import Settings from "@material-ui/icons/Settings";
import RecebimentosPacienteListagem from "./components/RecebimentosPacienteListagem";
import RecebimentosPacienteCadastro from "./components/RecebimentosPacienteCadastro.jsx";
// import ServicosConfig from './components/ServicosConfig';

class RecebimentosRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "RecebimentosPacientes", numero: 0 },
        { url: "Dentista", numero: 1 },
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() =>
              this.props.history.push(`${match.url}/RecebimentosPacientes`)
            }
            label="Recebimentos"
            icon={<Face />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/Dentista`)}
            label="Pagamento Dentista"
            icon={<Settings />}
          />
        </BottomNavigation>
        <Switch>
          <Route
            exact
            path={`${match.url}/recebimentospacientes`}
            render={(props) => (
              <RecebimentosPacienteListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/cadastrarRecebimentoPaciente`}
            render={(props) => <RecebimentosPacienteCadastro {...props} />}
          />
          {/* <Route exact path={`${match.url}/Dentista`} render={props => <ServicosConfig {...props} tab={tab} trocarTab={this.trocarTab.bind(this)} />} />					 */}
          <Route
            exact
            path={`${match.url}/`}
            render={() => (
              <Redirect to={`${match.url}/recebimentospacientes`} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default connect(null)(RecebimentosRouter);
