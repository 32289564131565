import React, { Component } from "react";
import { connect } from "react-redux";
import {
  buscarEspecialidadeDentista,
  desvincularEspecialidade,
} from "../../../../api/dentistas";
import TableComponent from "../../../table/TableComponent";

class EspecialidadeConfigDentistaRelacao extends Component {
  state = {
    dentistas: [],
    idDentista: "",
  };

  removeEspecialidade(objeto) {
    desvincularEspecialidade(this.state.idDentista, objeto);
  }

  componentWillMount() {
    buscarEspecialidadeDentista(
      this.props.clinica._id,
      this.props.match.params.dentista_id,
    )
      .then((result) => {
        var dentista = [];
        result.especialidades.length > 0 &&
          result.especialidades.map((especialidade, index) => {
            var novoEspecialidade = {};
            novoEspecialidade.titulo = especialidade.titulo;
            novoEspecialidade._id = especialidade._id;
            dentista.push(novoEspecialidade);
          });
        this.setState({
          dentistas: dentista,
          idDentista: this.props.match.params.dentista_id,
        });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Especialidade",
      colunas = [{ label: "Titulo", name: "titulo" }],
      objetos = this.state.dentistas,
      inserir = {
        nome: "Vincular Especialidade",
        link: `/dentistas/vincularEspecialidade/${this.props.match.params.dentista_id}`,
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.removeEspecialidade.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(EspecialidadeConfigDentistaRelacao);
