import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "../VendorsFormStyles";

import Button from "../../../common/Button";

class ModalDeleteMaterial extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.render = this.render.bind(this);

    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  onSubmit(values) {
    const { del, idMaterial, onCancel } = this.props;
    del(idMaterial);
    onCancel();
  }

  renderAuthMsg() {
    const { errorMsg } = this.state;

    if (errorMsg) {
      return (
        <div className={css(styles.msgAuth_modal)}>
          <span>{errorMsg}</span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, onCancel } = this.props;

    return (
      <form
        className={css(styles.form_modal)}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        {this.renderAuthMsg()}
        <div className={css(styles.listContainer)}>
          <span>Deseja mesmo excluir esse produto?</span>
        </div>
        <Button text="Excluir" color="red" submit />

        <Button text="Cancelar" color="secondary" onClick={onCancel} right />
      </form>
    );
  }
}

const modalDeleteMaterial = reduxForm({
  form: "modalDeleteMaterial",
})(ModalDeleteMaterial);

function mapStateToProps(state, props) {
  return {};
}

export default connect(mapStateToProps, {})(modalDeleteMaterial);
