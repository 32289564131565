import axios from "axios";

// Listar tabelas
export async function listarTabelas(active) {
  const params = {}

  if(active !== "undefined") {
    params.active = active
  }

  var status = await axios
    .get("/api/servico/listarTabelas", { params })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar servicos
export async function listarServicos(tabela_id) {
  return await axios.post("/api/servico/listarServicos", {
    tabela_id: tabela_id,
  });
}

export function listServices() {
  return axios.get("/api/servico/list");
}

// Cadastrar tabela
export async function cadastrarTabela(objeto) {
  await axios.post("/api/servico/cadastrarTabela", {
    titulo: objeto.titulo,
    indice: objeto.indice,
    ativo: objeto.ativo,
    convenio: objeto.convenio,
  });
}

// Cadastrar servico
export async function cadastrarServico(service) {
  var status = await axios
    .post("/api/servico/cadastrarServico", {
      service,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

export async function importarTuss(clinic) {
  var tuss = await axios
    .post("/api/servico/replicarTuss", {
      clinic_id: clinic,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return tuss;
}

// Editar serviço
export async function editarServico(objServico) {
  var status = await axios
    .put("/api/servico/editarServico", {
      objServico,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Editar tabela
export async function editarTabela(objTabela) {
  await axios.put("/api/servico/editarTabela", {
    objTabela,
  });
}

// Buscar tabela
export async function buscarTabela(tabela_id) {
  var status = await axios
    .post("/api/servico/buscarTabela", { tabela_id: tabela_id })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Buscar servico
export async function buscarServico(servico_id) {
  var status = await axios
    .post("/api/servico/buscarServico", {
      servico_id: servico_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Excluir servico
export async function deletarServico(serviceId) {
  return await axios.delete(`/api/servico/deletarServico/${serviceId}`);
}

// Excluir tabela
export async function deletarTabela(tabelaId) {
  return await axios.delete(`/api/servico/deletarTabela/${tabelaId}`);
}

// Desvincular servico da tabela
export async function desvincularServico(tabela_id, objServico) {
  var objRemove = {};
  var arrServico = [];

  objRemove.tabela_id = tabela_id;

  objServico.map((servico) => {
    arrServico.push(servico[0]._id);
  });

  objRemove.servicos = arrServico;

  var status = await axios
    .post("/api/servico/removerServicoTabela", objRemove)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Vincular servico na tabela
export async function adicionarServicoTabela(tabela_id, objServico) {
  console.log(objServico);
  var status = await axios
    .post("/api/servico/adicionarServicoTabela", {
      tabela_id: tabela_id,
      servicos: [objServico.servicos],
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}
