import React, { Component } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../table/TableComponent";
import Edit from "@material-ui/icons/Edit";
import {
  deletarTipoPagamento,
  buscasTipoPagamentos,
  getPaymentTypes,
} from "../../../../api/formaPagamento";
import { IconButton } from "@material-ui/core";

class FormaPagamentoListagem extends Component {
  state = {
    formaPagamentos: [],
  };

  deletaTipoPagamento(objeto) {
    const { clinica } = this.props;
    deletarTipoPagamento(objeto, clinica._id);
  }

  componentWillMount() {
    getPaymentTypes(this.props.clinica._id).then(({ data }) => {
      var listaFormaPagamentos = [];
      data.length &&
        data.map((formaPagamento, index) => {
          var novo = {};
          novo.codigo = formaPagamento.codigo;
          novo.tipo = formaPagamento.tipo;
          novo.formaPagamentoId = formaPagamento._id;
          novo.funcoes = [
            <IconButton
              key={index}
              onClick={() =>
                this.props.history.push(
                  `${this.props.match.url}/${formaPagamento._id}`,
                )
              }
            >
              <Edit />
            </IconButton>,
          ];
          listaFormaPagamentos.push(novo);
        });
      this.setState({ formaPagamentos: listaFormaPagamentos });
    });
  }
  render() {
    const titulo = "Tipos de Pagamento",
      colunas = [
        { label: "Código", name: "codigo" },
        { label: "Tipo", name: "tipo" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.formaPagamentos,
      inserir = {
        nome: "Cadastrar Forma Pagamento",
        link: `${this.props.match.url}/novo`,
      };
    return (
      <TableComponent
        funcoes={{ deletar: this.deletaTipoPagamento.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(FormaPagamentoListagem);
