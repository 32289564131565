import axios from "axios";

// Listar pacientes
export async function buscarPacientes() {
  var pacientes = await axios
    .post("/api/pacientes/listar")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return pacientes;
}

// Listar situacoes
export async function buscarSituacoes(clinicaId) {
  var situacoes = await axios
    .post("/api/pacientes/listarSituacao", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return situacoes;
}

// Cadastrar situacoes
export async function cadastrarSituacao(clinicaId, situacao) {
  return await axios.post("/api/pacientes/cadastrarSituacao", {
    clinic_id: clinicaId,
    titulo: situacao,
  });
}

// Buscar situacao
export async function buscarSituacao(clinicaId, situacaoId) {
  var situacao = await axios
    .post("/api/pacientes/buscarSituacao", {
      situacao_id: situacaoId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return situacao;
}

// Editar situacao
export async function editarSituacao(objSituacao) {
  var situacao = await axios
    .put("/api/pacientes/editarSituacao", {
      objSituacao,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return situacao;
}

// Excluir situacao
export async function deletarSituacao(situacaoId) {
  return await axios.delete(`/api/pacientes/deletarSituacao/${situacaoId}`);
}

// Listar status
export async function listarStatus(clinicaId) {
  var status = await axios
    .post("/api/pacientes/listarStatus", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar todos os status e itens
export async function listarTodoStatus(clinicaId) {
  var status = await axios
    .post("/api/pacientes/listarTodoStatus", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar itens do status
export async function listarStatusItem(status_id) {
  var status = await axios
    .post("/api/pacientes/listarStatusItem", {
      status_id: status_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Cadastrar status
export async function cadastrarStatus(clinicaId, status) {
  var status = await axios
    .post("/api/pacientes/cadastrarStatus", {
      clinic_id: clinicaId,
      status: status,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Cadastrar item status
export async function cadastrarStatusItem(status_id, item) {
  var status = await axios
    .post("/api/pacientes/cadastrarStatusItem", {
      status_id: status_id,
      titulo: item,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Buscar status
export async function buscarStatus(clinic_id, status_id) {
  var status = await axios
    .post("/api/pacientes/buscarStatus", {
      status_id: status_id,
      clinic_id: clinic_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Buscar itens do status
export async function buscarStatusItem(statusItem_id) {
  var status = await axios
    .post("/api/pacientes/buscarStatusItem", {
      statusItem_id: statusItem_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Editar status
export async function editarStatus(objStatus) {
  var status = await axios
    .put("/api/pacientes/editarStatus", {
      objStatus,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Editar status
export async function editarStatusItem(objStatusItem) {
  var status = await axios
    .put("/api/pacientes/editarStatusItem", {
      objStatusItem,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Excluir status
export async function deletarStatus(objStatus, clinic_id) {
  objStatus.clinic_id = clinic_id;

  var status = await axios
    .delete("/api/pacientes/deletarStatus", {
      data: objStatus,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Excluir itens do status
export async function deletarStatusItem(objStatusItem) {
  var status = await axios
    .delete("/api/pacientes/deletarStatusItem", {
      data: objStatusItem,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Buscar cadastro Ficha de ATM
export async function buscarFichaAtm() {
  var ficha = await axios
    .post("/api/fichas/atm/buscar")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return ficha;
}

// Editar cadastro Ficha de ATM
export async function editarFichaAtm(fichas) {
  var ficha = await axios
    .put("/api/fichas/atm/editar", { fichas })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => {
      throw Error(error.message);
    });

  return ficha;
}

// Cadastrar ficha de ATM do paciente
export async function editarFichaAtmPaciente(objFicha) {
  try {
    const ficha = await axios.put("/api/fichas/atm/paciente/editar", objFicha);
    return ficha;
  } catch (err) {
    console.log(err);
  }
}

// Buscar Ficha de ATM do paciente
export async function buscarFichaAtmPaciente(idPaciente) {
  var ficha = await axios
    .get(`/api/fichas/atm/paciente/buscar/${idPaciente}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return ficha;
}

// Buscar cadastro Ficha de ANAMNESE
export async function buscarFichaAnamnese() {
  var ficha = await axios
    .get("/api/fichas/anamnese/buscar")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return ficha;
}

export async function editarFichaAnamnese(fichas) {
  var ficha = await axios
    .put("/api/fichas/anamnese/editar", {
      fichas,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => {
      throw Error(error.message);
    });

  return ficha;
}

// Cadastrar ficha de ATM do paciente
export async function editarFichaAnamnesePaciente(objFicha) {
  var ficha = await axios
    .put("/api/fichas/anamnese/paciente/editar", {
      objFicha,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return ficha;
}

// Buscar Ficha de ATM do paciente
export async function buscarFichaAnamnesePaciente(idPaciente) {
  var ficha = await axios
    .get(`/api/fichas/anamnese/paciente/buscar/${idPaciente}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return ficha;
}

// Buscar laudo do paciente
export async function buscarLaudo(idPaciente, laudo) {
  var laudo = await axios
    .get(`/api/pacientes/buscarLaudo/${idPaciente}/${laudo}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return laudo;
}

// Buscar laudo do paciente
export async function salvarLaudo(idPaciente, laudo, texto) {
  var laudo = await axios
    .post(`/api/pacientes/atualizarlaudo/${idPaciente}`, {
      filename: laudo,
      laudo: texto,
    })
    .then((response) => response.status)
    .catch((error) => console.log(error));

  return laudo;
}

export async function editarServicoProntuario(servico) {
  try {
    const prontuario = await axios.put(
      `/api/pacientes/prontuario/servico/${servico._id}`,
      { servico },
    );
    return prontuario.data;
  } catch (err) {
    console.log(err);
  }
}

export async function adicionarServicoProntuario(idPaciente, servico) {
  try {
    const prontuario = await axios.post(
      `/api/pacientes/inserirServicoProntuario/${idPaciente}`,
      { servico },
    );
    return prontuario.data;
  } catch (err) {
    console.log(err);
  }
}

export async function alterarPropsMedidaImage(props) {
  try {
    const response = await axios.post(
      "/api/file/patient/updatePropsMeasureImage",
      props,
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function buscarPropsImage(props) {
  try {
    const response = await axios.post("/api/file/patient/getPropsImage", props);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getOdontogram(patientId) {
  try {
    const { status, data } = await axios.get(
      `/api/patient/${patientId}/odontogram`,
    );
    if (status === 200) return data;
  } catch (err) {
    console.log(err);
  }
}

export async function createEndodontic(patientId, endodontic) {
  await axios.post(`/api/patient/${patientId}/endodontic/create`, {
    endodontic,
  });
}

export async function updateEndodontic(patientId, endodontic) {
  await axios.put(`/api/patient/${patientId}/endodontic/update`, {
    endodontic,
  });
}

export async function getListEndodontic(patientId) {
  const status = await axios.get(`/api/patient/${patientId}/endodontic/list`);
  return status;
}

export async function getEndodontic(patientId, endodonticId) {
  const { data } = await axios.get(
    `/api/patient/${patientId}/endodontic/${endodonticId}`,
  );
  return data;
}

export async function filterEndodontic(filters) {
  return await axios.post(`/api/patient/endodontic/filter`, filters);
}


export async function getAgendamento(patientId) {
  return await axios.get(`/api/patient/${patientId}/schedule`);
}



