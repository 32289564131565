import { StyleSheet } from "aphrodite/no-important";

import { COLORS } from "../../_constants/colors";

export const styles = StyleSheet.create({
  flex: {
    display: "flex",
    justifyContent: "center",
    padding: "4px",
    overflow: "auto",
  },
  form: {
    width: "100%",
    height: "100%",
  },
  sectionTitle: {
    textTransform: "uppercase",
    color: COLORS.grey50,
    fontSize: ".75rem",
    borderBottom: `1px solid ${COLORS.grey77}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: ".5rem",
  },
  section: {
    marginBottom: "1rem",
    padding: "1rem",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    boxShadow: "2px 3px 4px 0px rgba(0,0,0,0.08)",
  },
  list: {
    listStyle: "none",
    marginTop: "0",
    borderRadius: "2px",
    maxHeight: "100%",
    overflowY: "auto",
    marginBottom: "1rem",
    backgroundColor: "#ececec",
    padding: "1rem",
  },
  status_odontogram: {
    width: "1157px",
    margin: "auto",
  },
  listItemStatusTop: {
    background: "linear-gradient(#e6e6e6, #fff);",
    borderRadius: "4px",
    float: "left",
    height: "175px",
    width: "70px",
    margin: "1px",
    position: "relative",
    border: "1px solid #e6e6e6",
  },
  listItemStatusTopDeciduos: {
    float: "left",
    height: "70px",
    width: "70px",
    margin: "1px",
    position: "relative",
    padding: "12px 20px",
  },
  listItemStatusTopCrown: {
    float: "left",
    height: "50px",
    width: "50px",
    position: "relative",
    zIndex: "1",
  },
  listItemStatusBot: {
    background: "linear-gradient(#fff, #e6e6e6);",
    borderRadius: "4px",
    float: "left",
    height: "175px",
    width: "70px",
    margin: "1px",
    position: "relative",
    border: "1px solid #e6e6e6",
  },
  listItemStatusBotDeciduos: {
    float: "left",
    height: "60px",
    width: "70px",
    margin: "1px",
    position: "relative",
    padding: "12px 20px",
  },
  statusNumberTop: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    fontWeight: "600",
  },
  statusNumberBot: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: "155px",
    fontWeight: "600",
  },
  crownNumberTop: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "50px",
    marginTop: "50px",
    fontWeight: "600",
  },
  crownNumberTopDeciduos: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "34px",
    marginTop: "34px",
    fontWeight: "600",
  },
  crownNumberBotDeciduos: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "34px",
    marginTop: "-17px",
    fontWeight: "600",
  },
  crownNumberBot: {
    fontSize: ".8rem",
    lineHeight: "19px",
    color: COLORS.grey65,
    transition: "color .5s ease",
    position: "absolute",
    textAlign: "center",
    width: "50px",
    marginTop: "-17px",
    fontWeight: "600",
  },
  red: {
    color: COLORS.red,
    ":hover": {
      color: COLORS.redDark,
    },
  },
  status_top_left: {
    display: "block",
    float: "left",
  },
  status_top_right: {
    display: "block",
    float: "left",
  },
  status_top_left_deciduos: {
    display: "block",
    float: "left",
    paddingLeft: "213px",
  },
  status_top_right_deciduos: {
    display: "block",
    float: "left",
  },
  status_bot_left_deciduos: {
    display: "block",
    float: "left",
    paddingLeft: "213px",
  },
  status_bot_right_deciduos: {
    display: "block",
    float: "left",
  },
  status_bot_left: {
    marginRight: "5px",
    display: "block",
    float: "left",
  },
  status_bot_right: {
    display: "block",
    float: "left",
  },
  status_top: {
    display: "block",
    clear: "both",
  },
  status_top_deciduos: {
    display: "block",
    clear: "both",
    marginBottom: "5px",
  },
  status_bot_deciduos: {
    display: "block",
    clear: "both",
    marginBottom: "5px",
  },
  status_bot: {
    display: "block",
    clear: "both",
  },
  pointer: {
    pointerEvents: "auto !important",
    cursor: "pointer",
    width: "77px !important",
    background: "#FFFFFF !important",
    border: "1px solid #DCDCDC !important",
    borderRadius: "4px !important",
    padding: "3px !important",
    display: "flex !important",
    flexWrap: "wrap !important",
    ":after": {
      borderLeftColor: "#FFFFFF !important",
    },
  },
  btn_modal: {
    cursor: "pointer",
    height: "100%",
    width: "100%",
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "10%",
    borderRight: "1px solid " + COLORS.grey85,
    borderBottom: "1px solid " + COLORS.grey85,
    margin: "2px",
    padding: "1px",
    height: "100%",
    lineHeight: "25px",
    color: COLORS.grey35,
    fontSize: ".75rem",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    transition: "color .5s ease, background-color .25s ease",
    ":hover": {
      color: "#FFFFFF !important",
      backgroundColor: COLORS.grey85,
    },
    backgroundColor: "#ececec",
  },
});
