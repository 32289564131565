import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const BUSCAR_MOBILIDADE = "BUSCAR_MOBILIDADE";
export const EDITAR_MOBILIDADE = "EDITAR_MOBILIDADE";

export function buscarMobilidade(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/pacientes/mobilidade/buscar/${patientId}`,
      );

      dispatch({ type: BUSCAR_MOBILIDADE, payload: res.data });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Mobilidade não encontrada.`, type: "error" },
      });
    }
  };
}

export function atualizarMobilidade(data, patientId, callback) {
  return async (dispatch) => {
    try {
      data.patient_id = patientId;

      const res = await axios.put(
        `/api/pacientes/mobilidade/editar/${patientId}`,
        data,
      );

      dispatch({ type: EDITAR_MOBILIDADE, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Mobilidade atualizada com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Mobilidade não encontrada.`, type: "error" },
      });
      console.log(err);
    }
  };
}
