import { Container, IconButton, Tooltip } from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import TableComponent from "../../../table/TableComponent";
import {
  buscarTabela,
  deletarServico,
  listarServicos,
} from "../../../../api/servicos";
import SimpleDialog from "../../../dialog/SimpleDialog";
import { toast } from "react-toastify";

function ServicosListagem({ match, history }) {
  const { tabela_id } = match.params;
  const [services, setServices] = useState([]);
  const [tabela, setTabela] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const services = await listarServicos(tabela_id);
        const tabela = await buscarTabela(tabela_id);

        if (services.data.length) setServices(services.data);

        if (tabela) setTabela(tabela);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const colunas = [
    { label: "", name: "_id", options: { display: false } },
    { label: "Descrição", name: "description" },
    {
      label: "Status",
      name: "active",
      options: {
        customBodyRender: (value) => {
          return value ? "Ativo" : "Inativo";
        },
      },
    },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => {
          return value && formataMoedaReal(value);
        },
      },
    },
    {
      label: "Valor Final",
      name: "finalValue",
      options: {
        customBodyRender: (value) => {
          return value && formataMoedaReal(value);
        },
      },
    },
    {
      label: " ",
      name: "_id",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Tooltip title="Editar serviço">
                <IconButton
                  onClick={() => history.push(`${match.url}/${value}`)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Excluir serviço">
                <IconButton
                  onClick={() => {
                    setSelectedService(value);
                    setOpenDialog(true);
                  }}
                >
                  <Delete color="secondary" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  const options = {
    customToolbar: () => {
      return (
        <IconButton onClick={() => history.push(`${match.url}/novo`)}>
          <Add />
        </IconButton>
      );
    },
  };

  const handleDeleteService = async () => {
    try {
      await deletarServico(selectedService);
      toast.success("Serviço removido com sucesso");
      fetchServices();
    } catch (error) {
      toast.error("Não foi possível remover o serviço");
    }
    setSelectedService("");
    setOpenDialog(false);
  };

  const fetchServices = async () => {
    try {
      const services = await listarServicos(tabela_id);

      if (services.data.length) setServices(services.data);
    } catch (error) {}
  };

  return (
    <>
      <TableComponent
        colunas={colunas}
        titulo={tabela?.titulo ?? ""}
        objetos={services}
        otherOptions={options}
      />

      <SimpleDialog
        open={openDialog}
        title="Deseja excluir o serviço?"
        content="Ao excluir o serviço a operação não poderá ser desfeita."
        closeButtonTitle="Cancelar"
        submitButtonTitle="Excluir"
        handleClose={() => setOpenDialog(false)}
        handleSubmit={handleDeleteService}
      />
    </>
  );
}

export default ServicosListagem;
