import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { hideMessage } from "../../actions/systemMsg";
import { Notificacao2 } from "../notificacao/Notificacao2";

const SystemMsg = ({ type, show, message, hideMessage }) => {
  const handleClose = () => {
    hideMessage();
  };

  return (
    show && (
      <div>
        <Notificacao2
          alerta={{ tipo: type, abrir: show, msg: message }}
          onClose={handleClose}
        />
      </div>
    )
  );
};

function mapStateToProps({ systemMsg }) {
  return {
    message: systemMsg.message,
    show: systemMsg.show,
    type: systemMsg.type,
  };
}

export default connect(mapStateToProps, { hideMessage })(SystemMsg);
