import React, { useEffect, useState } from "react";
import TableComponent from "../../table/TableComponent";
import { Container, Paper, Typography } from "@material-ui/core";
import { getDentistComissions } from "../../../api/dentist_comission";
import { formataMoedaReal } from "../../../helpers/Helpers";
import moment from "moment";
import { toast } from "react-toastify";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Button } from "@material-ui/core";

const defaultListDentistComission = {
  data: [],
  in: 0,
  out: 0,
};

export default function ListDentistComission() {
  const [dentistComissions, setDentistComissions] = useState([]);
  const [listDentistComission, setListDentistComission] = useState(defaultListDentistComission);
  const [startDate, setStartDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  const columns = [
    { name: "dentist", label: "Dentista" },
    { name: "procedure", label: "Procedimento" },
    { name: "procedureConcludedDate", label: "Data de conclusão" },
    { name: "value", label: "Valor" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getDentistComissions(startDate, finalDate);
      if (data.length) {
        const dataTable = data.map((element) => {
          return {
            value: formataMoedaReal(element.value),
            procedure: element.procedure.procedure?.description || "",
            dentist: element.dentist.name,
            procedureConcludedDate: moment(
              element.procedure.procedure?.concludedDate || new Date()
            ).format("DD/MM/YYYY"),
          };
        });
        setDentistComissions(dataTable);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async () => {
    try {
      const dataTable = [];
      const { data } = await getDentistComissions(startDate, finalDate);
      if (data.length) {
         data.map((element) => {
          const comission =  {
            value: formataMoedaReal(element.value),
            procedure: element.procedure.procedure?.description || "",
            dentist: element.dentist.name,
            procedureConcludedDate: moment(
              element.procedure.procedure?.concludedDate || new Date()
            ).format("DD/MM/YYYY"),
          };
          dataTable.push(comission)
        });
      }
      setDentistComissions(dataTable);
    } catch (e) {
      console.log(e)
      toast.error("Erro ao obter comissões");
    }
  };

  return (
    <Container maxWidth="lg" component={Paper}>

      <Typography
        variant="h5"
        fontWeight="bold"
        style={{ marginBottom: "20px" }}
      >
        Comissões dentistas
      </Typography>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Inicial"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          value={startDate}
          onChange={(e) => setStartDate(e)}
          style={{ 
            marginRight:"20px",
            marginLeft:"200px"
          }}

        />
      </MuiPickersUtilsProvider>

      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Data Final"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          value={finalDate}
          onChange={(e) => setFinalDate(e)}

        />
      </MuiPickersUtilsProvider>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        style={{ marginLeft: "10px", marginBottom: "30px", marginTop: "10px" }}
        onClick={handleSearch}
      >
        Buscar
      </Button>

      <div style={{ marginTop: "20px" }}>
        <TableComponent colunas={columns} objetos={dentistComissions} />
      </div>
    </Container>
  );
}
