import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import ListProceduresBudget from "./ListProceduresBudget";
import ProcedimentoOrcamentoFormDialog from "./ProcedimentoOrcamentoFormDialog";
import { Add, Delete } from "@material-ui/icons";
import BudgetResume from "./BudgetResume";
import {
  addProcedure,
  atualizarParcelas,
  concluirProcedimento,
  editInterestAndPenalty,
  editProcedure,
  getBudget,
  removeProcedure,
  getBudgetResume,
  listParcelsByBudget,
  parcelPay,
  confirmPayment,
} from "../../../../api/budget";
import JurosMultaValidadeForm from "./JurosMultaValidadeForm";
import BudgetSimulator from "./BudgetSimulator";
import moment from "moment";
import ParcelsTable from "./ParcelsTable";
import ParcelReceiptFormDialog from "./ParcelReceiptFormDialog";
import { toast } from "react-toastify";
import ProcedureConclusionDialog from "./ProcedureConclusionDialog";
import SimpleDialog from "../../../dialog/SimpleDialog";

const initialResume = {
  grossValue: 0,
  discountValue: 0,
  netValue: 0,
  entranceValue: 0,
  interestValue: 0,
  parcelValue: 0,
  paidValue: 0,
  balance: 0,
};

function TabPanelContent({ budgetId, handleDeleteBudget }) {
  const [budget, setBudget] = useState({ procedures: [] });
  const [resume, setResume] = useState(initialResume);
  const [parcels, setParcels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [procedureFormDialog, setProcedureFormDialog] = useState({
    procedure: "",
    open: false,
    defaultServiceTableId: "",
  });

  const [executarProcedimentoFormDialog, setExecutarProcedimentoFormDialog] =
    useState({ procedure: "", open: false });

  const [jurosMultaValidadeFormDialog, setJurosMultaValidadeFormDialog] =
    useState(false);

  const [parcelReceiptFormDialog, setParcelReceiptFormDialog] = useState({
    open: false,
    parcelId: "",
  });

  const [simulacaoOrcamentoDialog, setSimulacaoOrcamentoDialog] =
    useState(false);

  const [confirmPaymentDialog, setConfirmPaymentDialog] = useState({
    open: false,
    parcelId: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Promise.all([
          getBudget(budgetId),
          getBudgetResume(budgetId),
          listParcelsByBudget(budgetId),
        ]);

        setBudget(response[0].data);
        setResume(response[1].data);
        setParcels(response[2].data);
        setIsLoading(false);
      } catch (err) {
        toast.error("Erro ao obter dados do orçamento");
      }
    };

    fetchData();
  }, [isLoading]);

  const handleSalvarProcedimento = async (procedure) => {
    try {
      procedure._id
        ? await editProcedure(procedure)
        : await addProcedure(budgetId, procedure);

      setProcedureFormDialog({
        procedure: "",
        open: false,
        defaultServiceTableId: procedure.list_id,
      });

      toast.success(
        procedure._id ? "Procedimento alterado" : "Procedimento inserido",
      );

      setIsLoading(true);
    } catch (err) {
      toast.error(
        `Erro ao ${procedure._id ? "editar" : "salvar"} procedimento`,
      );
    }
  };

  const handleRemoverProcedimento = async (procedureId) => {
    try {
      await removeProcedure(procedureId);
      toast.success("Procedimento removido");
      setIsLoading(true);
    } catch (error) {
      if (error.response?.data.error === "Budget require a procedure")
        return toast.error("Orçamento precisa de ao menos um serviço!");

      return toast.error("Erro ao remover serviço");
    }
  };

  const handleEditarProcedimento = async (procedureId) => {
    const procedure = budget.procedures.find(
      (procedure) => procedure._id === procedureId,
    );
    setProcedureFormDialog({ procedure, open: true });
  };

  const handleConcluirProcedimento = async (procedureId) => {
    const procedure = budget.procedures.find(
      (procedure) => procedure._id === procedureId,
    );
    setExecutarProcedimentoFormDialog({ procedure, open: true });
  };

  const concluirExecucaoProcedimento = async (data, procedureId) => {
    const concluded = {
      budgetId,
      procedureId,
      dentistId: data.dentist._id,
      concludedDate: data.concludedDate,
    };

    try {
      await concluirProcedimento(concluded);
      setExecutarProcedimentoFormDialog({ open: false, procedure: "" });
      toast.success("Procedimento concluído");
      setIsLoading(true);
    } catch (err) {
      toast.error("Erro ao concluir procedimento");
    }
  };

  const alterarJurosMulta = async (data) => {
    try {
      await editInterestAndPenalty(budgetId, data);
      setJurosMultaValidadeFormDialog(false);
      toast.success("Juros e multas alterados");
      setIsLoading(true);
    } catch (err) {
      toast.error("Erro ao editar juros e multas");
    }
  };

  const gerarParcelas = async (
    entrance,
    discount,
    numberOfParcels,
    firstExpiration,
  ) => {
    if (budget.totalValueProcedures <= 0) return;

    const request = {
      budgetId: budget._id,
      entrance,
      discount,
      numberOfParcels,
      firstExpiration,
    };

    try {
      await atualizarParcelas(request);
      setSimulacaoOrcamentoDialog(false);
      toast.success("Parcelas geradas com sucesso!");
      setIsLoading(true);
    } catch (err) {
      toast.error("Erro ao gerar parcelas");
    }
  };

  const handleParcelReceipt = useCallback(
    (parcelId) => {
      setParcelReceiptFormDialog({ open: true, parcelId: parcelId ?? null });
    },
    [parcels],
  );

  const submitParcelReceipt = useCallback(
    async (parcelId, payment) => {
      try {
        const nextPayment = {
          payment,
          budgetId,
          parcelId,
        };
        await parcelPay(nextPayment);
        setParcelReceiptFormDialog({ open: false });
        toast.success("Pagamento realizado com sucesso");
        setIsLoading(true);
      } catch (error) {
        toast.error("Erro ao concluir pagamento");
      }
    },
    [parcels],
  );

  const handleConfirmPayment = (parcelId) => {
    setConfirmPaymentDialog({ open: true, parcelId });
  };

  const submitConfirmPayment = useCallback(async (parcelId) => {
    const type = parcelId ? "parcel" : "entrance";
    try {
      await confirmPayment({ budgetId, parcelId, type });
      const parcels = await listParcelsByBudget(budgetId);
      setParcels(parcels.data);
      setConfirmPaymentDialog({ open: false, parcelId: "" });
      toast.success("Pagamento confirmado");
    } catch (error) {
      toast.error("Erro ao confirmar pagamento");
    }
  });

  return (
    <>
      <Paper style={{ padding: "12px" }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                startIcon={<Add />}
                onClick={() =>
                  setProcedureFormDialog({
                    ...procedureFormDialog,
                    procedure: "",
                    open: true,
                  })
                }
              >
                Serviço
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ListProceduresBudget
                procedures={budget.procedures ?? []}
                conclusionProcedure={handleConcluirProcedimento}
                editProcedure={handleEditarProcedimento}
                removeProcedure={handleRemoverProcedimento}
              />
            </Grid>
            <Grid item xs={10} style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item xs={3}>
                  Validade orçamento:{" "}
                  {moment(budget.budget_expiration).format("DD/MM/YYYY")}
                </Grid>
                <Grid item xs={3}>
                  Juros Mensal: {budget.monthlyInterestRate}%
                </Grid>
                <Grid item xs={3}>
                  Juros Mora: {budget.defaultInterestRate}%
                </Grid>
                <Grid item xs={3}>
                  Multa Atraso: {budget.penaltyDelayRate}%
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => setJurosMultaValidadeFormDialog(true)}
              >
                Juros / Multa
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="sm">
                <BudgetResume resume={resume} />
              </Container>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => setSimulacaoOrcamentoDialog(true)}
              >
                Gerar parcelamento
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ParcelsTable
                receiptParcel={handleParcelReceipt}
                confirmPayment={handleConfirmPayment}
                parcels={parcels}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                startIcon={<Delete />}
                onClick={() => handleDeleteBudget(budgetId)}
              >
                Deletar
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>

      {procedureFormDialog.open && (
        <ProcedimentoOrcamentoFormDialog
          procedimento={procedureFormDialog.procedure}
          onClose={() =>
            setProcedureFormDialog({
              ...procedureFormDialog,
              procedure: "",
              open: false,
            })
          }
          onSubmit={handleSalvarProcedimento}
          defaultServiceTableId={procedureFormDialog.defaultServiceTableId}
        />
      )}

      {executarProcedimentoFormDialog.open && (
        <ProcedureConclusionDialog
          onClose={() =>
            setExecutarProcedimentoFormDialog({ procedure: "", open: false })
          }
          onSubmit={concluirExecucaoProcedimento}
          procedureId={executarProcedimentoFormDialog.procedure._id}
        />
      )}

      {jurosMultaValidadeFormDialog && (
        <JurosMultaValidadeForm
          defaultInterestRate={budget.defaultInterestRate}
          monthlyInterestRate={budget.monthlyInterestRate}
          penaltyDelayRate={budget.penaltyDelayRate}
          expiration={budget.budget_expiration}
          onClose={() => setJurosMultaValidadeFormDialog(false)}
          onSubmit={alterarJurosMulta}
        />
      )}

      {simulacaoOrcamentoDialog && (
        <BudgetSimulator
          generateParcels={gerarParcelas}
          budget={budget}
          onClose={() => setSimulacaoOrcamentoDialog(false)}
        />
      )}

      {parcelReceiptFormDialog.open && (
        <ParcelReceiptFormDialog
          budgetId={budgetId}
          parcelId={parcelReceiptFormDialog.parcelId}
          onClose={() =>
            setParcelReceiptFormDialog({ parcelId: null, open: false })
          }
          onSubmit={submitParcelReceipt}
        />
      )}

      {confirmPaymentDialog.open && (
        <SimpleDialog
          open={confirmPaymentDialog.open}
          title="Confirmar pagamento?"
          content="Após confirmado a operação não poderá ser desfeita"
          handleClose={() =>
            setConfirmPaymentDialog({
              parcelId: null,
              open: false,
            })
          }
          handleSubmit={() =>
            submitConfirmPayment(confirmPaymentDialog.parcelId)
          }
          submitButtonTitle="Confirmar"
          closeButtonTitle="Fechar"
        />
      )}
    </>
  );
}

export default TabPanelContent;
