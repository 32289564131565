import React, { Component } from "react";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { styles } from "../forms/InputFieldStyles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import Button from "../common/Button";

import { listarStatus } from "../../api/pacientes";

class StatusLista extends Component {
  constructor(props) {
    super(props);

    this.addStatus = this.addStatus.bind(this);
    this.removeStatus = this.removeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      // status: [{ name: '', value: '' }],
      status: [{ status: "", status_id: "" }],
      listaStatus: "",
      StatusSelecionado: "",
    };
  }

  async componentWillMount() {
    var objStatus = await listarStatus(this.props.clinica._id);
    this.setState({ listaStatus: objStatus });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ status: [...nextProps.status] });
  }

  handleChange(_, newEvent) {
    this.setState({ StatusSelecionado: newEvent });
  }

  addStatus = () => {
    const { getStatus } = this.props;
    var { StatusSelecionado } = this.state;

    if (StatusSelecionado !== "") {
      const nextStatus = {
        status: StatusSelecionado.status,
        status_id: StatusSelecionado._id ?? "",
      };

      this.setState({
        status: this.state.status.concat([nextStatus]),
      });

      getStatus(this.state.status.concat([nextStatus]));
    }
  };

  removeStatus = (idx) => () => {
    const { getStatus } = this.props;
    this.setState({
      status: this.state.status.filter((s, sidx) => idx !== sidx),
    });

    getStatus(this.state.status.filter((s, sidx) => idx !== sidx));
  };

  render() {
    return (
      <div>
        {this.state.listaStatus && (
          <Autocomplete
            size="small"
            freeSolo
            options={this.state.listaStatus ?? []}
            getOptionLabel={(option) => `${option.status}`}
            renderOption={(option) => <span>{option.status}</span>}
            onChange={this.handleChange}
            onInputChange={(e) =>
              this.setState({ StatusSelecionado: { status: e.target.value } })
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        )}

        <Button
          text="Adicionar"
          color="primary"
          onClick={this.addStatus}
          style={{ marginTop: "10px" }}
        />
        {this.state.status.map((status, idx) => (
          <div key={idx} className={css(styles.row_2)}>
            <fieldset className={css(styles.fieldset)}>
              <label className={css(styles.fieldset)}>{status.status}</label>
            </fieldset>

            <fieldset className={css(styles.fieldset)}>
              <span
                className={css(styles.link, styles.red)}
                onClick={this.removeStatus(idx)}
              >
                Remover
              </span>
            </fieldset>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}

export default connect(mapStateToProps)(StatusLista);
