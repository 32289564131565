import React, { useState, useEffect } from "react";
import { listarTabelas, deletarTabela } from "../../../../api/servicos";
import { Add, Edit, List, Delete as DeleteIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import TableComponent from "../../../table/TableComponent";
import { toast } from "react-toastify";
import SimpleDialog from "../../../dialog/SimpleDialog";
function TabelaServicoListagem({ history, match }) {
  const [tabelas, setTabelas] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState();

  useEffect(() => {
    const fetchTabelas = async () => {
      const tabelas = await listarTabelas();
      tabelas.map((tabela) => {
        tabela.nomeConvenio = tabela.convenio?.nomeConvenio ?? "";
        tabela.funcoes = tabela._id;
        return tabela;
      });
      setTabelas(tabelas);
    };
    fetchTabelas();
  }, []);

  const removerTabela = async (servicoId) => {
    try {
      await deletarTabela(servicoId);
      toast.success("Tabela serviço excluída com sucesso");
      setTabelas(tabelas.filter((tabela) => tabela._id !== servicoId));
      setIsOpenDialog(false);
      setSelectedTableId("");
    } catch (error) {
      toast.error("Erro ao excluir Tabela serviço");
    }
  };

  const handleOpenDialog = (tableId) => {
    setIsOpenDialog(true);
    setSelectedTableId(tableId);
  };

  const colunas = [
    { label: "Titulo", name: "titulo" },
    { label: "Índice(%)", name: "indice" },
    {
      label: "Ativo",
      name: "ativo",
      options: {
        customBodyRender: (value) => {
          return value ? "Ativo" : "Inativo";
        },
      },
    },
    { label: "Convenio", name: "nomeConvenio" },
    {
      label: " ",
      name: "funcoes",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                title="Editar tabela"
                onClick={() => history.push(`${match.url}/${value}`)}
              >
                <Edit />
              </IconButton>
              <IconButton
                title="Visualizar serviços"
                onClick={() => history.push(`${match.url}/${value}/servicos`)}
              >
                <List />
              </IconButton>
              <IconButton
                title="Deletar tabela"
                onClick={() => handleOpenDialog(value)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  const options = {
    elevation: 1,
    customToolbar: () => {
      return (
        <IconButton
          onClick={() => history.push(`${match.url}/cadastrarTabela`)}
        >
          <Add />
        </IconButton>
      );
    },
  };

  return (
    <>
      <TableComponent
        objetos={tabelas}
        colunas={colunas}
        otherOptions={options}
      />
      <SimpleDialog
        open={isOpenDialog}
        title="Deseja excluir a tabela?"
        content="Ao excluir a tabela a operação não poderá ser desfeita."
        closeButtonTitle="Cancelar"
        submitButtonTitle="Excluir"
        handleClose={() => setIsOpenDialog(false)}
        handleSubmit={() => removerTabela(selectedTableId)}
      />
    </>
  );
}

export default TabelaServicoListagem;
