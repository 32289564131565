export function defaultPeriogram() {
  var PERIOGRAM = {
    dentalStatus: null,
    dentes: {
      superior: {
        tooth_18: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 2,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_17: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_16: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_15: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_14: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_13: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_12: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_11: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_21: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_22: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_23: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_23: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_24: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_25: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_26: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_27: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_28: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },
      },

      inferior: {
        tooth_48: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_47: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_46: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_45: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_44: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_44: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_43: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_42: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_41: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_31: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_32: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_33: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_34: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_35: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_36: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_37: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },

        tooth_38: {
          lingual: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          bucal: {
            furcation: 0,
            bleeding: [0, 0, 0],
            plaque: [0, 0, 0],
            gingival_margin: [0, 0, 0],
            probing_depth: [0, 0, 0],
          },
          status: "",
          mobility: 0,
          implant: false,
        },
      },
    },
  };
  return { type: "PERIOGRAM", periogram: PERIOGRAM };
}
