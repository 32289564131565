import axios from "axios";

//Listar tipos de pagamentos
export async function getPaymentTypes() {
  return await axios.post("/api/formaPagamento/listar");
}

//Cadastrar tipos de pagamento
export async function cadastrarTipoPagamento(clinicaId, objeto) {
  var pagamento = await axios
    .post("/api/formaPagamento/cadastrarFormaPagamento", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      tipo: objeto.tipo,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return pagamento;
}

// Buscar tipos de pagamento
export async function buscarTipoPagamento(clinicaId, formaPagamentoId) {
  var pagamento = await axios
    .post("/api/formaPagamento/buscarFormaPagamento", {
      formaPagamento_id: formaPagamentoId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return pagamento;
}

// Editar tipos de pagamento
export async function editarTipoPagamento(objFormaPagamento) {
  var pagamento = await axios
    .put("/api/formaPagamento/editarFormaPagamento", {
      objFormaPagamento,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return pagamento;
}

// Excluir tipo de pagamento
export async function deletarTipoPagamento(objFormaPagamento, clinic_id) {
  objFormaPagamento.clinic_id = clinic_id;

  var pagamento = await axios
    .delete("/api/formaPagamento/deletarFormaPagamento", {
      data: objFormaPagamento,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return pagamento;
}
