import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { buscarProteticos, deletarProtetico } from "../../../../api/proteticos";

import TableComponent from "../../../table/TableComponent";
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";
import DialogEmail from "../../../common/DialogEmail";
class ProteticosListagem extends Component {
  state = {
    proteticos: [],
    openDialogEmail: false,
    emailModels: [],
    listEmails: [],
    variables: [],
  };

  deletaProtetico(objeto) {
    const { clinica } = this.props;
    deletarProtetico(objeto, clinica._id);
  }

  componentWillMount() {
    buscarProteticos(this.props.clinica._id).then((proteticos) => {
      var listaProteticos = [];
      proteticos.length > 0 &&
        proteticos.map((protetico, index) => {
          var novoProtetico = {};
          novoProtetico.codigo = protetico.codigo;
          novoProtetico.proteticoId = protetico._id;
          novoProtetico.nome = protetico.nome;
          novoProtetico.email = protetico.email;
          novoProtetico.telefone = protetico.telefone;
          novoProtetico.funcoes = [
            <Tooltip key={protetico._id} title={"Editar protético"}>
              <IconButton
                onClick={() =>
                  this.props.history.push(
                    `/proteticos/cadastrarProtetico/${protetico._id}`,
                  )
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>,
            <Tooltip title={"Listar Tabela de Serviços"}>
              <IconButton
                onClick={() =>
                  this.props.history.push(`/proteticos/tabela/${protetico._id}`)
                }
              >
                <Pageview />
              </IconButton>
            </Tooltip>,
          ];
          listaProteticos.push(novoProtetico);
        });
      this.setState({ proteticos: listaProteticos });
    });
  }

  handleSelectedRows = (list) => {
    const newList = list.map((item) => {
      return {
        id: item.proteticoId,
        name: item.nome,
        email: item.email,
      };
    });
    this.setState({ listEmails: newList, openDialogEmail: true });
  };

  onCloseDialogEmail = (list) => {
    this.setState({ openDialogEmail: false });
  };

  render() {
    const titulo = "Protéticos",
      colunas = [
        { label: "Codigo", name: "codigo" },
        { label: "Nome", name: "nome" },
        { label: "Email", name: "email" },
        { label: "Telefone", name: "telefone" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.proteticos,
      inserir = {
        nome: "Cadastrar Protético",
        link: "cadastrarProtetico",
      };
    return (
      <>
        <TableComponent
          funcoes={{
            deletar: this.deletaProtetico.bind(this),
            handleSelectedRows: this.handleSelectedRows,
          }}
          titulo={titulo}
          inserir={inserir}
          colunas={colunas}
          objetos={objetos}
          {...this.props}
        />

        {this.state.openDialogEmail && (
          <DialogEmail
            list={this.state.listEmails}
            onClose={this.onCloseDialogEmail}
            typeMentions={["prosthetic", "receiver"]}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ProteticosListagem);
