import axios from "axios";

export async function updatePermissions(userId, permissions) {
  try {
    const response = await axios.patch(
      `/api/user/update_permissions/${userId}`,
      { permissions },
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getUserPermission(userId) {
  try {
    const response = await axios.get(`/api/user/permission/${userId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
