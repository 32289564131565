import axios from "axios";

// Listar Codigos
export async function listarCodigos() {
  var odontogramas = await axios
    .get("/api/odontograma/listarCodigos")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return odontogramas;
}
