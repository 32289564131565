import axios from "axios";

export async function createBudget(patientId, title) {
  return await axios.post(`/api/budget/create`, { patientId, title });
}

export async function deleteBudget(budgetId) {
  return await axios.delete(`/api/budget/${budgetId}`);
}

export async function getBudgets(patientId) {
  const { data } = await axios.get(`/api/budget/list/${patientId}`);
  return data;
}

export async function getBudget(budgetId) {
  return await axios.get(`/api/budget/${budgetId}`);
}

export async function addProcedure(budgetId, procedure) {
  return await axios.post("/api/budget/procedure", {
    budgetId,
    procedure,
  });
}

export async function editProcedure(procedure) {
  return await axios.patch(`/api/budget/procedure/${procedure._id}`, procedure);
}

export async function removeProcedure(procedureId) {
  return await axios.delete(`/api/budget/procedure/${procedureId}`);
}

export async function concluirProcedimento(concluded) {
  return await axios.post(`/api/budget/procedure/conclusion`, concluded);
}

export async function editInterestAndPenalty(budgetId, data) {
  return await axios.patch(`/api/budget/interestAndPenalty/${budgetId}`, data);
}

export async function listParcelsByBudget(budgetId) {
  return await axios.get(`/api/budget/listParcels/${budgetId}`);
}

export async function atualizarParcelas(data) {
  return await axios.put(`/api/budget/parcel/updateAll`, data);
}

export async function parcelPay(payment) {
  return await axios.post(`/api/budget/parcel/pay`, payment);
}

export async function confirmPayment({ budgetId, parcelId, type }) {
  return await axios.post(`/api/budget/parcel/confirmPayment`, {
    budgetId,
    parcelId,
    type,
  });
}

export async function calcSimulation(params) {
  return await axios.post(`/api/budget/simulation`, params);
}

export async function getBudgetResume(budgetId) {
  return await axios.get(`/api/budget/resume/${budgetId}`);
}

export async function getFeeAndPenalty(budgetId, parcelId, type) {
  return await axios.get(`/api/budget/parcel/feeAndPenalty`, {
    params: {
      budgetId,
      parcelId,
      type,
    },
  });
}
