import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Paper,
  Button,
  Typography,
  Container,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { buscarConvenios } from "../../../../api/convenios";
import {
  buscarTabela,
  cadastrarTabela,
  editarTabela,
} from "../../../../api/servicos";
import { showMessageSystem } from "../../../../actions/systemMsg";
import { toast } from "react-toastify";

function ServicosCadastroTabela({ history, showMessageSystem, match }) {
  const [convenios, setConvenios] = useState([]);
  const [formulario, setFormulario] = useState({
    convenio: "",
    indice: "",
    titulo: "",
    ativo: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchConvenios = async () => {
      const convenios = await buscarConvenios();
      setConvenios(convenios);
    };

    const verificaEdicaoCadastro = async () => {
      if (match.params.tabela_id) {
        const tabela = await buscarTabela(match.params.tabela_id);
        if (tabela) {
          setFormulario({
            _id: tabela._id,
            convenio: tabela.convenio ?? "",
            indice: tabela.indice,
            titulo: tabela.titulo,
            ativo: tabela.ativo,
          });
        }
      }
    };
    fetchConvenios();
    verificaEdicaoCadastro();
  }, [match]);

  const handleChangeIndice = (e) => {
    const indice = e.target.value ?? 0;
    setFormulario({ ...formulario, indice });
  };

  const salvarTabela = async () => {
    setIsLoading(true);

    if (!formulario.titulo) {
      toast.error("Necessário preencher o campo título");
      setIsLoading(false);

      return;
    }

    const novaTabela = {
      ...formulario,
      convenio: formulario.convenio?._id,
    };

    try {
      if (formulario._id) {
        await editarTabela(novaTabela);
      } else {
        await cadastrarTabela(novaTabela);
      }

      toast.success("Tabela salva com sucesso.");
      history.push("/servicos");
    } catch (err) {
      toast.error("Erro ao salvar tabela!");
    }
    setIsLoading(false);
  };

  const handleChangeConvenio = (event, newValue) => {
    const convenio = newValue;
    setFormulario({
      ...formulario,
      convenio: convenio,
      indice: convenio?.indice,
    });
  };

  return (
    <Container
      maxWidth="sm"
      component={Paper}
      style={{ padding: "16px" }}
      elevation={0}
    >
      <Typography variant="h6">Dados tabela</Typography>
      <div style={{ marginTop: "12px" }}>
        <TextField
          label="Título"
          type="text"
          value={formulario.titulo}
          onChange={(e) =>
            setFormulario({ ...formulario, titulo: e.target.value })
          }
          required
          fullWidth
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <Autocomplete
          value={formulario.convenio}
          getOptionLabel={(option) => option.nomeConvenio}
          options={convenios}
          renderInput={(params) => <TextField {...params} />}
          onChange={handleChangeConvenio}
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <TextField
          label="Índice (%)"
          type="number"
          value={formulario.indice ?? 0}
          onChange={handleChangeIndice}
          required
          fullWidth
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formulario.ativo}
              color="primary"
              onChange={(e) =>
                setFormulario({ ...formulario, ativo: e.target.checked })
              }
            />
          }
          label="Ativo"
        />
      </div>
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={salvarTabela}
          disabled={isLoading}
        >
          {isLoading ? "Salvando..." : "Salvar"}
        </Button>
      </div>
    </Container>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { showMessageSystem })(
  ServicosCadastroTabela,
);
