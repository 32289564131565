import React, { useEffect, useState } from "react";
import { buscarConta, editarContaAPagar } from "../../../../api/contasPagar";
import {
  Container,
  Grid,
  TextField,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function FormUpdateExpensesPaid({ clinicId, expenseId }) {
  const history = useHistory();
  const [expense, setExpense] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const fetchExpensePaid = await buscarConta(clinicId, expenseId);
      setExpense(fetchExpensePaid);
    };
    fetchData();
  }, []);

  const onSubmit = async () => {
    const message = "Conta atualizada com sucesso!";
    try {
      const data = {
        _id: expense.parcelaId,
        dataVenc: expense.dataVenc,
        observacao: expense.observacao,
        valor: expense.valor,
        contaId: expense.contaId,
      };
      const editar = await editarContaAPagar(data);
      if (!editar) throw Error();
      toast.success(message);
      history.goBack();
    } catch (err) {
      toast.error("Erro ao atualizar conta!");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box padding="16px 0 16px 0">
        <Typography variant="h6">Editar conta à pagar</Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Credor"
            variant="outlined"
            disabled={expense.nome}
            value={expense.nome || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberFormat
            fullWidth
            required
            label="Valor à pagar"
            customInput={TextField}
            variant="outlined"
            value={expense.valor || ""}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"R$"}
            onValueChange={(values) =>
              setExpense({
                ...expense,
                valor: values.floatValue,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              label="Data de pagamento"
              format="dd/MM/yyyy"
              value={expense.dataVenc || null}
              defaultValue={null}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={(date) => {
                setExpense({
                  ...expense,
                  dataVenc: date,
                });
              }}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Observação"
            variant="outlined"
            value={expense.observacao || ""}
            onChange={(e) =>
              setExpense({
                ...expense,
                observacao: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            style={{ marginRight: "16px" }}
            disableElevation
            variant="contained"
          >
            Voltar
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
