import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

import DentistaListagem from "./components/DentistasListagem";
import DentistasConfig from "./components/DentistasConfig";
import ProcedimentosDentistas from "./components/ProcedimentosDentistas";
import DentistasListagemCadastro from "./components/DentistasListagemCadastro";
import EspecialidadeConfigDentistaRelacao from "./components/EspecialidadeConfigDentistaRelacao";
import EspecialidadeVinculaDentista from "./components/EspecialidadeVinculaDentista";
import CategoriaVinculaDentista from "./components/CategoriaVinculaDentista";
import CategoriaConfigDentistaRelacao from "./components/CategoriaConfigDentistaRelacao";
// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GroupIcon from "@material-ui/icons/Group";
import Settings from "@material-ui/icons/Settings";

class DentistasRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "listagem", numero: 0 },
        { url: "arquivados", numero: 1 },
        { url: "configuracoes", numero: 2 },
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Dentistas"
            icon={<GroupIcon />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/config`)}
            label="Configurações"
            icon={<Settings />}
          />
        </BottomNavigation>
        <Paper style={{ minHeight: "83vh", paddingBottom: "20px" }}>
          <Switch>
            <Route
              path={`${match.url}/config`}
              render={(props) => (
                <DentistasConfig
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/listagem`}
              render={(props) => (
                <DentistaListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/cadastrarDentista/:dentista_id?`}
              render={(props) => <DentistasListagemCadastro {...props} />}
            />
            <Route
              exact
              path={`${match.url}/vincularCategoria/:dentista_id?`}
              render={(props) => <CategoriaVinculaDentista {...props} />}
            />
            <Route
              exact
              path={`${match.url}/categoriaDentistaRelacao/:dentista_id`}
              render={(props) => <CategoriaConfigDentistaRelacao {...props} />}
            />
            <Route
              exact
              path={`${match.url}/vincularEspecialidade/:dentista_id?`}
              render={(props) => <EspecialidadeVinculaDentista {...props} />}
            />
            <Route
              exact
              path={`${match.url}/especialidadeDentistaRelacao/:dentista_id`}
              render={(props) => (
                <EspecialidadeConfigDentistaRelacao {...props} />
              )}
            />
            <Route
              exact
              path={`${match.url}/procedimentos/:dentista_id`}
              render={(props) => <ProcedimentosDentistas {...props} />}
            />
            <Route
              exact
              path={`${match.url}/`}
              render={() => <Redirect to={`${match.url}/listagem`} />}
            />
          </Switch>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectDentist: state.patientsCreation.selectDentist,
  };
}
export default connect(mapStateToProps)(DentistasRouter);
