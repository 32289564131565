import { StyleSheet } from "aphrodite/no-important";

import { COLORS } from "../../_constants/colors";

export const styles = StyleSheet.create({
  account: {
    backgroundColor: COLORS.grey85,
    float: "left",
    height: "50px",
    padding: "0px 26px",
    lineHeight: "49px",
    color: COLORS.grey35,
    fontSize: ".75rem",
    fontWeight: "bold",
  },
  labelTop: {
    display: "inline-block",
    fontSize: ".75rem",
    fontWeight: "bold",
    textAlign: "left",
    color: COLORS.grey35,
    textIndent: ".5rem",
    paddingTop: ".7rem",
    paddingRight: ".7rem",
  },
  gridDate: {
    backgroundColor: "#fff",
    height: "50px",
    borderBottom: `1px solid ${COLORS.grey85}`,
    // width: '1900px',
    display: "flex",
    paddingTop: "7px",
    paddingLeft: "1rem",
  },
  addAccount: {
    float: "left",
    height: "50px",
    padding: "6px 26px",
    backgroundColor: "#fff",
    borderBottom: `1px solid ${COLORS.grey85}`,
  },
  actions: {
    display: "flex",
    borderBottom: "1px solid #d9d9d9",
    height: "50px",
    fontSize: ".85rem",
    color: "#595959",
    lineHeight: "49px",
    fontWeight: "bold",
    backgroundColor: "#FFF",
    cursor: "pointer",
  },
  dropdown: {
    borderLeft: "1px solid #d9d9d9",
    borderRight: "1px solid #d9d9d9",
    padding: "0px",
    position: "relative",
  },
  content: {
    display: "flex",
    padding: "0 1rem",
  },
  contentDrop: {
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 10px",
    position: "absolute",
    backgroundColor: "#fff",
    fontWeight: "normal",
    lineHeight: "normal",
    zIndex: 1,
    right: "0px",
    top: "50px",
    width: "max-content",
  },
  itemDrop: {
    padding: ".55rem",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#d9d9d9",
    },
  },
  accountModal: {
    width: "400px",
    ":nth-child(1n) > :last-child": {
      padding: "1rem",
    },
  },
  financeModal: {
    width: "432px",
    maxHeight: "90vh",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    ":nth-child(1n) > :last-child": {
      overflow: "auto",
    },
  },
  fieldset: {
    marginBottom: "1rem",
  },
  label: {
    display: "inline-block",
    marginBottom: ".5rem",
    fontSize: ".75rem",
    fontWeight: "bold",
    float: "left",
    color: COLORS.grey35,
    textIndent: ".5rem",
  },
  labelLine: {
    marginBottom: ".5rem",
    fontSize: ".75rem",
    fontWeight: "bold",
    color: COLORS.grey35,
    paddingLeft: ".5rem",
  },
  textArea: {
    width: "100%",
    padding: ".5rem",
    border: `1px solid #ccc`,
    borderRadius: "3px",
    fontSize: ".875em",
    color: COLORS.grey15,
    ":focus": {
      borderColor: COLORS.blue,
    },
    boxShadow: "inset 0 2px 4px 0 hsla(0,0%,0%, 0.08)",
    resize: "none",
  },
  select: {
    width: "100%",
    padding: ".5rem",
    border: `1px solid ${COLORS.grey65}`,
    webkitBorderRadius: "2px",
    mozBorderRadius: "2px",
    borderRadius: "2px",
    height: "34px",
    fontSize: ".875em",
    backgroundColor: "#fff",
    color: COLORS.grey15,
    ":focus": {
      borderColor: COLORS.blue,
    },
  },
  financeGrid: {
    padding: "1rem",
    display: "grid",
    gridTemplateRows: "1fr auto",
    gridRowGap: "1rem",
  },
  gridRepeat: {
    display: "grid",
    gridTemplateColumns: "1fr calc(180px + .5rem)",
  },
  gridInterval: {
    display: "grid",
    gridTemplateColumns: "80px 100px",
    gridColumnGap: ".5rem",
  },
  gridInfinity: {
    display: "grid",
    gridTemplateColumns: "150px 1fr",
    gridColumnGap: ".5rem",
  },
  gridPortion: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: ".5rem",
  },
});
