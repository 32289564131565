import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import ConveniosListagem from "./components/ConveniosListagem";
import ConveniosCadastro from "./components/ConveniosCadastro";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";

class ConveniosRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [{ url: "listagem", numero: 0 }],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Convenios"
            icon={<CreditCardIcon />}
          />
        </BottomNavigation>
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={0}
          style={{ padding: "16px" }}
        >
          <Switch>
            <Route
              exact
              path={`${match.url}/listagem`}
              render={(props) => (
                <ConveniosListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/cadastrarConvenio/:convenio_id?`}
              render={(props) => <ConveniosCadastro {...props} />}
            />
            <Route
              exact
              path={`${match.url}/`}
              render={() => <Redirect to={`${match.url}/listagem`} />}
            />
          </Switch>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // selectDentist: state.patientsCreation.selectDentist,
  };
}
export default connect(mapStateToProps)(ConveniosRouter);
