import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  listarServicos,
  adicionarServicoTabela,
} from "../../../../api/servicos";

class TabelaCadastro extends Component {
  state = {
    formulario: [],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    tabela_id: "",
    servicos: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.tabela_id) {
      var objServicos = await listarServicos(clinica._id);
      var formulario = this.state.formulario;

      var selectServicos = {
        type: "select",
        name: "servicos",
        label: "Serviços",
        values: [],
      };

      objServicos.map((obj) => {
        var novo = {};
        novo.name = obj.descricao;
        novo.value = obj._id;
        return selectServicos.values.push(novo);
      });

      formulario.push(selectServicos);
      this.setState({
        servicos: objServicos,
        await: false,
        tabela_id: this.props.match.params.tabela_id,
        formulario: formulario,
      });
    } else {
      this.setState({ await: false });
    }
  }

  vinculaServico(objeto) {
    const { tabela_id } = this.state;

    adicionarServicoTabela(tabela_id, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Serviço vinculado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível vincular a tabela!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            action={this.vinculaServico.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(TabelaCadastro);
