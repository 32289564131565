import React from "react";
import PropTypes from "prop-types";

import { css } from "aphrodite/no-important";
import { styles } from "./ViewNavBarStyles";

import { NavLink } from "react-router-dom";
import Icon from "../common/Icon";
import smoothScroll from "../common/SmoothScroll";

const propTypes = {
  /**  The text of the Button */
  match: PropTypes.object.isRequired,
  /**
   * An array of objects containing the info to render the buttons in the nav bar.
   * The object for the links should have the following format: { text: String, path: String, exact: Boolean }
   */
  links: PropTypes.array.isRequired,
  children: PropTypes.object,
};

/**
 * This component is used to implement a navigation bar inside the views of the app
 *
 * /src/components/bars/ViewNavBar.js
 */

class ViewNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.renderLinks = this.renderLinks.bind(this);
    this.scrollButtons = this.scrollButtons.bind(this);
    const { match, links, children } = props;
    this.state = {
      match,
      links,
      children,
      scrollRight: false,
      scrollLeft: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { match, links, children } = nextProps;
    this.setState({ match, links, children });
  }
  componentDidUpdate() {
    this.scrollButtons();
  }
  scrollButtons() {
    if (this.state.links.length > 1) {
      if (
        this.refs.container.scrollLeft + this.refs.container.offsetWidth <
        this.refs.allLinks.offsetWidth
      ) {
        if (!this.state.scrollRight) this.setState({ scrollRight: true });
      } else if (this.state.scrollRight) this.setState({ scrollRight: false });
      if (this.refs.container.scrollLeft > 0) {
        if (!this.state.scrollLeft) this.setState({ scrollLeft: true });
      } else if (this.state.scrollLeft) this.setState({ scrollLeft: false });
    }
  }
  renderLinks() {
    return this.state.links.map((link, index) => {
      return (
        <span key={`link_${index}`}>
          <span ref={`link_${index}`}>
            <NavLink
              className={css(styles.link)}
              activeClassName={css(styles.active)}
              to={`${this.state.match.url}${link.path}`}
              exact={link.exact}
            >
              {link.text}
            </NavLink>
          </span>
        </span>
      );
    });
  }
  scrollLeft() {
    var soma = 16;
    if (this.refs.container.scrollLeft < this.refs.container.offsetWidth)
      smoothScroll.scrollTo(
        this.refs.link_0,
        this.refs.container,
        "horizontal",
        -16,
      );
    else {
      for (
        var i = this.state.links.length - 1;
        soma < this.refs.allLinks.offsetWidth - this.refs.container.scrollLeft;
        i--
      )
        soma += this.refs[`link_${i}`].offsetWidth;
      smoothScroll.scrollTo(
        this.refs[`link_${i + 1}`],
        this.refs.container,
        "horizontal",
        +16 -
          this.refs.container.offsetWidth +
          this.refs[`link_${i + 1}`].offsetWidth,
      );
    }
  }
  scrollRight() {
    var soma = 16;
    if (
      this.refs.allLinks.offsetWidth - this.refs.container.scrollLeft <
      2 * this.refs.container.offsetWidth
    )
      smoothScroll.scrollTo(
        this.refs[`link_${this.state.links.length - 1}`],
        this.refs.container,
        "horizontal",
        +16,
      );
    else {
      for (
        var i = 0;
        soma < this.refs.container.scrollLeft + this.refs.container.offsetWidth;
        i++
      )
        soma += this.refs[`link_${i}`].offsetWidth;
      smoothScroll.scrollTo(
        this.refs[`link_${i - 1}`],
        this.refs.container,
        "horizontal",
        -16 +
          this.refs.container.offsetWidth -
          this.refs[`link_${i - 1}`].offsetWidth,
      );
    }
  }
  render() {
    return (
      <div style={{ display: "flex", minHeight: "50px", minWidth: "690px" }}>
        <div className={css(styles.bar)}>
          {this.state.scrollLeft && (
            <div
              className={css(styles.leftArrow, styles.arrow)}
              onClick={() => this.scrollLeft()}
            >
              <Icon icon="leftArrow" color="grey" size="small" />
            </div>
          )}
          {this.state.scrollRight && (
            <div
              className={css(styles.rightArrow, styles.arrow)}
              onClick={() => this.scrollRight()}
            >
              <Icon icon="rightArrow" color="grey" size="small" />
            </div>
          )}
          <div
            className={css(styles.barScroll)}
            ref="container"
            onScroll={() => this.scrollButtons()}
          >
            <div
              style={{ width: "max-content", padding: ".75rem 1rem" }}
              ref="allLinks"
            >
              {this.renderLinks()}
            </div>
          </div>
        </div>
        {this.state.children}
      </div>
    );
  }
}
ViewNavBar.propTypes = propTypes;

export default ViewNavBar;
