import React, { Component } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../table/TableComponent";
import { Edit, Delete as DeleteIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import {
  deletarContasPagas,
  ListarContasPagas,
} from "../../../../api/contasPagar";
import { IconButton, Button, Paper } from "@material-ui/core";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import SimpleDialog from "../../../dialog/SimpleDialog";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";

const currentDate = new Date();
class ContasPagasListagem extends Component {
  state = {
    contasPagas: [],
    isOpenDialog: false,
    selectedContaId: "",
    selectedParcelaId: "",
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    finalDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ),
  };

  deletaContaPaga = (objeto) => {
    const { clinica } = this.props;
    deletarContasPagas(objeto, clinica._id);
  };

  listarContas = async () => {
    const { startDate, finalDate } = this.state;
    const contasPagas = await ListarContasPagas(
      this.props.clinica._id,
      startDate,
      finalDate
    );
    const listaContasPagas = contasPagas.map((contaPaga, index) => ({
      contasPagar: contaPaga.nome,
      indice: contaPaga.indice,
      valorPago: formataMoedaReal(contaPaga.valorPago),
      contaPagaId: contaPaga.parcelaId,
      dataPagamento: moment(contaPaga.dataPagamento).format("DD/MM/YYYY"),
      observacao: contaPaga.observacao,
      funcoes: [
        <IconButton
          title="Editar"
          onClick={() =>
            this.props.history.push(
              `/contas/cadastrarContasPagas/${contaPaga.parcelaId}`
            )
          }
        >
          <Edit />
        </IconButton>,
        <IconButton
          title="Deletar parcela"
          key={index}
          style={{ maxWidth: 45 }}
          onClick={() => {
            this.setState({
              isOpenDialog: true,
              selectedContaId: contaPaga._id,
              selectedParcelaId: contaPaga.parcelaId,
            });
          }}
        >
          <DeleteIcon />
        </IconButton>,
      ],
    }));
    this.setState({ contasPagas: listaContasPagas });
  };

  async removerConta() {
    try {
      await deletarContasPagas({
        contaId: this.state.selectedContaId,
        parcelaId: this.state.selectedParcelaId,
      });
      toast.success("Pagamento excluído com sucesso!");
      // setTabelas(tabelas.filter((tabela) => tabela._id !== servicoId));
      this.setState({ isOpenDialog: false });
      this.setState({ selectedContaId: "" });
      this.setState({ selectedParcelaId: "" });
    } catch (error) {
      toast.error("Erro ao excluir pagamento!");
    }
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date }, this.listarContas);
  };

  componentDidMount() {
    this.listarContas();
  }

  render() {
    const { startDate, finalDate, contasPagas, isOpenDialog } = this.state;
    const titulo = "Contas Pagas",
      colunas = [
        { label: "Conta Paga", name: "contasPagar" },
        { label: "Nº da Parcela", name: "indice" },
        { label: "Valor Pago", name: "valorPago" },
        { label: "Data Pagamento", name: "dataPagamento" },
        { label: "Observação", name: "observacao" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      inserir = {
        nome: "Cadastrar Contas Pagas",
        link: "cadastrarContasPagas",
      };
    return (
      <>
        <Paper style={{ padding: "16px" }}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Inicial"
              format="dd/MM/yyyy"
              style={{
                marginBottom: "30px",
                marginRight: "10px",
                marginLeft: "170px",
                marginTop: "10px",
              }}
              inputVariant="outlined"
              value={startDate}
              onChange={(date) => this.handleDateChange(date, "startDate")}
            />
            <KeyboardDatePicker
              label="Data Final"
              format="dd/MM/yyyy"
              style={{ marginBottom: "30px", marginTop: "10px" }}
              inputVariant="outlined"
              value={finalDate}
              onChange={(date) => this.handleDateChange(date, "finalDate")}
            />
          </MuiPickersUtilsProvider>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{
              marginLeft: "10px",
              marginBottom: "30px",
              marginTop: "10px",
            }}
            onClick={this.listarContas}
          >
            Buscar
          </Button>
          <TableComponent
            funcoes={{ deletar: this.deletaContaPaga.bind(this) }}
            titulo={titulo}
            inserir={inserir}
            colunas={colunas}
            objetos={contasPagas}
            {...this.props}
          />
        </Paper>
        <SimpleDialog
          open={this.state.isOpenDialog}
          title="Deseja excluir a tabela?"
          content="Ao excluir a tabela a operação não poderá ser desfeita."
          closeButtonTitle="Cancelar"
          submitButtonTitle="Excluir"
          handleClose={() => this.setState({ isOpenDialog: false })}
          handleSubmit={this.removerConta}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ContasPagasListagem);
