import React from "react";
import { Redirect, Route } from "react-router-dom";

import { css } from "aphrodite/no-important";
import { gridStyles } from "../DashboardStyles";

import ViewNavBar from "../../bars/ViewNavBar";

import ClinicUploadPatients from "./ClinicUploadPatients";
import ClinicUploadDentist from "./ClinicUploadDentist";
import ClinicUploadContracts from "./ClinicUploadContracts";
import ClinicUploadTableProsthetics from "./ClinicUploadTableProsthetics";
import ClinicUploadProsthetics from "./ClinicUploadProsthetics";
import ClinicUploadTableServices from "./ClinicUploadTableServices";
import ClinicUploadServices from "./ClinicUploadServices";
import ClinicUploadContasPagar from "./ClinicUploadContasPagar";
import ClinicUploadContasPagas from "./ClinicUploadContasPagas";

const LINKS = [
  { text: "Pacientes", path: "/upload-patients", exact: true },
  { text: "Dentistas", path: "/upload-dentists" },
  { text: "Convênio", path: "/upload-contract" },
  { text: "Protéticos", path: "/upload-prosthetics" },
  { text: "Tabela protéticos", path: "/upload-table-prosthetics" },
  { text: "Serviços", path: "/upload-services" },
  { text: "Tabelas de serviços", path: "/upload-table-services" },
  { text: "Contas a Pagar", path: "/upload-contas-pagar" },
  { text: "Contas Pagas", path: "/upload-contas-pagas" },
];

function ConfigurationRouter(props) {
  const { match, history } = props;

  return (
    <div className={css(gridStyles.flex)}>
      <ViewNavBar history={history} match={match} links={LINKS} />
      <Route
        path={`${match.url}/upload-patients`}
        component={ClinicUploadPatients}
      />
      <Route
        path={`${match.url}/upload-dentists`}
        component={ClinicUploadDentist}
      />
      <Route
        path={`${match.url}/upload-contract`}
        component={ClinicUploadContracts}
      />
      <Route
        path={`${match.url}/upload-table-prosthetics`}
        component={ClinicUploadTableProsthetics}
      />
      <Route
        path={`${match.url}/upload-prosthetics`}
        component={ClinicUploadProsthetics}
      />
      <Route
        path={`${match.url}/upload-table-services`}
        component={ClinicUploadTableServices}
      />
      <Route
        path={`${match.url}/upload-services`}
        component={ClinicUploadServices}
      />
      <Route
        path={`${match.url}/upload-contas-pagar`}
        component={ClinicUploadContasPagar}
      />
      <Route
        path={`${match.url}/upload-contas-pagas`}
        component={ClinicUploadContasPagas}
      />
      <Route
        exact
        path={`${match.url}`}
        render={() => <Redirect to={`${match.url}/upload-patients`} />}
      />
    </div>
  );
}

export default ConfigurationRouter;
