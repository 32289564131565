import React, { Component } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../table/TableComponent";

import {
  deletarTabelaPreco,
  buscarTabelaPrecos,
} from "../../../../api/proteticos";

import Edit from "@material-ui/icons/Edit";

class TabelaPrecoListagem extends Component {
  state = {
    tabelaPrecos: [],
  };

  deletaTabelaPreco(objeto) {
    const { clinica } = this.props;

    deletarTabelaPreco(objeto, clinica._id);
  }

  componentWillMount() {
    buscarTabelaPrecos(this.props.clinica._id).then((tabelaPrecos) => {
      var listaTabelaPrecos = [];
      tabelaPrecos.length > 0 &&
        tabelaPrecos.map((tabelaPreco, index) => {
          var novaTabela = {};
          novaTabela.codigo = tabelaPreco.codigo;
          novaTabela.tabelaPrecoId = tabelaPreco._id;
          novaTabela.descricao = tabelaPreco.descricao;
          novaTabela.valorIndice = tabelaPreco.valorIndice;
          novaTabela.valor = tabelaPreco.valor;
          novaTabela.funcoes = [
            <Edit
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/proteticos/cadastrarTabelaPreco/${tabelaPreco._id}`,
                )
              }
            />,
          ];

          listaTabelaPrecos.push(novaTabela);
        });

      this.setState({ tabelaPrecos: listaTabelaPrecos });
    });
  }

  render() {
    const titulo = "Tabela Serviços Protéticos",
      colunas = [
        { label: "Codigo", name: "codigo" },
        { label: "Descrição", name: "descricao" },
        { label: "Valor Índice", name: "valorIndice" },
        { label: "Valor", name: "valor" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.tabelaPrecos,
      inserir = {
        nome: "Cadastrar Tabela Servico",
        link: "cadastrarTabelaPreco",
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaTabelaPreco.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(TabelaPrecoListagem);
