export const COLORS = {
  primary: "#003399",
  secondary: "#d9d9d9",
  green: "#33cc99",
  green2: "#42C0B8",
  greenDark: "#009927",
  greenDark2: "#3cada5",
  red: "#CC4C29",
  redDark: "#B23412",
  blue: "#2995CC",
  blueDark: "#006599",
  grey85: "#d9d9d9",
  grey77: "#c4c4c4",
  grey65: "#a6a6a6",
  grey50: "#7f7f7f",
  grey35: "#595959",
  grey25: "#404040",
  grey15: "#262626",
  gradient: "linear-gradient(135deg, #003399 0%, #33cc99 100%)",
  white: "#FFF",
};

export const PALLETE = {
  primary: {
    light: "#085AFB",
    lighter: "#0043C7",
    main: "#003399",
    dark: "#00297B",
    darker: "#001F5B",
  },
};
