import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { listServices } from "../../../api/servicos";
import { toast } from "react-toastify";
import { buscarDentistas } from "../../../api/dentistas";
import { listMedicalRecord } from "../../../api/medical_record";
import { buscarPacientes } from "../../../api/pacientes";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import MedicalRecordReportPDF from "./medical_record_report_pdf";
import { facesEnum, teeth } from "../../../utils/teeth";
import { pdf } from "@react-pdf/renderer";
import { formataMoedaReal } from "../../../helpers/Helpers";
import moment from "moment";

const defaultFilters = {
  service: "",
  dentist: "",
  patient: "",
  initialDate: null,
  finalDate: null,
  teeth: [],
  faces: [],
  type: "and",
  gender: "",
};

const defaultHeader = [
  { name: "patient", label: "Paciente", checked: true },
  { name: "codService", label: "Cód. Serv.", checked: false },
  { name: "service", label: "Serviço", checked: true },
  { name: "dentist", label: "Dentista", checked: true },
  { name: "teeth", label: "Dentes", checked: true },
  { name: "birthday", label: "D. Nasc.", checked: false },
  { name: "treatmentDate", label: "D. Trat.", checked: false },
  { name: "age", label: "Idade", checked: false },
  { name: "gender", label: "Gen.", checked: false },
  // { name: "situation", label: "Situação", checked: false },
  { name: "value", label: "Valor", checked: true },
];

export default function MedicalRecordReport() {
  const [filters, setFilters] = useState(defaultFilters);
  const [services, setServices] = useState([]);
  const [dentists, setDentists] = useState([]);
  const [patients, setPatients] = useState([]);
  const [pdfHeader, setPdfHeader] = useState(defaultHeader);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [promise1, promise2, promise3] = await Promise.all([
          listServices(),
          buscarDentistas(),
          buscarPacientes(),
        ]);
        setServices(promise1.data);
        setDentists(promise2);
        setPatients(promise3);
      } catch (error) {
        toast.error("Erro ao obter dados!");
      }
    };
    fetchData();
  }, []);

  const onSubmit = async () => {
    try {
      const params = {
        serviceId: filters.service?._id ?? "",
        dentistId: filters.dentist?._id ?? "",
        patientId: filters.patient?._id ?? "",
        initialDate: filters.initialDate ?? "",
        teeth: filters.teeth,
        faces: filters.faces,
        finalDate: filters.finalDate ?? "",
        type: filters.type,
        gender: filters.gender,
      };

      const report = await listMedicalRecord(params);

      if (!report.data) {
        return toast.error("Nenhum resultado encontrado!");
      }

      const { data, totalPatients, totalRecords } = report.data;

      const pdfData = data.map((elem) => {
        return {
          patient: `${elem.patient.name} ${elem.patient.lastName}`,
          service: elem.service.description,
          dentist: elem.dentist.name,
          gender: elem.patient.gender
            ? elem.patient.gender === "m"
              ? "Masc."
              : "Fem."
            : "",
          birthday: elem.patient.birthday
            ? moment(elem.patient.birthday).format("DD/MM/YYYY")
            : "",
          treatmentDate: elem.patient.dateFirstAppoiment
            ? moment(elem.patient.dateFirstAppoiment).format("DD/MM/YYYY")
            : "",
          value: formataMoedaReal(elem.value),
          teeth: elem.teeth.map((tooth) => {
            return `${tooth.tooth} ${tooth.faces.toString()}`;
          }),
        };
      });

      const header = pdfHeader.filter((elem) => elem.checked);

      const blob = await pdf(
        <MedicalRecordReportPDF
          data={{ pdfData, totalPatients, totalRecords }}
          header={header}
        />,
      ).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Erro ao gerar relatório");
    }
  };

  const handleChangePdfHeader = (name, value) => {
    const index = pdfHeader.findIndex((header) => header.name === name);
    const nextPdfHeader = [...pdfHeader];
    nextPdfHeader[index].checked = value;
    setPdfHeader(nextPdfHeader);
  };

  return (
    <>
      <Typography variant="h5" fontWeight="bold">
        Estatística do Prontuário
      </Typography>
      <Container
        component={Paper}
        style={{ padding: "16px", marginTop: "24px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              loading
              value={filters.service}
              onChange={(_, newValue) => {
                setFilters({
                  ...filters,
                  service: newValue,
                });
              }}
              options={services}
              getOptionLabel={(option) => option.description || ""}
              renderOption={(option) => <span>{option.description}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Serviço"}
                  // error={error.tabela}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              loading
              value={filters.dentist}
              onChange={(_, newValue) => {
                setFilters({
                  ...filters,
                  dentist: newValue,
                });
              }}
              options={dentists}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(option) => <span>{option.name}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Dentista"}
                  // error={error.tabela}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              loading
              value={filters.patient}
              onChange={(_, newValue) => {
                setFilters({
                  ...filters,
                  patient: newValue,
                });
              }}
              options={patients}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(option) => <span>{option.name}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Paciente"}
                  // error={error.tabela}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                label="Desde a data"
                format="dd/MM/yyyy"
                value={filters.initialDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => {
                  setFilters({ ...filters, initialDate: date });
                }}
                inputVariant="outlined"
                // error={errors.concludedDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                label="Até a data"
                format="dd/MM/yyyy"
                value={filters.finalDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => {
                  setFilters({ ...filters, finalDate: date });
                }}
                inputVariant="outlined"
                // error={errors.concludedDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              value={filters.teeth}
              options={teeth}
              onChange={(_, newValue) => {
                setFilters({ ...filters, teeth: newValue });
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label={"Dentes"} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              value={filters.faces}
              options={facesEnum}
              onChange={(_, newValue) => {
                setFilters({ ...filters, faces: newValue });
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Faces" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Gênero"
              variant="outlined"
              fullWidth
              value={filters.gender}
              onChange={(e) =>
                setFilters({ ...filters, gender: e.target.value })
              }
            >
              <MenuItem value={""}>Nenhum</MenuItem>
              <MenuItem value={"m"}>Masculino</MenuItem>
              <MenuItem value={"f"}>Feminino</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Tipo pesquisa"
              value={filters.type}
              variant="outlined"
              fullWidth
              onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            >
              <MenuItem value={"and"}>E</MenuItem>
              <MenuItem value={"or"}>OU</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {pdfHeader.map((header) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={header.checked}
                    onChange={(e) =>
                      handleChangePdfHeader(header.name, e.target.checked)
                    }
                  />
                }
                label={header.label}
              />
            ))}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onSubmit}
            >
              Gerar relatório
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
