import React, { useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import OrcamentosPendentes from "./OrcamentosPendentes";
import FinanceiroPaciente from "./FinanceiroPaciente";
import FinanceiroPacienteResumo from "./FinanceiroPacienteResumo";

const FinanceiroPacientesRouter = ({ match, history }) => {
  const [value, setValue] = useState(0);

  const menu = [
    {
      url: "/resumo",
      label: "Resumo",
      componente: FinanceiroPacienteResumo,
    },
    {
      url: "/faturas",
      // icon: <AttachMoney />,
      label: "Faturas",
      componente: FinanceiroPaciente,
    },
    {
      url: "/orcamentosPendentes",
      // icon: <HourglassEmpty />,
      label: "Orçamentos pendentes",
      componente: OrcamentosPendentes,
    },
  ];

  return (
    <>
      <BottomNavigation
        className="navbar"
        showLabels
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
      >
        {menu.map((item, idx) => (
          <BottomNavigationAction
            key={idx}
            onClick={() => history.push(match.url + item.url)}
            icon={item.icon}
            label={item.label}
          />
        ))}
        ;
      </BottomNavigation>
      <Switch>
        {menu.map((item, idx) => (
          <Route
            key={idx}
            path={match.url + item.url}
            component={item.componente}
          />
        ))}
        ;
      </Switch>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(FinanceiroPacientesRouter);
