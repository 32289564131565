import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarDentista,
  buscarDentista,
  editarDentista,
} from "../../../../api/dentistas";
import { validaCpf, validaEmail } from "../../../../helpers/Helpers";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogEmail from "../../../common/DialogEmail";
import { toast } from "react-toastify";

class DentistasListagemCadastro extends Component {
  state = {
    disabledButton: false,
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "name", label: "Nome" },
      { type: "mask", name: "cpf", label: "CPF", maskDesign: "999.999.999-99" },
      {
        type: "maskVariant",
        name: "rg",
        label: "RG",
      },
      { type: "mask", name: "cep", label: "CEP", maskDesign: "99999-999" },
      { type: "text", name: "uf", label: "UF" },
      { type: "text", name: "city", label: "Cidade" },
      { type: "text", name: "adress", label: "Endereço Residencial" },
      { type: "number", name: "cro", label: "CRO" },
      { type: "number", name: "partic", label: "Particip.(%)" },
      { type: "date", name: "birthday", label: "Data de Nascimento" },
      {
        type: "maskVariant",
        name: "telephone",
        label: "Fone",
        maskDesign: ["(00) 0000-0000", "(00) 0 0000-0000"],
      },
      { type: "text", name: "email", label: "E-mail" },
      {
        type: "multiplo",
        name: "schedule",
        label: "Grade de horário",
        colunas: [
          {
            title: "Dia da semana",
            field: "scheduleDay",
            validate: (rowData) => rowData.hasOwnProperty("scheduleDay"),
            lookup: {
              sun: "Domingo",
              mon: "Segunda",
              tue: "Terça",
              wed: "Quarta",
              thu: "Quinta",
              fri: "Sexta",
              sat: "Sábado",
            },
          },
          {
            title: "Entrada",
            field: "start",
            initialEditValue: new Date(),
            render: (hour) =>
              new Date(hour.start).toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            editComponent: (hour) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  ampm={false}
                  id="time-picker"
                  label="Entrada"
                  value={hour.value}
                  onChange={(e) => hour.onChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            ),
          },
          {
            title: "Saída",
            field: "end",
            initialEditValue: new Date(),
            render: (hour) =>
              new Date(hour.end).toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            editComponent: (hour) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  ampm={false}
                  id="time-picker-end"
                  label="Saída"
                  value={hour.value}
                  onChange={(e) => hour.onChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            ),
          },
        ],
      },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    dentista: "",
    cadastro: true,
    await: true,
    openDialogEmail: false,
    emailModels: [],
    mentions: [],
  };

  validaCampos(objeto) {
    var update = false;
    var itens = {};

    this.state.formulario.forEach((element, index) => {
      itens[element.name] = index;
      this.state.formulario[index].error = "";
    });

    if (objeto.name == "") {
      var update = true;
      this.state.formulario[itens.name].error = "Favor inserir um nome";
    }

    if (objeto.cpf) {
      if (validaCpf(objeto.cpf) === false) {
        var update = true;
        this.state.formulario[itens.cpf].error = "CPF inválido";
      }
    }
    if (!objeto.email) {
      var update = true;
      this.state.formulario[itens.email].error = "Favor inserir email";
    }
    if (objeto.email) {
      if (validaEmail(objeto.email) === false) {
        var update = true;
        this.state.formulario[itens.email].error = "E-mail inválido";
      }
    }

    if (update) {
      this.setState({
        alerta: {
          tipo: "error",
          msg: "Verifique todos os campos e tente novamente!",
          abrir: true,
        },
      });
      setTimeout(() => this.setState({ alerta: { abrir: false } }), 2000);
      return false;
    }

    return true;
  }

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.dentista_id) {
      var objDentista = await buscarDentista(
        clinica._id,
        this.props.match.params.dentista_id,
      );

      var schedule = [];

      if (objDentista.schedule) {
        var dias = Object.keys(objDentista.schedule).map((i) => ({
          [i]: objDentista.schedule[i],
        }));

        dias.map((dia, index) =>
          dia[Object.keys(objDentista.schedule)[index]].map((horario) =>
            schedule.push({
              start: new Date(horario.start),
              end: new Date(horario.end),
              scheduleDay: Object.keys(objDentista.schedule)[index],
              _id: horario._id,
            }),
          ),
        );
      }

      objDentista.schedule = schedule;

      this.setState({ cadastro: false, dentista: objDentista, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  editaDentista(objeto) {
    this.setState({ disabledButton: true });
    if (!this.validaCampos(objeto)) {
      this.setState({ disabledButton: false });
      return false;
    }

    objeto.telephone = objeto.telephone
      .toString()
      .split(/[.,\/ \/-]/)
      .toString()
      .replace(/,/g, "");

    var schemaSchedule = {
      sun: [],
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
    };

    objeto.schedule.map((schedule) => {
      schemaSchedule[schedule.scheduleDay].push(schedule);
    });

    objeto.schedule = schemaSchedule;

    editarDentista(objeto)
      .then((r) => {
        if (r) {
          toast.success("Dentista atualizado com sucesso.");
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o dentista!",
              abrir: true,
            },
          });
        }
      })
      .then(() =>
        this.setState({ disabledButton: false, alerta: { abrir: false } }),
      );
  }

  salvarDentista(objeto) {
    const { clinica } = this.props;
    this.setState({ disabledButton: true });
    if (!this.validaCampos(objeto)) {
      this.setState({ disabledButton: false });
      return false;
    }

    objeto.telephone = objeto.telephone
      .toString()
      .split(/[.,\/ \/-]/)
      .toString()
      .replace(/,/g, "");

    var schemaSchedule = {
      sun: [],
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
    };

    objeto.schedule.length > 0 &&
      objeto.schedule.map((schedule) => {
        schemaSchedule[schedule.scheduleDay].push(schedule);
      });

    objeto.schedule = schemaSchedule;

    cadastrarDentista(clinica, objeto)
      .then((r) => {
        if (r) {
          toast.success("Dentista cadastrado com sucesso.");
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o dentista!",
              abrir: true,
            },
          });
        }
      })
      .then(() =>
        this.setState({ disabledButton: false, alerta: { abrir: false } }),
      );
  }

  onCloseDialogEmail = () => {
    this.setState({ openDialogEmail: false });
  };

  handleSendEmail = () => {
    this.setState({ openDialogEmail: true });
  };

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            emailButton={{
              show: this.props.match.params.dentista_id ? true : false,
              handleSendEmail: this.handleSendEmail,
            }}
            inputs={this.state.formulario}
            objeto={this.state.dentista}
            disabledAction={this.state.disabledButton}
            action={
              this.state.cadastro
                ? this.salvarDentista.bind(this)
                : this.editaDentista.bind(this)
            }
          />
        )}
        {this.state.openDialogEmail && (
          <DialogEmail
            list={[this.state.dentista]}
            onClose={this.onCloseDialogEmail}
            typeMentions={["dentist", "receiver"]}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DentistasListagemCadastro);
