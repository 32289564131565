import React, { useEffect, useState } from "react";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { getUserPermission, updatePermissions } from "../../../../api/user";
import { toast } from "react-toastify";

const defaultPermissions = [
  {
    label: "Paciente",
    name: "patient",
    roles: [
      {
        label: "Informações do Paciente",
        name: "info",
        view: false,
        edit: false,
        delete: false,
      },
      {
        label: "Orcamentos",
        name: "budget",
        view: false,
        edit: false,
        delete: false,
      },
    ],
  },
];

export const PermissionsForm = (props) => {
  const { userId, onClose, open } = props;
  const [permissions, setPermissions] = useState(defaultPermissions);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserPermission(userId);

      if (data?.permissions && data.permissions.length > 0) {
        const populatePermissions = permissions.map((permission) => {
          let userPermission = data.permissions.find(
            (element) => element.name === permission.name,
          );

          if (userPermission) {
            const newRoles = permission.roles.map((role) => {
              const userRole = userPermission.roles.find(
                (elementRole) => elementRole.name === role.name,
              );

              if (userRole) {
                return {
                  ...role,
                  view: userRole.view ?? false,
                  edit: userRole.edit ?? false,
                  delete: userRole.delete ?? false,
                };
              }

              return role;
            });
            permission.roles = newRoles;
          }
          return permission;
        });
        setPermissions(populatePermissions);
      }
    };

    fetchData();
  }, []);

  const changeCheckedPermission = (
    indexPermission,
    indexRole,
    prop,
    checked,
  ) => {
    const newPermissions = [...permissions];
    newPermissions[indexPermission].roles[indexRole][prop] = checked;
    setPermissions(newPermissions);
  };

  const onSubmit = async () => {
    const result = await updatePermissions(userId, permissions);
    if (!result) {
      toast.error("Erro ao atualizar as permissões");
    }
    toast.success("Permissões atualizadas com sucesso!");
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Permissões</DialogTitle>
      <DialogContent>
        {permissions.map((permission, permissionIndex) => (
          <ExpansionPanel key={permissionIndex}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography>{permission.label}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Visualizar</TableCell>
                    <TableCell>Editar</TableCell>
                    <TableCell>Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permission.roles.map((role, roleIndex) => (
                    <TableRow key={roleIndex}>
                      <TableCell>{role.label}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.view}
                          onChange={(e) =>
                            changeCheckedPermission(
                              permissionIndex,
                              roleIndex,
                              "view",
                              e.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.edit}
                          onChange={(e) =>
                            changeCheckedPermission(
                              permissionIndex,
                              roleIndex,
                              "edit",
                              e.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={role.delete}
                          onChange={(e) =>
                            changeCheckedPermission(
                              permissionIndex,
                              roleIndex,
                              "delete",
                              e.target.checked,
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          onClick={() => onSubmit()}
          color="primary"
          variant="contained"
          disableElevation
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
