import React, { Component } from "react";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { styles } from "./financeAccountStyles";

import { createAccount, getFinances } from "../../../actions/financeAccount";

import ViewNavBar from "../../bars/ViewNavBar";
import DateTimePicker from "../../forms/date/DateTimePicker";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import InputField from "../../forms/InputField";
import Modal from "../../modals/Modal";
import AutoCompleteByProps from "../../forms/AutoCompleteByProps";
import Toggle from "../../common/Toggle";

class financeAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    var params = props.match.params;
    this.state = {
      document: "",
      credor: "",
      category: "",
      paymentMethod: "",
      description: "",
      maturity: new Date(),
      amount: "R$ 00,00",
      repeat: {
        will: false,
        interval: {
          qtd: 1,
          rule: "day",
        },
        infinity: {
          will: false,
          portion: 2,
          paidPortion: 1,
        },
      },
      rules: [
        { value: "day", label: "Dia" },
        { value: "week", label: "Semana" },
        { value: "month", label: "Mês" },
        { value: "year", label: "Ano" },
      ],
      paymentMethods: [
        { value: "", label: "" },
        { value: "ticket", label: "Boleto" },
        { value: "creditCard", label: "Cartão de Crédito" },
        { value: "debitCard", label: "Cartão de Débito" },
        { value: "check", label: "Cheque" },
        { value: "automaticDebit", label: "Débito Automático" },
        { value: "inCash", label: "Em Dinheiro" },
        { value: "promissory", label: "Promissória" },
        { value: "transfer", label: "Transferência" },
        { value: "deposit", label: "Depósito" },
      ],
      LINKS_TYPE: [],
      LINKS_ACCOUNTS: [],
      dropOpen: false,
      showAccountModal: false,
      showFinanceModal: false,
      openingBalance: "R$ 00,00",
      accountName: "",
      accounts: [],
      balance: 0,
    };
  }
  componentWillReceiveProps(nextProps) {
    var params = nextProps.match.params,
      change = false,
      entries = Object.entries(params);
    const { getFinances } = this.props;
    getFinances(params.account, params.type, params.since, params.to, (res) => {
      console.log(res);
      var balance = 0;
      if (params.account === "all")
        res.accounts.map((item) => (balance += item.balance));
      else
        balance = {
          ...res.accounts.find((item) => params.account === item._id),
        }.balance;
      this.setState({
        balance,
        LINKS_TYPE: [
          {
            text: "Geral",
            path: `/general/${params.account}/${params.since}/${params.to}`,
          },
          {
            text: "Receitas",
            path: `/payments/${params.account}/${params.since}/${params.to}`,
          },
          {
            text: "Despesas",
            path: `/bills/${params.account}/${params.since}/${params.to}`,
          },
        ],
        LINKS_ACCOUNTS: [
          {
            text: "Todas as contas",
            path: `/${params.type}/all/${params.since}/${params.to}`,
          },
          ...res.accounts.map((item) => {
            return {
              text: item.name,
              path: `/${params.type}/${item._id}/${params.since}/${params.to}`,
            };
          }),
        ],
      });
    });
    // }
  }
  formatBR(v) {
    if (!v) {
      v = "";
    }

    v = v.toString();
    v = v.replace(/\D/g, "");

    while (v.length < 4) {
      v = "0" + v;
    }
    if (v.charAt(0) === "0" && v.length >= 5) {
      v = v.substr(1);
    }
    if (v.length >= 3) {
      v = v.replace(/(\d{1,2})$/, ",$1");
    }
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    v = v != "" ? "R$ " + v : "R$ ";
    return v;
  }
  handleOutsideClick(e) {
    if (
      this.refs.dropDown === null ||
      this.refs.dropDown.contains(e.target.parentNode)
    )
      return;
    if (this.state.dropOpen !== false) this.setState({ dropOpen: false });
    document.removeEventListener("click", this.handleOutsideClick, false);
  }
  render() {
    const {
      history,
      match: { params },
    } = this.props;
    return (
      <div>
        <div>
          <div className={css(styles.account)}>
            Saldo: {this.formatBR(this.state.balance)}
          </div>
          <ViewNavBar
            history={history}
            match={{ url: "/finances" }}
            links={this.state.LINKS_TYPE}
          >
            <div className={css(styles.gridDate)}>
              <label className={css(styles.labelTop)}>
                Visualizar registros do dia
              </label>
              <DateTimePicker
                audit={true}
                name="initDate"
                value={new Date(`${params.since}T03:00:00.000Z`)}
                onChange={() => {}}
              />
              <label className={css(styles.labelTop)}>até</label>
              <DateTimePicker
                audit={true}
                name="endDate"
                value={
                  params.to === "infinite"
                    ? ""
                    : new Date(`${params.to}T03:00:00.000Z`)
                }
                onChange={() => {}}
              />
            </div>
          </ViewNavBar>
        </div>
        <div>
          <div className={css(styles.addAccount)}>
            <Button
              text="Criar Conta"
              color="primary"
              onClick={() =>
                this.setState({
                  showAccountModal: true,
                  openingBalance: "R$ 00,00",
                })
              }
            />
            <Button
              text={`Adicionar ${this.props.match.params.type === "payments" ? "Receita" : this.props.match.params.type === "bills" ? "Despesa" : "Finança"}`}
              color="green"
              onClick={() => this.setState({ showFinanceModal: true })}
            />
          </div>
          <ViewNavBar
            history={history}
            match={{ url: "/finances" }}
            links={this.state.LINKS_ACCOUNTS}
          >
            <div className={css(styles.actions)}>
              <div ref={"dropDown"} className={css(styles.dropdown)}>
                <div
                  className={css(styles.action, styles.content)}
                  onClick={() => {
                    if (!this.state.dropOpen)
                      document.addEventListener(
                        "click",
                        this.handleOutsideClick,
                        false,
                      );
                    this.setState((prevState) => {
                      return { dropOpen: !prevState.dropOpen };
                    });
                  }}
                >
                  <div style={{ marginRight: "0.3rem" }}>Ações</div>
                  {this.state.dropOpen ? (
                    <Icon icon="upArrow" color="grey" size="small" />
                  ) : (
                    <Icon icon="downArrow" size="small" color="grey" />
                  )}
                </div>
                {this.state.dropOpen ? (
                  <div className={css(styles.contentDrop)}>
                    <div className={css(styles.itemDrop)} onClick={() => {}}>
                      Emitir Recibo
                    </div>
                    <div className={css(styles.itemDrop)}>
                      Emitir Autorização
                    </div>
                    <div className={css(styles.itemDrop)}>Remover</div>
                  </div>
                ) : null}
              </div>
            </div>
          </ViewNavBar>
        </div>
        <Modal
          isOpen={this.state.showAccountModal}
          header={"Criar Conta"}
          adjustStyle={styles.accountModal}
        >
          <div>
            <InputField
              label={"Nome da conta"}
              input={{
                onChange: (e) => (this.state.accountName = e.target.value),
              }}
            />
            <InputField
              type={"money"}
              label={"Saldo inicial"}
              input={{
                onChange: (e) =>
                  this.setState({ openingBalance: e.target.value }),
                value: this.state.openingBalance,
              }}
            />
            <Button
              text="Cancelar"
              color="secondary"
              onClick={() => this.setState({ showAccountModal: false })}
            />
            <Button
              right
              text="Salvar"
              color="green"
              onClick={() =>
                this.props.createAccount(
                  {
                    name: this.state.accountName,
                    balance:
                      parseFloat(
                        this.state.openingBalance
                          .substr(3)
                          .replace(/[.,\s]/g, ""),
                      ) / 100,
                  },
                  (res) => {
                    const { _id, name, balance } = res,
                      {
                        match: { params },
                      } = this.props;
                    this.state.accounts.push({ _id, name, balance });
                    this.state.LINKS_ACCOUNTS.push({
                      text: name,
                      path: `/${params.type}/${_id}/${params.since}/${params.to}`,
                    });
                    this.setState({ showAccountModal: false });
                  },
                )
              }
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showFinanceModal}
          header={"Criar Conta"}
          adjustStyle={styles.financeModal}
        >
          <div className={css(styles.financeGrid)}>
            <div>
              <InputField
                label={"Nº do documento"}
                input={{
                  onChange: (e) => (this.state.document = e.target.value),
                }}
              />
              <AutoCompleteByProps
                label={"Credor"}
                results={[
                  { _id: "1", name: "Pedro" },
                  { _id: "2", name: "Emerson" },
                  { _id: "3", name: "Lucas" },
                ]}
                value={this.state.credor}
                onSelect={(res) => this.setState({ credor: res.value })}
              />
              <AutoCompleteByProps
                label={"Categoria"}
                results={[
                  { _id: "1", name: "Pedro" },
                  { _id: "2", name: "Emerson" },
                  { _id: "3", name: "Lucas" },
                ]}
                value={this.state.credor}
                onSelect={(res) => this.setState({ category: res.value })}
              />
              <fieldset className={css(styles.fieldset)}>
                <label className={css(styles.label)}>Método de pagamento</label>
                <select
                  className={css(styles.select)}
                  onChange={(e) => (this.state.paymentMethod = e.target.value)}
                >
                  {this.state.paymentMethods.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </fieldset>
              <fieldset className={css(styles.fieldset)}>
                <label className={css(styles.label)}>Descrição</label>
                <textarea
                  className={css(styles.textArea)}
                  rows="3"
                  onChange={(e) => (this.state.description = e.target.value)}
                ></textarea>
              </fieldset>
              <DateTimePicker
                label={"Data de vencimento"}
                name="maturity"
                value={this.state.maturity}
                onChange={(value) => this.setState({ maturity: value })}
              />
              <div className={css(styles.gridRepeat)}>
                <div>
                  <Toggle
                    label={"Repetir"}
                    defaultValue={this.state.repeat.will}
                    change={(e) => {
                      this.setState((prevState) => {
                        return { repeat: { ...prevState.repeat, will: e } };
                      });
                    }}
                  />
                </div>
                {this.state.repeat && (
                  <div>
                    <div className={css(styles.labelLine)}>Intervalo</div>
                    <div className={css(styles.gridInterval)}>
                      <input
                        type="number"
                        className={css(styles.select)}
                        onChange={(e) =>
                          (this.state.repeat.interval.qtd = e.target.value)
                        }
                        defaultValue={"1"}
                      />
                      <select
                        className={css(styles.select)}
                        onChange={(e) =>
                          (this.state.repeat.interval.rule = e.target.value)
                        }
                      >
                        {this.state.rules.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <InputField
                type={"money"}
                label={"Valor" + (this.state.repeat ? " da parcela" : "")}
                input={{
                  onChange: (e) => this.setState({ amount: e.target.value }),
                  value: this.state.amount,
                }}
              />
              {this.state.repeat && (
                <div className={css(styles.gridInfinity)}>
                  <div>
                    <Toggle
                      label={"Data de término definida"}
                      defaultValue={this.state.repeat.infinity.will}
                      change={(e) => {
                        this.setState((prevState) => {
                          return {
                            repeat: {
                              ...prevState.repeat,
                              infinity: { ...prevState.infinity, will: e },
                            },
                          };
                        });
                      }}
                    />
                  </div>
                  {this.state.infinity && (
                    <div className={css(styles.gridPortion)}>
                      <div>
                        <div className={css(styles.labelLine)}>
                          Total de parcelas
                        </div>
                        <input
                          type="number"
                          className={css(styles.select)}
                          onChange={(e) =>
                            (this.state.repeat.infinity.portion =
                              e.target.value)
                          }
                          defaultValue={"2"}
                        />
                      </div>
                      <div>
                        <div className={css(styles.labelLine)}>
                          Parcelas pagas
                        </div>
                        <input
                          type="number"
                          className={css(styles.select)}
                          onChange={(e) =>
                            (this.state.repeat.infinity.paidPortions =
                              e.target.value)
                          }
                          defaultValue={"1"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <Button
                text="Cancelar"
                color="secondary"
                onClick={() => this.setState({ showFinanceModal: false })}
              />
              <Button right text="Salvar" color="green" onClick={() => {}} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     response: state.patientsSearch.suggestions,
//     agenda: state.agenda.suggestions,
//     clinic: state.auth.clinic
//   }
// }

export default connect(null, { createAccount, getFinances })(financeAccount);
