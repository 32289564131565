import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const GET_TREATMENT = "GET_TREATMENT";
export const FETCH_TREATMENT = "FETCH_TREATMENT";
export const EDIT_TREATMENT = "EDIT_TREATMENT";
export const CLEAR_TREATMENT = "CLEAR_TREATMENT";

export function getTreatment(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/treatment/${patientId}`);
      dispatch({ type: GET_TREATMENT, payload: res.data });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Tratamento não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export async function getTreatmentByPatientId(patientId, callback) {
  try {
    const res = await axios.get(`/api/treatmentByPatientId/${patientId}`);
    callback(res.data);
  } catch (err) {
    console.log(err);
  }
}

export function updateTreatment(data, patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/treatment/${patientId}`, data);

      dispatch({ type: EDIT_TREATMENT, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Tratamento atualizado com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Tratamento não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export function editProcedures(treatment, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.patch(
        `/api/treatment/${treatment._id}/procedures/edit`,
        treatment,
      );
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Procedimentos atualizados com sucesso!`,
          type: "success",
        },
      });
      callback(res.data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Não foi possível executar a operação.",
          type: "error",
        },
      });
    }
  };
}

export function fetchProceduresByDentists(callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/treatment/procedures/byDentists");
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Não foi possível buscar a lista de procedimentos. ",
          type: "error",
        },
      });
    }
  };
}

export function editTreatmentName(treatmentId, name, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/treatment/editName/${treatmentId}`, {
        name,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Nome do tratamento atualizado com sucesso.`,
          type: "success",
        },
      });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Não foi possível executar a operação.`,
          type: "error",
        },
      });
    }
  };
}

export function fetchTreatments(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/treatments/${patientId}`);

      dispatch({ type: FETCH_TREATMENT, payload: res.data });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro. Alguma informação está faltando.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export const createBudget = (treatmentId, budget, callback) => {
  return async (dispatch) => {
    try {
      const budgetObject = { budget };
      const res = await axios.put(
        `/api/treatment/${treatmentId}/budgets/create`,
        budgetObject,
      );
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Orçamento criado com sucesso.`, type: "success" },
      });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro ao criar orçamento.`, type: "error" },
      });
    }
  };
};

export const deleteBudget = (budgetId, callback) => {
  return async (dispatch) => {
    try {
      const res = axios.put(`/api/treatment/budgets/${budgetId}/remove`);
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Orçamento removido com sucesso.",
          type: "success",
        },
      });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Não foi possível remover orçamento.`,
          type: "error",
        },
      });
    }
  };
};

export const editBudget = (budget, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(
        `/api/treatment/budgets/${budget._id}`,
        budget,
      );
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Orçamento alterado com sucesso!`,
          type: "success",
        },
      });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro ao editar orçamento.`, type: "error" },
      });
    }
  };
};

export function fetchBudgets(approved = false, patientId, callback) {
  return async (dispatch) => {
    const params = {
      approved,
      patientId,
    };

    try {
      const res = await axios.get("/api/treatment/budgets", { params });
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro ao buscar faturas.`, type: "error" },
      });
    }
  };
}

export function createReceipt(receipt) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/treatment/budgets/receipts/create", {
        ...receipt,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Recebimento efetuado com sucesso!",
          type: "success",
        },
      });
      return res.data;
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Erro. Alguma informação está faltando.",
          type: "error",
        },
      });
    }
  };
}

export const fetchInstallments = (
  receipt = true,
  startDate,
  endDate,
  callback,
) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/treatment/budgets/installments?receipt=${receipt}&startDate=${startDate}&endDate=${endDate}`,
      );
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Erro ao buscar resumo financeiro de pacientes. ",
          type: "error",
        },
      });
    }
  };
};

export const getSumFinanceBudgetResume = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/treatment/budgets/resume");
      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: "Erro ao buscar resumo financeiro de pacientes. ",
          type: "error",
        },
      });
    }
  };
};

export function clearOdontogram() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_TREATMENT, payload: null });
  };
}
