import axios from "axios";

//Listar contas
export async function buscarContas(clinicaId, params = {}) {
  const { dataInicial, dataFinal, pago } = params;
  var contas = await axios
    .post("/api/contas/listar", {
      clinic_id: clinicaId,
      dataInicial,
      dataFinal,
      pago,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contas;
}
//Listar Descrição
export async function buscarDescricoes(clinicaId) {
  var descricoes = await axios
    .post("/api/contas/listarDescricao", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return descricoes;
}

//Cadastrar contas
export async function cadastrarConta(clinicaId, objeto) {
  var conta = await axios
    .post("/api/contas/cadastrarConta", {
      clinic_id: clinicaId._id,
      nome: objeto.nome,
      nota: objeto.nota,
      duplicada: objeto.duplicada,
      dataVenc: objeto.dataVenc,
      valor: objeto.valor,
      qtdParcelas: objeto.qtdParcelas,
      observacao: objeto.observacao,
      descricao: objeto.descricao,
      contasPagas: objeto.contasPagas,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));
  return conta;
}

//Cadastrar descricoes
export async function cadastrarDescricao(clinicaId, objeto) {
  var descricao = await axios
    .post("/api/contas/cadastrarDescricao", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      descricao: objeto.descricao,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return descricao;
}

// Buscar conta
export async function buscarConta(clinicaId, contaId) {
  var conta = await axios
    .post("/api/contas/buscarConta", {
      conta_id: contaId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return conta;
}

// Buscar descricao
export async function buscarDescricao(clinicaId, descricaoId) {
  var descricao = await axios
    .post("/api/contas/buscarDescricao", {
      descricao_id: descricaoId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return descricao;
}

// Editar contas
export async function editarConta(objConta) {
  var conta = await axios
    .put("/api/contas/editarConta", {
      objConta,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return conta;
}

// Novo editar contas à pagar
export async function editarContaAPagar(objConta) {
  const conta = await axios
    .put("/api/contas/editarContaAPagar", { objConta })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return conta;
}

// Editar descricao
export async function editarDescricao(objDescricao) {
  var descricao = await axios
    .put("/api/contas/editarDescricao", {
      objDescricao,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return descricao;
}

// Excluir conta
export async function deletarConta(objConta, clinic_id) {
  objConta.clinic_id = clinic_id;

  var conta = await axios
    .delete("/api/contas/deletarConta", {
      data: objConta,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return conta;
}

// Excluir parcela da conta
export async function deletarParcelaConta(parcelaId) {
  const parcela = await axios
    .delete("/api/contas/deletarParcelaConta", {
      data: parcelaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));
}

// Excluir descricao
export async function deletarDescricao(objDescricao, clinic_id) {
  objDescricao.clinic_id = clinic_id;

  var descricao = await axios
    .delete("/api/contas/deletarDescricao", {
      data: objDescricao,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return descricao;
}

//Listar contas pagas
export async function ListarContasPagas(clinicaId, startDate, finalDate) {
  var contasPagas = await axios
    .post("/api/contas/listarContasPagas", {
      clinic_id: clinicaId,
      startDate,
      finalDate,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contasPagas;
}

//Cadastrar contas Pagas
export async function cadastrarContasPagas(clinicaId, objeto) {
  if (!objeto.contasPagar || !objeto.valorPago || !objeto.dataPagamento)
    return null;
  var contaPaga = await axios
    .post("/api/contas/cadastrarContasPagas", {
      clinic_id: clinicaId._id,
      valorPago: objeto.valorPago,
      dataPagamento: objeto.dataPagamento,
      observacao: objeto.observacao,
      contasPagar: objeto.contasPagar,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contaPaga;
}

// Buscar contas pagas
export async function buscarContasPagas(clinicaId, contaPagaId) {
  var contaPaga = await axios
    .post("/api/contas/buscarContasPagas", {
      contaPaga_id: contaPagaId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contaPaga;
}

// Editar contas pagas
export async function editarContasPagas(conta) {
  var objContaPaga = {};

  objContaPaga.clinic_id = conta.clinic_id;
  objContaPaga._id = conta._id;
  objContaPaga.valorPago = conta.valorPago;
  objContaPaga.observacao = conta.observacao;

  var contaPaga = await axios
    .put("/api/contas/editarContasPagas", {
      objContaPaga,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contaPaga;
}

// Excluir conta pagas
export async function deletarContasPagas(objContaPaga, clinic_id) {
  objContaPaga.clinic_id = clinic_id;

  var contaPaga = await axios
    .delete("/api/contas/deletarContasPagas", {
      data: objContaPaga,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return contaPaga;
}
