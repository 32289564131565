import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

export default function JurosMultaValidadeForm({
  defaultInterestRate,
  monthlyInterestRate,
  penaltyDelayRate,
  expiration,
  onClose,
  onSubmit,
}) {
  const [form, setForm] = useState({
    defaultInterestRate,
    monthlyInterestRate,
    penaltyDelayRate,
    expiration,
  });

  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle>Multa / Juros</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                invalidDateMessage="Data em formato inválido."
                label="Validade do Orçamento"
                format="dd/MM/yyyy"
                value={form.validade}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => setForm({ ...form, expiration: date })}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <TextField
              variant="outlined"
              label="Juros mensal(%)"
              type="number"
              value={form.monthlyInterestRate}
              onChange={(e) =>
                setForm({ ...form, monthlyInterestRate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <TextField
              variant="outlined"
              label="Juros mora (%)"
              type="number"
              value={form.defaultInterestRate}
              onChange={(e) =>
                setForm({ ...form, defaultInterestRate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <TextField
              variant="outlined"
              label="Multa por atraso (%)"
              value={form.penaltyDelayRate}
              onChange={(e) =>
                setForm({ ...form, penaltyDelayRate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={onClose}>
          Fechar
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => onSubmit(form)}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
