import axios from "axios";
import { SHOW_MESSAGE } from "../systemMsg";

export const FETCH_DENTISTS = "FETCH_DENTISTS";
export const PAGINATE_DENTISTS = "PAGINATE_DENTISTS";
export const SELECT_ALL = "SELECT_ALL";
export const CLEAR_DENTISTS = "CLEAR_DENTISTS";
export const SELECT_DENTISTS = "SELECT_DENTISTS";
export const FILTER_DENTISTS = "FILTER_DENTISTS";
export const HEADER_LISTENER = "HEADER_LISTENER";

export function fetchDentists(value, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/dentist/search", value);
      dispatch({ type: FETCH_DENTISTS, payload: res.data });
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function paginationDentists(
  { active, cad, name, email, tel, cpf, page, limit, column, order },
  callback,
) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/dentist/paginate", {
        active,
        cad,
        name,
        email,
        tel,
        cpf,
        page,
        limit,
        column,
        order,
      });
      dispatch({ type: PAGINATE_DENTISTS, payload: res.data, status: active });
      callback();
    } catch (err) {
      console.log(err);
    }
  };
}

export function advancedFilter(props, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/dentist/filter", props);
      dispatch({ type: FILTER_DENTISTS, payload: res.data });
      callback();
    } catch (err) {
      console.log(err);
    }
  };
}

export function selectAll({ active, cad, name, email, tel, cpf }, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/dentist/selectall", {
        active,
        cad,
        name,
        email,
        tel,
        cpf,
      });
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function editDentist({ id, props, message, update }, callback) {
  return async (dispatch) => {
    console.log(id);
    try {
      const res = await axios.put("/api/dentist/" + id, props);
      console.log(res);
      if (update)
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            message: message ? message : `Dentista editado com sucesso`,
            type: "success",
            undo: { db: "dentist", props: update },
          },
        });
      else
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            message: message ? message : `Dentista editado com sucesso`,
            type: "success",
          },
        });
      callback(res.data);
    } catch (err) {
      console.log(err);
    }
  };
}

export function editDentists({ array, props, message, update }, callback) {
  return async (dispatch) => {
    try {
      await axios.post("/api/dentist/edit", { array, props });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: message ? message : `Dentistas editados com sucesso`,
          type: "success",
          undo: { db: "dentist", props: update },
        },
      });
      callback();
    } catch (err) {
      console.log(err);
    }
  };
}

export function dentistAgendaValidation(data, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/dentist/agenda_validation", data);
      if (callback) {
        callback(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function clearDentist() {
  return (dispatch) => {
    dispatch({ type: CLEAR_DENTISTS });
  };
}

export function selectDentist(selected, callback) {
  return (dispatch) => {
    dispatch({ type: SELECT_DENTISTS, selected: selected });
    callback(selected._id);
  };
}

export function headerListener(func) {
  return (dispatch) => {
    dispatch({ type: HEADER_LISTENER, payload: func });
  };
}

export function createFile(id, file, names, dir, name, callback) {
  return async (dispatch) => {
    try {
      var formData = new FormData();
      formData.append("dir", dir);
      formData.append("path", dir.replace(/\//g, "."));
      formData.append("qtd", names.length);
      names.map((item, index) => formData.append(item, file[index]));
      const res = await axios.post(`/api/file/dentist/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Arquivo salvo com sucesso`, type: "success" },
      });
      if (callback) callback(res.data);
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro no upload do arquivo`, type: "error" },
      });
    }
  };
}

export function deleteFile(id, dir, hash, callback) {
  return async (dispatch) => {
    try {
      await axios.put(`/api/file/dentist/${id}/delete`, {
        dir,
        path: dir.replace(/\//g, "."),
        hash,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Arquivo excluído com sucesso`, type: "success" },
      });
      if (callback) callback();
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro na exclusão do arquivo`, type: "error" },
      });
    }
  };
}

export function renameFile(id, dir, hash, newName, callback) {
  return async (dispatch) => {
    try {
      var res = await axios.put(`/api/file/dentist/${id}`, {
        path: dir.replace(/\//g, "."),
        hash,
        newName,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Arquivo excluído com sucesso`, type: "success" },
      });
      if (callback) callback(res.data);
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro na exclusão do arquivo`, type: "error" },
      });
    }
  };
}

export function createDirectory(id, dir, name, callback) {
  return async (dispatch) => {
    try {
      await axios.post(`/api/directory/dentist/${id}`, {
        dir,
        path: dir.replace(/\//g, "."),
        name,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Pasta criada com sucesso`, type: "success" },
      });
      if (callback) callback();
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro no criação da pasta`, type: "error" },
      });
    }
  };
}

export function renameDirectory(id, dir, name, newName, callback) {
  return async (dispatch) => {
    try {
      await axios.put(`/api/directory/dentist/${id}`, {
        dir,
        path: dir.replace(/\//g, "."),
        name,
        newName,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Pasta criada com sucesso`, type: "success" },
      });
      if (callback) callback();
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro no criação da pasta`, type: "error" },
      });
    }
  };
}

export function fetchDirectory(id, callback) {
  return async (dispatch) => {
    try {
      var res = await axios.get(`/api/directory/dentist/${id}`);
      callback(res.data);
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro ao carregar pasta`, type: "error" },
      });
    }
  };
}

export function deleteDirectory(id, dir, name, callback) {
  return async (dispatch) => {
    try {
      await axios.post(`/api/directory/dentist/${id}/delete`, {
        dir,
        path: dir.replace(/\//g, "."),
        name,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Pasta apagada com sucesso`, type: "success" },
      });
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro ao apagar a pasta`, type: "error" },
      });
    }
  };
}
