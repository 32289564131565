import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const BUSCAR_PLACAS = "BUSCAR_PLACAS";
export const EDITAR_PLACAS = "EDITAR_PLACAS";
export const NOVA_MEDICAO_PLACAS = "NOVA_MEDICAO_PLACAS";

export function novaMedicaoPlacas(data, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/pacientes/placas/nova`, data);

      dispatch({ type: NOVA_MEDICAO_PLACAS, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Nova medição criada com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro. Não foi possível criar outra medição.`,
          type: "error",
        },
      });
    }
  };
}

export function buscarPlacas(patientId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/pacientes/placas/buscar/${patientId}`);

      dispatch({ type: BUSCAR_PLACAS, payload: res.data });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Placas não encontradas.`, type: "error" },
      });
    }
  };
}

export function atualizarPlacas(data, patientId, callback) {
  return async (dispatch) => {
    try {
      data.patient_id = patientId;

      const res = await axios.put(
        `/api/pacientes/placas/editar/${patientId}`,
        data,
      );

      dispatch({ type: EDITAR_PLACAS, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Placas atualizada com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Placas não encontradas.`, type: "error" },
      });
      console.log(err);
    }
  };
}
