import React, { Component, forwardRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { CirclePicker } from "react-color";
import InputLabel from "@material-ui/core/InputLabel";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Select, TextField, Icon } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import "@material-ui/icons";
import Axios from "axios";
import InputMask from "react-input-mask";

import AddBox from "@material-ui/icons/AddBox";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import FormHelperText from "@material-ui/core/FormHelperText";
import { IMaskInput } from "react-imask";
import { Save } from "@material-ui/icons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const TextMaskCustom = (props) => {
  const { onChange, mask, ...other } = props;

  return (
    <IMaskInput
      mask={mask}
      unmask={true}
      onAccept={(value) => onChange({ target: { value } })}
      {...other}
    />
  );
};
export default class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = !props.objeto
      ? this.criarState(this.props.inputs)
      : this.props.objeto;
    this.retornaState = this.retornaState.bind(this);
  }

  retornaState() {
    var props = this.props;

    if (props.buscarStateFilho) {
      props.buscarStateFilho(this.state, props.idGrupoForm);
    }
  }

  checkChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked }, () => {
      window.setTimeout(() => this.retornaState(), 200);
    });
  };

  handleChange = (name, fn) => (event) => {
    if (name === "cep") {
      var cep = event.target.value.replace("-", "");
      if (cep.length === 8) {
        Axios.get(`https://viacep.com.br/ws/${cep}/json`)
          .then((r) => {
            console.log(r);
            this.setState({
              ...this.state,
              address: r.data.logradouro ?? "",
              city: r.data.localidade ?? "",
              uf: r.data.uf ?? "",
              cep: cep,
            });
          })
          .catch((err) => console.log(err));
      }
    }
    var err = name + "Error";

    this.setState({ [name]: event.target.value, [err]: "" }, () => {
      window.setTimeout(() => this.retornaState(), 200);
    });
  };

  handleChangeColor = (e, name) => {
    this.setState({ [name]: e.hex });
  };

  criarState(arr) {
    var state = {};
    arr.map((i) => {
      if (i.type === "multiplo") {
        state[i.name] = [];
      } else {
        state[i.name] = "";
      }
    });

    return state;
  }

  componentWillReceiveProps(props) {
    props.inputs.map((element) => {
      if (element.error) {
        this.state[element.name + "Error"] = element.error;
      }
    });
  }

  renderizarAcao(action, disabledAction = false) {
    var button = "";

    button = (
      <Button
        onClick={() => action(this.state)}
        variant="contained"
        color="secondary"
        disabled={disabledAction}
      >
        Salvar
      </Button>
    );

    return button;
  }

  renderizar(objeto) {
    var input = "";

    switch (objeto.type) {
      case "autocomplete":
        const defaultProps = {
          options: objeto.values,
          getOptionLabel: (option) => option[objeto.autoLabel],
        };

        input = (
          <Autocomplete
            {...defaultProps}
            id={objeto.name}
            style={{ width: "50%" }}
            onChange={(event, newValue) => {
              this.setState({
                [objeto.name]: newValue[objeto.name],
              });
            }}
            debug
            renderInput={(params) => (
              <TextField {...params} label={objeto.label} margin="normal" />
            )}
          />
        );
        break;
      case "discursive":
      case "text":
        input = (
          <div>
            <InputLabel htmlFor={objeto.name}>{objeto.label}</InputLabel>
            <Input
              error={this.state[objeto.name + "Error"] ? true : false}
              value={this.state[objeto.name]}
              onChange={this.handleChange(
                objeto.name,
                objeto.funcao && objeto.funcao.fn,
              )}
              style={{ width: "50%" }}
              aria-describedby={objeto.label}
              id={objeto.name}
            />
            {this.state[objeto.name + "Error"] && (
              <FormHelperText id="component-error-text">
                {objeto.error}
              </FormHelperText>
            )}
          </div>
        );
        break;
      case "boolean":
        input = (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  checked={this.state[objeto.name]}
                  id={objeto.name}
                  onChange={this.checkChange(objeto.name)}
                  value={this.state[objeto.name]}
                  color="primary"
                />
              }
              label={objeto.label}
            />
          </div>
        );
        break;
      case "number":
        input = (
          <div>
            <InputLabel htmlFor={objeto.name}>{objeto.label}</InputLabel>
            <Input
              error={this.state[objeto.name + "Error"] ? true : false}
              type="number"
              value={this.state[objeto.name]}
              onChange={this.handleChange(objeto.name)}
              style={{ width: "50%" }}
              aria-describedby={objeto.label}
              id={objeto.name}
            />
            {this.state[objeto.name + "Error"] && (
              <FormHelperText id="component-error-text">
                {objeto.error}
              </FormHelperText>
            )}
          </div>
        );
        break;
      case "select":
        input = (
          <div>
            <FormControl style={{ width: "50%" }}>
              <InputLabel htmlFor="age-native-simple">
                {objeto.label}
              </InputLabel>
              <Select
                value={this.state[objeto.name]}
                onChange={this.handleChange(objeto.name)}
                inputProps={{ id: "age-native-simple" }}
              >
                <option value={0} defaultValue={0}>
                  Selecione ----
                </option>
                {objeto.values.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        );
        break;
      case "date":
        input = (
          <TextField
            id={objeto.name}
            label={objeto.label}
            style={{ width: "50%" }}
            type="date"
            value={this.state[objeto.name]}
            defaultValue="2017-05-24T10:30"
            onChange={this.handleChange(objeto.name)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
        break;
      case "hour":
        input = (
          <TextField
            id={objeto.name}
            label={objeto.label}
            type="time"
            defaultValue={this.state[objeto.name]}
            value={this.state[objeto.name]}
            onChange={this.handleChange(objeto.name)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        );
        break;
      case "mask":
        input = (
          <div>
            <InputMask
              id={objeto.name}
              label={objeto.label}
              mask={objeto.maskDesign}
              maskChar={objeto.maskChar ?? ""}
              value={this.state[objeto.name]}
              onChange={this.handleChange(objeto.name)}
            >
              {(inputProps) => (
                <TextField
                  error={this.state[objeto.name + "Error"] ? true : false}
                  {...inputProps}
                />
              )}
            </InputMask>
            {this.state[objeto.name + "Error"] && (
              <FormHelperText id="component-error-text">
                {objeto.error}
              </FormHelperText>
            )}
          </div>
        );
        break;
      case "maskVariant":
        input = (
          <div>
            <TextField
              label={objeto.label}
              error={this.state[objeto.name + "Error"] ? true : false}
              InputProps={{
                inputComponent: TextMaskCustom,
                inputProps: {
                  mask: objeto.maskDesign,
                  value: this.state[objeto.name],
                  onChange: this.handleChange(objeto.name),
                },
              }}
            />
          </div>
        );
        break;
      case "color":
        input = (
          <div>
            <InputLabel
              style={{
                display: "block",
                margin: "7px 0",
                transform: "none",
                position: "relative",
              }}
            >
              {objeto.label}
            </InputLabel>
            <div>
              <CirclePicker
                color={this.state[objeto.name]}
                onChangeComplete={(e) => this.handleChangeColor(e, objeto.name)}
              />
            </div>
          </div>
        );
        break;
      case "multiplo":
        input = (
          <MaterialTable
            icons={tableIcons}
            options={{ actionsColumnIndex: -1 }}
            style={{ marginTop: "20px" }}
            title={objeto.label}
            columns={objeto.colunas}
            data={this.state[objeto.name]}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const data = [...this.state[objeto.name]];
                    data.push(newData);
                    this.setState({ [objeto.name]: data });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const data = [...this.state[objeto.name]];
                    data[data.indexOf(oldData)] = newData;
                    this.setState({ [objeto.name]: data });
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const data = [...this.state[objeto.name]];
                    data.splice(data.indexOf(oldData), 1);
                    this.setState({ [objeto.name]: data });
                  }, 600);
                }),
            }}
          />
        );
        break;
      default:
        break;
    }

    return input;
  }

  render() {
    const {
      inputs,
      action,
      noBack,
      idGrupoForm,
      emailButton,
      disabledAction = false,
    } = this.props;

    return (
      <Container>
        {inputs.map((input, key) => (
          <FormControl fullWidth key={key}>
            {this.renderizar(input, key)}
          </FormControl>
        ))}
        <FormControl style={{ display: "block", marginTop: "30px" }}>
          {!noBack && (
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => this.props.history.goBack()}
              variant="contained"
              color="primary"
            >
              voltar
            </Button>
          )}

          {action && this.renderizarAcao(action, disabledAction)}

          {/* Se tiver emailButton então mostra o botão de e-mail */}
          {emailButton && emailButton.show && (
            <div style={{ float: "right" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => emailButton.handleSendEmail()}
                endIcon={<Icon>send</Icon>}
              >
                Enviar e-mail
              </Button>
            </div>
          )}
        </FormControl>
      </Container>
    );
  }
}
