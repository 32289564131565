import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { buscarPacientes } from "../../../../api/pacientes";
import { editPatient } from "../../../../actions/patientsSearch";

//Icone de desarquivar
import Unarchive from "@material-ui/icons/Unarchive";
import OpenInNew from "@material-ui/icons/OpenInNew";

import TableComponent from "../../../table/TableComponent";

class PacientesArquivados extends Component {
  state = {
    pacientes: [],
  };

  componentWillMount() {
    const { tab, trocarTab, editPatient } = this.props;
    const tabSelecionada = tab.lista.filter(
      (tab) => tab.url === "arquivados",
    )[0];

    if (tabSelecionada.numero !== tab.tabAtiva) {
      trocarTab(tabSelecionada.numero);
    }

    buscarPacientes(this.props.clinica._id)
      .then((pacientes) => {
        var listaPacientes = [];

        pacientes.length > 0 &&
          pacientes.map((paciente) => {
            if (!paciente.active) {
              var novoPaciente = {};

              novoPaciente.registro = paciente.registry;
              novoPaciente.paciente = paciente.name;
              novoPaciente.email = paciente.email || "";
              novoPaciente.telefone =
                paciente.telephones.filter(
                  (telefone) => telefone.name === "Principal",
                )[0].value || "";
              novoPaciente.funcoes = [
                <>
                  <Tooltip title={"Visualizar Cadastro"}>
                    <IconButton
                      onClick={() =>
                        this.props.history.push(
                          `/patients/profile/${paciente._id}`,
                        )
                      }
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Ativar"}>
                    <IconButton
                      onClick={() =>
                        editPatient({
                          id: paciente._id,
                          message: `paciente ${paciente.name} ativado com sucesso!`,
                          props: { active: true },
                        })
                      }
                    >
                      <Unarchive />
                    </IconButton>
                  </Tooltip>
                  ,
                </>,
              ];

              listaPacientes.push(novoPaciente);
            }
          });

        this.setState({ pacientes: listaPacientes });
      })
      .catch((err) => {
        this.setState({ pacientes: [] });
      });
  }

  render() {
    const titulo = "Pacientes Arquivados",
      corTitulo = "red",
      colunas = [
        { label: "Registro", name: "registro" },
        { label: "Paciente", name: "paciente" },
        { label: "Email", name: "email" },
        { label: "Telefone", name: "telefone" },
        {
          label: "Funções",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.pacientes;

    return (
      <TableComponent
        titulo={titulo}
        corTitulo={corTitulo || ""}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editPatient })(PacientesArquivados);
