import axios from "axios";

import { SHOW_MESSAGE } from "./systemMsg";

export const CREATE_FINANCE = "CREATE_FINANCE";
export const FETCH_FINANCES = "FETCH_FINANCES";
export const GET_FINANCE = "GET_FINANCE";
export const UPDATE_FINANCE = "UPDATE_FINANCE";

export const CLEAR_FINANCE = "CLEAR_FINANCE";

export function createAccount(data, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/finances/account", data);
      // dispatch({ type: CREATE_FINANCE, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Conta criada com sucesso (${res.data.name})`,
          type: "success",
        },
      });
      if (callback) callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro na criação da Conta. Alguma informação está faltando.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export function createFinance(id, finance, data, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/finances/${id}/${finance}`, data);
      // dispatch({ type: CREATE_FINANCE, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `${finance === "bills" ? "Despesa" : "Receita"} criada com sucesso (${res.data.name})`,
          type: "success",
        },
      });
      if (callback) callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro na criação da ${finance === "bills" ? "Despesa" : "Receita"}. Alguma informação está faltando.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export function getFinances(idAccount, finance, since, to, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/finances/get/${idAccount}/${finance}`, {
        since,
        to,
      });

      // dispatch({ type: FETCH_FINANCES, payload: res.data });
      if (callback) callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `Erro na criação do financea. Alguma informação está faltando.`,
          type: "error",
        },
      });
      console.log(err);
    }
  };
}

export function updateFinance(data, financeId) {
  return async (dispatch) => {
    try {
      const res = await axios.put(`/api/finances/${financeId}`, data);

      dispatch({ type: UPDATE_FINANCE, payload: res.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: ` As informações de ${res.data.name} foram atualizadas`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Financea não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export function deleteFinance(financeId, callback) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/finance_delete/${financeId}`);

      dispatch({ type: CLEAR_FINANCE, payload: null });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: ` Financea excluido com sucesso! `,
          type: "success",
        },
      });

      callback(res.data);
    } catch (err) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: `Erro. Financea não encontrado.`, type: "error" },
      });
      console.log(err);
    }
  };
}

export function clearFinance() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_FINANCE, payload: null });
  };
}
