import React, { useEffect } from "react";
import moment from "moment";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import TableComponent from "../../../table/TableComponent.js";

export default function CashFlowList({ dados }) {
  const colunas = [
    {
      label: "Data",
      name: "date",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Entrada",
      name: "in",
      options: {
        customBodyRender: (value) => {
          const color = value === 0 ? "black" : "green";
          return (
            <span style={{ color: `${color}` }}>
              {value && formataMoedaReal(value)}
            </span>
          );
        },
      },
    },
    {
      label: "Saída",
      name: "out",
      options: {
        customBodyRender: (value) => {
          const color = value === 0 ? "black" : "red";
          return (
            <span style={{ color: `${color}` }}>
              {value && formataMoedaReal(value)}
            </span>
          );
        },
      },
    },
    {
      label: "Saldo",
      name: "balance",
      options: {
        customBodyRender: (value) => {
          const color = value < 0 ? "red" : "black";
          return (
            <span style={{ color: `${color}`, fontWeight: "bold" }}>
              {value && formataMoedaReal(value)}
            </span>
          );
        },
      },
    },
  ];

  return (
    <TableComponent colunas={colunas} objetos={dados} selecionarLinhas="none" />
  );
}
