export const teeth = [
  11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33,
  34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 51, 52, 53, 54, 55, 61,
  62, 63, 64, 65, 71, 72, 73, 74, 75, 81, 82, 83, 84, 85,
];

export const facesEnum = ["V", "D", "M", "L", "O"];

export const gruposProcedimentosEnum = [
  { label: "Coroa", name: "CROWN", group: "faces" },
  { label: "Implante zigomatico", name: "ZYGOMATIC", group: "faces" },
  { label: "Restauração de face", name: "RESTORATION", group: "faces" },
  { label: "Rizectomia", name: "RIZECTOMY", group: "faces" },
  { label: "Apicectomia", name: "APICECTOMY", group: "faces" },
  { label: "Canal", name: "CHANNEL", group: "faces" },
  { label: "Bloco Metálico", name: "METAL_BLOCK", group: "faces" },
  { label: "Reabsorção de Raíz", name: "RESORPTION", group: "faces" },
  // { label: "Rap", name: "RAP", group: "faces" },
  { label: "Frenuloctomia", name: "FRENULTOMY", group: "faces" },
  { label: "Selante", name: "SELANTE", group: "faces" },
  { label: "Raio-X", name: "XRAY", group: "teeth" },
  { label: "Extração", name: "EXTRACTION", group: "teeth" },
  { label: "Protese Total", name: "DENTURES", group: "teeth" },
  { label: "Ponte Móvel", name: "MOBILE", group: "teeth" },
  { label: "Ponte Fixa", name: "FIXED", group: "teeth" },
  { label: "Ponte Adesiva", name: "ADHESIVE", group: "teeth" },
  { label: "Splinter", name: "SPLINTER", group: "teeth" },
  { label: "Enxerto Gengival", name: "GRAFT", group: "teeth" },
  { label: "Implante Conemorce", name: "CONEMORSE", group: "teeth" },
  { label: "Implante Curto", name: "SHORT", group: "teeth" },
  { label: "Implante Hexagono Externo", name: "HEXAGONAL_OUT", group: "teeth" },
  { label: "Implante Hexagono Interno", name: "HEXAGONAL_IN", group: "teeth" },
  {
    label: "Implante Nobel Replace Tapred",
    name: "NOBEL_REPLACE",
    group: "teeth",
  },
  { label: "Retalho periodontal", name: "RETAIL", group: "teeth" },
  { label: "Coroa de aço", name: "STEEL_CROWN", group: "teeth" },
  { label: "Pulpectomia", name: "PULPECTOMY", group: "teeth" },
  { label: "Facetas", name: "FACETS", group: "teeth" },
  { label: "Núcleo", name: "CORE", group: "teeth" },
];

const xrayEnum = [
  { value: "CM", label: "região da coroa e com tomada mesio-radial" },
  { value: "CD", label: "região da coroa e com tomada disto-radial" },
  { value: "CO", label: "região da coroa e com tomada orto-radial" },
  { value: "RM", label: "região da raiz e com tomada mesio-radial" },
  { value: "RD", label: "região da raiz e com tomada disto-radial" },
  { value: "RO", label: "região da raiz e com tomada orto-radial" },
  { value: "C", label: "região da cervical e com tomada mesio-radial" },
];

export const getChannel = (tooth) => {
  if (
    (tooth >= 11 && tooth <= 13) ||
    (tooth >= 21 && tooth <= 23) ||
    (tooth >= 31 && tooth <= 33) ||
    (tooth >= 41 && tooth <= 43)
  ) {
    return 1;
  } else if (
    (tooth >= 14 && tooth <= 15) ||
    (tooth >= 24 && tooth <= 25) ||
    (tooth >= 34 && tooth <= 35) ||
    (tooth >= 44 && tooth <= 45)
  ) {
    return 2;
  } else if (
    (tooth >= 16 && tooth <= 18) ||
    (tooth >= 26 && tooth <= 28) ||
    (tooth >= 36 && tooth <= 38) ||
    (tooth >= 46 && tooth <= 48)
  ) {
    return 3;
  }
};
