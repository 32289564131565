import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarTabelaPreco,
  buscarTabelaPreco,
  editarTabelaPreco,
} from "../../../../api/proteticos";

class TabelaPreco extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "descricao", label: "Descrição" },
      { type: "number", name: "valorIndice", label: "Valor Índice" },
      { type: "number", name: "valor", label: "Valor" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    tabelaPreco: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.tabelaPreco_id) {
      var objTabelaPreco = await buscarTabelaPreco(
        clinica._id,
        this.props.match.params.tabelaPreco_id,
      );
      this.setState({
        cadastro: false,
        tabelaPreco: objTabelaPreco,
        await: false,
      });
    } else {
      this.setState({ await: false });
    }
  }

  editaTabelaPreco(objeto) {
    editarTabelaPreco(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Tabela de Preços editada com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 3000);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a Tabela de Preços!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  salvarTabelaPreco(objeto) {
    const { clinica } = this.props;

    cadastrarTabelaPreco(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Tabela de Preços cadastrada com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 3000);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar a Tabela de Preços!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        <Paper>
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
            />
          )}
          {!this.state.await && (
            <FormComponent
              {...this.props}
              inputs={this.state.formulario}
              objeto={this.state.tabelaPreco}
              action={
                this.state.cadastro
                  ? this.salvarTabelaPreco.bind(this)
                  : this.editaTabelaPreco.bind(this)
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(TabelaPreco);
