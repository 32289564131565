import React, { Component } from "react";
import { COLORS } from "../../_constants/colors";

import { css } from "aphrodite/no-important";
import { styles } from "./MaterialsListStyles";

import TableFilter from "./TableFilterStorage";
import ModalDeleteMaterial from "../../forms/storage/modal/ModalDeleteMaterial";

import Modal from "../../modals/Modal";

class MaterialsList extends Component {
  constructor(props) {
    super(props);
    this.renderItems = this.renderItems.bind(this);
    this.orderTable = this.orderTable.bind(this);
    this.delMaterial = this.delMaterial.bind(this);
    this.onWrite = this.onWrite.bind(this);
    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);

    let primary = {
      color: COLORS.blueDark,
      fontSize: ".875rem",
      textDecoration: "underline",
    };
    let danger = {
      color: COLORS.red,
      fontSize: ".875rem",
      textDecoration: "underline",
    };

    this.state = {
      idMaterial: null,
      showDeleteModal: false,
      noStorage: true,
      column: "date",
      order: true,
      infos: {
        warning: { type: "Warning" },
        name: { type: "String", content: "", head: "Produto" },
        vendor_name: {
          type: "String",
          content: "",
          head: "Fornecedor",
          width: "250px",
        },
        quantity: {
          type: "String",
          content: "",
          head: "Qtd. Atual",
          width: "100px",
          margin: "18px",
        },
        max: {
          type: "String",
          content: "",
          head: "Qtd. Máx.",
          width: "100px",
          margin: "18px",
        },
        min: {
          type: "String",
          content: "",
          head: "Qtd. Mín.",
          width: "100px",
          margin: "18px",
        },
        edit: {
          type: "String",
          style: primary,
          placeholder: "Editar",
          width: "50px",
          link: { function: this.onOpenEditModal },
        }, //link: 'function'
        delete: {
          type: "String",
          style: danger,
          placeholder: "Excluir",
          width: "50px",
          link: { function: this.onOpenDeleteModal, parameters: ["_id"] },
        },
      },
    };
  }

  onOpenEditModal(id) {
    this.props.onOpenEditModal(id);
  }

  onOpenDeleteModal(id) {
    this.setState({
      showDeleteModal: true,
      idMaterial: id,
    });
  }

  onCloseDeleteModal() {
    this.setState({
      showDeleteModal: false,
      idMaterial: null,
    });
  }

  delMaterial(id) {
    const { deleteMaterialForm } = this.props;
    deleteMaterialForm(id);
  }

  orderTable(coluna, order) {
    this.setState({
      column: coluna,
      order: order,
    });
  }

  onWrite(coluna, conteudo) {
    this.state.infos[coluna].content = conteudo.toString();
    this.setState({});
  }

  renderItems(materials, match) {
    if (materials.length > 0) {
      for (let i = 0; i < materials.length; i++) {
        materials[i].vendor_name = materials[i].vendor_id?.name ?? "";
        if (materials[i].quantity <= materials[i].min) {
          materials[i].warning = "true";
        }
      }

      return (
        <TableFilter
          width="100%"
          id="_id"
          // array JSON
          array={materials}
          // infos das colunas da tabela
          infos={this.state.infos}
          // evento ao escrever no input (coluna, conteudo)
          onWrite={this.onWrite}
          // qtd de linhas
          limit={this.state.limit}
          // evento clica coluna (coluna, order-boolean)
          orderTable={this.orderTable}
          // default coluna ordenada
          column={this.state.column}
          order={this.state.order}
          type="materials"
        />
      );
    } else {
      return <li className={css(styles.noItems)}>Nenhum produto cadastrado</li>;
    }
  }

  // 'proceduresList' will manage the routes inside the app
  render() {
    const { match, materials } = this.props;
    return (
      <div>
        {this.renderItems(materials, match)}
        <div className={css(styles.grid)}>
          <Modal
            isOpen={this.state.showDeleteModal}
            header="Deletar Produto"
            adjustStyle={styles.newProcedureModal}
          >
            <ModalDeleteMaterial
              onCancel={this.onCloseDeleteModal}
              idMaterial={this.state.idMaterial}
              del={this.delMaterial}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default MaterialsList;
