import React from "react";
import { useEffect, useState } from "react";
import { getEndodontic } from "../../../../api/pacientes";
import EndodonticForm from "./EndodonticForm";

export default function EndodonticEditView(props) {
  const { patientId, match } = props;
  const [currentEndodontic, setCurrentEndodontic] = useState();

  useEffect(() => {
    const fetchEndodontic = async () => {
      const endodontic = await getEndodontic(
        patientId,
        match.params.endodonticId,
      );
      setCurrentEndodontic(endodontic);
    };
    fetchEndodontic();
  }, []);

  return (
    <EndodonticForm
      patientId={patientId}
      {...props}
      currentEndodontic={currentEndodontic}
    />
  );
}
