import React from "react";
import moment from "moment";
import { Chip } from "@material-ui/core";
import TableComponent from "../../table/TableComponent";
import { formataMoedaReal } from "../../../helpers/Helpers";

const TabelaFaturas = ({ installments, title }) => {
  const colunas = [
    { label: "Paciente", name: "patient.name" },
    { label: "Fatura", name: "number" },
    { label: "Orçamento", name: "budgetName" },
    {
      label: "Data Vencimento",
      name: "expiration",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let color = "";
          let label = "";
          switch (value) {
            case "paid":
              color = "#198754";
              label = "Pago";
              break;
            case "overdue":
              color = "#dc3545";
              label = "Vencido";
              break;
            default:
              color = "#0d6efd";
              label = "Aberto";
              break;
          }
          return (
            <Chip
              size="small"
              label={label}
              style={{ background: `${color}`, color: "white" }}
            />
          );
        },
      },
    },
    {
      label: "Valor Fatura",
      name: "value",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Data Recebimento",
      name: "datePayment",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Valor Recebimento",
      name: "valuePayment",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    // Nova coluna para a data de compensação
    {
      label: "Data de Compensação",
      name: "compensationDate",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
  ];

  const options = {
    elevation: 1,
    selectableRows: "none",
  };

  return (
    <TableComponent
      titulo={title ? `Total: ${formataMoedaReal(title)}` : null}
      colunas={colunas}
      objetos={installments}
      options={options}
    />
  );
};

export default TabelaFaturas;
