import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listarTabelas,
  deletarTabela,
  buscarTabela,
  desvincularServico,
} from "../../../../api/servicos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";

class ServicosConfigTabelaRelacao extends Component {
  state = {
    tabelas: [],
    idTabela: "",
  };

  removeServico(objeto) {
    desvincularServico(this.state.idTabela, objeto);
  }

  componentWillMount() {
    buscarTabela(this.props.clinica._id, this.props.match.params.tabela_id)
      .then((result) => {
        var tabela = [];

        result.servicos.length > 0 &&
          result.servicos.map((servico, index) => {
            var novaTabela = {};
            novaTabela.descricao = servico.descricao;
            novaTabela.preco = servico.preco;
            novaTabela.ativo = servico.ativo ? "Ativo" : "Inativo";
            novaTabela._id = servico._id;

            tabela.push(novaTabela);
          });

        this.setState({
          tabelas: tabela,
          idTabela: this.props.match.params.tabela_id,
        });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Servico",
      colunas = [
        { label: "Serviço", name: "descricao" },
        { label: "Preço", name: "preco" },
        { label: "Ativo", name: "ativo" },
      ],
      objetos = this.state.tabelas,
      inserir = {
        nome: "Vincular serviço",
        link: `/servicos/config/vincularServico/${this.props.match.params.tabela_id}`,
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.removeServico.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ServicosConfigTabelaRelacao);
