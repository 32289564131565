import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  cadastrarCategoria,
  buscarCategoria,
  editarCategoria,
} from "../../../../api/dentistas";
import { toast } from "react-toastify";

class DentistasCategorias extends Component {
  state = {
    formulario: [{ type: "text", name: "titulo", label: "Categoria" }],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    categoria: "",
    cadastro: true,
    await: true,
    disabledButton: false,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.categoria_id) {
      var objCategoria = await buscarCategoria(
        clinica._id,
        this.props.match.params.categoria_id,
      );
      this.setState({ cadastro: false, categoria: objCategoria, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  editaCategoria(objeto) {
    this.setState({ disabledButton: true });
    editarCategoria(objeto)
      .then((r) => {
        if (r) {
          toast.success("Categoria editada com sucesso");
          this.props.history.goBack();
        } else {
          toast.error("Infelizmente não foi possível salvar a categoria!");
        }
      })
      .then(() =>
        this.setState({ disabledButton: false, alerta: { abrir: false } }),
      );
  }

  salvarCategoria(objeto) {
    const { clinica } = this.props;
    this.setState({ disabledButton: true });
    cadastrarCategoria(clinica, objeto.titulo)
      .then((r) => {
        if (r) {
          toast.success("Categoria editada com sucesso");
          this.props.history.goBack();
        } else {
          toast.error("Infelizmente não foi possível salvar a categoria!");
        }
      })
      .then(() =>
        this.setState({ disabledButton: false, alerta: { abrir: false } }),
      );
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            objeto={this.state.categoria}
            disabledAction={this.state.disabledButton}
            action={
              this.state.cadastro
                ? this.salvarCategoria.bind(this)
                : this.editaCategoria.bind(this)
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(DentistasCategorias);
