import React from "react";
import { formataMoedaReal } from "../../../helpers/Helpers";
import {
  createMuiTheme,
  MuiThemeProvider,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import TableComponent from "../../table/TableComponent";

export default function ListaParcelasResumo({ parcelas, pago }) {
  const colunas = [
    {
      label: "Mês",
      name: "mes",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <CalendarToday />
              <span style={{ marginLeft: "8px" }}>{getNomeMes(value)}</span>
            </>
          );
        },
      },
    },
    {
      label: "Total",
      name: "totalFaturas",
      options: {
        customBodyRender: (value) => value && formataMoedaReal(value),
      },
    },
  ];

  const subColunas = [
    {
      label: "Data de vencimento",
      name: "vencimento",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD/MM/YYYY");
        },
      },
    },
    { label: "Paciente", name: "nomePaciente" },
    { label: "Orçamento", name: "nomeOrcamento" },
    { label: "Parcela", name: "numero" },
    {
      label: "Valor Fatura",
      name: "valorFatura",
      options: {
        customBodyRender: (value) => formataMoedaReal(value),
      },
    },
  ];

  if (pago) {
    colunas.push({
      label: "Total Pago",
      name: "totalPago",
      options: {
        customBodyRender: (value) => value && formataMoedaReal(value),
      },
    });

    subColunas.push({
      label: "Valor pago",
      name: "valorFatura",
      options: {
        customBodyRender: (value) => value && formataMoedaReal(value),
      },
    });
  }

  const myTheme = createMuiTheme({
    overrides: {
      MUIDataTableToolbar: {
        root: {
          display: "none",
        },
      },
    },
  });

  const getNomeMes = (mes) => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    date.setMonth(mes - 1);
    return date.toLocaleString("pt-BR", { month: "long" });
  };

  const options = {
    expandableRows: true,
    expandableRowsHeader: true,
    selectableRows: "none",
    elevation: 1,
    sort: false,
    customFooter: () => {},
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow key={rowMeta.rowIndex}>
          <TableCell colSpan={rowData.length + 1}>
            <MuiThemeProvider theme={myTheme}>
              <MUIDataTable
                columns={subColunas}
                data={parcelas.meses[rowMeta.rowIndex].parcelas}
                options={subTableOptions}
              />
            </MuiThemeProvider>
          </TableCell>
        </TableRow>
      );
    },
  };

  const subTableOptions = {
    selectableRows: "none",
    elevation: 0,
    customFooter: () => {},
  };

  return (
    <>
      {/* TODO: Verificar no backend o motivo de não estar trazendo a propriedade "ANO". */}
      <Typography variant="h6">{parcelas.ano}</Typography>
      <MuiThemeProvider theme={myTheme}>
        <MUIDataTable
          columns={colunas}
          data={parcelas.meses}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}
