import { StyleSheet } from "aphrodite/no-important";

import { COLORS, PALLETE } from "../_constants/colors";

export const styles = StyleSheet.create({
  navLink: {
    position: "relative",
    display: "block",
    // height: '2.5rem',
    padding: "0.25rem 1rem",
    color: "white",
    textDecoration: "none",
    transition: "all .5s ease",
    ":hover": {
      backgroundColor: PALLETE.primary.dark,
      color: COLORS.white,
    },
  },
  active: {
    color: COLORS.white,
    backgroundColor: PALLETE.primary.darker,
  },
  navText: {
    verticalAlign: "middle",
    lineHeight: "2rem",
    marginLeft: ".9em",
  },
  warning: {
    paddingTop: "2px",
    marginTop: "6px",
    float: "right",
    display: "table-cell",
    height: "20px",
    width: "20px",
    textAlign: "center",
    verticalAlign: "middle",
    borderRadius: "50%",
    backgroundColor: COLORS.red,
    color: "white",
  },
});
