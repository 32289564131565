import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "./OdontogramFormStyles";
import ReactTooltip from "react-tooltip";

import {
  buscarMobilidade,
  atualizarMobilidade,
} from "../../../actions/mobilidadeActions";

import { mobilidadeConst } from "../../../components/_constants/mobilidadeConst.js";
import IconDentalStatus from "../../common/IconDentalStatus";

class MobilidadeForm extends Component {
  constructor(props) {
    super(props);

    this.onClickTooth = this.onClickTooth.bind(this);
    this.onClickMobilidade = this.onClickMobilidade.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      modal: false,
      mobilidade: null,
      toothSelected: null,
    };
  }

  componentWillMount() {
    const { selectedPatient, buscarMobilidade } = this.props;

    var mobilidade;

    if (selectedPatient) {
      buscarMobilidade(selectedPatient._id, (ret) => {
        if (ret) {
          this.setState({
            mobilidade: ret,
          });
        }
      });
    }

    mobilidade = mobilidadeConst();
    mobilidade = mobilidade.mobilidade;

    this.setState({
      mobilidade: mobilidade,
    });
  }

  openModal() {
    this.setState({
      modal: true,
    });
  }

  closeModal() {
    this.setState({
      modal: false,
    });
  }

  onClickTooth(res) {
    this.setState({
      toothSelected: res.currentTarget.id,
    });
  }

  onClickMobilidade(res) {
    const { selectedPatient, atualizarMobilidade } = this.props;

    var split = this.state.toothSelected.split("_");
    var dente = split[0] + "_" + split[1];

    this.state.mobilidade[dente].grauMobilidade = res;

    atualizarMobilidade(this.state.mobilidade, selectedPatient._id, (ret) => {
      this.setState({
        mobilidade: ret,
      });
    });
  }

  renderOdontogram() {
    return (
      <div className={css(styles.status_odontogram)}>
        <div className={css(styles.status_top)}>
          <div className={css(styles.status_top_left)}>
            <div
              id={"tooth_18"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"18"} </span>
              <IconDentalStatus
                number={"18"}
                tooth={this.state.mobilidade.tooth_18}
              />
              <span>{this.state.mobilidade.tooth_18.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_17"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"17"} </span>
              <IconDentalStatus
                number={"17"}
                tooth={this.state.mobilidade.tooth_17}
              />
              <span>{this.state.mobilidade.tooth_17.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_16"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"16"} </span>
              <IconDentalStatus
                number={"16"}
                tooth={this.state.mobilidade.tooth_16}
              />
              <span>{this.state.mobilidade.tooth_16.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_15"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"15"} </span>
              <IconDentalStatus
                number={"15"}
                tooth={this.state.mobilidade.tooth_15}
              />
              <span>{this.state.mobilidade.tooth_15.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_14"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"14"} </span>
              <IconDentalStatus
                number={"14"}
                tooth={this.state.mobilidade.tooth_14}
              />
              <span>{this.state.mobilidade.tooth_14.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_13"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"13"} </span>
              <IconDentalStatus
                number={"13"}
                tooth={this.state.mobilidade.tooth_13}
              />
              <span>{this.state.mobilidade.tooth_13.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_12"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"12"} </span>
              <IconDentalStatus
                number={"12"}
                tooth={this.state.mobilidade.tooth_12}
              />
              <span>{this.state.mobilidade.tooth_12.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_11"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"11"} </span>
              <IconDentalStatus
                number={"11"}
                tooth={this.state.mobilidade.tooth_11}
              />
              <span>{this.state.mobilidade.tooth_11.grauMobilidade}</span>
            </div>
          </div>

          <div className={css(styles.status_top_right)}>
            <div
              id={"tooth_21"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"21"} </span>
              <IconDentalStatus
                number={"21"}
                tooth={this.state.mobilidade.tooth_21}
              />
              <span>{this.state.mobilidade.tooth_21.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_22"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"22"} </span>
              <IconDentalStatus
                number={"22"}
                tooth={this.state.mobilidade.tooth_22}
              />
              <span>{this.state.mobilidade.tooth_22.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_23"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"23"} </span>
              <IconDentalStatus
                number={"23"}
                tooth={this.state.mobilidade.tooth_23}
              />
              <span>{this.state.mobilidade.tooth_23.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_24"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"24"} </span>
              <IconDentalStatus
                number={"24"}
                tooth={this.state.mobilidade.tooth_24}
              />
              <span>{this.state.mobilidade.tooth_24.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_25"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"25"} </span>
              <IconDentalStatus
                number={"25"}
                tooth={this.state.mobilidade.tooth_25}
              />
              <span>{this.state.mobilidade.tooth_25.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_26"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"26"} </span>
              <IconDentalStatus
                number={"26"}
                tooth={this.state.mobilidade.tooth_26}
              />
              <span>{this.state.mobilidade.tooth_26.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_27"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"27"} </span>
              <IconDentalStatus
                number={"27"}
                tooth={this.state.mobilidade.tooth_27}
              />
              <span>{this.state.mobilidade.tooth_27.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_28"}
              className={css(styles.listItemStatusTop)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"28"} </span>
              <IconDentalStatus
                number={"28"}
                tooth={this.state.mobilidade.tooth_28}
              />
              <span>{this.state.mobilidade.tooth_28.grauMobilidade}</span>
            </div>
          </div>
        </div>

        <div className={css(styles.status_bot)}>
          <div className={css(styles.status_bot_left)}>
            <div
              id={"tooth_48"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"48"} </span>
              <IconDentalStatus
                number={"48"}
                tooth={this.state.mobilidade.tooth_48}
              />
              <span>{this.state.mobilidade.tooth_48.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_47"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"47"} </span>
              <IconDentalStatus
                number={"47"}
                tooth={this.state.mobilidade.tooth_47}
              />
              <span>{this.state.mobilidade.tooth_47.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_46"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"46"} </span>
              <IconDentalStatus
                number={"46"}
                tooth={this.state.mobilidade.tooth_46}
              />
              <span>{this.state.mobilidade.tooth_46.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_45"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"45"} </span>
              <IconDentalStatus
                number={"45"}
                tooth={this.state.mobilidade.tooth_45}
              />
              <span>{this.state.mobilidade.tooth_45.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_44"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"44"} </span>
              <IconDentalStatus
                number={"44"}
                tooth={this.state.mobilidade.tooth_44}
              />
              <span>{this.state.mobilidade.tooth_44.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_43"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"43"} </span>
              <IconDentalStatus
                number={"43"}
                tooth={this.state.mobilidade.tooth_43}
              />
              <span>{this.state.mobilidade.tooth_43.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_42"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"42"} </span>
              <IconDentalStatus
                number={"42"}
                tooth={this.state.mobilidade.tooth_42}
              />
              <span>{this.state.mobilidade.tooth_42.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_41"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"41"} </span>
              <IconDentalStatus
                number={"41"}
                tooth={this.state.mobilidade.tooth_41}
              />
              <span>{this.state.mobilidade.tooth_41.grauMobilidade}</span>
            </div>
          </div>

          <div className={css(styles.status_bot_right)}>
            <div
              id={"tooth_31"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"31"} </span>
              <IconDentalStatus
                number={"31"}
                tooth={this.state.mobilidade.tooth_31}
              />
              <span>{this.state.mobilidade.tooth_31.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_32"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"32"} </span>
              <IconDentalStatus
                number={"32"}
                tooth={this.state.mobilidade.tooth_32}
              />
              <span>{this.state.mobilidade.tooth_32.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_33"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"33"} </span>
              <IconDentalStatus
                number={"33"}
                tooth={this.state.mobilidade.tooth_33}
              />
              <span>{this.state.mobilidade.tooth_33.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_34"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"34"} </span>
              <IconDentalStatus
                number={"34"}
                tooth={this.state.mobilidade.tooth_34}
              />
              <span>{this.state.mobilidade.tooth_34.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_35"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"35"} </span>
              <IconDentalStatus
                number={"35"}
                tooth={this.state.mobilidade.tooth_35}
              />
              <span>{this.state.mobilidade.tooth_35.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_36"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"36"} </span>
              <IconDentalStatus
                number={"36"}
                tooth={this.state.mobilidade.tooth_36}
              />
              <span>{this.state.mobilidade.tooth_36.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_37"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"37"} </span>
              <IconDentalStatus
                number={"37"}
                tooth={this.state.mobilidade.tooth_37}
              />
              <span>{this.state.mobilidade.tooth_37.grauMobilidade}</span>
            </div>
            <div
              id={"tooth_38"}
              className={css(styles.listItemStatusBot)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberBot)}> {"38"} </span>
              <IconDentalStatus
                number={"38"}
                tooth={this.state.mobilidade.tooth_38}
              />
              <span>{this.state.mobilidade.tooth_38.grauMobilidade}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.mobilidade) {
      return (
        <div className={css(styles.flex)}>
          <form className={css(styles.form2)}>
            <div className={css(styles.sectionStatus)}>
              <div className={css(styles.flex, styles.flex2)}>
                {this.renderOdontogram(this.state.mobilidade)}
              </div>
            </div>
            <ReactTooltip
              place="left"
              globalEventOff="click"
              effect="solid"
              className={css(styles.pointer)}
              offset={{ top: 10, left: -15 }}
            >
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickMobilidade(0);
                }}
              >
                Remover
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickMobilidade(1);
                }}
              >
                Grau 1
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickMobilidade(2);
                }}
              >
                Grau 2
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickMobilidade(3);
                }}
              >
                Grau 3
              </div>
            </ReactTooltip>
          </form>
        </div>
      );
    }
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  return errors;
}

const mobilidadeForm = reduxForm({
  validate,
  form: "mobilidadeForm",
})(MobilidadeForm);

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;
  let initialValues = {};

  if (selectedPatient) {
    initialValues = selectedPatient;
  }

  return {
    selectedPatient: state.patientsCreation.selectedPatient,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  buscarMobilidade,
  atualizarMobilidade,
  mobilidadeConst,
})(mobilidadeForm);
