import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

const columns = [
  "Data",
  "Abertura",
  "Preparação",
  "Medição",
  "Selamento",
  "Obturação",
  "Drenagem",
];

export default function EdondonticSessionTable({ rows, handleChangeSessions }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.name}>
              {Object.keys(row).map((item) => (
                <TableCell>
                  {item === "date" ? (
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        label=""
                        format="dd/MM/yyyy"
                        value={row.date}
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onChange={(date) => {
                          handleChangeSessions(date, index, item);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={row[item]}
                        onChange={(e) => {
                          handleChangeSessions(e.target.checked, index, item);
                        }}
                      />
                    </TableCell>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
