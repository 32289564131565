import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GroupIcon from "@material-ui/icons/Group";

import FornecedorCadastro from "./components/FornecedorCadastro";
import FornecedorListagem from "./components/FornecedorListagem";
import { Paper } from "@material-ui/core";

class FornecedorRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [{ url: "listagem", numero: 0 }],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Fornecedores"
            icon={<GroupIcon />}
          />
        </BottomNavigation>
        <Paper style={{ minHeight: "83vh", paddingBottom: "20px" }}>
          <Switch>
            <Route
              exact
              path={`${match.url}/listagem`}
              render={(props) => (
                <FornecedorListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/cadastrarFornecedor/:fornecedor_id?`}
              render={(props) => <FornecedorCadastro {...props} />}
            />
            <Route
              exact
              path={`${match.url}/`}
              render={() => <Redirect to={`${match.url}/listagem`} />}
            />
          </Switch>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // selectDentist: state.patientsCreation.selectDentist,
  };
}
export default connect(mapStateToProps)(FornecedorRouter);
