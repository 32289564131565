import {
  ABRIR_MODAL,
  ESCONDER_MODAL,
} from "../actions/notificacao/NotificacaoAction";

export default function (state = { show: false }, action) {
  switch (action.type) {
    case ABRIR_MODAL:
      return { show: true, payload: action.payload };
    case ESCONDER_MODAL:
      return { show: false, payload: action.payload };
    default:
      return state;
  }
}
