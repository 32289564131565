import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";

import ServicosListagem from "./components/ServicosListagem.jsx";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Face from "@material-ui/icons/Face";
import Archive from "@material-ui/icons/Archive";
import Settings from "@material-ui/icons/Settings";
import ServicosConfig from "./components/ServicosConfig";
import ServicosCadastro from "./components/ServicosCadastro";
import ServicosCadastroTabela from "./components/ServicosCadastroTabela.jsx";
import { Paper } from "@material-ui/core";
import TabelaServicoListagem from "./components/TabelaServicoListagem";
import ServicosConfigTabela from "./components/ServicosConfigTabela";
import FormCadastroServico from "./components/FormCadastroServico.jsx";

class ServicossRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "/", numero: 0 },
        // { url: "configuracoes", numero: 1 }
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}`)}
            label="Tabela de Serviço"
            icon={<Face />}
          />
          {/* <BottomNavigationAction onClick={() => this.props.history.push(`${match.url}/config`)} label="Configurações" icon={<Settings />} /> */}
        </BottomNavigation>
        <Switch>
          <Route
            path={`${match.url}/config`}
            render={(props) => (
              <ServicosConfig
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/`}
            render={(props) => (
              <TabelaServicoListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/cadastrarTabela`}
            render={(props) => <ServicosCadastroTabela {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:tabela_id?`}
            render={(props) => <ServicosCadastroTabela {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:tabela_id/servicos`}
            render={(props) => <ServicosListagem {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:tabela_id/servicos/novo`}
            render={(props) => <FormCadastroServico {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:tabela_id/servicos/:servico_id`}
            render={(props) => <FormCadastroServico {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
}
export default connect(mapStateToProps)(ServicossRouter);
