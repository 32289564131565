import React, { useState, useEffect } from "react";
import { buscarTodos } from "../../../../../api/clientes";
import MUIDataTable from "mui-datatables";
import { Paper, Button, Container, IconButton } from "@material-ui/core";
import { Add, Visibility } from "@material-ui/icons";
import moment from "moment";
import TableComponent from "../../../../table/TableComponent";

function ClienteListagem({ history, match }) {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    buscarTodos((res) => setClientes(res));
  }, []);

  const colunas = [
    { label: "Nome", name: "nome" },
    { label: "CNPJ/CPF", name: "cnpj" },
    {
      label: "Data criação",
      name: "dataCriacao",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: "Status",
      name: "ativo",
      options: {
        customBodyRender: (value) => {
          return value ? "Ativo" : "Desativado";
        },
      },
    },
    {
      label: " ",
      name: "clientId",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                title="Visualizar cliente"
                onClick={() => history.push(`${match.url}/${value}`)}
              >
                <Visibility />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <div style={{ marginBottom: "16px", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`${match.url}/novo`)}
        >
          <Add />
          Novo
        </Button>
      </div>
      <TableComponent
        colunas={colunas}
        objetos={clientes}
        titulo="Lista de clientes"
      />
    </>
  );
}

export default ClienteListagem;
