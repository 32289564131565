import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { changePassRecover } from "../../actions/auth";

import { css } from "aphrodite/no-important";
import { styles } from "./RememberPassFormStyles";

import LinkExternal from "../navigation/LinkExternal";
import Button from "../common/Button";
import InputField from "./InputField";
import { toast } from "react-toastify";

// ChangePassForm handles the form where the user enter the app
class ChangePassForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.renderChangeSucess = this.renderChangeSucess.bind(this);

    this.state = {
      emailsent: false,
    };
  }

  onSubmit({ password }) {
    const { changePassRecover } = this.props;
    const token = new URLSearchParams(window.location.search).get("tk");

    if (!token) {
      toast.error("Url inválida, acesse seu e-mail novamente!");
      return;
    }

    changePassRecover({ token, password });
  }

  renderChangeSucess() {
    const { auth } = this.props;

    if (auth.authenticated === "unauthorized") {
      return (
        <div className={css(styles.msgAuth)}>
          <span className={css(styles.msgSpan)}>
            Não conseguimos reconhecer esta conta. Gostaria de tentar novamente
            ou
          </span>
          <LinkExternal
            text="criar uma conta nova?"
            url="http://www.softmanager.com.br/contato/"
            decoration={false}
          />
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className={css(styles.form)} onSubmit={handleSubmit(this.onSubmit)}>
        {this.renderChangeSucess()}

        <Field
          type="password"
          name="password"
          label="Senha"
          component={InputField}
        />

        <Field
          type="password"
          name="confirmPassword"
          label="Confirme sua senha"
          component={InputField}
        />

        <Button text="Enviar" size="large" color="green" submit />
      </form>
    );
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  if (!values.password) errors.password = "Preencha a senha!";
  if (!values.confirmPassword) errors.confirmPassword = "Preencha a senha!";

  if (values.confirmPassword !== values.password)
    errors.confirmPassword = "Senhas não conferem!";

  return errors;
}

const changePassForm = reduxForm({
  validate,
  form: "changePassForm",
})(ChangePassForm);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { changePassRecover })(changePassForm);
