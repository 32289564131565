import axios from "axios";

export async function getMedicalRecord(medicalRecordId) {
  return await axios.get(`/api/medicalRecord/get/${medicalRecordId}`);
}

export async function listMedicalRecord(filters) {
  return await axios.get("/api/medicalRecord/list", {
    params: filters,
  });
}

export async function createMedicalRecord(medicalRecord) {
  return await axios.post("/api/medicalRecord/create", medicalRecord);
}

export async function editMedicalRecord(medicalRecord) {
  return await axios.put("/api/medicalRecord/edit", medicalRecord);
}
