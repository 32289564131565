import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { calcSimulation } from "../../../../api/budget";

const defaultSimulation = {
  grossAmount: 0,
  discountValue: 0,
  netValue: 0,
  entranceValue: 0,
  parcelValue: 0,
  interestValue: 0,
  paidValue: 0,
  balance: 0,
};

export default function BudgetSimulator({ budget, onClose, generateParcels }) {
  const [simulation, setSimulation] = useState(defaultSimulation);
  const [discount, setDiscount] = useState(budget.discount);
  const [numberOfParcels, setNumberOfParcels] = useState(budget.parcels.length);
  const [entrance, setEntrance] = useState(budget.entrance);
  const [firstExpiration, setFirstExpiration] = useState(new Date());

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await calcSimulation({
          budgetId: budget._id,
          entrance,
          discount,
          numberOfParcels,
        });
        setSimulation(data);
      } catch (err) {}
    };
    fetch();
  }, []);

  const fetchCalcSimulation = async () => {
    try {
      const { data } = await calcSimulation({
        budgetId: budget._id,
        entrance,
        discount,
        numberOfParcels,
      });

      setSimulation(data);
    } catch (err) {}
  };

  const onSubmit = () => {
    generateParcels(entrance, discount, numberOfParcels, firstExpiration);
  };

  const table = [
    {
      title: "Total bruto",
      value: formataMoedaReal(simulation.grossAmount),
    },
    { title: "Desconto", value: formataMoedaReal(simulation.discountValue) },
    { title: "Total líquido", value: formataMoedaReal(simulation.netValue) },
    { title: "Entrada", value: formataMoedaReal(simulation.entranceValue) },
    { title: "Juro", value: formataMoedaReal(simulation.interestValue) },
    { title: "Parcela", value: formataMoedaReal(simulation.parcelValue) },
    {
      title: "Total pago",
      value: formataMoedaReal(simulation.paidValue),
    },
    { title: "Saldo", value: formataMoedaReal(simulation.balance) },
  ];

  return (
    <Dialog maxWidth="sm" open={true}>
      <DialogTitle>Gerar parcelamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <TextField
              label="Desconto"
              type="number"
              variant="outlined"
              value={discount.value_calc || ""}
              onChange={(e) =>
                setDiscount({ ...discount, value_calc: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              select
              value={discount.value_type}
              onChange={(e) =>
                setDiscount({ ...discount, value_type: e.target.value })
              }
            >
              <MenuItem value="percent">%</MenuItem>
              <MenuItem value="money">R$</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              type="number"
              label="N. Parcelas"
              variant="outlined"
              value={numberOfParcels || ""}
              onChange={(e) => setNumberOfParcels(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              type="number"
              label="Entrada"
              variant="outlined"
              value={entrance.value_calc || ""}
              disabled={entrance.paid}
              onChange={(e) =>
                setEntrance({ ...entrance, value_calc: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              select
              disabled={entrance.paid}
              value={entrance.value_type}
              onChange={(e) =>
                setEntrance({ ...entrance, value_type: e.target.value })
              }
            >
              <MenuItem value="percent">%</MenuItem>
              <MenuItem value="money">R$</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                invalidDateMessage="Data em formato inválido."
                label="Vencimento primeira parcela"
                format="dd/MM/yyyy"
                disabled={entrance.paid}
                value={firstExpiration}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => setFirstExpiration(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={fetchCalcSimulation}
            >
              Calcular
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Container maxWidth="sm">
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>R$</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table.map((item, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={onClose}>
          Fechar
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={onSubmit}
        >
          Gerar Parcelas
        </Button>
      </DialogActions>
    </Dialog>
  );
}
