import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  buscarContasPagas,
  editarContasPagas,
  cadastrarContasPagas,
  buscarContas,
} from "../../../../api/contasPagar";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

class ContasPagasCadastro extends Component {
  state = {
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    contaPaga: {
      valorPago: null,
      dataPagamento: null,
      observacao: "",
      contasPagar: "",
    },
    contas: [],
    cadastro: true,
    await: true,
    buttonDisabled: false,
    dataRegistrada: false, // Adicionado para verificar se a data já foi registrada
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.contaPaga_id) {
      var objContaPaga = await buscarContasPagas(
        clinica._id,
        this.props.match.params.contaPaga_id
      );

      const contas = [
        {
          ...objContaPaga,
          nome: objContaPaga.nome,
          observacao: objContaPaga.observacao,
          indice: objContaPaga.indice,
        },
      ];

      this.setState({
        cadastro: false,
        contaPaga: objContaPaga,
        await: false,
        contas,
        dataRegistrada: objContaPaga.dataPagamento !== null, // Verifica se a data já foi registrada
      });
    } else {
      var objConta = await buscarContas(clinica._id);

      const contas = [];

      if (objConta.length > 0)
        objConta.map((obj) => {
          var pago = 0;

          // obj.contasPagas.length > 0 &&
          //   obj.contasPagas.map((de) => {
          //     pago = pago + de.valorPago;
          //   });
          if (obj.parcelas.valor - pago > 0) {
            var novo = {};
            novo.nome = obj.parcelas.nome;
            novo.parcelaId = obj.parcelas.parcelaId;
            novo.valor = obj.parcelas.valor;
            novo.indice = obj.parcelas.indice;
            contas.push(novo);
          }
        });
      this.setState({
        await: false,
        contaPaga_id: this.props.match.params.contaPaga_id,
        contas,
      });
    }
  }

  editaContaPaga() {
    const objeto = this.state.contaPaga;
    this.setState({ buttonDisabled: true });
    editarContasPagas(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Conta Paga editada com sucesso",
              abrir: true,
            },
          });
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o lançamento da Conta Paga!",
              abrir: true,
            },
          });
        }
      })
      .then(() => this.setState({ buttonDisabled: false }));
  }

  salvarContaPaga() {
    const objeto = this.state.contaPaga;
    this.setState({ buttonDisabled: true });
    const { clinica } = this.props;
    cadastrarContasPagas(clinica, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Conta Paga cadastrada com sucesso",
              abrir: true,
            },
          });
          this.props.history.goBack();
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o lançamento da conta paga!",
              abrir: true,
            },
          });
        }
      })
      .then(() => this.setState({ buttonDisabled: false }));
  }

  onSubmit = () => {
    if (this.state.cadastro) {
      this.salvarContaPaga();
    } else {
      this.editaContaPaga();
    }
  };

  render() {
    return (
      <div>
        <Paper>
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
              onClose={() =>
                this.setState({
                  alerta: { ...this.state.alerta, abrir: false },
                })
              }
            />
          )}
          {!this.state.await && (
            <Container maxWidth="sm">
              <Box padding="16px 0 16px 0">
                <Typography variant="h6">
                  {this.state.cadastro
                    ? "Inserir conta paga"
                    : "Editar conta paga"}
                </Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição da conta"
                    variant="outlined"
                    select
                    // disabled={!this.state.cadastro}
                    value={this.state.contaPaga.contasPagar}
                    onChange={(e) =>
                      this.setState({
                        contaPaga: {
                          ...this.state.contaPaga,
                          contasPagar: e.target.value,
                        },
                      })
                    }
                  >
                    {this.state.contas.map((conta) => (
                      <MenuItem
                        key={conta.parcelaId}
                        value={
                          this.state.cadastro
                            ? conta.parcelaId
                            : this.state.contaPaga.contasPagar
                        }
                      >
                        {`Credor: ${conta.nome} ---- Número da Parcela: ${conta.indice}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <NumberFormat
                    fullWidth
                    required
                    label="Valor pago"
                    customInput={TextField}
                    variant="outlined"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"R$"}
                    // disabled={!this.state.cadastro}
                    value={this.state.contaPaga.valorPago}
                    onValueChange={(values) =>
                      this.setState({
                        contaPaga: {
                          ...this.state.contaPaga,
                          valorPago: values.floatValue,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      label="Data de pagamento"
                      format="dd/MM/yyyy"
                      value={this.state.contaPaga.dataPagamento}
                      disabled={this.state.dataRegistrada}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(date) => {
                        this.setState({
                          contaPaga: {
                            ...this.state.contaPaga,
                            dataPagamento: date,
                          },
                        });
                      }}
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Observação"
                    variant="outlined"
                    fullWidth
                    value={this.state.contaPaga.observacao}
                    onChange={(e) =>
                      this.setState({
                        contaPaga: {
                          ...this.state.contaPaga,
                          observacao: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    style={{ marginRight: "16px" }}
                    disableElevation
                    variant="contained"
                    onClick={() => this.props.history.goBack()}
                  >
                    Voltar
                  </Button>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={this.onSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Container>
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ContasPagasCadastro);
