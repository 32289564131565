export const endodonticChannels = [
  {
    label: "Canal",
    name: "channel",
  },
  {
    label: "Odontometria",
    name: "odontometric",
  },
  {
    label: "Comp. Real",
    name: "realLength",
  },
  {
    label: "Comp. Trabalho",
    name: "workLength",
  },
  {
    label: "Limas",
    name: "lime",
  },
  {
    label: "Broca",
    name: "drill",
  },
  {
    label: "Núcleo",
    name: "core",
  },
  {
    label: "Referência",
    name: "reference",
  },
];

export const endodonticProceduresObjectFormat = {
  xray: {
    label: "Raio X",
    options: [
      { label: "RA", cod: "1" },
      { label: "Mesial", cod: "2" },
      { label: "Distal", cod: "3" },
    ],
  },
  diagnostic: {
    label: "Diagnóstico",
    options: [
      { label: "Pulpite Incipiente (Hiperimia)", cod: "1" },
      { label: "Pulpite Reversivel", cod: "2" },
      { label: "Pulpite Irreversivel", cod: "3" },
      { label: "Necrose Pulpar", cod: "4" },
      { label: "Necrose - Lesão Cronica", cod: "5" },
      { label: "Necrose - Lesao em fase aguda", cod: "6" },
      { label: "Necrose - Sem lesao", cod: "7" },
      { label: "Neuralgia Facial", cod: "8" },
      { label: "Retratamento", cod: "9" },
      { label: "Endodontia para protese", cod: "10" },
    ],
  },
  dentalTrauma: {
    label: "Trauma Dental",
    options: [
      { label: "Frat. Esmalte", cod: "1" },
      { label: "Frat. Esmalte/Dentina S/Envolv.Pulpar", cod: "2" },
      { label: "Frat. Esmalte/Dentina C/Envolv.Pulpar", cod: "3" },
      { label: "Frat. Radicular Vertical", cod: "4" },
      { label: "Frat. Radicular Horizontal Cervical", cod: "5" },
      { label: "Frat. Radicular Horizontal Medio", cod: "6" },
      { label: "Frat. Radicular Horizontal Apical", cod: "7" },
      { label: "Concussão", cod: "8" },
      { label: "Sub-Luxação", cod: "9" },
      { label: "Luxação Intrusiva", cod: "10" },
      { label: "Luxação Extrusiva", cod: "11" },
      { label: "Luxação Lateral", cod: "12" },
      { label: "Avulsão", cod: "13" },
    ],
  },
  procedure: {
    label: "Procedimento",
    options: [
      { label: "Contenção Rigida", cod: "1" },
      { label: "Contenção Semi-rigida", cod: "2" },
    ],
  },
  curative: {
    label: "Curativos",
    options: [
      { label: "Corticosteroide", cod: "1" },
      { label: "Ca (OH) 2", cod: "2" },
      { label: "P.M.C.F.C", cod: "3" },
      { label: "Ca(OH)2+P.M.C.F.C", cod: "4" },
      { label: "Anti-inflamatório", cod: "5" },
      { label: "Antibiotico", cod: "6" },
    ],
  },
  irrigationSolutions: {
    label: "Soluções Irrigadoras",
    options: [
      { label: "Detergente", cod: "1" },
      { label: "Hipoclorito de Na (0,5%)", cod: "2" },
      { label: "Hipoclorito de Na (1,0%)", cod: "3" },
      { label: "Hipoclorito de Na (2,5%)", cod: "4" },
      { label: "Hipoclorito de Na (2 a 4%)", cod: "5" },
      { label: "Solucao Saturada de Ca(OH)2", cod: "6" },
      { label: "Soro Fisiologico", cod: "7" },
      { label: "Tween 80", cod: "8" },
      { label: "Endo PTC", cod: "9" },
      { label: "EDTA", cod: "10" },
    ],
  },
  preparionTechniques: {
    label: "Técnicas de preparo",
    options: [
      { label: "Convencional", cod: "1" },
      { label: "Escalonada", cod: "2" },
      { label: "Modificada", cod: "3" },
      { label: "Apicificação", cod: "4" },
      { label: "Apicificação Apicegenese", cod: "5" },
      { label: "Ins.Aut. Sonico", cod: "6" },
      { label: "Ins.Aut. Ultrasonico", cod: "7" },
      { label: "Ins.Aut. Canal Finder", cod: "8" },
    ],
  },
  dentalCement: {
    label: "Cimento Obturador",
    options: [
      { label: "Oxido de Zinco e Eugenol", cod: "1" },
      { label: "N-Rickert", cod: "2" },
      { label: "Rickert", cod: "3" },
      { label: "Rickertin", cod: "4" },
      { label: "Endomethazone", cod: "5" },
      { label: "Alfacanal", cod: "6" },
      { label: "Grossman", cod: "7" },
      { label: "Fillcanal", cod: "8" },
      { label: "Tricanal", cod: "9" },
      { label: "Ca(OH)2", cod: "10" },
      { label: "Selapex", cod: "11" },
      { label: "Apexit", cod: "12" },
      { label: "Resina Policarboxilato", cod: "13" },
      { label: "Sealer", cod: "14" },
      { label: "AH-26", cod: "15" },
      { label: "Diakit A", cod: "16" },
    ],
  },
  typeFilling: {
    label: "Tipo de Obturação",
    options: [
      { label: "Convencional", cod: "1" },
      { label: "McSpaden", cod: "2" },
      { label: "Termoplastificada", cod: "3" },
    ],
  },
};

export const endodonticProcedures = [
  {
    label: "Raio X",
    name: "xray",
    options: [
      { label: "RA", cod: "1" },
      { label: "Mesial", cod: "2" },
      { label: "Distal", cod: "3" },
    ],
  },
  {
    label: "Diagnóstico",
    name: "diagnostic",
    options: [
      { label: "Pulpite Incipiente (Hiperimia)", cod: "1" },
      { label: "Pulpite Reversivel", cod: "2" },
      { label: "Pulpite Irreversivel", cod: "3" },
      { label: "Necrose Pulpar", cod: "4" },
      { label: "Necrose - Lesão Cronica", cod: "5" },
      { label: "Necrose - Lesao em fase aguda", cod: "6" },
      { label: "Necrose - Sem lesao", cod: "7" },
      { label: "Neuralgia Facial", cod: "8" },
      { label: "Retratamento", cod: "9" },
      { label: "Endodontia para protese", cod: "10" },
    ],
  },
  {
    label: "Trauma Dental",
    name: "dentalTrauma",
    options: [
      { label: "Frat. Esmalte", cod: "1" },
      { label: "Frat. Esmalte/Dentina S/Envolv.Pulpar", cod: "2" },
      { label: "Frat. Esmalte/Dentina C/Envolv.Pulpar", cod: "3" },
      { label: "Frat. Radicular Vertical", cod: "4" },
      { label: "Frat. Radicular Horizontal Cervical", cod: "5" },
      { label: "Frat. Radicular Horizontal Medio", cod: "6" },
      { label: "Frat. Radicular Horizontal Apical", cod: "7" },
      { label: "Concussão", cod: "8" },
      { label: "Sub-Luxação", cod: "9" },
      { label: "Luxação Intrusiva", cod: "10" },
      { label: "Luxação Extrusiva", cod: "11" },
      { label: "Luxação Lateral", cod: "12" },
      { label: "Avulsão", cod: "13" },
    ],
  },
  {
    label: "Procedimento",
    name: "procedure",
    options: [
      { label: "Contenção Rigida", cod: "1" },
      { label: "Contenção Semi-rigida", cod: "2" },
    ],
  },
  {
    label: "Curativos",
    name: "curative",
    options: [
      { label: "Corticosteroide", cod: "1" },
      { label: "Ca (OH) 2", cod: "2" },
      { label: "P.M.C.F.C", cod: "3" },
      { label: "Ca(OH)2+P.M.C.F.C", cod: "4" },
      { label: "Anti-inflamatório", cod: "5" },
      { label: "Antibiotico", cod: "6" },
    ],
  },
  {
    label: "Soluções Irrigadoras",
    name: "irrigationSolutions",
    options: [
      { label: "Detergente", cod: "1" },
      { label: "Hipoclorito de Na (0,5%)", cod: "2" },
      { label: "Hipoclorito de Na (1,0%)", cod: "3" },
      { label: "Hipoclorito de Na (2,5%)", cod: "4" },
      { label: "Hipoclorito de Na (2 a 4%)", cod: "5" },
      { label: "Solucao Saturada de Ca(OH)2", cod: "6" },
      { label: "Soro Fisiologico", cod: "7" },
      { label: "Tween 80", cod: "8" },
      { label: "Endo PTC", cod: "9" },
      { label: "EDTA", cod: "10" },
    ],
  },
  {
    label: "Técnicas de preparo",
    name: "preparionTechniques",
    options: [
      { label: "Convencional", cod: "1" },
      { label: "Escalonada", cod: "2" },
      { label: "Modificada", cod: "3" },
      { label: "Apicificação", cod: "4" },
      { label: "Apicificação Apicegenese", cod: "5" },
      { label: "Ins.Aut. Sonico", cod: "6" },
      { label: "Ins.Aut. Ultrasonico", cod: "7" },
      { label: "Ins.Aut. Canal Finder", cod: "8" },
    ],
  },
  {
    label: "Cimento Obturador",
    name: "dentalCement",
    options: [
      { label: "Oxido de Zinco e Eugenol", cod: "1" },
      { label: "N-Rickert", cod: "2" },
      { label: "Rickert", cod: "3" },
      { label: "Rickertin", cod: "4" },
      { label: "Endomethazone", cod: "5" },
      { label: "Alfacanal", cod: "6" },
      { label: "Grossman", cod: "7" },
      { label: "Fillcanal", cod: "8" },
      { label: "Tricanal", cod: "9" },
      { label: "Ca(OH)2", cod: "10" },
      { label: "Selapex", cod: "11" },
      { label: "Apexit", cod: "12" },
      { label: "Resina Policarboxilato", cod: "13" },
      { label: "Sealer", cod: "14" },
      { label: "AH-26", cod: "15" },
      { label: "Diakit A", cod: "16" },
    ],
  },
  {
    label: "Tipo de Obturação",
    name: "typeFilling",
    options: [
      { label: "Convencional", cod: "1" },
      { label: "McSpaden", cod: "2" },
      { label: "Termoplastificada", cod: "3" },
    ],
  },
];

export const endodonticSessions = [
  { label: "Data", name: "date" },
  { label: "Abertura", name: "opening" },
  { label: "Preparação", name: "preparation" },
  { label: "Medição", name: "measurement" },
  { label: "Selamento", name: "sealing" },
  { label: "Obturação", name: "obturation" },
  { label: "Drenagem", name: "dressing" },
];

export const endodonticOdontometric = [
  { name: "digitalDenistry", label: "Odontometria Eletrônica" },
  { name: "ultrasonicInstrumentation", label: "Instrumentação Ultrasônica" },
  { name: "digitalXray", label: "Raio X Digital" },
];
