import { StyleSheet } from "aphrodite/no-important";

export const styles = StyleSheet.create({
  form: {
    margin: "1.5rem 0 1rem 0",
  },
  questions: {
    marginTop: "1rem",
    marginLeft: "1rem",
  },
  bottom5rem: {
    marginBottom: ".5rem",
  },
  checkOption: {
    marginLeft: ".5rem",
    fontSize: ".75rem",
  },
  modal: {
    width: "500px",
  },
  modalForm: {
    padding: "1rem",
  },
  modalMsg: {
    marginBottom: ".5rem",
  },
});
