import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import PacientesConfigSituacao from "./PacientesConfigSituacao";
import PacientesConfigStatus from "./PacientesConfigStatus";
import PacientesConfigStatusItem from "./PacientesConfigStatusItem";
import PacientesSituacao from "./PacientesSituacao";
import PacientesStatus from "./PacientesStatus";
import PacientesStatusItem from "./PacientesStatusItem";
import PacientesConfigFichaATM from "./PacientesConfigFichaATM";
import PacientesConfigFichaAnamnese from "./PacientesConfigFichaAnamnese";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    minHeight: "83vh",
    position: "relative",
  },
}));

function PacientesConfig(props) {
  useEffect(() => {
    const { tab, trocarTab } = props;

    const tabSelecionada = tab.lista.filter(
      (tab) => tab.url === "configuracoes",
    )[0];

    if (tabSelecionada.numero !== tab.tabAtiva) {
      trocarTab(tabSelecionada.numero);
    }
  });

  const classes = useStyles();
  const { match } = props;

  return (
    <Paper className={classes.paper}>
      {match.isExact && (
        <List
          component="nav"
          className={classes.root}
          aria-label="Configurações"
        >
          <ListItem
            button
            onClick={() => props.history.push(`${match.url}/situacao`)}
          >
            <ListItemText primary="Cadastro de situações" />
          </ListItem>
          <Divider />
          <ListItem
            button
            divider
            onClick={() => props.history.push(`${match.url}/status`)}
          >
            <ListItemText primary="Cadastro de status" />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => props.history.push(`${match.url}/configAtm`)}
          >
            <ListItemText primary="Configuração ficha ATM" />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => props.history.push(`${match.url}/configAnamnese`)}
          >
            <ListItemText primary="Configuração ficha Anamnese" />
          </ListItem>
        </List>
      )}
      <Switch>
        <Route
          exact
          path={`${match.url}/situacao`}
          render={(props) => <PacientesConfigSituacao {...props} />}
        />
        <Route
          exact
          path={`${match.url}/situacao/novo`}
          render={(props) => <PacientesSituacao {...props} />}
        />
        <Route
          exact
          path={`${match.url}/situacao/:situacao_id?`}
          render={(props) => <PacientesSituacao {...props} />}
        />
        <Route
          exact
          path={`${match.url}/status`}
          render={(props) => <PacientesConfigStatus {...props} />}
        />
        <Route
          exact
          path={`${match.url}/status/:status_id`}
          render={(props) => <PacientesConfigStatusItem {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarStatus/:status_id?`}
          render={(props) => <PacientesStatus {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarStatusItem/:statusItem_id?`}
          render={(props) => <PacientesStatusItem {...props} />}
        />
        <Route
          exact
          path={`${match.url}/configAtm/:ficha?`}
          render={(props) => <PacientesConfigFichaATM {...props} />}
        />
        <Route
          exact
          path={`${match.url}/configAnamnese/:ficha?`}
          render={(props) => <PacientesConfigFichaAnamnese {...props} />}
        />
      </Switch>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(PacientesConfig);
