import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { formataMoedaReal } from "../../../../helpers/Helpers";

export default function BudgetResume({ resume }) {
  const {
    grossValue,
    discountValue,
    netValue,
    entranceValue,
    interestValue,
    parcelValue,
    paidValue,
    balance,
  } = resume;
  const tabela = [
    { titulo: "Total bruto", percent: 0, valor: formataMoedaReal(grossValue) },
    { titulo: "Desconto", percent: 0, valor: formataMoedaReal(discountValue) },
    {
      titulo: "Total líquido",
      percent: 0,
      valor: formataMoedaReal(netValue),
    },
    { titulo: "Entrada", percent: 0, valor: formataMoedaReal(entranceValue) },
    { titulo: "Juro", percent: 0, valor: formataMoedaReal(interestValue) },
    { titulo: "Parcela", percent: 0, valor: formataMoedaReal(parcelValue) },
    // { titulo: 'Total + Juro', percent: 0, valor: formataMoedaReal(totalJuro) },
    { titulo: "Total pago", percent: 0, valor: formataMoedaReal(paidValue) },
    { titulo: "Saldo", percent: 0, valor: formataMoedaReal(balance) },
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>(%)</TableCell>
            <TableCell>R$</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabela.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell>{item.titulo}</TableCell>
              <TableCell>{item.porcento}</TableCell>
              <TableCell>{item.valor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
