import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { teeth, getChannel } from "../../../../utils/teeth";
import TableComponent from "../../../table/TableComponent";
import { createEndodontic, updateEndodontic } from "../../../../api/pacientes";
import { toast } from "react-toastify";
import { Add } from "@material-ui/icons";
import {
  endodonticChannels,
  endodonticProcedures,
  endodonticProceduresObjectFormat,
  endodonticOdontometric,
} from "../../../../utils/endodontic";

import EndodonticSessionTable from "./endodontic_session_table";

const defaultChannel = {
  channel: null,
  odontometric: null,
  realLength: null,
  workLength: null,
  lime: null,
  drill: null,
  core: null,
  reference: null,
};

const defaultOdontometricOptions = {
  digitalDenistry: false,
  ultrasonicInstrumentation: false,
  digitalXray: false,
};

const defaultSession = {
  date: new Date(),
  opening: false,
  preparation: false,
  measurement: false,
  sealing: false,
  obturation: false,
  dressing: false,
};

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTableToolbar: {
      root: {
        display: "none",
      },
    },
  },
});

export default function EndodonticForm({
  patientId,
  currentEndodontic,
  history,
}) {
  const [tooth, setTooth] = useState(null);
  const [channels, setChannels] = useState([]);
  const [odontometricOptions, setOdontometricOptions] = useState(
    defaultOdontometricOptions,
  );
  const [procedures, setProcedures] = useState([]);
  const [procedureToAdd, setProcedureToAdd] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (currentEndodontic) {
      setTooth(currentEndodontic.tooth);
      setChannels(currentEndodontic.channels);
      setOdontometricOptions(currentEndodontic.odontometricOptions);
      setProcedures(currentEndodontic.procedures);
      setSessions(currentEndodontic.sessions);
      setComments(currentEndodontic.comments);
    }
  }, [currentEndodontic]);

  const columnsChannel = useMemo(() => {
    const nextColumns = endodonticChannels.map((column) => {
      return {
        ...column,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <TextField
                size="small"
                defaultValue={value}
                onChange={(event) => {
                  updateValue(event.target.value);
                  handleChangeColumnChannel(
                    event.target.value,
                    tableMeta.rowIndex,
                    column.name,
                  );
                }}
              />
            </>
          ),
        },
      };
    });
    return nextColumns;
  }, [channels]);

  const handleChangeColumnChannel = useCallback(
    (value, row, column) => {
      const nextChannels = JSON.parse(JSON.stringify(channels));
      nextChannels[row][column] = Number(value);
      setChannels(nextChannels);
    },
    [channels],
  );

  const handleChangeTooth = (event, newValue) => {
    const channel = getChannel(newValue);
    if (!channel) return;
    setChannels([...Array(Number(channel))].map(() => defaultChannel));
    setTooth(newValue);
  };

  const handleChangeChannel = (event) => {
    const diffLengthChannels = Number(event.target.value) - channels.length;

    let nextChannels = [...channels];

    if (diffLengthChannels > 0) {
      const diffChannels = [...Array(Number(diffLengthChannels))].map(
        () => defaultChannel,
      );
      nextChannels.push(...diffChannels);
    } else {
      nextChannels.splice(diffLengthChannels);
    }

    setChannels(nextChannels);
  };

  const handleChangeOdontometricOptions = (name, value) => {
    setOdontometricOptions({ ...odontometricOptions, [name]: value });
  };

  const handleAddProcedure = () => {
    if (!procedureToAdd) return;
    setProcedureToAdd(null);
    setProcedures([...procedures, { name: procedureToAdd.name, value: null }]);
  };

  const handleChangeOptionProcedure = (value, nameProcedure) => {
    const index = procedures.findIndex(
      (procedure) => procedure.name === nameProcedure,
    );

    if (index < 0) return;

    const nextProcedures = JSON.parse(JSON.stringify(procedures));
    nextProcedures[index].value = value;
    setProcedures(nextProcedures);
  };

  const handleAddSessions = () => {
    setSessions([...sessions, defaultSession]);
  };

  const handleChangeSessions = useCallback(
    (value, row, column) => {
      const nextSessions = JSON.parse(JSON.stringify(sessions));
      nextSessions[row][column] = value;
      setSessions(nextSessions);
    },
    [sessions],
  );

  const onSubmit = async () => {
    if (!tooth || !channels.length) {
      toast.error("Dente e número de canais são obrigatórios");
      return;
    }

    const endodontic = {
      tooth: tooth,
      odontometricOptions: { ...odontometricOptions },
      channels: channels,
      procedures: procedures,
      sessions: sessions,
      comments: comments,
    };

    try {
      let message = "Ficha endodontica criado com sucesso!";
      if (currentEndodontic) {
        endodontic._id = currentEndodontic._id;
        await updateEndodontic(patientId, endodontic);
        message = "Ficha edodontica atualizada com sucesso!";
      } else {
        await createEndodontic(patientId, endodontic);
      }
      toast.success(message);
      history.goBack();
    } catch (err) {
      toast.error("Nenhuma modificação encontrada!");
    }
  };

  const getOptionProcedure = (name) => {
    const procedure = endodonticProcedures.find(
      (procedure) => procedure.name === name,
    );
    return procedure?.options ?? [];
  };

  return (
    <Container
      maxWidth="md"
      component={Paper}
      style={{ paddingBottom: "16px", paddingTop: "16px" }}
    >
      <Box display="flex" style={{ marginBottom: "16px" }}>
        <Box flexGrow={1}>
          <Typography variant="h5" gutterBottom>
            {currentEndodontic ? "Editar ficha" : "Criar nova ficha"}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Autocomplete
            value={tooth}
            onChange={handleChangeTooth}
            options={teeth.filter((element) => element <= 48)}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField {...params} label={"Dente"} variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={10}>
          <RadioGroup
            row
            value={channels.length}
            onChange={handleChangeChannel}
          >
            <FormControlLabel value={1} control={<Radio />} label="1 Canal" />
            <FormControlLabel value={2} control={<Radio />} label="2 Canais" />
            <FormControlLabel value={3} control={<Radio />} label="3 Canais" />
            <FormControlLabel value={4} control={<Radio />} label="4 Canais" />
            <FormControlLabel value={5} control={<Radio />} label="5 Canais" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            selecionarLinhas="none"
            theme={myTheme}
            colunas={columnsChannel}
            objetos={channels}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {endodonticOdontometric.map((option, index) => (
            <FormControlLabel
              key={index}
              label={option.label}
              control={
                <Checkbox
                  color="primary"
                  checked={odontometricOptions[option.name]}
                  onChange={(event) =>
                    handleChangeOdontometricOptions(
                      option.name,
                      event.target.checked,
                    )
                  }
                />
              }
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography>Procedimentos</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Autocomplete
            options={endodonticProcedures}
            value={procedureToAdd}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={"Adicionar procedimento"}
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => setProcedureToAdd(newValue)}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disableElevation
            onClick={handleAddProcedure}
            startIcon={<Add />}
          >
            Inserir
          </Button>
        </Grid>
        <Grid container spacing={1}>
          {procedures.map((procedure, index) => (
            <Grid key={index} item xs={12}>
              <Autocomplete
                value={procedure.value}
                options={getOptionProcedure(procedure.name)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      endodonticProceduresObjectFormat[procedure.name]?.label ??
                      ""
                    }
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) =>
                  handleChangeOptionProcedure(newValue, procedure.name)
                }
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={8}>
          <Typography>Sessões</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disableElevation
            onClick={handleAddSessions}
            startIcon={<Add />}
          >
            Adicionar sessão
          </Button>
        </Grid>
        <Grid item xs={12}>
          <EndodonticSessionTable
            rows={sessions}
            handleChangeSessions={handleChangeSessions}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Comentários"
            variant="outlined"
            value={comments}
            onChange={(event) => setComments(event.target.value)}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
