import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { buscarDentistas, deletarDentista } from "../../../../api/dentistas";
import { editDentist } from "../../../../actions/dentistas/dentistSearch";
import { toast } from "react-toastify";

import TableComponent from "../../../table/TableComponent";
import SimpleDialog from "../../../dialog/SimpleDialog";

import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Pageview from "@material-ui/icons/Pageview";
import { Category } from "@material-ui/icons";
import moment from "moment";

class DentistaListagem extends Component {
  state = {
    dentistas: [],
    openDialogEmail: false,
    listEmails: [],
    isOpenDialog: false,
    selectedDentistaId: "",
  };

  async deletaDentista(dentistaId) {
    try {
      const Dentista = await deletarDentista(dentistaId);
      if (Dentista) {
        toast.success("Dentista excluído com sucesso");
        this.findDentista();
        this.setState({ isOpenDialog: false, selectedDentistaId: "" });
        return;
      }
      toast.error("Erro ao excluir Dentista");
    } catch (error) {
      toast.error("Erro ao excluir Dentista");
    }
  }

  findDentista() {
    buscarDentistas(this.props.clinica._id).then((dentistas) => {
      const listaDentistas = dentistas.map((dentista, index) => ({
        dentistaId: dentista._id,
        name: dentista.name,
        email: dentista.email,
        telephone: dentista.telephone,
        birthday: dentista.birthday,
        funcoes: [
          <Tooltip title={"Editar Dentista"} key={"a" + index}>
            <IconButton
              onClick={() =>
                this.props.history.push(
                  `/dentistas/cadastrarDentista/${dentista._id}`
                )
              }
            >
              <Edit />
            </IconButton>
          </Tooltip>,
          <Tooltip title={"Listar Categorias"} key={"b" + index}>
            <IconButton
              onClick={() =>
                this.props.history.push(
                  `/dentistas/categoriaDentistaRelacao/${dentista._id}`
                )
              }
            >
              <Category />
            </IconButton>
          </Tooltip>,
          <Tooltip title={"Listar Especialidades"} key={"c" + index}>
            <IconButton
              onClick={() =>
                this.props.history.push(
                  `/dentistas/especialidadeDentistaRelacao/${dentista._id}`
                )
              }
            >
              <Pageview />
            </IconButton>
          </Tooltip>,
          <Tooltip title={"Deletar dentista"} key={`delete-${index}`}>
            <IconButton onClick={() => this.handleOpenDialog(dentista._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ],
      }));
      this.setState({ dentistas: listaDentistas });
    });
  }

  handleOpenDialog = (dentistaId) => {
    this.setState({ isOpenDialog: true, selectedDentistaId: dentistaId });
  };

  componentWillMount() {
    this.findDentista();
  }

  onClose = () => {
    this.setState({ openDialogEmail: false });
  };

  handleSelectedRows = (list) => {
    const newList = list.map((item) => ({
      id: item.dentistaId,
      name: item.name,
      email: item.email,
    }));
    this.setState({ listEmails: newList, openDialogEmail: true });
  };

  render() {
    const { dentistas, isOpenDialog, selectedDentistaId } = this.state;
    const titulo = "Dentistas",
      colunas = [
        { label: "Nome", name: "name" },
        { label: "Email", name: "email" },
        { label: "Telefone", name: "telephone" },
        {
          label: "Data Nascimento",
          name: "birthday",
          options: {
            customBodyRender: (value) => {
              return value ? moment(value).format("DD/MM/YYYY") : "";
            },
          },
        },
        {
          label: "Funções",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = dentistas,
      inserir = {
        nome: "Cadastrar Dentista",
        link: "cadastrarDentista",
      };

    return (
      <>
        <TableComponent
          funcoes={{
            deletar: this.deletaDentista.bind(this),
            handleSelectedRows: this.handleSelectedRows,
          }}
          titulo={titulo}
          inserir={inserir}
          colunas={colunas}
          objetos={objetos}
          {...this.props}
        />
        <SimpleDialog
          open={isOpenDialog}
          title="Deseja excluir o dentista?"
          content="Ao excluir o dentista a operação não poderá ser desfeita."
          closeButtonTitle="Cancelar"
          submitButtonTitle="Excluir"
          handleClose={() => this.setState({ isOpenDialog: false })}
          handleSubmit={() => this.deletaDentista(selectedDentistaId)}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editDentist })(DentistaListagem);
