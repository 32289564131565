import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { css } from "aphrodite/no-important";
import { styles } from "./MaterialsFormStyles";
import MaterialsList from "../../lists/storage/MaterialsList";
import {
  createMaterial,
  fetchMaterials,
  deleteMaterial,
  updateMaterial,
  fetchVendors,
} from "../../../actions/storage";
import Button from "../../common/Button";
import ModalCreateMaterial from "./modal/ModalCreateMaterial";
import ModalDeleteMaterial from "./modal/ModalDeleteMaterial";
import ModalEditMaterial from "./modal/ModalEditMaterial";
import ListVendorsForm from "./ListVendorsForm";
import { toast } from "react-toastify";
import { Dialog, DialogTitle } from "@material-ui/core";

class ListMaterialsForm extends Component {
  constructor(props) {
    super(props);

    this.renderList = this.renderList.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.deleteMaterialForm = this.deleteMaterialForm.bind(this);
    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);

    this.state = {
      redirect: false,
      showModal: false,
      showDeleteModal: false,
      idMaterial: null,
      material: null,
      showEditModal: false,
    };
  }

  onSubmit(values) {}

  deleteMaterialForm(id) {
    const { fetchMaterials, deleteMaterial, fetchVendors } = this.props;
    if (!id._id) {
      toast.error("Erro, tente novamente!");
      return;
    }
    deleteMaterial(id._id, (ret) => {
      fetchMaterials();
      fetchVendors();
    });
  }

  onOpenModal() {
    this.setState({
      showModal: true,
    });
  }

  onCloseModal() {
    this.setState({
      showModal: false,
    });
    const { fetchMaterials, fetchVendors } = this.props;
    fetchMaterials();
    fetchVendors();
  }

  onOpenEditModal(vv) {
    this.setState({
      showEditModal: true,
      material: vv,
    });
  }

  onCloseEditModal() {
    this.setState({
      showEditModal: false,
      material: null,
    });
    const { fetchMaterials, fetchVendors } = this.props;
    fetchMaterials();
    fetchVendors();
  }

  onOpenDeleteModal(id) {
    this.setState({
      showDeleteModal: true,
      idMaterial: id,
    });
  }

  onCloseDeleteModal() {
    this.setState({
      showDeleteModal: false,
      idMaterial: null,
    });
  }

  componentWillMount() {
    const { fetchMaterials, fetchVendors } = this.props;
    fetchMaterials();
    fetchVendors();
  }

  renderList() {
    const { match, materialsById, vendorsById } = this.props;
    let materials = materialsById ?? [];
    return (
      <MaterialsList
        materials={materials}
        deleteMaterialForm={this.deleteMaterialForm}
        onOpenEditModal={this.onOpenEditModal}
        match={match}
      />
    );
  }

  render() {
    const { handleSubmit, history, match, vendorsById } = this.props;

    return (
      <div className={css(styles.grid)}>
        <form
          className={css(styles.form)}
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className={css(styles.listContainer)}>
            <h3 className={css(styles.sectionTitle)}>Lista de Produtos</h3>
            <div className={css(styles.card)}>
              {this.renderList()}
              <Button text="Cadastrar Produto" onClick={this.onOpenModal} />
            </div>
          </div>

          <Dialog open={this.state.showModal}>
            <DialogTitle>Novo produto</DialogTitle>
            <ModalCreateMaterial
              onCancel={this.onCloseModal}
              history={history}
              match={match}
              vendors={vendorsById}
            />
          </Dialog>

          <Dialog open={this.state.showDeleteModal}>
            <DialogTitle>Deletar produto</DialogTitle>
            <ModalDeleteMaterial
              onCancel={this.onCloseDeleteModal}
              idMaterial={this.state.idMaterial}
              del={this.deleteMaterialForm}
            />
          </Dialog>

          <Dialog open={this.state.showEditModal}>
            <DialogTitle>Editar produto</DialogTitle>
            <ModalEditMaterial
              onCancel={this.onCloseEditModal}
              history={history}
              match={match}
              vendors={vendorsById}
              material={this.state.material}
            />
          </Dialog>
        </form>
      </div>
    );
  }
}

const listMaterialsForm = reduxForm({
  enableReinitialize: true,
  form: "listMaterialsForm",
})(ListMaterialsForm);

const listVendorsForm = reduxForm({
  enableReinitialize: true,
  form: "listVendorsForm",
})(ListVendorsForm);

function mapStateToProps(state) {
  state.storage.selectedMaterial = null;
  state.storage.selectedVendor = null;

  return {
    materialsById: state.storage.materialsById,
    vendorsById: state.storage.vendorsById,
  };
}

export default connect(mapStateToProps, {
  fetchMaterials,
  createMaterial,
  deleteMaterial,
  updateMaterial,
  fetchVendors,
})(listMaterialsForm, listVendorsForm);
