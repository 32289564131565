import React, { useState, useEffect } from "react";
import { buscarDentistas } from "../../../../api/dentistas";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

export default function ProcedureConclusionDialog({
  onClose,
  onSubmit,
  procedureId,
}) {
  const [dentists, setDentists] = useState();
  const [form, setForm] = useState({
    dentist: null,
    concludedDate: null,
  });

  useEffect(() => {
    const fetchDentistas = async () => {
      const list = await buscarDentistas();
      setDentists(list);
    };
    fetchDentistas();
  }, []);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Executar serviço</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              value={form.dentist}
              onChange={(event, newValue) => {
                setForm({ ...form, dentist: newValue });
              }}
              options={dentists}
              getOptionLabel={(option) => `${option.name}`}
              renderOption={(option) => <span>{option.name}</span>}
              renderInput={(params) => (
                <TextField {...params} label={"Dentista"} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                label="Data de execuação"
                format="dd/MM/yyyy"
                size="small"
                value={form.concludedDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => {
                  setForm({ ...form, concludedDate: date });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmit(form, procedureId)}
          disableElevation
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
