import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { css } from "aphrodite/no-important";
import { styles } from "./ClinicFormStyles";
import { updateClinic, getClinic } from "../../../actions/auth";
import Button from "../../common/Button";
import InputField from "../../forms/InputField";

const CLINIC_INFO = {
  name: {
    name: "name",
    label: "Nome/Razão Social",
    placeholder: "Nome da clínica",
  },
  cpfCnpj: { name: "cnpj", label: "Cpf/Cnpj", placeholder: "Cpf/Cnpj" },
  responsible: {
    name: "responsible",
    label: "Responsável",
    placeholder: "Nome do responsável",
  },
  address: {
    name: "address",
    label: "Endereço",
    placeholder: "Endereço da clínica",
  },
  city: {
    name: "city",
    label: "Cidade",
    placeholder: "Ex.: Curitiba, São Paulo...",
  },
  state: {
    name: "state",
    label: "Estado",
    placeholder: "Ex.: Paraná, São Paulo...",
  },
  zip: {
    name: "zip",
    label: "CEP",
    placeholder: "XXXXX-XXX",
    mask: "99999-999",
  },
};

// ClinicForm handles the form where the user enter the app
class ClinicForm extends Component {
  constructor(props) {
    super(props);
    this.newList = this.newList.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.state = {
      loading: true,
      showModal: false,
    };
  }

  newList() {
    const { updateClinic, clinic } = this.props;
    // var formData = new FormData();
    // if (this.state.logo.file) {
    //   formData.append("file", this.state.logo.file);
    //   axios.post('/api/image/clinic', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
    // 	.then((response) => updateClinic({ logo_clinic: response.data.link }, clinic._id, clinic.clinic_data, () => {}))
    // 	.catch(error => showMessage({message: "Erro no upload da imagem", type: 'danger'}));
    // }
    // else
    updateClinic(
      { logo_clinic: this.state.logo.src },
      clinic._id,
      clinic.clinic_data,
      () => {}
    );
  }
  componentDidMount() {
    const { getClinic, clinic } = this.props;
    if (clinic) {
      const clinicId = clinic._id;
      const clinicDb = clinic.clinic_data;

      getClinic({ clinicId, clinicDb }, () => {
        const { clinic } = this.props;
        this.setState({
          loading: false,
          logo: { src: clinic.logo_clinic, file: "" },
        });
      });
    }
  }

  onSubmit(values) {
    const { user, clinic, updateClinic } = this.props;
    values.updated_by = user._id;
    updateClinic(values, clinic._id, clinic.clinic_data, (clinic) => {});
  }

  renderFields(fields) {
    return fields.map((field) => {
      const mask = field.name === "cnpj" ? this.applyCpfCnpjMask : field.mask;
      return (
        <Field
          key={field.name}
          type={field.type || "text"}
          name={field.name}
          label={field.label}
          placeholder={field.placeholder || ""}
          component={InputField}
          parse={mask}
        />
      );
    });
  }

  applyCpfCnpjMask(value) {
    if (!value) return value;

    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 14);

    if (truncatedValue.length <= 11) {
      return truncatedValue
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      return truncatedValue
        .replace(/^(\d{2})(\d)/, "$1.$2")
        .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        .replace(/\.(\d{3})(\d)/, ".$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2");
    }
  }

  renderForm() {
    const { loading } = this.state;

    if (!loading) {
      return (
        <div className={css(styles.section, styles.infoRow)}>
          <div>
            <div>{this.renderFields([CLINIC_INFO.name])}</div>
            <div>{this.renderFields([CLINIC_INFO.cpfCnpj])}</div>
            <div>{this.renderFields([CLINIC_INFO.responsible])}</div>
            <div>{this.renderFields([CLINIC_INFO.address])}</div>
            <div className={css(styles.row_2)}>
              {this.renderFields([CLINIC_INFO.city, CLINIC_INFO.state])}
            </div>
            <div className={css(styles.row_2)}>
              {this.renderFields([CLINIC_INFO.zip])}
            </div>

            <Button
              text="Atualizar Informações"
              color="green"
              submit
              onClick={this.newList}
            />
          </div>
        </div>
      );
    } else {
      return <div className={css(styles.loading)}>Carregando...</div>;
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className={css(styles.grid)}>
        <form
          className={css(styles.form)}
          onSubmit={handleSubmit(this.onSubmit)}
        >
          {/* --- INFORMAÇÕES DA CLÍNICA --- */}
          <h3 className={css(styles.sectionTitle)}>Informações da clínica</h3>
          {this.renderForm()}
        </form>
      </div>
    );
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  return errors;
}

const clinicForm = reduxForm({
  validate,
  enableReinitialize: true,
  form: "clinicForm",
})(ClinicForm);

function mapStateToProps(state) {
  const selectedClinic = state.auth.clinic;
  let initialValues = {};
  if (selectedClinic) {
    initialValues = {
      name: selectedClinic.name,
      cnpj: selectedClinic.cnpj,
      responsible: selectedClinic.responsible,
      address: selectedClinic.address,
      city: selectedClinic.city,
      state: selectedClinic.state,
      zip: selectedClinic.zip,
    };
  }

  return {
    //clinicConfig: state.clinicConfig,
    user: state.auth.user,
    clinic: state.auth.clinic,
    initialValues,
  };
}

export default connect(mapStateToProps, { getClinic, updateClinic })(
  clinicForm,
);
