import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { getUser, updateUser } from "../../../../actions/users";

import { css } from "aphrodite/no-important";
import { styles } from "./PermissionsModalStyles";

import Icon from "../../../common/Icon";
import Button from "../../../common/Button";
import CheckBox from "../../../common/CheckBox";
import { Dialog, DialogActions } from "@material-ui/core";
import ButtonMui from "@material-ui/core/Button";
import { updatePermissions } from "../../../../api/user";

// PermissionsModal handles the form where the user enter the app
class PermissionsModal extends Component {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      permitError: null,
      openPatient: false,
      openAgenda: false,
      openFinances: false,
      openStorage: false,
      openCommunication: false,
      openBI: false,
      checkBox: false,
      permissions: [
        {
          label: "Pacientes",
          name: "patient",
          open: false,
          roles: [
            {
              label: "Informações do Paciente",
              name: "info",
              view: false,
              edit: false,
              delete: false,
            },
            // {
            // 	label: 'Documentos',
            // 	name: 'Documentos',
            // 	view: false,
            // 	edit: false,
            // 	delete: false,
            // },
            // {
            // 	label: 'Periograma',
            // 	name: 'Periograma',
            // 	view: false,
            // 	edit: false,
            // 	delete: false,
            // },
            // {
            // 	label: 'Tratamentos',
            // 	name: 'Tratamentos',
            // 	view: false,
            // 	edit: false,
            // 	delete: false,
            // },
            // {
            // 	label: 'Financeiro',
            // 	name: 'Financeiro',
            // 	view: false,
            // 	edit: false,
            // 	delete: false,
            // },
            // {
            // 	label: 'Acompanhamento Clínico',
            // 	name: 'Acompanhamento Clínico',
            // 	view: false,
            // 	edit: false,
            // 	delete: false,
            // }
          ],
        },
        // {
        // 	label: 'Agenda',
        // 	name: 'Agenda',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Agenda',
        // 			name: 'Teste Agenda',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Agenda 2',
        // 			name: 'Teste Agenda 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
        // {
        // 	label: 'Finanças',
        // 	name: 'Finanças',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Finanças',
        // 			name: 'Teste Finanças',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Finanças 2',
        // 			name: 'Teste Finanças 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
        // {

        // 	label: 'Estoque',
        // 	name: 'Estoque',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Estoque',
        // 			name: 'Teste Estoque',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Estoque 2',
        // 			name: 'Teste Estoque 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
        // {
        // 	label: 'Comunicação',
        // 	name: 'Comunicação',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Comunicação',
        // 			name: 'Teste Comunicação',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Comunicação 2',
        // 			name: 'Teste Comunicação 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
        // {
        // 	label: 'Inteligência Empresarial',
        // 	name: 'Inteligência Empresarial',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Inteligência Empresarial',
        // 			name: 'Teste Inteligência Empresarial',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Inteligência Empresarial 2',
        // 			name: 'Teste Inteligência Empresarial 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
        // {
        // 	label: 'Configurações',
        // 	name: 'Configurações',
        // 	open: false,
        // 	options: [
        // 		{
        // 			label: 'Teste Configurações',
        // 			name: 'Teste Configurações',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 		{
        // 			label: 'Teste Configurações 2',
        // 			name: 'Teste Configurações 2',
        // 			view: false,
        // 			edit: false,
        // 			delete: false,
        // 		},
        // 	],
        // },
      ],
    };
  }

  componentDidMount() {
    const { getUser, userId } = this.props;
    getUser(userId, (ret) => {
      const { permissions } = ret.clinics;
      console.log(permissions);
      if (ret.permissions && ret.permissions.length !== 0) {
        const newRoles = this.state.permissions.map((role) => {
          const userRole = ret.permissions.find(
            (elem) => elem.name === role.name,
          );
          if (userRole) {
            role.roles = role.roles.map((option) => {
              const userOption = userRole.roles.find(
                (elem2) => elem2.name === option.name,
              );
              if (userOption) {
                option.view = userOption.view;
              }
              return option;
            });
          }
          return role;
        });
        this.setState({ roles: newRoles });
      }
    });
  }

  getPermitError(permitError) {
    this.state.permitError = permitError;
  }

  onToggle(str) {
    const {
      openPatient,
      openAgenda,
      openFinances,
      openStorage,
      openCommunication,
      openBI,
    } = this.state;
    if (str === "patient") {
      if (openPatient) {
        this.setState({ openPatient: false });
      } else {
        this.setState({ openPatient: true });
      }
    } else if (str === "agenda") {
      if (openAgenda) {
        this.setState({ openAgenda: false });
      } else {
        this.setState({ openAgenda: true });
      }
    } else if (str === "finances") {
      if (openFinances) {
        this.setState({ openFinances: false });
      } else {
        this.setState({ openFinances: true });
      }
    } else if (str === "storage") {
      if (openStorage) {
        this.setState({ openStorage: false });
      } else {
        this.setState({ openStorage: true });
      }
    } else if (str === "communication") {
      if (openCommunication) {
        this.setState({ openCommunication: false });
      } else {
        this.setState({ openCommunication: true });
      }
    } else if (str === "bi") {
      if (openBI) {
        this.setState({ openBI: false });
      } else {
        this.setState({ openBI: true });
      }
    }
  }

  async onSubmit() {
    const { selectedUser, updateUser, onCancel, clinic } = this.props;
    await updatePermissions(selectedUser._id, this.state.roles);
    onCancel();
  }

  openHeader() {
    return (
      <div className={css(styles.headerContainer)}>
        <span className={css(styles.header)}>Visualizar</span>
        <span className={css(styles.header)}>Editar</span>
        <span className={css(styles.header)}>Excluir</span>
      </div>
    );
  }

  render() {
    const { handleSubmit, onClose } = this.props;

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={this.props.open}>
        <form className={css(styles.form)}>
          <div className={css(styles.container)}>
            <ul className={css(styles.list)}>
              {this.state.permissions.map((item, index) => (
                <li className={css(styles.moduleLI)} key={index}>
                  <div
                    onClick={() => {
                      this.state.permissions[index].open = !item.open;
                      this.setState({});
                    }}
                    className={
                      item.open ? css(styles.openModules) : css(styles.modules)
                    }
                  >
                    <Icon
                      icon={item.open ? "upArrow" : "downArrow"}
                      size="small"
                      color="grey"
                    />
                    {item.open ? (
                      <span className={css(styles.openModHeader)}>
                        {item.label}
                        {this.openHeader()}
                      </span>
                    ) : (
                      <span className={css(styles.label)}>{item.label}</span>
                    )}
                  </div>
                  {item.open &&
                    item.roles.map((itm, ndx) => (
                      <div className={css(styles.dropDown)} key={ndx}>
                        <ul className={css(styles.list)}>
                          <li className={css(styles.subModules)}>
                            <div className={css(styles.subText)}>
                              {itm.label}
                            </div>
                            <div className={css(styles.checksContainer)}>
                              <CheckBox
                                checked={itm.view ? "check" : "none"}
                                onChange={(old, newState) => {
                                  this.state.permissions[index].roles[
                                    ndx
                                  ].view = newState === "check";
                                }}
                              />
                              <CheckBox
                                checked={itm.edit ? "check" : "none"}
                                onChange={(old, newState) => {
                                  this.state.permissions[index].roles[
                                    ndx
                                  ].edit = newState === "check";
                                }}
                              />
                              <CheckBox
                                checked={itm.delete ? "check" : "none"}
                                onChange={(old, newState) => {
                                  this.state.permissions[index].roles[
                                    ndx
                                  ].delete = newState === "check";
                                }}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                </li>
              ))}
            </ul>
          </div>
          <DialogActions>
            <ButtonMui disableElevation={true} onClick={onClose}>
              Fechar
            </ButtonMui>
            <ButtonMui
              color="primary"
              variant="contained"
              disableElevation={true}
              onClick={() => this.onSubmit()}
            >
              Salvar
            </ButtonMui>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const permissionsModal = reduxForm({
  form: "permissionsModal",
})(PermissionsModal);

function mapStateToProps(state) {
  const { selectedUser } = state.clinicConfig;
  const { auth } = state;
  let initialValues = {};

  if (selectedUser) {
    initialValues = selectedUser;
  }

  return {
    selectedUser,
    clinic: auth.clinic,
    initialValues,
  };
}

export default connect(mapStateToProps, { getUser, updateUser })(
  permissionsModal,
);
