import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";

import PacientesListagem from "./components/PacientesListagem";
import PacientesArquivados from "./components/PacientesArquivados";
import PacientesConfig from "./components/PacientesConfig";

// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Face from "@material-ui/icons/Face";
import Archive from "@material-ui/icons/Archive";
import Settings from "@material-ui/icons/Settings";
import Search from "@material-ui/icons/Search";
import AdvancedSearch from "./components/AdvancedSearch";

class PacientesRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  state = {
    advanced: {
      filters: { status: {}, save: {}, array: [] },
      select: { content: [], selected: [], unselected: [], out: [] },
      pagination: { page: 1, limit: 0 },
      orderTable: { column: "name", order: true },
      content: { registry: "", name: "", email: "", telephones: "", cpf: "" },
      accumulate: [],
      update: false,
    },
  };

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [
        { url: "listagem", numero: 0 },
        { url: "arquivados", numero: 1 },
        { url: "busca", busca: 2 },
        { url: "configuracoes", numero: 3 },
      ],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/listagem`)}
            label="Pacientes Ativos"
            icon={<Face />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/arquivados`)}
            label="Pacientes Arquivados"
            icon={<Archive />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/busca`)}
            label="Busca Avançada"
            icon={<Search />}
          />
          <BottomNavigationAction
            onClick={() => this.props.history.push(`${match.url}/config`)}
            label="Configurações"
            icon={<Settings />}
          />
        </BottomNavigation>
        <Switch>
          <Route
            path={`${match.url}/config`}
            render={(props) => (
              <PacientesConfig
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/listagem`}
            render={(props) => (
              <PacientesListagem
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/arquivados`}
            render={(props) => (
              <PacientesArquivados
                {...props}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/busca`}
            render={(props) => (
              <AdvancedSearch
                {...props}
                filters={this.state.advanced.filters}
                save={this.state.advanced}
                tab={tab}
                trocarTab={this.trocarTab.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/listagem`} />}
          />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
}
export default connect(mapStateToProps)(PacientesRouter);
