import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { updateStatus, fetchUsers } from "../../actions/users";
import {
  IconButton,
  Switch,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Lock, Pageview } from "@material-ui/icons";
import TableComponent from "../table/TableComponent";
import { PermissionsForm } from "../forms/clinic/modals/PermissionsForm";

function UsersList({ history, updateStatus, fetchUsers }) {
  const [usuarios, setUsuarios] = useState([]);
  const [dialogChangeStatusUsuario, setDialogChangeStatusUsuario] = useState({
    dialog: false,
  });
  const [isOpenDialogPermission, setIsOpenDialogPermission] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const colunas = [
    { label: "", name: "id", options: { display: false } },
    { label: "Nome", name: "name" },
    { label: "E-mail", name: "email" },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const usuario = {
            id: tableMeta.rowData[0],
            nome: tableMeta.rowData[1],
          };
          return (
            <Switch
              size="small"
              checked={value}
              onChange={(e) => {
                openDialogChangeStatusUsuario(e.target.checked, usuario);
              }}
            />
          );
        },
      },
    },
    {
      label: "Funções",
      name: "funcoes",
      options: {
        customBodyRender: (_, tableMeta) => {
          return (
            <>
              <Tooltip title="Visualizar cadastro">
                <IconButton
                  onClick={() =>
                    history.push(`/configuration/user/${tableMeta.rowData[0]}`)
                  }
                >
                  <Pageview />
                </IconButton>
              </Tooltip>
              <Tooltip title="Permissões">
                <IconButton
                  onClick={() => {
                    setSelectedUserId(tableMeta.rowData[0]);
                    setIsOpenDialogPermission(true);
                  }}
                >
                  <Lock />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    customToolbarSelect: () => {
      return;
    },
  };

  const montarUsuariosTabela = (users) => {
    const listaUsuarios = users.map((user) => {
      return {
        id: user._id,
        name: user.name,
        email: user.email,
        status: user.clinics[0].userActive,
        funcoes: true,
      };
    });
    return listaUsuarios;
  };

  const buscarUsuarios = () => {
    fetchUsers((res) => {
      const usuarios = montarUsuariosTabela(res);
      setUsuarios(usuarios);
    });
  };

  const openDialogChangeStatusUsuario = (status, { id, nome }) => {
    setDialogChangeStatusUsuario({
      id,
      nome,
      status,
      dialog: true,
    });
  };

  const persistirAlteracaoStatusUsuario = () => {
    const userPersist = {
      id: dialogChangeStatusUsuario.id,
      status: dialogChangeStatusUsuario.status,
    };
    closeDialogChangeStatusUsuario();
    updateStatus(userPersist, (res) => {
      buscarUsuarios();
    });
  };

  const closeDialogChangeStatusUsuario = () => {
    setDialogChangeStatusUsuario({
      ...dialogChangeStatusUsuario,
      dialog: false,
    });
  };

  useEffect(() => {
    buscarUsuarios();
  }, []);

  return (
    <>
      <TableComponent
        titulo="Usuários"
        colunas={colunas}
        objetos={usuarios}
        otherOptions={options}
      />
      <Dialog
        onClose={closeDialogChangeStatusUsuario}
        open={dialogChangeStatusUsuario.dialog}
      >
        <DialogTitle>Alterar status usuário</DialogTitle>
        <DialogContent>
          Deseja alterar o status do usuário {dialogChangeStatusUsuario.nome}{" "}
          para {dialogChangeStatusUsuario.status ? "Ativo" : "Inativo"}?
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={persistirAlteracaoStatusUsuario}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {isOpenDialogPermission && (
        <PermissionsForm
          open={isOpenDialogPermission}
          userId={selectedUserId}
          onClose={() => setIsOpenDialogPermission(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { updateStatus, fetchUsers })(
  UsersList,
);
