import { percentualOuDinheiro } from "./Helpers";

export const montaParcelasPacientes = (treatments) => {
  const verificaStatusPagamento = (dataVencimento, dataPagamento) => {
    dataVencimento = new Date(dataVencimento);
    const dataAtual = new Date();
    dataAtual.setHours(0, 0, 0, 0); // para que não haja comparação entre horas seta o valor da horas para 0.

    if (dataPagamento) {
      return "Pago";
    } else if (dataVencimento < dataAtual) {
      return "Vencido";
    } else {
      return "Aberto";
    }
  };

  const listaObjetos = [];

  treatments.map((treatment) => {
    treatment.budgets.map((budget) => {
      let somaParcelas = 0;
      budget.parcels.forEach((parcel) => (somaParcelas += parcel.value));

      // verificar se possui entrada e popula a listaObjetos como sendo uma parcela
      if (budget.entrance.value > 0) {
        // verificar se possui desconto e o calcula
        if (budget.discount.value > 0) {
          const desconto = percentualOuDinheiro(
            budget.discount.value_type,
            budget.discount.value,
            somaParcelas,
          );
          somaParcelas += desconto;
        }

        // calcula a entrada
        const entrada = percentualOuDinheiro(
          budget.entrance.value_type,
          budget.entrance.value,
          somaParcelas,
        );

        const parcela = {
          orcamentoId: budget._id,
          tipo: "entrance",
          paciente: `${treatment.patient.name} ${treatment.patient.lastName}`,
          tratamento: treatment.name,
          fatura: "Entrada",
          orcamento: budget.name,
          vencimento: new Date(budget.entrance.expiration),
          status: verificaStatusPagamento(
            budget.entrance.expiration,
            budget.entrance.receipt && budget.entrance.receipt.date,
          ),
          dataRecebimento:
            budget.entrance.receipt && new Date(budget.entrance.receipt.date),
          valor: parseFloat(entrada.toFixed(2)),
          valorRecebimento:
            budget.entrance.receipt &&
            parseFloat(budget.entrance.receipt.value.toFixed(2)),
        };

        listaObjetos.push(parcela);
      }

      // popula listaObjetos com o restante das parcelas
      budget.parcels.map((parcel) => {
        const parcela = {
          orcamentoId: budget._id,
          parcelaId: parcel._id,
          tipo: "parcel",
          paciente: `${treatment.patient.name} ${treatment.patient.lastName}`,
          tratamento: treatment.name,
          fatura: parcel.number,
          orcamento: budget.name,
          vencimento: new Date(parcel.expiration),
          status: verificaStatusPagamento(
            parcel.expiration,
            parcel.receipt && parcel.receipt.date,
          ),
          dataRecebimento: parcel.receipt && new Date(parcel.receipt.date),
          valor: parseFloat(parcel.value.toFixed(2)),
          valorRecebimento:
            parcel.receipt && parseFloat(parcel.receipt.value.toFixed(2)),
        };
        somaParcelas += parcel.value;
        listaObjetos.push(parcela);
      });
    });
  });
  return listaObjetos;
};

export const montaFaturasAgrupadasData = (lista) => {
  return lista.map((item) => {
    const objeto = {
      ano: item._id.year,
      mes: item._id.month,
      total: item.total,
      faturas: [],
    };

    item.parcels.map((fatura) => {
      const novaFatura = {
        paciente: fatura.patientName,
        fatura:
          fatura.parcelNumber === "entrance"
            ? "Entranda"
            : fatura.parcelNumber === "unic"
              ? "Única"
              : fatura.parcelNumber,
        tratamento: fatura.treatmentName,
        orcamento: fatura.budgetName,
        dataVencimento: fatura.expiration && fatura.expiration,
        valorFatura: fatura.valueParcel,
        dataRecebimento: fatura.dateReceipt && fatura.dateReceipt,
        valorRecebimento: fatura.valueReceipt && fatura.valueReceipt,
      };

      objeto.faturas.push(novaFatura);
    });
    return objeto;
  });
};
