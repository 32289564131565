import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { getUsersPlatform } from "../../../actions/users";
import { Add } from "@material-ui/icons";
import TableComponent from "../../table/TableComponent";
function UsuarioPlataformaLista({ match, history, getUsersPlatform }) {
  const [usersPlatform, setUsersPlatform] = useState([]);

  useEffect(() => {
    getUsersPlatform((res) => {
      setUsersPlatform(res);
    });
  }, []);

  const colunas = [
    { label: "Nome", name: "name" },
    { label: "Email", name: "email" },
  ];

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(`${match.url}/cadastrar`)}
        >
          <Add />
          Novo
        </Button>
      </div>
      <div style={{ marginTop: "16px" }}>
        <TableComponent colunas={colunas} objetos={usersPlatform} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { getUsersPlatform })(
  UsuarioPlataformaLista,
);
