import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { listarTabelas } from "../../../../api/servicos";
import { Autocomplete } from "@material-ui/lab";
import {
  teeth,
  facesEnum,
  gruposProcedimentosEnum,
} from "../../../../utils/teeth";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons"; // Adicionando importação do ícone Add

const initialState = {
  tabela: null,
  servico: null,
  valor: "",
  dentes: [],
  tipoServico: "",
  multiplicarValor: false,
  observacao: "",
};

export default function BudgetProcedureFormDialog({
  procedimento,
  onClose,
  onSubmit,
  defaultServiceTableId,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaTabelas, setListaTabelas] = useState([]);
  const [form, setForm] = useState(initialState);
  const [error, setError] = useState({ tabela: false, servico: false });
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const lista = await listarTabelas(true);

      if (defaultServiceTableId) {
        const tabela = lista.find(
          (tabela) => tabela._id === defaultServiceTableId
        );
        setForm({ ...form, tabela });
      }

      if (procedimento) {
        const tabela = lista.find(
          (tabela) => tabela._id === procedimento.list_id
        );

        if (tabela) {
          const servico = tabela.servicos.find(
            (servico) => servico._id === procedimento.procedure._id
          );
          const tipoProcedimento = gruposProcedimentosEnum.find(
            (element) => element.name === procedimento.procedure.procedure_type
          );
          const procedimentoEditar = {
            tabela: tabela,
            servico: servico,
            valor: procedimento.specification.value,
            dentes: procedimento.specification.tooth,
            tipoServico: tipoProcedimento,
            multiplicarValor: procedimento.specification.value_acc,
          };
          setForm(procedimentoEditar);
        }
      }
      setIsLoading(false);
      setListaTabelas(lista);
    };
    fetchData();
  }, []);

  const handleChangeTabela = (_, newValue) => {
    setForm({
      tabela: newValue,
      servico: null,
      valor: "",
      dentes: [],
      tipoServico: "",
      multiplicarValor: false,
    });
  };

  const handleChangeServico = (value) => {
    if (!value) {
      setForm({
        ...form,
        servico: null,
        valor: "",
        dentes: [],
        tipoServico: "",
        multiplicarValor: false,
      });
      return;
    }
    const serv = gruposProcedimentosEnum.find(
      (element) => element.name === value.procedure_type
    );
    setForm({
      ...form,
      servico: value,
      valor: value.finalValue,
      tipoServico: serv,
      multiplicarValor: false,
    });
  };

  const handleChangeDentes = (newValue) => {
    const newDentes = newValue.map((dente) => {
      return {
        dente: dente,
        faces: [],
      };
    });
    setForm({ ...form, dentes: newDentes });
  };

  const handleChangeFaces = (newValue, denteIndex) => {
    const newDentes = [...form.dentes];
    newDentes[denteIndex].faces = newValue;
    setForm({ ...form, dentes: newDentes });
  };

  const handleChangeMultiplicarValor = (e) => {
    setForm({ ...form, multiplicarValor: e.target.checked });
  };

  const handleChangeObservacao = (e) => {
    setForm({ ...form, observacao: e.target.value });
  };

  const validarForm = () => {
    const error = {
      tabela: !form.tabela ? true : false,
      servico: !form.servico ? true : false,
    };
    setError(error);
    return Object.values(error).every((item) => item === false);
  };

  const handleSubmit = () => {
    const { tabela, servico, dentes, multiplicarValor, valor } = form;

    if (!validarForm()) return;

    const face = dentes.map((tooth) => {
      const { dente, faces } = tooth;
      return {
        tooth: dente,
        faces: faces?.toString(),
      };
    });

    const servicoSalvar = {
      list_id: tabela._id,
      date: new Date(),
      procedure: servico,
      specification: {
        face: face,
        tooth: dentes,
        value: multiplicarValor ? valor * dentes.length : valor,
        value_acc: multiplicarValor,
      },
    };
    if (procedimento._id) servicoSalvar._id = procedimento._id;

    onSubmit(servicoSalvar);
  };

  const renderInputDentes = () => {
    return (
      <Autocomplete
        multiple
        value={form.dentes.map((d) => d.dente)}
        options={teeth}
        onChange={(_, newValue) => {
          handleChangeDentes(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={"Dentes"} variant="outlined" />
        )}
      />
    );
  };

  const renderFaces = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderInputDentes()}
          </Grid>
          {form.dentes.map((item, denteIndex) => (
            <Grid item xs={12}>
              <Autocomplete
                multiple
                value={item?.faces}
                options={facesEnum}
                onChange={(_, newValue) => {
                  handleChangeFaces(newValue, denteIndex);
                }}
                key={denteIndex}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Face dente " + item?.dente}
                    variant="outlined"
                    size="small"
                    // variant="outlined"
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderInputXray = () => {
    return renderInputDentes();
  };

  const renderComplementoTipoProcedimento = () => {
    switch (form.tipoServico.group) {
      case "faces":
        return renderFaces();
      case "teeth":
        return renderInputDentes();
      case "xray":
        return renderInputXray();
      case "":
        return renderInputDentes();
    }
  };

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>Escolher serviço</DialogTitle>
      {!isLoading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={form.tabela}
                onChange={handleChangeTabela}
                options={listaTabelas}
                getOptionLabel={(option) => `${option.titulo}`}
                renderOption={(option) => <span>{option.titulo}</span>}
                renderInput={(params) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      {...params}
                      label={"Tabela de serviço"}
                      error={error.tabela}
                      variant="outlined"
                      style={{ flex: 1 }}
                    />
                    <IconButton
                      color="primary"
                      title="Cadastrar tabela de serviços"
                      onClick={() => history.push("/servicos")}
                    >
                      <Add />
                    </IconButton>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={form.servico}
                onChange={(event, newValue) => {
                  handleChangeServico(newValue);
                }}
                options={form.tabela?.servicos ? form.tabela.servicos : []}
                getOptionLabel={(option) => `${option.description}`}
                renderOption={(option) => <span>{option.description}</span>}
                renderInput={(params) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      {...params}
                      label={"Serviço"}
                      error={error.servico}
                      variant="outlined"
                      style={{ flex: 1 }}
                    />
                    <IconButton
                      onClick={() =>
                        form.tabela &&
                        history.push(`/servicos/${form.tabela._id}/servicos`)
                      }
                      color="primary"
                      title="Cadastrar serviço"
                      disabled={!form.tabela} // Desabilitar se não houver tabela selecionada
                    >
                      <Add />
                    </IconButton>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberFormat
                label="Valor do serviço"
                variant="outlined"
                fullWidth
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"R$"}
                value={form.valor}
                onValueChange={(value) =>
                  setForm({ ...form, valor: value.floatValue })
                }
              />
            </Grid>
            {form.tipoServico && (
              <Grid item xs={12}>
                {renderComplementoTipoProcedimento()}
              </Grid>
            )}
            {form.dentes.length > 1 && (
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.multiplicarValor}
                        onChange={handleChangeMultiplicarValor}
                      />
                    }
                    label="Multiplicar valor"
                  />
                </FormGroup>
              </Grid>
            )}
            {form.multiplicarValor && (
              <Grid item xs={12}>
                Valor total: R${form.valor * form.dentes.length}
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Observação"
                variant="outlined"
                value={form.observacao}
                onChange={handleChangeObservacao}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button disableElevation onClick={onClose}>
          Fechar
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleSubmit}
        >
          {procedimento ? "Salvar" : "Inserir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
