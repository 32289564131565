import React, { useState } from "react";
import {
  Typography,
  TextField,
  Paper,
  Button,
  Box,
  Container,
} from "@material-ui/core";
import { subscription } from "../../../../../api/clientes";

function FormCadastroCliente({ history }) {
  const [form, setForm] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    const cliente = montaObjetoSubscription();
    subscription(cliente, () => {
      redirecionarListaClientes();
    });
  };

  const redirecionarListaClientes = () => {
    history.goBack();
  };

  const montaObjetoSubscription = () => {
    return {
      isClinic: "true",
      clinic: {
        name: form.nomeClinica,
        cnpj: form.cnpj,
      },
      user: {
        name: form.nome,
        telephones: [
          {
            name: "Principal",
            value: form.telefone,
          },
        ],
        email: form.email,
        password: form.senha,
      },
    };
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h6"> Cadastro de cliente </Typography>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "12px" }}>
          <Typography variant="subtitle1"> Dados da clínica </Typography>
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Nome da clínica"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, nomeClinica: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="CNPJ"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, cnpj: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <Typography variant="subtitle1"> Dados do usuário </Typography>
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Telefone"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, telefone: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Senha"
            type="password"
            variant="outlined"
            size="small"
            onChange={(e) => setForm({ ...form, senha: e.target.value })}
            required
          />
        </div>
        <div style={{ marginTop: "16px", textAlign: "right" }}>
          <Button variant="contained" color="primary" type="submit">
            Inserir
          </Button>
        </div>
      </form>
    </Container>
  );
}

export default FormCadastroCliente;
