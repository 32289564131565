import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import { getPaymentTypes } from "../../../../api/formaPagamento";
import { getFeeAndPenalty } from "../../../../api/budget";
import { Skeleton } from "@material-ui/lab";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const defaultFeeAndPenalty = {
  penaltyValue: 0,
  defaultInterestValue: 0,
  parcelNumber: "",
  daysOfDelay: 0,
  total: 0,
};

export default function ParcelReceiptFormDialog(props) {
  const { budgetId, parcelId, onClose, onSubmit } = props;
  const [feeAndPenalty, setFeeAndPenalty] = useState(defaultFeeAndPenalty);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [payment, setPayment] = useState({
    paymentType: "",
    date: null,
    compensationDate: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({
    date: false,
    compensationDate: false,
    paymentType: false,
  });

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Promise.all([
          getPaymentTypes(),
          getFeeAndPenalty(
            budgetId,
            parcelId,
            parcelId ? "parcel" : "entrance"
          ),
        ]);
        setPaymentTypeList(response[0].data);
        setFeeAndPenalty(response[1].data);
        setIsLoading(false);
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
  }, [budgetId, parcelId]);

  const handleSubmit = () => {
    const errors = validateForm();

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
    }

    onSubmit(parcelId, payment);
  };

  const validateForm = () => {
    const errors = {};

    if (!payment.value) errors.value = true;

    if (!payment.date) {
      errors.date = true;
    } else {
      if (!moment(payment.date).isValid()) errors.date = true;
    }

    if (!payment.compensationDate) {
      errors.compensationDate = true;
    } else {
      if (!moment(payment.compensationDate).isValid())
        errors.compensationDate = true;
    }

    if (!payment.paymentType) errors.paymentType = true;

    return errors;
  };

  const table = [
    {
      title: "Pacela",
      value:
        feeAndPenalty.parcelNumber === "entrance"
          ? "Entrada"
          : feeAndPenalty.parcelNumber,
    },
    // { title: "Valor da parcela", value: formataMoedaReal(feeAndPenalty) },
    // {
    //   title: "Data de vencimento",
    //   value: moment(parcela.expiration).format("DD/MM/YYYY"),
    // },
    { title: "Dias atraso", value: feeAndPenalty.daysOfDelay },
    {
      title: "Multa atraso",
      value: formataMoedaReal(feeAndPenalty.penaltyValue),
    },
    {
      title: "Juros Mora",
      value: formataMoedaReal(feeAndPenalty.defaultInterestValue),
    },
    { title: "Valor total", value: formataMoedaReal(feeAndPenalty.total) },
  ];

  const renderTable = isLoading ? (
    [...Array(6)].map((_, index) => (
      <Skeleton
        key={index}
        variant="rect"
        animation="wave"
        style={{ marginTop: "8px" }}
      />
    ))
  ) : (
    <Container>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>R$</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );

  const renderForm = (
    <Grid container spacing={2} style={{ marginTop: "16px" }}>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            invalidDateMessage="Data em formato inválido."
            label="Data de pagamento"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={payment.date}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={(date) => setPayment({ ...payment, date })}
            error={errors.date}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            invalidDateMessage="Data em formato inválido."
            label="Data de compensação"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={payment.compensationDate}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={(date) =>
              setPayment({ ...payment, compensationDate: date })
            }
            error={errors.compensationDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Meio de Pagamento</InputLabel>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              label="Meio de pagamento"
              value={payment.paymentType}
              onChange={(e) =>
                setPayment({ ...payment, paymentType: e.target.value })
              }
              error={errors.paymentType}
              style={{ flex: 1 }}
            >
              <MenuItem value="">Nenhum</MenuItem>
              {paymentTypeList.length
                ? paymentTypeList.map((tipo) => (
                    <MenuItem key={tipo._id} value={tipo}>
                      {tipo.tipo}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <IconButton
              color="primary"
              title="Cadastrar Meio de Pagamento"
              onClick={() =>
                history.push("/configuration/financeiro/tiposPagamento")
              }
            >
              <Add />
            </IconButton>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Recebimento de parcela</DialogTitle>
      <DialogContent>
        {renderTable}
        {renderForm}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
