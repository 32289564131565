import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { css } from "aphrodite/no-important";
import { styles } from "./OdontogramFormStyles";
import ReactTooltip from "react-tooltip";

import { getDentalStatus } from "../../../actions/dentalStatus";
import {
  getFurcation,
  updateDentalFurcation,
} from "../../../actions/furcationActions.js";

import Modal from "../../modals/Modal";

import { furcaConst } from "../../../components/_constants/furcaConst.js";
import IconDentalStatus from "../../common/IconDentalStatus";

import ModalDentalStatus from "./dentalModal/ModalDentalStatus";

class FurcaForm extends Component {
  constructor(props) {
    super(props);

    this.onClickTooth = this.onClickTooth.bind(this);
    this.onClickStatus = this.onClickStatus.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      modal: false,
      furcation: null,
      toothSelected: null,
    };
  }

  componentWillMount() {
    const { furcaConst, selectedPatient, getFurcation } = this.props;

    var furcation;

    if (selectedPatient) {
      getFurcation(selectedPatient._id, (ret) => {
        if (ret) {
          this.setState({
            furcation: ret,
          });
        }
      });
    }

    furcation = furcaConst();
    furcation = furcation.furcation;

    this.setState({
      furcation: furcation,
    });
  }

  openModal() {
    this.setState({
      modal: true,
    });
  }

  closeModal() {
    this.setState({
      modal: false,
    });
  }

  onClickTooth(res) {
    this.setState({
      toothSelected: res.currentTarget.id,
    });
  }

  onClickStatus(res) {
    const { selectedPatient, updateDentalFurcation } = this.props;

    var split = this.state.toothSelected.split("_");
    var dente = split[0] + "_" + split[1];
    var face = this.state.toothSelected.split("_")[2];

    this.state.furcation[dente].face[face] = res;

    updateDentalFurcation(this.state.furcation, selectedPatient._id, (ret) => {
      this.setState({
        furcation: ret,
      });
    });
  }

  renderOdontogram() {
    return (
      <div className={css(styles.status_odontogram)}>
        <div className={css(styles.status_top)}>
          <div className={css(styles.status_top_left)}>
            <div
              id={"tooth_18_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"18"} </span>
              <IconDentalStatus
                number={"18"}
                tooth={this.state.furcation.tooth_18}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_18.face.distal}</span>
            </div>
            <div
              id={"tooth_17_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"17"} </span>
              <IconDentalStatus
                number={"17"}
                tooth={this.state.furcation.tooth_17}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_17.face.distal}</span>
            </div>
            <div
              id={"tooth_16_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"16"} </span>
              <IconDentalStatus
                number={"16"}
                tooth={this.state.furcation.tooth_16}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_16.face.distal}</span>
            </div>
            <div
              id={"tooth_15_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"15"} </span>
              <IconDentalStatus
                number={"15"}
                tooth={this.state.furcation.tooth_15}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_15.face.distal}</span>
            </div>
            <div
              id={"tooth_14_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"14"} </span>
              <IconDentalStatus
                number={"14"}
                tooth={this.state.furcation.tooth_14}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_14.face.distal}</span>
            </div>
          </div>

          <div className={css(styles.furca_top_right)}>
            <div
              id={"tooth_24_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"24"} </span>
              <IconDentalStatus
                number={"24"}
                tooth={this.state.furcation.tooth_24}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_24.face.distal}</span>
            </div>
            <div
              id={"tooth_25_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"25"} </span>
              <IconDentalStatus
                number={"25"}
                tooth={this.state.furcation.tooth_25}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_25.face.distal}</span>
            </div>
            <div
              id={"tooth_26_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"26"} </span>
              <IconDentalStatus
                number={"26"}
                tooth={this.state.furcation.tooth_26}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_26.face.distal}</span>
            </div>
            <div
              id={"tooth_27_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"27"} </span>
              <IconDentalStatus
                number={"27"}
                tooth={this.state.furcation.tooth_27}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_27.face.distal}</span>
            </div>
            <div
              id={"tooth_28_distal"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"28"} </span>
              <IconDentalStatus
                number={"28"}
                tooth={this.state.furcation.tooth_28}
              />
              <span className={css(styles.statusNumberBot)}>Distal</span>
              <span>{this.state.furcation.tooth_28.face.distal}</span>
            </div>
          </div>
        </div>

        <div className={css(styles.status_top)}>
          <div className={css(styles.status_top_left)}>
            <div
              id={"tooth_18_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"18"} </span>
              <IconDentalStatus
                number={"18"}
                tooth={this.state.furcation.tooth_18}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_18.face.mesial}</span>
            </div>
            <div
              id={"tooth_17_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"17"} </span>
              <IconDentalStatus
                number={"17"}
                tooth={this.state.furcation.tooth_17}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_17.face.mesial}</span>
            </div>
            <div
              id={"tooth_16_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"16"} </span>
              <IconDentalStatus
                number={"16"}
                tooth={this.state.furcation.tooth_16}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_16.face.mesial}</span>
            </div>
          </div>

          <div className={css(styles.furca_top_right)}>
            <div
              id={"tooth_26_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"26"} </span>
              <IconDentalStatus
                number={"26"}
                tooth={this.state.furcation.tooth_26}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_26.face.mesial}</span>
            </div>
            <div
              id={"tooth_27_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"27"} </span>
              <IconDentalStatus
                number={"27"}
                tooth={this.state.furcation.tooth_27}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_27.face.mesial}</span>
            </div>
            <div
              id={"tooth_28_mesial"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"28"} </span>
              <IconDentalStatus
                number={"28"}
                tooth={this.state.furcation.tooth_28}
              />
              <span className={css(styles.statusNumberBot)}>Mesial</span>
              <span>{this.state.furcation.tooth_28.face.mesial}</span>
            </div>
          </div>
        </div>

        <div className={css(styles.status_top)}>
          <div className={css(styles.status_top_left)}>
            <div
              id={"tooth_18_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"18"} </span>
              <IconDentalStatus
                number={"18"}
                tooth={this.state.furcation.tooth_18}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_18.face.vestibular}</span>
            </div>
            <div
              id={"tooth_17_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"17"} </span>
              <IconDentalStatus
                number={"17"}
                tooth={this.state.furcation.tooth_17}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_17.face.vestibular}</span>
            </div>
            <div
              id={"tooth_16_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"16"} </span>
              <IconDentalStatus
                number={"16"}
                tooth={this.state.furcation.tooth_16}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_16.face.vestibular}</span>
            </div>
          </div>

          <div className={css(styles.furca_top_right)}>
            <div
              id={"tooth_26_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"26"} </span>
              <IconDentalStatus
                number={"26"}
                tooth={this.state.furcation.tooth_26}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_26.face.vestibular}</span>
            </div>
            <div
              id={"tooth_27_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"27"} </span>
              <IconDentalStatus
                number={"27"}
                tooth={this.state.furcation.tooth_27}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_27.face.vestibular}</span>
            </div>
            <div
              id={"tooth_28_vestibular"}
              className={css(styles.listItemStatusTop, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"28"} </span>
              <IconDentalStatus
                number={"28"}
                tooth={this.state.furcation.tooth_28}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_28.face.vestibular}</span>
            </div>
          </div>
        </div>

        <div className={css(styles.status_bot)}>
          <div className={css(styles.status_bot_left)}>
            <div
              id={"tooth_48_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"48"} </span>
              <IconDentalStatus
                number={"48"}
                tooth={this.state.furcation.tooth_48}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_48.face.vestibular}</span>
            </div>
            <div
              id={"tooth_47_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"47"} </span>
              <IconDentalStatus
                number={"47"}
                tooth={this.state.furcation.tooth_47}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_47.face.vestibular}</span>
            </div>
            <div
              id={"tooth_46_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"46"} </span>
              <IconDentalStatus
                number={"46"}
                tooth={this.state.furcation.tooth_46}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_46.face.vestibular}</span>
            </div>
          </div>

          <div className={css(styles.furca_bot_right)}>
            <div
              id={"tooth_36_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"36"} </span>
              <IconDentalStatus
                number={"36"}
                tooth={this.state.furcation.tooth_36}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_36.face.vestibular}</span>
            </div>
            <div
              id={"tooth_37_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"37"} </span>
              <IconDentalStatus
                number={"37"}
                tooth={this.state.furcation.tooth_37}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_37.face.vestibular}</span>
            </div>
            <div
              id={"tooth_38_vestibular"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"38"} </span>
              <IconDentalStatus
                number={"38"}
                tooth={this.state.furcation.tooth_38}
              />
              <span className={css(styles.statusNumberBot)}>Vestibular</span>
              <span>{this.state.furcation.tooth_38.face.vestibular}</span>
            </div>
          </div>
        </div>
        <div className={css(styles.status_bot)}>
          <div className={css(styles.status_bot_left)}>
            <div
              id={"tooth_48_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"48"} </span>
              <IconDentalStatus
                number={"48"}
                tooth={this.state.furcation.tooth_48}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_48.face.lingual}</span>
            </div>
            <div
              id={"tooth_47_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"47"} </span>
              <IconDentalStatus
                number={"47"}
                tooth={this.state.furcation.tooth_47}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_47.face.lingual}</span>
            </div>
            <div
              id={"tooth_46_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"46"} </span>
              <IconDentalStatus
                number={"46"}
                tooth={this.state.furcation.tooth_46}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_46.face.lingual}</span>
            </div>
          </div>

          <div className={css(styles.furca_bot_right)}>
            <div
              id={"tooth_36_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"36"} </span>
              <IconDentalStatus
                number={"36"}
                tooth={this.state.furcation.tooth_36}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_36.face.lingual}</span>
            </div>
            <div
              id={"tooth_37_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"37"} </span>
              <IconDentalStatus
                number={"37"}
                tooth={this.state.furcation.tooth_37}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_37.face.lingual}</span>
            </div>
            <div
              id={"tooth_38_lingual"}
              className={css(styles.listItemStatusBot, styles.w70)}
              data-tip
              data-event="click"
              onMouseUp={this.onClickTooth}
            >
              <span className={css(styles.statusNumberTop)}> {"38"} </span>
              <IconDentalStatus
                number={"38"}
                tooth={this.state.furcation.tooth_38}
              />
              <span className={css(styles.statusNumberBot)}>Lingual</span>
              <span>{this.state.furcation.tooth_38.face.lingual}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.furcation) {
      return (
        <div className={css(styles.flex)}>
          <form className={css(styles.form2)}>
            <div className={css(styles.sectionStatus)}>
              <div className={css(styles.flex, styles.flex2)}>
                {this.renderOdontogram(this.state.furcation)}
              </div>
            </div>
            <ReactTooltip
              place="left"
              globalEventOff="click"
              effect="solid"
              className={css(styles.pointer)}
              offset={{ top: 10, left: -15 }}
            >
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickStatus(0);
                }}
              >
                Remover
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickStatus(1);
                }}
              >
                Nível 1
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickStatus(2);
                }}
              >
                Nível 2
              </div>
              <div
                className={css(styles.btn_modal)}
                onClick={() => {
                  this.onClickStatus(3);
                }}
              >
                Nível 3
              </div>
            </ReactTooltip>

            <Modal
              isOpen={this.state.modal}
              header={"Editar Todos"}
              adjustStyle={styles.modalEdit}
            >
              <ModalDentalStatus
                onCancel={this.closeModal}
                onClickStatus={this.onClickStatus}
              />
            </Modal>
          </form>
        </div>
      );
    }
  }
}

// Redux Form function to handle form validation
function validate(values) {
  const errors = {};

  return errors;
}

const furcaForm = reduxForm({
  validate,
  form: "furcaForm",
})(FurcaForm);

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;
  let initialValues = {};

  if (selectedPatient) {
    initialValues = selectedPatient;
  }

  return {
    selectedPatient: state.patientsCreation.selectedPatient,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  getFurcation,
  getDentalStatus,
  updateDentalFurcation,
  furcaConst,
})(furcaForm);
