import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// Componente
import ServicosConfigServico from "./ServicosConfigServico";
import ServicosCadastro from "./ServicosCadastro";
import ServicosConfigTabela from "./ServicosConfigTabela";
import ServicosCadastroTabela from "./ServicosCadastroTabela";
import ServicosConfigTabelaRelacao from "./ServicosConfigTabelaRelacao";
import ServicosVinculaTabela from "./ServicosVinculaTabela";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    minHeight: "83vh",
  },
}));

function ServicosConfig(props) {
  useEffect(() => {
    const { tab, trocarTab } = props;

    const tabSelecionada = tab.lista.filter(
      (tab) => tab.url === "configuracoes",
    )[0];

    if (tabSelecionada.numero !== tab.tabAtiva) {
      trocarTab(tabSelecionada.numero);
    }
  });

  const classes = useStyles();
  const { match } = props;

  return (
    <Paper className={classes.paper}>
      {match.isExact && (
        <List
          component="nav"
          className={classes.root}
          aria-label="Configurações"
        >
          <ListItem
            onClick={() => props.history.push(`${match.url}/tabelaServico`)}
            button
          >
            <ListItemText primary="Cadastro de tabela" />
          </ListItem>
          <Divider />
          <ListItem
            button
            divider
            onClick={() => props.history.push(`${match.url}/servico`)}
            button
          >
            <ListItemText primary="Cadastro de serviço" />
          </ListItem>
        </List>
      )}
      <Switch>
        <Route
          exact
          path={`${match.url}/servico`}
          render={(props) => <ServicosConfigServico {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarServico`}
          render={(props) => <ServicosCadastro {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarTabela`}
          render={(props) => <ServicosCadastroTabela {...props} />}
        />
        <Route
          exact
          path={`${match.url}/tabelaServico`}
          render={(props) => <ServicosConfigTabela {...props} />}
        />
        <Route
          exact
          path={`${match.url}/tabelaRelacao/:tabela_id`}
          render={(props) => <ServicosConfigTabelaRelacao {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarTabela/:tabela_id?`}
          render={(props) => <ServicosCadastroTabela {...props} />}
        />
        <Route
          exact
          path={`${match.url}/vincularServico/:tabela_id`}
          render={(props) => <ServicosVinculaTabela {...props} />}
        />
        <Route
          exact
          path={`${match.url}/cadastrarServico/:servico_id?`}
          render={(props) => <ServicosCadastro {...props} />}
        />
      </Switch>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ServicosConfig);
