import axios from "axios";

export async function buscarOrcamentos() {
  try {
    const result = await axios.get();
  } catch (err) {
    console.log(err);
  }
}

export async function editarTratamento(orcamento) {
  try {
    const result = await axios.put(
      `/api/treatments/budgets/${orcamento._id}`,
      orcamento,
    );
  } catch (err) {
    console.log(err);
  }
}

export const buscarTodosProcedimentos = async () => {
  try {
    const result = await axios.get("/api/treatment/procedures/byDentists");
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProcedimento = async (id) => {
  try {
    const result = await axios.get(`/api/treatment/procedures/${id}`);
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const criarPagamentoProcedimento = async (pagamento) => {
  try {
    const result = await axios.patch(
      `/api/treatment/procedures/payments/create/${pagamento.id}`,
      pagamento,
    );
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export async function alterarConclusaoProcedimento(procedimento) {
  try {
    const result = await axios.patch(
      `/api/treatment/procedures/conclusao/edit`,
      { procedimento },
    );
    return result.data;
  } catch (err) {
    console.log(err);
  }
}
