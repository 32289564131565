import { IconButton } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { buscarServicos } from "../../../../api/proteticos";
import { formataMoedaReal } from "../../../../helpers/Helpers";
import moment from "moment";
import TableComponent from "../../../table/TableComponent";

function ProteticosServicos({ match, history }) {
  const [servicos, setServicos] = useState([]);
  const colunas = [
    {
      label: "Data Solicitação",
      name: "servico.dataSolicitacao",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    { label: "Serviço", name: "tabela.descricao" },
    { label: "Protético", name: "nome" },
    { label: "Dentista", name: "dentista.name" },
    {
      label: "Paciente",
      name: "paciente",
      options: {
        customBodyRender: (value) => {
          return value && value.name + " " + value.lastName;
        },
      },
    },
    {
      label: "Data Prevista",
      name: "servico.dataPrevista",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: "Data Entrega",
      name: "servico.dataRetorno",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: "Data Quitação",
      name: "servico.dataPagamento",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: "Valor",
      name: "servico.valor",
      options: {
        customBodyRender: (value) => value && formataMoedaReal(value),
      },
    },
    { label: "Status", name: "servico.concluido" },
    {
      label: "Funções",
      name: "servico._id",
      options: {
        customBodyRender: (value) => (
          <IconButton onClick={() => editarServico(value)}>
            <Edit />
          </IconButton>
        ),
      },
    },
  ];
  const options = {
    customToolbar: () => {
      return (
        <IconButton onClick={inserirServicoNovo}>
          <Add />
        </IconButton>
      );
    },
  };

  useEffect(() => {
    const buscarDados = async () => {
      const dados = await buscarServicos();
      setServicos(dados);
    };
    buscarDados();
  }, []);

  const editarServico = (id) => {
    history.push(`${match.url}/${id}`);
  };

  const inserirServicoNovo = () => {
    history.push(`${match.url}/novo`);
  };

  return (
    <>
      <TableComponent
        colunas={colunas}
        objetos={servicos}
        otherOptions={options}
        titulo="Serviços protéticos"
      />
    </>
  );
}

export default ProteticosServicos;
