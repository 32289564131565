import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import LogIn from "./views/Login";
import Dashboard from "./views/Dashboard";
import RememberPass from "./views/RememberPass";
import ChangePass from "./views/ChangePass";

import RequireAuth from "./hoc/requireAuth";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { COLORS } from "./_constants/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
  },
});

// 'App' will manage redux and routes
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LogIn} />
          <Route path="/resetPwdForm" component={ChangePass} />
          <Route path="/rememberpass" component={RememberPass} />
          <Route path="/" component={RequireAuth(Dashboard)} />
          <Route component={() => <h1>404</h1>} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
