import React, { Component } from "react";
import { connect } from "react-redux";
import { deletarStatusItem, listarStatusItem } from "../../../../api/pacientes";
import { editPatient } from "../../../../actions/patientsSearch";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";

class PacientesConfigStatusItem extends Component {
  state = {
    statusItem: [],
  };

  deletaStatusItem(objeto) {
    deletarStatusItem(objeto);
  }

  componentWillMount() {
    listarStatusItem(this.props.match.params.status_id)
      .then((arrItens) => {
        var listaStatusItens = [];

        arrItens.length > 0 &&
          arrItens.map((item, index) => {
            var novoItem = {};

            novoItem.titulo = item.titulo;
            novoItem.status_id = item.status_id;
            novoItem.statusItem_id = item._id;
            novoItem.funcoes = [
              <Edit
                key={index}
                onClick={() =>
                  this.props.history.push({
                    pathname: `/pacientes/config/cadastrarStatusItem/${item._id}`,
                    state: { status_id: item.status_id },
                  })
                }
              />,
            ];

            listaStatusItens.push(novoItem);
          });

        this.setState({ statusItem: listaStatusItens });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const status_id = this.props.match.params.status_id;

    const titulo = "Status item",
      colunas = [
        { label: "Status Item", name: "titulo" },
        { label: "Id Status", name: "status_id" },
        { label: "", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.statusItem,
      inserir = {
        nome: "Cadastrar Item",
        link: {
          pathname: "/pacientes/config/cadastrarStatusItem",
          state: { status_id: status_id },
        },
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaStatusItem.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editPatient })(
  PacientesConfigStatusItem,
);
