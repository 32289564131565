import axios from "axios";

//Listas Dentistas
export async function buscarDentistas() {
  var dentistas = await axios
    .post("/api/dentistas/listar")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return dentistas;
}

//Cadastrar Dentista
export async function cadastrarDentista(clinicaId, objeto) {
  var obj = [];
  obj.push(objeto.especialidade);
  var dentista = await axios
    .post("/api/dentistas/cadastrarDentista", {
      clinic_id: clinicaId._id,
      especialidades: obj,
      codigo: objeto.codigo,
      name: objeto.name,
      adress: objeto.adress,
      city: objeto.city,
      uf: objeto.uf,
      cep: objeto.cep,
      cpf: objeto.cpf,
      rg: objeto.rg,
      cro: objeto.cro,
      partic: objeto.partic,
      birthday: objeto.birthday,
      telephone: objeto.telephone,
      email: objeto.email,
      schedule: objeto.schedule,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return dentista;
}

// Buscar dentista
export async function buscarDentista(clinicaId, dentistaId) {
  var dentista = await axios
    .post("/api/dentistas/buscarDentista", {
      dentista_id: dentistaId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return dentista;
}

// Buscar categoria dentista
export async function buscarCategoriaDentista(clinic_id, dentista_id) {
  var status = await axios
    .post("/api/dentistas/buscarCategoriaDentista", {
      clinic_id: clinic_id,
      dentista_id: dentista_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Salvar uma anotacao
export async function salvarAnotacao(dentista_id, texto) {
  var anotacao = await axios
    .post("/api/dentistas/adicionarAnotacao", {
      dentista_id: dentista_id,
      anotacao: texto,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return anotacao;
}

// Buscar especialidade dentista
export async function buscarEspecialidadeDentista(clinic_id, dentista_id) {
  var status = await axios
    .post("/api/dentistas/buscarEspecialidadeDentista", {
      clinic_id: clinic_id,
      dentista_id: dentista_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar categorias
export async function listarCategoriaDentista(clinic_id) {
  var status = await axios
    .post("/api/dentistas/listarCategoriaDentista", {
      clinic_id: clinic_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Editar dentista
export async function editarDentista(objDentista) {
  var dentista = await axios
    .put("/api/dentistas/editarDentista", {
      objDentista,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return dentista;
}

// Excluir Dentista
export async function deletarDentista(dentistaId) {
  var dentista = await axios
    .delete(`/api/dentistas/deletarDentista/${dentistaId}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return dentista;
}

// Listar Categorias
export async function buscarCategorias(clinicaId) {
  var categorias = await axios
    .post("/api/dentistas/listarCategoria", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return categorias;
}

// Cadastrar categoria
export async function cadastrarCategoria(clinicaId, categoria) {
  var categoria = await axios
    .post("/api/dentistas/cadastrarCategoria", {
      clinic_id: clinicaId,
      titulo: categoria,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return categoria;
}

// Buscar categoria
export async function buscarCategoria(clinicaId, categoriaId) {
  var categoria = await axios
    .post("/api/dentistas/buscarCategoria", {
      categoria_id: categoriaId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return categoria;
}

// Editar categoria
export async function editarCategoria(objCategoria) {
  var categoria = await axios
    .put("/api/dentistas/editarCategoria", {
      objCategoria,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return categoria;
}

// Excluir categoria
export async function deletarCategoria(categoriaId) {
  var categoria = await axios
    .delete(`/api/dentistas/deletarCategoria/${categoriaId}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return categoria;
}

// Desvincular categoria no cadastro dentista
export async function desvincularCategoria(dentista_id, objCategoria) {
  var objRemove = {};
  var arrCategoria = [];

  objRemove.dentista_id = dentista_id;

  objCategoria.map((categoria) => {
    arrCategoria.push(categoria[0]._id);
  });

  objRemove.categorias = arrCategoria;

  var status = await axios
    .post("/api/dentistas/removerCategoriaDentista", objRemove)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Vincular categoria no cadastro dentista
export async function adicionarCategoria(dentista_id, objCategoria) {
  var status = await axios
    .post("/api/dentistas/adicionarCategoriaDentista", {
      dentista_id: dentista_id,
      categorias: [objCategoria.categorias],
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar Especialidades
export async function buscarEspecialidades(clinicaId) {
  var especialidades = await axios
    .post("/api/dentistas/listarEspecialidade", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return especialidades;
}

// Cadastrar especialidade
export async function cadastrarEspecialidade(clinicaId, especialidade) {
  var especialidade = await axios
    .post("/api/dentistas/cadastrarEspecialidade", {
      clinic_id: clinicaId,
      titulo: especialidade,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return especialidade;
}

// Buscar especialidade
export async function buscarEspecialidade(clinicaId, especialidadeId) {
  var especialidade = await axios
    .post("/api/dentistas/buscarEspecialidade", {
      especialidade_id: especialidadeId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return especialidade;
}

// Editar especialidade
export async function editarEspecialidade(objEspecialidade) {
  var especialidade = await axios
    .put("/api/dentistas/editarEspecialidade", {
      objEspecialidade,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return especialidade;
}

// Excluir especialidade
export async function deletarEspecialidade(especialidadeId) {
  var especialidade = await axios
    .delete(`/api/dentistas/deletarEspecialidade/${especialidadeId}`)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return especialidade;
}

// Desvincular especialidade no cadastro dentista
export async function desvincularEspecialidade(dentista_id, objEspecialidade) {
  var objRemove = {};
  var arrEspecialidade = [];

  objRemove.dentista_id = dentista_id;

  objEspecialidade.map((especialidade) => {
    arrEspecialidade.push(especialidade[0]._id);
  });

  objRemove.especialidades = arrEspecialidade;

  var status = await axios
    .post("/api/dentistas/removerEspecialidadeDentista", objRemove)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Vincular especialidade no cadastro dentista
export async function adicionarEspecialidade(dentista_id, objEspecialidade) {
  console.log(dentista_id);
  var status = await axios
    .post("/api/dentistas/adicionarEspecialidadeDentista", {
      dentista_id: dentista_id,
      especialidades: [objEspecialidade.especialidades],
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}
