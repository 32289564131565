import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  inserirItemTabelaPreco,
  buscarItemTabelaPreco,
  editarItemTabelaPreco,
} from "../../../../api/proteticos";
import NumberFormat from "react-number-format";

function FormTabelaPrecoProtetico({ match, history }) {
  const [form, setForm] = useState({
    descricao: "",
    valor: "",
    codigo: "",
    ativo: true,
  });
  const [formErrors, setFormErrors] = useState({
    descricao: false,
    valor: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.tabelapreco_id) {
      buscarItemTabelaPreco(match.params.tabelapreco_id, (res) => {
        setForm(res.item);
      });
    }
  }, []);

  const onSubmit = () => {
    const errors = validarCampos();
    if (errors) {
      return setFormErrors(errors);
    }
    setLoading(true);
    const itemSalvar = {
      proteticoId: match.params.protetico_id,
      item: form,
    };
    if (itemSalvar.item._id) {
      editarItemTabelaPreco(itemSalvar, (res) => {
        setLoading(false);
        history.goBack();
      });
    } else {
      inserirItemTabelaPreco(itemSalvar, (res) => {
        setLoading(false);
        history.goBack();
      });
    }
  };

  const validarCampos = () => {
    const errors = {};
    if (!form.descricao) errors.descricao = true;
    if (!form.valor) errors.valor = true;
    if (Object.keys(errors).length !== 0) {
      return errors;
    }
    return false;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ padding: "24px", maxWidth: "600px" }}>
        <Typography variant="h6">Serviço de tabela</Typography>
        <TextField
          fullWidth
          label="Código"
          value={form.codigo}
          onChange={(e) => setForm({ ...form, codigo: e.target.value })}
        />
        <TextField
          error={formErrors.descricao}
          fullWidth
          label="Descrição"
          value={form.descricao}
          onChange={(e) => setForm({ ...form, descricao: e.target.value })}
        />
        <NumberFormat
          label="Valor"
          error={formErrors.valor}
          required
          fullWidth
          customInput={TextField}
          prefix="R$"
          value={form.valor}
          onValueChange={(e) => setForm({ ...form, valor: e.floatValue })}
          thousandSeparator="."
          decimalSeparator=","
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={form.ativo}
              color="primary"
              onChange={(e) => setForm({ ...form, ativo: e.target.checked })}
            />
          }
          label="Ativo"
        />
        <div style={{ marginTop: "24px", textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FormTabelaPrecoProtetico;
