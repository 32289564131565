import React, { Component } from "react";
import { connect } from "react-redux";

import TableComponent from "../../../table/TableComponent";
//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import { deletarConvenio, buscarConvenios } from "../../../../api/convenios";
import { IconButton } from "@material-ui/core";

class ConveniosListagem extends Component {
  state = {
    convenios: [],
  };
  deletaConvenio(objeto) {
    const { clinica } = this.props;
    deletarConvenio(objeto, clinica._id);
  }

  componentWillMount() {
    buscarConvenios(this.props.clinica._id).then((convenios) => {
      var listaConvenios = [];
      convenios.length > 0 &&
        convenios.map((convenio, index) => {
          var novo = {};
          novo.codigo = convenio.codigo;
          novo.convenioId = convenio._id;
          novo.nomeConvenio = convenio.nomeConvenio;
          novo.indice = convenio.indice;
          novo.funcoes = [
            <IconButton
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/convenios/cadastrarConvenio/${convenio._id}`,
                )
              }
            >
              <Edit />
            </IconButton>,
          ];
          listaConvenios.push(novo);
        });
      this.setState({ convenios: listaConvenios });
    });
  }
  render() {
    const titulo = "Convênios",
      colunas = [
        { label: "Codigo", name: "codigo" },
        { label: "Nome Convenio", name: "nomeConvenio" },
        { label: "Índice (%)", name: "indice" },
        {
          label: "Funções",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.convenios,
      inserir = {
        nome: "Cadastrar Convenio",
        link: "cadastrarConvenio",
      };
    return (
      <TableComponent
        funcoes={{ deletar: this.deletaConvenio.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ConveniosListagem);
