export function defaultDentalStatus() {
  var DENTALSTATUS = {
    tooth_18: {
      status: "NORMAL",
    },
    tooth_17: {
      status: "NORMAL",
    },
    tooth_16: {
      status: "NORMAL",
    },
    tooth_15: {
      status: "NORMAL",
    },
    tooth_14: {
      status: "NORMAL",
    },
    tooth_13: {
      status: "NORMAL",
    },
    tooth_12: {
      status: "NORMAL",
    },
    tooth_11: {
      status: "NORMAL",
    },
    /////////////
    tooth_55: {
      status: "NORMAL",
    },
    tooth_54: {
      status: "NORMAL",
    },
    tooth_53: {
      status: "NORMAL",
    },
    tooth_52: {
      status: "NORMAL",
    },
    tooth_51: {
      status: "NORMAL",
    },
    ////////////
    tooth_21: {
      status: "NORMAL",
    },
    tooth_22: {
      status: "NORMAL",
    },
    tooth_23: {
      status: "NORMAL",
    },
    tooth_24: {
      status: "NORMAL",
    },
    tooth_25: {
      status: "NORMAL",
    },
    tooth_26: {
      status: "NORMAL",
    },
    tooth_27: {
      status: "NORMAL",
    },
    tooth_28: {
      status: "NORMAL",
    },
    ////////////
    tooth_61: {
      status: "NORMAL",
    },
    tooth_62: {
      status: "NORMAL",
    },
    tooth_63: {
      status: "NORMAL",
    },
    tooth_64: {
      status: "NORMAL",
    },
    tooth_65: {
      status: "NORMAL",
    },
    ///////////////
    tooth_48: {
      status: "NORMAL",
    },
    tooth_47: {
      status: "NORMAL",
    },
    tooth_46: {
      status: "NORMAL",
    },
    tooth_45: {
      status: "NORMAL",
    },
    tooth_44: {
      status: "NORMAL",
    },
    tooth_43: {
      status: "NORMAL",
    },
    tooth_42: {
      status: "NORMAL",
    },
    tooth_41: {
      status: "NORMAL",
    },
    //////////////
    tooth_85: {
      status: "NORMAL",
    },
    tooth_84: {
      status: "NORMAL",
    },
    tooth_83: {
      status: "NORMAL",
    },
    tooth_82: {
      status: "NORMAL",
    },
    tooth_81: {
      status: "NORMAL",
    },
    ////////////
    tooth_31: {
      status: "NORMAL",
    },
    tooth_32: {
      status: "NORMAL",
    },
    tooth_33: {
      status: "NORMAL",
    },
    tooth_34: {
      status: "NORMAL",
    },
    tooth_35: {
      status: "NORMAL",
    },
    tooth_36: {
      status: "NORMAL",
    },
    tooth_37: {
      status: "NORMAL",
    },
    tooth_38: {
      status: "NORMAL",
    },
    ////////////
    tooth_71: {
      status: "NORMAL",
    },
    tooth_72: {
      status: "NORMAL",
    },
    tooth_73: {
      status: "NORMAL",
    },
    tooth_74: {
      status: "NORMAL",
    },
    tooth_75: {
      status: "NORMAL",
    },
  };
  return { type: "DENTALSTATUS", dentalStatus: DENTALSTATUS };
}
