import React, { Component } from "react";
import { connect } from "react-redux";

import { css } from "aphrodite/no-important";
import { styles } from "./RememberPassStyles";
import { ToastContainer } from "react-toastify";
import ChangePassForm from "../forms/ChangePassForm";

// 'ChangePass' will manage fogrt password form
class ChangePass extends Component {
  constructor(props) {
    super(props);

    this.renderView = this.renderView.bind(this);
  }

  renderView() {
    return (
      <div className={css(styles.formContainer)}>
        <h3>Altere sua senha</h3>
        <p className={css(styles.msg)}>
          Redefina sua senha para acessar a plataforma agora mesmo!
        </p>
        <ChangePassForm />
      </div>
    );
  }

  render() {
    return (
      <div className={css(styles.grid)}>
        {this.renderView()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(ChangePass);
