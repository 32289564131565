import axios from "axios";

//Listas Proteticos
export async function buscarProteticos() {
  var proteticos = await axios
    .post("/api/proteticos/listar")
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return proteticos;
}

//Cadastrar proteticos

export async function cadastrarProtetico(clinicaId, objeto) {
  var protetico = await axios
    .post("/api/proteticos/cadastrarProtetico", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      nome: objeto.nome,
      address: objeto.address,
      city: objeto.city,
      uf: objeto.uf,
      cep: objeto.cep,
      cpf: objeto.cpf,
      rg: objeto.rg,
      cnpj: objeto.cnpj,
      inscEstadual: objeto.inscEstadual,
      telefone: objeto.telefone,
      email: objeto.email,
      site: objeto.site,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return protetico;
}

// Buscar protetico
export async function buscarProtetico(clinicaId, proteticoId) {
  var protetico = await axios
    .post("/api/proteticos/buscarProtetico", {
      protetico_id: proteticoId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return protetico;
}

export async function inserirItemTabelaPreco(item, callback) {
  try {
    const response = await axios.post(
      "/api/proteticos/inserirItemTabelaPreco",
      item,
    );
    callback((response.status = 200 && response.data));
  } catch (err) {
    console.log(err);
  }
}

export async function buscarItemTabelaPreco(item_id, callback) {
  try {
    const status = await axios.post("/api/proteticos/buscarItemTabelaPreco", {
      item_id,
    });
    callback(status.data);
  } catch (err) {
    console.log(err);
  }
}

export async function editarItemTabelaPreco(item, callback) {
  try {
    const response = await axios.post(
      "/api/proteticos/editarItemTabelaPreco",
      item,
    );
    callback((response.status = 200 && response.data));
  } catch (err) {
    console.log(err);
  }
}

// Buscar tabelaPreco proteticos
export async function buscarTabelaPrecoProtetico(protetico_id) {
  var status = await axios
    .post("/api/proteticos/buscarTabelaPrecoProtetico", {
      protetico_id: protetico_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar TabelaPreco
export async function listarTabelaPreco(clinic_id) {
  var status = await axios
    .post("/api/proteticos/listarTabelaPreco", {
      clinic_id: clinic_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Editar proteticos
export async function editarProtetico(objProtetico) {
  var protetico = await axios
    .put("/api/proteticos/editarProtetico", {
      objProtetico,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return protetico;
}

// Excluir protetico
export async function deletarProtetico(objProtetico, clinic_id) {
  objProtetico.clinic_id = clinic_id;

  var protetico = await axios
    .delete("/api/proteticos/deletarProtetico", {
      data: objProtetico,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return protetico;
}

// Listar tabelaPreco proteticos
export async function listarTabelaPrecoProteticos(clinic_id) {
  var status = await axios
    .post("/api/proteticos/listarTabelaPrecoProteticos", {
      clinic_id: clinic_id,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Listar TabelaPreco
export async function buscarTabelaPrecos(clinicaId) {
  var tabelaPrecos = await axios
    .post("/api/proteticos/listarTabelaPreco", {
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return tabelaPrecos;
}

// Cadastrar tabela preço
export async function cadastrarTabelaPreco(clinicaId, objeto) {
  var tabelaPreco = await axios
    .post("/api/proteticos/cadastrarTabelaPreco", {
      clinic_id: clinicaId._id,
      codigo: objeto.codigo,
      descricao: objeto.descricao,
      valorIndice: objeto.valorIndice,
      valor: objeto.valor,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return tabelaPreco;
}

// Buscar tabelaPreco
export async function buscarTabelaPreco(clinicaId, tabelaPrecoId) {
  var tabelaPreco = await axios
    .post("/api/proteticos/buscarTabelaPreco", {
      tabelaPreco_id: tabelaPrecoId,
      clinic_id: clinicaId,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return tabelaPreco;
}

// Editar Tabela Preco
export async function editarTabelaPreco(objTabelaPreco) {
  var tabelaPreco = await axios
    .put("/api/proteticos/editarTabelaPreco", {
      objTabelaPreco,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return tabelaPreco;
}

// Excluir TabelaPreco
export async function deletarTabelaPreco(objTabelaPreco, clinic_id) {
  objTabelaPreco.clinic_id = clinic_id;

  var protetico = await axios
    .delete("/api/proteticos/deletarTabelaPreco", {
      data: objTabelaPreco,
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return protetico;
}

// Desvincular TabelaPreco no cadastro protetico
export async function desvincularTabelaPreco(protetico_id, objTabelaPreco) {
  var objRemove = {};
  var arrTabelaPreco = [];

  objRemove.protetico_id = protetico_id;

  objTabelaPreco.map((tabelaPreco) => {
    arrTabelaPreco.push(tabelaPreco[0]._id);
  });

  objRemove.tabelaPrecos = arrTabelaPreco;

  var status = await axios
    .post("/api/proteticos/removerTabelaPrecoProtetico", objRemove)
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

// Vincular tabela preco no cadastro proteticos
export async function adicionarTabelaPreco(protetico_id, objTabelaPreco) {
  var status = await axios
    .post("/api/proteticos/adicionarTabelaPrecoProtetico", {
      protetico_id: protetico_id,
      tabelaPrecos: [objTabelaPreco.tabelaPrecos],
    })
    .then((response) => response.status === 200 && response.data)
    .catch((error) => console.log(error));

  return status;
}

export async function inserirServico(servico) {
  try {
    const dados = await axios.patch("/api/proteticos/servicos/inserir", {
      servico,
    });
    return dados.status;
  } catch (err) {
    console.log(err);
  }
}

export async function buscarServicos() {
  try {
    const dados = await axios.get(`/api/proteticos/servicos`);
    return dados.data;
  } catch (err) {
    console.log(err);
  }
}

export async function buscarServico(servicoId) {
  try {
    const response = await axios.get(`/api/proteticos/servicos/${servicoId}`);
    if (response.status === 200) return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function editarServico(servico) {
  try {
    const response = await axios.post("/api/proteticos/servicos/editar", {
      servico,
    });
    if (response.status === 200) return response.data;
  } catch (err) {
    console.log(err);
  }
}
