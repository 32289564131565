import React, { Component } from "react";
import { connect } from "react-redux";

import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import {
  buscarTabelaPrecos,
  adicionarTabelaPreco,
} from "../../../../api/proteticos";

class ProteticosCadastro extends Component {
  state = {
    formulario: [],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    protetico_id: "",
    tabelaPrecos: "",
    cadastro: true,
    await: true,
  };

  async componentWillMount() {
    const { clinica } = this.props;
    if (this.props.match.params.protetico_id) {
      var objTabelaPreco = await buscarTabelaPrecos(clinica._id);
      var formulario = this.state.formulario;

      var selectTabelaPreco = {
        type: "select",
        name: "tabelaPrecos",
        label: "Tabela Serviços",
        values: [],
      };
      objTabelaPreco.map((obj) => {
        var novo = {};
        novo.name = obj.descricao;
        novo.value = obj._id;
        selectTabelaPreco.values.push(novo);
      });

      formulario.push(selectTabelaPreco);
      this.setState({
        tabelaPrecos: objTabelaPreco,
        await: false,
        protetico_id: this.props.match.params.protetico_id,
        formulario: formulario,
      });
    } else {
      // this.setState({await:false});
    }
  }

  vinculaTabelaPreco(objeto) {
    const { protetico_id } = this.state;
    adicionarTabelaPreco(protetico_id, objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Servico vinculado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 1500);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível vincular o Servico!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  render() {
    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        {!this.state.await && (
          <FormComponent
            {...this.props}
            inputs={this.state.formulario}
            action={this.vinculaTabelaPreco.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ProteticosCadastro);
