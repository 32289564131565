import React, { Component } from "react";
import { connect } from "react-redux";
import FormComponent from "../../../form/FormComponent";
import Notificacao from "../../../notificacao/Notificacao";
import Paper from "@material-ui/core/Paper";
import {
  buscarProtetico,
  cadastrarProtetico,
  editarProtetico,
} from "../../../../api/proteticos";
import { toast } from "react-toastify";

class ProteticosCadastro extends Component {
  state = {
    formulario: [
      { type: "text", name: "codigo", label: "Código" },
      { type: "text", name: "nome", label: "Nome" },
      { type: "mask", name: "cep", label: "CEP", maskDesign: "99999-999" },
      { type: "text", name: "address", label: "Endereço Comercial" },
      { type: "text", name: "city", label: "Cidade" },
      { type: "text", name: "uf", label: "UF" },
      { type: "mask", name: "cpf", label: "CPF", maskDesign: "999.999.999-99" },
      { type: "mask", name: "rg", label: "RG" },
      { type: "number", name: "inscEstadual", label: "Inscrição Estadual" },
      {
        type: "mask",
        name: "cnpj",
        label: "CNPJ",
        maskDesign: "99.999.999/9999-99",
      },
      {
        type: "mask",
        name: "telefone",
        label: "Fone",
        maskDesign: "(99) 99999-9999",
      },
      { type: "text", name: "email", label: "E-mail" },
      { type: "text", name: "site", label: "Site" },
    ],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
    protetico: "",
    cadastro: true,
    await: true,
    buttonDisabled: false,
  };

  async componentWillMount() {
    const { clinica } = this.props;

    if (this.props.match.params.protetico_id) {
      var objProtetico = await buscarProtetico(
        clinica._id,
        this.props.match.params.protetico_id,
      );
      this.setState({ cadastro: false, protetico: objProtetico, await: false });
    } else {
      this.setState({ await: false });
    }
  }

  editaProtetico(objeto) {
    this.setState({ buttonDisabled: true });
    editarProtetico(objeto)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Protético editado com sucesso",
              abrir: true,
            },
          });
          setTimeout(() => this.props.history.goBack(), 3000);
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente não foi possível salvar o Protético!",
              abrir: true,
            },
          });
        }
      })
      .then(() => {
        this.setState({ buttonDisabled: false, alerta: { abrir: false } });
      });
  }

  salvarProtetico(objeto) {
    const { clinica, history } = this.props;

    this.setState({ buttonDisabled: true });
    cadastrarProtetico(clinica, objeto)
      .then((r) => {
        if (r) {
          toast.success("Protético cadastrado com sucesso");
          history.goBack();
        } else {
          toast.error("Infelizmente não foi possível salvar o protético");
        }
      })
      .then(() => {
        this.setState({ buttonDisabled: false, alerta: { abrir: false } });
      });
  }

  render() {
    return (
      <div>
        <Paper>
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
            />
          )}
          {!this.state.await && (
            <FormComponent
              {...this.props}
              inputs={this.state.formulario}
              objeto={this.state.protetico}
              disabledAction={this.state.buttonDisabled}
              action={
                this.state.cadastro
                  ? this.salvarProtetico.bind(this)
                  : this.editaProtetico.bind(this)
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ProteticosCadastro);
