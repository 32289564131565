import React, { useState, useEffect } from "react";
import { fetchInstallments } from "../../../api/financeiro";
import {
  Button,
  CircularProgress,

  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import TabelaFaturas from "./TabelaFaturas";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { formataMoedaReal } from "../../../helpers/Helpers";

export default function FinanceiroPaciente() {
  const [installments, setInstallments] = useState([]);
  const [balance, setBalance] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setDate(1);
      endDate.setDate(30);
      const paymentId = false;
      const result = await fetchInstallments({
        startDate,
        endDate,
        search: "all",
        paymentId,
      });
      setInstallments(result.data);
      setBalance(result.balance);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const buscarParcelas = async (startDate, endDate, search, paymentId) => {
    setIsLoading(true);
    const result = await fetchInstallments({
      startDate,
      endDate,
      search,
      paymentId,
    });
    setInstallments(result.data);
    setBalance(result.balance);
    setIsLoading(false);
  };

  console.log(installments);

  const start = new Date();
  const final = new Date();
  start.setDate(1);
  final.setDate(30);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(final);
  const [search, setSearch] = useState("all");
  const paymentId = false;

  const handleChange = () => {
    buscarParcelas(startDate, endDate, search, paymentId);
  };

  return (
    <Paper style={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Inicial"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Final"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              style={{ marginLeft: "12px" }}
              value={endDate}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="Filtrar faturas"
            variant="outlined"
            select
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="paid">Pagas</MenuItem>
            <MenuItem value="overdue">Vencidas</MenuItem>
            <MenuItem value="open">Abertas</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={1}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleChange}
          >
            Buscar
          </Button>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TabelaFaturas installments={installments} title={balance} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
