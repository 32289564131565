import React, { Component } from "react";
import { connect } from "react-redux";
import { deletarSituacao, buscarSituacoes } from "../../../../api/pacientes";
import { editPatient } from "../../../../actions/patientsSearch";
import { toast } from "react-toastify";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
class PacientesConfigSituacao extends Component {
  state = {
    situacoes: [],
  };

  async deletaSituacao(situacaoId) {
    const situacao = await deletarSituacao(situacaoId);
    if (situacao) {
      toast.success("Situação excluída com sucesso");
      this.findSituacao();
      return;
    }
    toast.error("Erro ao excluir situação");
  }

  findSituacao() {
    buscarSituacoes(this.props.clinica._id).then((situacoes) => {
      var listaSituacoes = [];

      situacoes.length > 0 &&
        situacoes.map((situacao, index) => {
          var novaSituacao = {};

          novaSituacao.titulo = situacao.titulo;
          novaSituacao.situacaoId = situacao._id;
          novaSituacao.funcoes = [
            <IconButton
              key={index}
              onClick={() =>
                this.props.history.push(
                  `${this.props.match.url}/${situacao._id}`
                )
              }
            >
              <EditIcon />
            </IconButton>,
            <IconButton>
              <DeleteIcon
                key={`delete-${index}`}
                onClick={() => this.deletaSituacao(situacao._id)}
              />
            </IconButton>,
          ];

          listaSituacoes.push(novaSituacao);
        });

      this.setState({ situacoes: listaSituacoes });
    });
  }
  componentWillMount() {
    this.findSituacao();
  }

  render() {
    const titulo = "Situações",
      colunas = [
        { label: "Titulo", name: "titulo" },
        {
          label: "Funções",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.situacoes,
      inserir = {
        nome: "Cadastrar Situação",
        link: `${this.props.match.url}/novo`,
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaSituacao.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editPatient })(
  PacientesConfigSituacao,
);
